import {PageWrapper} from 'components/PageWrapper';
import {Steps} from 'components/Steps';
import {useEffect, useState} from 'react';
import ReturnReview from './components/ReturnReview';
import ReturnStep1 from './components/ReturnStep1';
import ReturnStep2 from './components/ReturnStep2';
import ReturnStep3 from './components/ReturnStep3';
import ReturnStep4 from './components/ReturnStep4';
import {useHistory} from 'react-router-dom';
import {getRetailer} from 'core/models/retailers/utils';
import {dataLayerEvents} from 'utils/dataLayer';

const formLayout = {
  labelCol: {span: 6},
  wrapperCol: {span: 14}
};

export interface IReturnsCreateStep {
  prevStep: () => void;
  nextStep: () => void;
  formLayout: any;
}

function ReturnCreate() {
  const [step, setStep] = useState(0);
  const [maxAllowedStep, setMaxAllowedStep] = useState(0);
  const history = useHistory();
  const {retailer, isLoading} = getRetailer();

  useEffect(() => {
    if (!isLoading && retailer) {
      dataLayerEvents('view_returns_create', retailer);
    }
  }, [retailer, isLoading]);

  const nextStep = () => {
    setStep(step + 1);
    setMaxAllowedStep(step + 1);
  };

  const prevStep = () => {
    if (step === 0) {
      return history.goBack();
    }
    setStep(step - 1);
  };

  const onStepChange = (stepIndex: number) => {
    setStep(stepIndex);
  };

  const stepsContent = [
    {
      title: 'Basic info',
      content: <ReturnStep1 formLayout={formLayout} nextStep={nextStep} prevStep={prevStep} />
    },
    {
      title: 'Product',
      content: <ReturnStep2 formLayout={formLayout} nextStep={nextStep} prevStep={prevStep} />
    },
    {
      title: 'Upload files',
      content: <ReturnStep3 formLayout={formLayout} nextStep={nextStep} prevStep={prevStep} />
    },
    {
      title: 'Final notes',
      content: <ReturnStep4 formLayout={formLayout} nextStep={nextStep} prevStep={prevStep} />
    },
    {
      title: 'Review',
      content: <ReturnReview />
    }
  ];

  return (
    <PageWrapper pageTitle='Request a credit'>
      <Steps maxAllowedStep={maxAllowedStep} currentStep={step} onChange={onStepChange} steps={stepsContent} />
    </PageWrapper>
  );
}

export default ReturnCreate;
