import InfoCircleOutlined from '@ant-design/icons/lib/icons/InfoCircleOutlined';
import QuestionCircleOutlined from '@ant-design/icons/lib/icons/QuestionCircleOutlined';
import {Button, Tag, Tooltip} from 'antd';
import {IconColorEnum} from 'components/Icon';
import {PageWrapper} from 'components/PageWrapper';
import Table, {IColumn} from 'components/Table/Table';
import {IQueryEnum, makeQueries} from 'core/api';
import {IRetailer} from 'core/models/retailers/types';
import {getRetailer} from 'core/models/retailers/utils';
import {IUser, UserRolesEnum} from 'core/models/users/types';
import {pageUrls} from 'core/router/pages';
import {useEffect} from 'react';
import {CSVLink} from 'react-csv';
import {useHistory} from 'react-router-dom';
import {dataLayerEvents} from 'utils/dataLayer';
import {getUrl} from 'utils/getUrl';

interface IExtendedRetailer extends IRetailer {
  status: string;
  users: IUser[];
  salesManagerName: string;
}

interface IData {
  retailers: IRetailer[];
  users: IUser[];
}
type TKey = keyof IRetailer;

const Status = () => {
  const {retailer, isLoading: isRetailerLoading} = getRetailer();
  const history = useHistory();
  const RETAILER_SETTINGS = ['productBrandIds', 'productCategoryIds', 'billingAddressId', 'priceListId'];

  useEffect(() => {
    if (!isRetailerLoading && retailer) {
      dataLayerEvents('view_status', retailer);
    }
  }, [isRetailerLoading, retailer]);

  const {
    data: {retailers, users},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetRetailers},
    {type: IQueryEnum.GetUsers, variables: {roles: [UserRolesEnum.Admin, UserRolesEnum.Owner, UserRolesEnum.Sales]}}
  ]);

  /** Data Transformers */
  function getRetailerStatus(retailer: IRetailer) {
    const {productBrandIds, productCategoryIds, billingAddressId, priceListId} = retailer;
    const retailerUsers = getRetailerUsers(retailer);
    const isValidSettings = productBrandIds && productCategoryIds && billingAddressId && priceListId;
    return isValidSettings ? 'Valid' : retailerUsers?.length > 0 ? 'Invalid' : 'Warning';
  }

  function getRetailerUsers(retailer: IRetailer) {
    return users?.filter(user => user.role === UserRolesEnum.Owner && user.retailerIds?.includes(retailer.id));
  }

  function getRetailerSalesManagerName(retailer: IRetailer) {
    const salesManager = users?.find(u => u.id === retailer.salesManagerId);
    return salesManager ? `${salesManager?.firstName} ${salesManager?.lastName}` : 'Not Found';
  }

  /** Renderers */
  const getStatusErrors = (row: IRetailer) => {
    const retailerKeys = Object.keys(row)?.filter((i: string) => RETAILER_SETTINGS.includes(i) && row[i as TKey] === null);
    if (!retailerKeys.length) return;
    return (
      <>
        <p>This settings are missing</p>
        <ul>
          {retailerKeys.map((key: string) => (
            <li key={key}>{key}</li>
          ))}
        </ul>
      </>
    );
  };

  function statusRenderer(status: 'Valid' | 'Warning' | 'Invalid', retailer: IRetailer) {
    let color;
    switch (status) {
      case 'Valid':
        color = 'green';
        break;
      case 'Warning':
        color = 'orange';
        break;
      default:
        color = 'red';
    }

    return (
      <>
        <Tooltip placement="topRight" title={getStatusErrors(retailer)}>
          <Tag color={color}>{status}</Tag>
          {status !== 'Valid' && <InfoCircleOutlined style={{color: IconColorEnum.Red}} />}
        </Tooltip>
      </>
    );
  }

  /** Utils */
  function statusSort(retailerA: IExtendedRetailer, retailerB: IExtendedRetailer) {
    if (retailerA.status === 'Invalid') return -1;
    if (retailerA.status === 'Warning' && retailerB.status === 'Valid') return -1;
    return 1;
  }

  const onRetailerClick = (retailer: IExtendedRetailer) => () => {
    history.push(getUrl(pageUrls.retailerGeneral, {retailerId: retailer.id}));
  };

  function getCSVData() {
    const headers = [
      {label: 'Id', key: 'id'},
      {label: 'Name', key: 'name'},
      {label: 'Status', key: 'status'},
      {label: 'Price List', key: 'priceList'},
      {label: 'Billing Address', key: 'billingAddress'},
      {label: 'Has Product Categories', key: 'productCategories'},
      {label: 'Has ProductBrands', key: 'productBrands'},
      {label: 'Has Sales Manager', key: 'salesManagerName'}
    ];

    const csvData = retailers?.map(r => ({
      id: r.id,
      name: r.name,
      status: getRetailerStatus(r),
      priceList: Boolean(r.priceListId) ? 'Yes' : 'No',
      billingAddress: Boolean(r.billingAddressId) ? 'Yes' : 'No',
      productCategories: Boolean(r.productCategoryIds?.length > 0) ? 'Yes' : 'No',
      productBrands: Boolean(r.productBrandIds?.length > 0) ? 'Yes' : 'No',
      salesManagerName: getRetailerSalesManagerName(r)
    }));

    return {
      data: csvData,
      headers,
      filename: 'Retailer_Status_Report.csv'
    };
  }

  const columns: IColumn<IExtendedRetailer>[] = [
    {
      title: 'Retailer',
      dataIndex: 'name',
      width: '50%',
      render: (retailerName, row) => (
        <h4 className="cursor-pointer" onClick={onRetailerClick(row)}>
          {retailerName}
        </h4>
      )
    },
    {
      title: (
        <Tooltip
          placement="topRight"
          title={
            <ul>
              <li>Valid - Buying profile is complete</li>
              <li>Warning - Buying profile is missing settings</li>
            </ul>
          }
        >
          <span className="mr10">Settings</span>
          {<QuestionCircleOutlined style={{color: IconColorEnum.Black}} />}
        </Tooltip>
      ),
      dataIndex: 'status',
      render: statusRenderer,
      sorter: statusSort,
      defaultSortOrder: 'ascend',
      width: '15%'
    },
    {
      title: (
        <Tooltip placement="topRight" title={'If missing, Sales User does not have Fulfil ID set.'}>
          <span className="mr10">SalesManger</span>
          {<QuestionCircleOutlined style={{color: IconColorEnum.Black}} />}
        </Tooltip>
      ),
      dataIndex: 'salesManagerName',
      width: '45%'
    }
  ];

  const data: IExtendedRetailer[] = retailers?.map(r => ({
    users: getRetailerUsers(r),
    status: getRetailerStatus(r),
    salesManagerName: getRetailerSalesManagerName(r),
    ...r
  }));

  return (
    <PageWrapper
      pageTitle="Status page"
      actions={
        <Button type="primary">
          <CSVLink {...getCSVData()}>Export to CSV</CSVLink>
        </Button>
      }
    >
      <Table
        data={data}
        filterConfig={{fulltextParams: ['name', 'salesManagerName'], fulltextPlaceholder: 'Search for a retailer or sales manager'}}
        columns={columns}
        loading={isLoading}
      />
    </PageWrapper>
  );
};

export default Status;
