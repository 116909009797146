import {IAsset} from 'core/brandfolder/types';
import moment from 'moment';
import {memo} from 'react';
import {getClassName} from 'utils/getClassName';

interface IAssetListItemProps {
  asset: IAsset;
  activeAssetId?: string;
  handleAssetClick: (asset: IAsset) => () => void;
}

const AssetsListItem = memo(({asset, activeAssetId, handleAssetClick}: IAssetListItemProps) => {
  return (
    <li
      onClick={handleAssetClick(asset)}
      className={getClassName(
        activeAssetId === asset.id
          ? 'ring-2 ring-offset-2 ring-cyan-500'
          : 'focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-cyan-500',
        'relative bg-white px-1 py-2 rounded-md cursor-pointer flex flex-col justify-between'
      )}
    >
      <p className="mb-2 block text-xs font-medium text-gray-900 truncate pointer-events-none text-center">{asset.name}</p>
      <div className={'group block w-full aspect-w-10 aspect-h-7 overflow-hidden'}>
        <img
          src={asset.thumbnailUrl}
          alt={asset.name}
          className={getClassName(activeAssetId === asset.id ? '' : 'group-hover:opacity-50', 'object-cover rounded-lg mx-auto')}
        />
      </div>
      <p className="mt-2 text-xs font-medium truncate pointer-events-none flex justify-between 2xl: px-4">
        <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-indigo-100 text-indigo-800 mr-6">
          {asset.type}
        </span>
        <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
          {moment(asset?.updatedAt).format('MMMM DD')}<span className='hidden 2xl:block'>&nbsp;{moment(asset?.updatedAt).format('YYYY')}</span>
        </span>
      </p>
    </li>
  );
});

export default AssetsListItem;
