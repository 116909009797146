/* eslint-disable react-hooks/rules-of-hooks */
import constants from '../../constants';
import {useQuery} from 'react-query';
import assetsInstance from './brandfolderInstance';
import {IAsset, IAssets, IBrandfolderAsset} from './types';
import {convertAssets, convertAsset, IAssetFilter, createSearchString} from './utils';

export function getAssets(filter: IAssetFilter, page?: number | null, allowedBrands?: string[]) {
  return useQuery<IAssets>({
    enabled: Boolean(page),
    queryKey: ['assets', page, filter],
    cacheTime: 2000,
    staleTime: 2000,
    queryFn: async () => {
      const {data} = await assetsInstance.get<IBrandfolderAsset[]>(
        `/brandfolders/${
          constants.brandfolder.id
        }/assets?sort_by=updated_at&order=DESC&fields=name,thumbnail_url,cdn_url,updated_at&include=attachments,tags,custom_fields&per=12&page=${page}&search=${createSearchString(
          filter, allowedBrands
        )}`
      );
      return convertAssets(data);
    },
    refetchOnMount: true
  });
}

export function getAsset(assetId?: string) {
  return useQuery<IAsset>({
    enabled: Boolean(assetId),
    queryKey: ['asset', assetId],
    cacheTime: 2000,
    staleTime: 2000,
    queryFn: async () => {
      const {data} = await assetsInstance.get<IBrandfolderAsset[]>(
        `/assets/${assetId}?fields=name,thumbnail_url,cdn_url,updated_at&include=attachments,custom_fields`
      );
      return convertAsset(data);
    },
    refetchOnMount: true
  });
}
