/* eslint-disable react-hooks/exhaustive-deps */
import {Button, Col, Radio, Row, Image, List} from 'antd';
import {N} from 'components/N';
import AuthStore from 'core/auth';
import {OrderTypeEnum} from 'core/models/orders/types';
import {IProductItem} from 'core/models/productItems/types';
import {getSupplyText} from 'core/models/productItems/utils';
import {IProduct} from 'core/models/products/types';
import {getRetailer} from 'core/models/retailers/utils';
import {FC, useContext, useRef, useState, useEffect} from 'react';
import {actionEnum, dataLayerEcommerceActions} from 'utils/dataLayer';
import useIntersectionObserver from 'utils/useIntersectionObserver';
import constants from '../../../../../constants';
import {getPromoDiscount} from 'utils/promoHelper';
import {IPromoBuilder} from 'core/models/promoBuilder/types';
import {IGroup} from 'core/models/groups/types';
import moment from 'moment-timezone';
import {Buffer} from 'buffer';

export interface IProductListItem {
  product: IProduct;
  productItems: Map<string, IProductItem>;
  onAdd: (product: IProduct, productItem: IProductItem) => void;
  onProductDetailClick: (item: {product: IProduct; productItem: IProductItem}) => any;
  productBrand: string;
  productCategory: string;
  orderType: string;
  activePromo: IPromoBuilder;
  groups: Map<string, IGroup>;
}

const ProductListItem: FC<IProductListItem> = ({
  product,
  onAdd,
  onProductDetailClick,
  productItems,
  productBrand,
  productCategory,
  orderType,
  activePromo,
  groups
}) => {
  const {isRetailer} = useContext(AuthStore);
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {});
  const isVisible = !!entry?.isIntersecting;

  const {retailer} = getRetailer();
  const allowedProductItemIds = product?.productItemIds?.filter(id => {
    const productItem = productItems.get(id);
    return retailer?.groupIds.includes(productItem?.productItemGroupId!);
  });

  const [productItem, setSelectedProductItem] = useState<IProductItem>(productItems?.get(allowedProductItemIds?.[0]!)!);

  useEffect(() => {
    isVisible && dataLayerEcommerceActions(actionEnum.viewItem, product, productItem, productBrand, productCategory);
  }, [isVisible]);

  if (!productItem) {
    if (isRetailer) return <div>Oups! You are missing a product here, contact our sales please.</div>;
    return <div>Price settings for {product.title} are missing.</div>;
  }

  const hasPromo = getPromoDiscount(product.id, productItem.id, productItem.price! + productItem.finalDiscountUSD!, activePromo, groups);

  function onVariantChange({target: {value}}: any) {
    dataLayerEcommerceActions(actionEnum.viewItem, product, value, productBrand, productCategory);
    setSelectedProductItem(value);
  }

  function onAddClick() {
    dataLayerEcommerceActions(actionEnum.addToCard, product, productItem, productBrand, productCategory);
    onAdd(product, productItem);
  }

  function openItemDetail() {
    onProductDetailClick({product, productItem});
  }

  return (
    <List.Item className={hasPromo ? 'bg-light-yellow d-flex direction-column' : ''}>
      <Row ref={ref} justify="space-between" align="middle" className="w100">
        <Col xs={4} className="cursor-pointer">
          <Image
            src={
              product?.images?.[0]
                ? `${constants.image.resizer}?width=250&height=250&url=${Buffer.from(product.images[0]).toString('base64')}`
                : constants.image.fallback
            }
            preview={false}
            fallback={constants.image.fallback}
            className="w100 p5"
            alt={product?.title}
            onClick={openItemDetail}
          />
        </Col>
        <Col xs={15} className="pl10">
          <h3 className="text-md font-semibold cursor-pointer" onClick={openItemDetail}>
            {product?.title}
          </h3>
          <h4 className="c-darkblue pb10">
            <span className="mr5">{productItem?.title}:</span>
            {getSupplyText(productItem.outOfStock)}
          </h4>
          <Radio.Group value={productItem} buttonStyle="solid" onChange={onVariantChange}>
            {allowedProductItemIds?.map(productItemId => {
              const productItem = productItems?.get(productItemId);
              return (
                <Radio.Button key={productItem?.sku} value={productItem}>
                  {productItem?.title}
                </Radio.Button>
              );
            })}
          </Radio.Group>
        </Col>
        <Col xs={5} className="tac">
          {hasPromo && <h3 className='text-md font-semibold c-red'>{activePromo.discount}% OFF! <N className='strike' value={productItem.price! + productItem.finalDiscountUSD!} type="price" /></h3>}
          <Button className="mt5 w100" type="primary" onClick={onAddClick} disabled={productItem.outOfStock}>
            Add&nbsp;•&nbsp;
            <N
              value={
                hasPromo
                  ? hasPromo.price
                  : orderType === OrderTypeEnum.FLOOR_MODELS
                  ? productItem.fMdiscount === 0
                    ? 0
                    : productItem.fMprice
                  : productItem.price
              }
              type="price"
            />
          </Button>
        </Col>
      </Row>
      {hasPromo && (
        <Row justify="end">
          <Col span={13}>
            <h4 className="m0">{activePromo.offerTitle}</h4>
          </Col>
          <Col span={11} className="pl15 tar c-darkblue">
            <span>
              <strong>Hurry up!</strong>
              {hasPromo.productlimit ? (
                <span>
                  &nbsp;Only <strong>{hasPromo.productlimit}</strong> items left for this price!
                </span>
              ) : (
                <span>
                  &nbsp;Offer expires at <strong>{moment(activePromo?.expDate).format('MM/DD/YYYY')}</strong>
                </span>
              )}
            </span>
          </Col>
        </Row>
      )}
    </List.Item>
  );
};

export default ProductListItem;
