import {Disclosure} from '@headlessui/react';
import {FunnelIcon} from '@heroicons/react/24/solid';
import {Radio} from 'antd';
import {IQueryEnum, makeQueries} from 'core/api';
import AuthStore from 'core/auth';
import {IProductBrand} from 'core/models/productBrands/types';
import {getRetailer} from 'core/models/retailers/utils';
import {useContext} from 'react';

const sections = [
  'Lifestyle',
  'Product',
  'Product Renders',
  'Videos',
  'Logos',
  'Fonts',
  'Brand Guidelines',
  'Partner Logos',
  'Price Sheets',
  'Spec Sheets',
  'Hang Tags',
  'Tent Cards',
  'Campaign Lockups',
  'Retail Social',
  'Retail Email Banners',
  'Retail Web Banners',
  'Retail Videos',
  '3D Animations',
  'Training'
];

const types = [
  {value: 'Image', label: 'Images'},
  {value: 'Video', label: 'Videos'},
  {value: 'Document', label: 'Documents'},
  {value: 'Font', label: 'Fonts'}
];

const categories = [
  {value: 'Mattress', label: 'Mattress'},
  {value: 'Bed Frame', label: 'Bed Frame'}
];

const extensions = [
  {value: 'png', type: 'Images'},
  {value: 'jpg', type: 'Images'},
  {value: 'jpeg', type: 'Images'},
  {value: 'tif', type: 'Images'},
  {value: 'svg', type: 'Images'},
  {value: 'mov', type: 'Videos'},
  {value: 'mp4', type: 'Videos'},
  {value: 'pdf', type: 'Documents'},
  {value: 'woff2', type: 'Fonts'}
];

interface IAssetFilterProps {
  totalItems?: number;
  page: number;
  setPage: (page: number) => void;

  nameFilter: string;
  setNameFilter: (name: string) => void;
  sectionFilter: string;
  setSectionFilter: (name: string) => void;
  brandsFilter: string[];
  setBrandsFilter: (brands: string[]) => void;
  categoriesFilter: string[];
  setCategoriesFilter: (category: string[]) => void;
  typesFilter: string[];
  setTypesFilter: (type: string[]) => void;
  extensionsFilter: string[];
  setExtensionsFilter: (extension: string[]) => void;
}

interface IData {
  productBrands?: IProductBrand[];
}

export default function AssetsFilter({
  brandsFilter,
  setBrandsFilter,
  page,
  setPage,
  totalItems,
  nameFilter,
  setNameFilter,
  sectionFilter,
  setSectionFilter,
  categoriesFilter,
  setCategoriesFilter,
  typesFilter,
  setTypesFilter,
  extensionsFilter,
  setExtensionsFilter
}: IAssetFilterProps) {
  const {retailer} = getRetailer();
  const {isRetailer} = useContext(AuthStore);

  const {
    data: {productBrands}
  } = makeQueries<IData>([{type: IQueryEnum.GetProductBrands, isEnabled: Boolean(retailer)}]);

  const brands =
    (isRetailer
      ? productBrands?.filter(brand => retailer?.productBrandIds && retailer.productBrandIds.includes(brand.id))
      : productBrands
    )?.filter(brand => Boolean(brand.brandfolderId)) || [];

  function handleNextPage() {
    setPage(page + 1);
  }

  function handlePreviousPage() {
    setPage(page - 1);
  }

  function handleApply(e: any) {
    e.preventDefault();
    const form: any = document.forms[0];

    const name = form.elements.fulltext.value;
    const section = form.elements.section.value;
    const selectedBrands = Array.from(form.elements.brands)
      .filter((e: any) => e.checked)
      .map((e: any) => e.value);
    const selectedCategories = Array.from(form.elements.categories)
      .filter((e: any) => e.checked)
      .map((e: any) => e.value);
    const selectedTypes = Array.from(form.elements.types)
      .filter((e: any) => e.checked)
      .map((e: any) => e.value);
    const selectedExtensions = Array.from(form.elements.extensions)
      .filter((e: any) => e.checked)
      .map((e: any) => e.value);

    setNameFilter(name);
    setSectionFilter(section);
    setBrandsFilter(selectedBrands);
    setCategoriesFilter(selectedCategories);
    setTypesFilter(selectedTypes);
    setExtensionsFilter(selectedExtensions);
    setPage(1);
  }

  function handleReset() {
    setNameFilter('');
    setSectionFilter('');
    setBrandsFilter([]);
    setTypesFilter([]);
    setCategoriesFilter([]);
    setExtensionsFilter([]);
  }

  const totalPages = totalItems ? Number((totalItems / 12).toFixed(0)) || 1 : 1;

  const activeFilterCount = [
    nameFilter?.length > 0,
    sectionFilter?.length > 0,
    brandsFilter?.length > 0,
    categoriesFilter?.length > 0,
    typesFilter?.length > 0,
    extensionsFilter?.length > 0
  ].filter(Boolean).length;

  return (
    <Disclosure
      as="section"
      aria-labelledby="filter-heading"
      className="relative z-10 border-t border-b border-gray-200 grid items-center bg-white rounded-md"
    >
      <div className="relative col-start-1 row-start-1 py-4">
        <div className="max-w-7xl mx-auto flex space-x-6 divide-x divide-gray-200 text-sm px-4 sm:px-6 lg:px-8">
          <div>
            <Disclosure.Button className="group text-gray-700 hover:text-gray-900 font-medium flex items-center">
              <FunnelIcon className="flex-none w-5 h-5 mr-2 text-cyan-700 group-hover:text-cyan-800" aria-hidden="true" />
              {activeFilterCount} Filters
            </Disclosure.Button>
          </div>
          <div className="pl-6">
            <Disclosure.Button type="button" onClick={handleReset} className="text-cyan-700 underline hover:text-cyan-900">
              Clear all
            </Disclosure.Button>
          </div>
        </div>
      </div>
      <Disclosure.Panel className="border-t border-gray-200 py-6 max-w-7xl mx-auto flex flex-col">
        <form id="asset-filter-form" name="asset-filter-form" onSubmit={handleApply}>
          <div className="flex flex-row content-between w-full mb-6 px-4 sm:px-6 lg:px-8">
            <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-cyan-600 focus-within:border-cyan-600 w-full mr-8">
              <label
                htmlFor="fulltext"
                className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
              >
                Asset Name
              </label>
              <input
                type="text"
                name="fulltext"
                defaultValue={nameFilter}
                className="block w-full border-0 p-0 outline-none text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                placeholder="Nectar 3.0 Lifestyle ..."
              />
            </div>
            <Disclosure.Button onClick={handleApply}>
              <input
                value="Apply"
                type="button"
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
              />
            </Disclosure.Button>
          </div>
          <div className='px-8 mb-4'>
            <Radio.Group name="section" defaultValue={sectionFilter}  buttonStyle="solid">
              {sections?.map(section => (
                <Radio.Button key={section} value={section} className="my-1">
                  {section}
                </Radio.Button>
              ))}
            </Radio.Group>
          </div>
          <div className="grid grid-cols-2 gap-x-4 px-4 text-sm sm:px-6 md:gap-x-6 lg:px-8">
            <div className="grid grid-cols-1 gap-y-10 auto-rows-min md:grid-cols-2 md:gap-x-6">
              <fieldset>
                <legend className="block font-medium">Brands</legend>
                <div className="pt-6 space-y-6 sm:pt-4 sm:space-y-4">
                  {brands.map(brand => (
                    <div key={brand.id} className="flex items-center text-base sm:text-sm">
                      <input
                        name="brands"
                        defaultValue={brand.brandfolderId}
                        type="checkbox"
                        defaultChecked={brandsFilter?.includes(brand.brandfolderId)}
                        className="flex-shrink-0 h-4 w-4 border-gray-300 rounded accent-cyan-600 cursor-pointer"
                      />
                      <label htmlFor={`brand-${brand.id}`} className="ml-3 min-w-0 flex-1 text-gray-600 cursor-pointer select-none">
                        {brand.title}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
              <fieldset>
                <legend className="block font-medium">Categories</legend>
                <div className="pt-6 space-y-6 sm:pt-4 sm:space-y-4">
                  {categories.map((category, categoryIdx) => (
                    <div key={category.value} className="flex items-center text-base sm:text-sm">
                      <input
                        name="categories"
                        defaultValue={category.value}
                        type="checkbox"
                        defaultChecked={categoriesFilter?.includes(category.value)}
                        className="flex-shrink-0 h-4 w-4 border-gray-300 rounded accent-cyan-600 cursor-pointer"
                      />
                      <label htmlFor={`type-${categoryIdx}`} className="ml-3 min-w-0 flex-1 text-gray-600 cursor-pointer select-none">
                        {category.label}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div>
            <div className="grid grid-cols-2 gap-y-10 auto-rows-min md:grid-cols-2 md:gap-x-6">
              <fieldset>
                <legend className="block font-medium">Types</legend>
                <div className="pt-6 space-y-6 sm:pt-4 sm:space-y-4">
                  {types.map((type, typeIdx) => (
                    <div key={type.value} className="flex items-center text-base sm:text-sm">
                      <input
                        name="types"
                        defaultValue={type.value}
                        type="checkbox"
                        defaultChecked={typesFilter?.includes(type.value)}
                        className="flex-shrink-0 h-4 w-4 border-gray-300 rounded accent-cyan-600 cursor-pointer"
                      />
                      <label htmlFor={`type-${typeIdx}`} className="ml-3 min-w-0 flex-1 text-gray-600 cursor-pointer select-none">
                        {type.label}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
              <fieldset>
                <legend className="block font-medium">Extensions</legend>
                <div className="pt-6 space-y-6 sm:pt-4 sm:space-y-4">
                  {extensions.map((extension, typeIdx) => (
                    <div key={extension.value} className="flex items-center text-base sm:text-sm">
                      <input
                        name="extensions"
                        defaultValue={extension.value}
                        type="checkbox"
                        defaultChecked={extensionsFilter?.includes(extension.value)}
                        className="flex-shrink-0 h-4 w-4 border-gray-300 rounded accent-cyan-600 cursor-pointer"
                      />
                      <label htmlFor={`extension-${typeIdx}`} className="ml-3 min-w-0 flex-1 text-gray-600 cursor-pointer select-none">
                        {extension.value}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div>
          </div>
        </form>
      </Disclosure.Panel>
      <div className="col-start-1 row-start-1 py-2">
        <div className="flex justify-end max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <nav className="bg-white flex items-center justify-between" aria-label="Pagination">
            <div className="flex-1 flex justify-between sm:justify-end pr-8">
              {page !== 1 && (
                <button
                  onClick={handlePreviousPage}
                  className="relative inline-flex items-center px-2 py-1 border border-gray-300 text-sm rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  Previous
                </button>
              )}
              {page !== totalPages && (
                <button
                  onClick={handleNextPage}
                  className="ml-3 relative inline-flex items-center px-2 py-1 border border-gray-300 text-sm rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  Next
                </button>
              )}
            </div>
            <div className="hidden sm:block">
              <div className="text-xs text-gray-700">
                Page <span className="font-medium">{page}</span> out of <span className="font-medium">{totalPages}</span>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </Disclosure>
  );
}
