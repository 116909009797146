import {IProductItem} from 'core/models/productItems/types';
import {IProduct} from 'core/models/products/types';
import {IRetailer} from 'core/models/retailers/types';

type TEvent = actionEnum;

export enum actionEnum {
  addToCard = 'add_to_cart',
  removeFromCard = 'remove_from_cart',
  viewItem = 'view_item',
  beginCheckout = 'begin_checkout',
  purchase = 'purchase'
}

interface IItem {
  item_name?: string; // Name or ID is required.
  item_id?: string;
  price?: number;
  item_brand?: string;
  item_category?: string;
  item_variant?: string;
  quantity?: number;
  discount?: number;
}

interface IEcommerceItems {
  transaction_id?: string;
  value?: string | number;
  currency?: string;
  items: IItem[];
  orderType?: string;
}

interface IProps {
  (action: TEvent, product?: IProduct, productItem?: IProductItem, productBrand?: string, productCategory?: string): void;
}

const invokeAction = (action: TEvent, data: IEcommerceItems): void => {
  window.dataLayer.push({ecommerce: null});
  window.dataLayer.push({
    event: action,
    ecommerce: data
  });
};

export const dataLayerEcommerceActions: IProps = (action, product, productItem, productBrand, productCategory) => {
  const data = {
    items: [
      {
        item_name: product?.title,
        item_id: product?.id,
        price: productItem?.price,
        item_brand: productBrand,
        item_category: productCategory,
        item_variant: productItem?.title,
        quantity: 1
      }
    ]
  };
  switch (action) {
    case actionEnum.removeFromCard:
      invokeAction(action, data);

      break;
    case actionEnum.addToCard:
      invokeAction(action, data);
      break;
    case actionEnum.viewItem:
      invokeAction(action, data);
      break;
    default:
      throw new Error(`${action} action not found`);
  }
};

export const dataLayerCheckoutOrPurchase = (
  action: string,
  orderItemsDataLayer: IItem[],
  orderInfo?: {transaction_id: string; value?: string | number}
) => {
  if (actionEnum.purchase === action) {
    const purchaseObj = {...orderInfo, currency: 'USD', items: orderItemsDataLayer};

    invokeAction(actionEnum.purchase, purchaseObj);
  } else {
    const beginCheckout = {items: orderItemsDataLayer};

    invokeAction(actionEnum.beginCheckout, beginCheckout);
  }
};

export const dataLayerEvents = (event: string, retailer?: IRetailer) => {
  window.dataLayer?.push({event, retailerName: retailer?.name || null });
};
