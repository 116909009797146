import {gql} from 'graphql-tag';

export const CREATE_TASK_COMMENT = gql`
  mutation createTaskComment($taskCommentInput: TaskCommentInput) {
    createTaskComment(taskCommentInput: $taskCommentInput) {
      id
      text
      createdBy
      createdAt
    }
  }
`;
