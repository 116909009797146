import EnvironmentOutlined from '@ant-design/icons/lib/icons/EnvironmentOutlined';
import {Button, Card, Col, Form, Input, Row, Select} from 'antd';
import {IMutationEnum, IQueryEnum, makeMutation, makeQueries} from 'core/api';
import {getRetailerId} from 'core/models/retailers/utils';
import {IState} from 'core/models/states/types';
import { pageUrls } from 'core/router/pages';
import {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { getUrl } from 'utils/getUrl';
import GoogleMap from '../RetailerAddressList/components/GoogleMap';
const {Option} = Select;

interface IData {
  states: IState[];
}
interface IAddress {
  street: string;
  state: string;
  city: string;
}

const addressStructure: IAddress = {street: '', state: '', city: ''};

const RetailerAddressCreate: React.FC = () => {
  const [form] = Form.useForm();
  const history = useHistory();

  const [address, setAddress] = useState<IAddress>(addressStructure);
  const retailerId = getRetailerId();

  const {
    data: {states}
  } = makeQueries<IData>([{type: IQueryEnum.GetStates}]);

  const mutation = makeMutation({
    type: IMutationEnum.CreateAddress,
    keyVars: {retailerId},
    loadingMsg: 'Creating address...',
    successMsg: 'Address successfully Created!'
  });

  useEffect(() => {
    if (mutation.isSuccess) {
      history.push(getUrl(pageUrls.retailerAddressList, {retailerId}));
    }
  }, [mutation, retailerId, history]);

  const onSaveClick = (data: any) => {
    mutation.mutate({
      addressInput: {
        retailerId,
        ...data
      }
    });
  };

  const handleAddressChange = (key: string) => (e: any) => {
    setAddress(obj => ({...obj, [key]: e.target.value}));
  };
  const handleStateChange = (value: string, {name}: any) => {
    setAddress(obj => ({...obj, state: name}));
  };

  return (
    <div className={'d-flex'}>
      <Card className={'w55'}>
        <Form layout="vertical" form={form} name="create-user" onFinish={onSaveClick}>
          <Form.Item name="name" label="Name" rules={[{required: true}]}>
            <Input />
          </Form.Item>

          <Form.Item name="email" label="Email" rules={[{required: true}]}>
            <Input />
          </Form.Item>
          <Form.Item name="addressLine1" label="Address 1" rules={[{required: true}]}>
            <Input onChange={handleAddressChange('street')} />
          </Form.Item>
          <Form.Item name="addressLine2" label="Address 2">
            <Input />
          </Form.Item>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item name={'stateId'} className="mb0" label="State" rules={[{required: true}]}>
                <Select onChange={handleStateChange}>
                  {states.map(state => (
                    <Option key={state.id} name={state.name} value={state.id}>
                      {state.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="city" label="City" rules={[{required: true}]}>
                <Input onChange={handleAddressChange('city')} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item name="zip" label="Zip" rules={[{required: true}]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="phone" label="Phone" rules={[{required: true}]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item className="tar pt15">
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <div className="map-container">
        <GoogleMap address={`${address.state} ${address.city} ${address.street}`} icon={EnvironmentOutlined} styles={{fontSize: '26px'}} />
      </div>
    </div>
  );
};

export default RetailerAddressCreate;
