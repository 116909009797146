export interface IUser {
  id: string;
  oktaId: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  photoUrl?: string;
  role?: UserRolesEnum;
  retailerIds?: string[];
  storeIds?: string[];
  fulfilId?: number;
  acceptedTermsAt?: string;
  lastSeen?: string;
}
export interface IOktaUser {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  photoUrl?: string;
  role?: UserRolesEnum;
  retailerIDs?: string[];
  storeIds?: string[];
  acceptedTermsAt?: string;
}

export enum UserRolesEnum {
  Admin = 'Admin',
  Sales = 'Sales',
  Owner = 'Owner',
  Manager = 'Manager',
  Returns = 'Returns'
}

export enum EnumUserStatus {
  Invited = 'Invited',
  Active = 'Active',
  Others = 'Others'
}
