import {DeleteOutlined} from '@ant-design/icons';
import {Button, Col, Popconfirm, Row} from 'antd';
import {PageWrapper} from 'components/PageWrapper';
import {IMutationEnum, IQueryEnum, makeMutation, makeQueries} from 'core/api';
import {IRetailer} from 'core/models/retailers/types';
import {getRetailer} from 'core/models/retailers/utils';
import {IUser, UserRolesEnum} from 'core/models/users/types';
import {pageUrls} from 'core/router/pages';
import {useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {dataLayerEvents} from 'utils/dataLayer';
import RetailerAssignment from './components/RetailerAssignment';
import UserCard from './components/UserCard';

interface IData {
  users: Map<string, IUser>;
  retailers: IRetailer[];
}
function SettingsUserView() {
  const history = useHistory();
  const {userId} = useParams<{userId: string}>();
  const {retailer, isLoading: isRetailerLoading} = getRetailer();

  const {
    data: {users, retailers},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetUsers, variables: {roles: [UserRolesEnum.Sales, UserRolesEnum.Admin]}, mapKey: 'id'},
    {type: IQueryEnum.GetRetailers}
  ]);

  const mutation = makeMutation({
    type: IMutationEnum.DeleteUser,
    loadingMsg: 'Deleting user...',
    successMsg: 'User deleted.'
  });

  useEffect(() => {
    if (!isRetailerLoading && retailer) {
      dataLayerEvents('view_settings_users_detail', retailer);
    }
    if (mutation.isSuccess) {
      history.push(pageUrls.settingsUserList);
    }
  }, [mutation, retailer, isRetailerLoading, history]);

  const user = users?.get(userId);

  function onDeleteClick() {
    mutation.mutate({id: userId});
  }

  const headerActions = (
    <Popconfirm
      title="Are you sure you want to delete this user?"
      placement="bottomRight"
      onConfirm={onDeleteClick}
      okText="Yes"
      cancelText="No"
    >
      <Button type="primary" danger icon={<DeleteOutlined />}>
        Delete
      </Button>
    </Popconfirm>
  );

  return (
    <PageWrapper pageTitle={`${user?.firstName} ${user?.lastName}`} actions={headerActions}>
      <Row>
        <Col xs={8} className="pr15">
          <UserCard user={user} />
        </Col>
        <Col xs={16}>
          {user?.role !== UserRolesEnum.Admin && (
            <RetailerAssignment userOktaId={user?.oktaId!} retailerIds={user ? user.retailerIds : []} retailers={retailers} isLoading={isLoading} />
          )}
        </Col>
      </Row>
    </PageWrapper>
  );
}

export default SettingsUserView;
