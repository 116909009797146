import {gql} from 'graphql-tag';

export const GET_PRODUCT_BRANDS = gql`
  query getProductBrands {
    getProductBrands {
      id
      title
      order
      brandfolderId
    }
  }
`;