export interface IQuery {
  /** Query type */
  type: IQueryEnum;
  /** Query variables */
  variables?: object;
  /** If parameter provided, result will be returned as a Map Collection */
  mapKey?: string;
  /** IF parameter is provided, endpoint will be polled (in milliseconds) */
  pollingInterval?: number;
  /** If false, API call will not be fired. Default = true */
  isEnabled?: boolean;
}

export interface IMakeQueries<DataObject> {
  data: DataObject;
  isLoading: boolean;
}

export enum IQueryEnum {
  GetRetailers = 'getRetailers',
  GetNotifications = 'getNotifications',
  GetTasks = 'getTasks',
  GetTaskComments = 'getTaskComments',
  GetAddresses = 'getAddresses',
  GetUsers = 'getUsers',
  GetOrders = 'getOrders',
  GetShipment = 'getShipmentByOrderId',
  GetSuppliers = 'getSuppliers',
  GetStates = 'getStates',
  GetOrderTracking = 'getOrderTracking',
  GetOrderDetail = 'getOrderDetail',
  GetOrderItems = 'getOrderItems',
  GetProducts = 'getProducts',
  GetPayments = 'getPayments',
  GetProductItems = 'getProductItems',
  GetProductCategories = 'getProductCategories',
  GetProductBrands = 'getProductBrands',
  GetPriceLists = 'getPriceLists',
  GetPriceListItems = 'getPriceListItems',
  GetAllProductItems = 'getAllProductItems',
  GetReturns = 'getReturns',
  TotalSalesReport = 'totalSalesReport',
  TotalProductsReport = 'totalProductsReport',
  GetInvoices = 'getInvoices',
  GetGroups = 'getGroups',
  GetShippingRules = 'getShippingRules',
  GetProductItemGroups = 'getProductItemGroups',
  GetShippingPlans = 'getShippingPlans',
  GetPromoBuilderList = 'getPromoBuilderList',
  GetActivePromo = 'getActivePromo',
  GetStocks = 'getStocks',
  GetLogs = 'getLogs',
  GetAnalyticsReports = 'getAnalyticsReports',
}
