import {Card} from 'antd';
import {N} from 'components/N';
import {memo} from 'react';
import {Tooltip, LineChart, Line, CartesianGrid, XAxis, YAxis,ResponsiveContainer} from 'recharts';

const SimpleLineChart = memo(({data, name, valueIsPrice}: any) => {
  return (
    <Card className="w100 tac mt15">
      <h2 className='text-lg font-semibold'>{name}</h2>
      <ResponsiveContainer width='100%' height={350}>

      <LineChart data={data} margin={{top: 5, right: 0, left: 30, bottom: 5}}>
        <CartesianGrid horizontal vertical={false} />
        <YAxis dataKey={valueIsPrice ? 'amount' : 'count'} axisLine={false} tickLine={false} />
        <XAxis dataKey="labels" tickLine={false} />
        <Tooltip
          useTranslate3d
          content={({active, payload, label}) => (
            <div className="bg-white b b-r4 p10" style={{textAlign: 'left'}}>
              <h2 className="text-lg font-semibold">
                <N value={payload?.[0]?.value as number} type={valueIsPrice && 'price'} />
              </h2>
              <h5 className="f-light c-darkblue mb0">{label}</h5>
            </div>
          )}
        />
        <Line type="monotone" dataKey={valueIsPrice ? 'amount' : 'count'} stroke="#739ea8" strokeWidth={3} dot={false} />
      </LineChart>
      </ResponsiveContainer>
    </Card>
  );
});

export default SimpleLineChart;
