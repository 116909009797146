import {DownOutlined, MinusCircleOutlined, PlusOutlined, UpOutlined} from '@ant-design/icons';
import {Button, Form, Input, InputNumber, Popconfirm, Select} from 'antd';
import {IMutationEnum, makeMutation} from 'core/api';
import {IGroup} from 'core/models/groups/types';
import {getRetailerId} from 'core/models/retailers/utils';
import {IShippingRule} from 'core/models/shippingRules/types';
import {useEffect, useState} from 'react';

interface IProductsItemSettings {
  name: string;
  shippingRules: IShippingRule[];
  groups: IGroup[];
}
const ShippingRuleList = ({name, shippingRules, groups}: IProductsItemSettings) => {
  const [form] = Form.useForm();
  const retailerId = getRetailerId();
  const [rules, setRules] = useState([{id: ''}]);

  const mutation = makeMutation({
    type: IMutationEnum.createOrUpdateShippingRules,
    loadingMsg: 'Creating...',
    successMsg: 'Created!'
  });

  const mutationDelete = makeMutation({
    type: IMutationEnum.deleteShippingRule,
    loadingMsg: 'Deleting...',
    successMsg: 'Deleted!'
  });

  useEffect(() => {
    const rules: any = shippingRules.filter(rule => rule.retailerId === retailerId && rule.orderType === name);

    form.setFieldsValue({fields: rules});
    setRules(rules);
  }, [form, name, shippingRules, retailerId]);

  const onFinish = (values: any) => {
    const shippingRuleInput = values.fields.map((v: any, i: number) => {
      return {...v, order: i, orderType: name, retailerId};
    });

    mutation.mutate({
      shippingRuleInput
    });
  };

  const onTitleDelete = (remove: (index: number | number[]) => void, index: number) => () => {
    const id = rules[index]?.id;
    if (id) {
      mutationDelete.mutate({
        id
      });
    }
    remove(index);
  };

  return (
    <Form layout="vertical" form={form} onFinish={onFinish}>
      <Form.List initialValue={rules} name={'fields'}>
        {(fields, {add, remove, move}, {errors}) => (
          <>
            {fields.map((field: any, i: number) => (
              <div key={`${field.name}-${i}`}>
                <div className={'d-flex b b-r4 mb15 pt15 pl15'}>
                  <div className={'d-flex w80 mr15'}>
                    <span style={{alignSelf: 'center', display: 'flex', alignItems: 'center'}}>
                      {i !== 0 && (
                        <UpOutlined className={`mr10 drag ${i === fields.length - 1 && 'ml15 pl10'}`} onClick={() => move(i, i - 1)} />
                      )}
                      {i !== fields.length - 1 && (
                        <DownOutlined className={`mr10 drag ${i === 0 && 'ml15 pl10'}`} onClick={() => move(i, i + 1)} />
                      )}
                    </span>
                    <Form.Item style={{width: '100%'}} required={false}>
                      <Form.Item label={'Product Group'} name={[i, 'groupId']}>
                        <Select allowClear placeholder="Select Group">
                          {groups.map((group, i) => (
                            <Select.Option value={group.id} key={group.id}>
                              {group.title}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <div className={'d-flex space-between'}>
                        <Form.Item label="Price range" className="mb0">
                          <Input.Group compact className="mb0">
                            <Form.Item
                              name={[i, 'priceRange', 0]}
                              validateTrigger={['onChange', 'onBlur']}
                              initialValue={0}
                              className="mb0"
                            >
                              <InputNumber min={0} className="mb0" />
                            </Form.Item>
                            <hr style={{margin: '15px 5px'}} className={'horizontal-line'} />
                            <Form.Item
                              name={[i, 'priceRange', 1]}
                              className="mb0"
                              dependencies={[i, 'priceRange', 0]}
                              rules={[
                                ({getFieldValue}) => ({
                                  validator(_, value) {
                                    if (!value || getFieldValue(['fields', i, 'priceRange', 0]) < value) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Should be bigger than lower bound'));
                                  }
                                })
                              ]}
                            >
                              <InputNumber min={0} className="mb0" />
                            </Form.Item>
                          </Input.Group>
                        </Form.Item>
                        <Form.Item label="Items quantity range" className="mb0">
                          <Input.Group compact className="mb0">
                            <Form.Item
                              name={[i, 'quantityRange', 0]}
                              validateTrigger={['onChange', 'onBlur']}
                              initialValue={0}
                              className="mb0"
                            >
                              <InputNumber min={0} className="mb0" />
                            </Form.Item>
                            <hr style={{margin: '15px 5px'}} className={'horizontal-line'} />
                            <Form.Item
                              name={[i, 'quantityRange', 1]}
                              className="mb0"
                              rules={[
                                ({getFieldValue}) => ({
                                  validator(_, value) {
                                    if (!value || getFieldValue(['fields', i, 'quantityRange', 0]) < value) {
                                      return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Should be bigger than lower bound'));
                                  }
                                })
                              ]}
                            >
                              <InputNumber min={0} className="mb0" />
                            </Form.Item>
                          </Input.Group>
                        </Form.Item>
                      </div>
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      rules={[{required: true, message: 'Please add a number!'}]}
                      initialValue={0}
                      label="Price"
                      name={[i, 'price']}
                    >
                      <InputNumber style={{width: 105}} min={0} formatter={v => Number(v)?.toFixed(0) || '0'} placeholder="price" />
                    </Form.Item>
                    <Popconfirm
                      title="Are you sure you want to delete this rule?"
                      onConfirm={onTitleDelete(remove, field.name)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        type="ghost"
                        style={{display: 'block', marginTop: 53, background: '#ff4242', color: 'white'}}
                        icon={<MinusCircleOutlined />}
                      >
                        Remove
                      </Button>
                    </Popconfirm>
                  </div>
                </div>
                <p className="w100 tac mb10">OR</p>
              </div>
            ))}
            <div>
              <Button type="dashed" onClick={() => add()} className="w100" icon={<PlusOutlined />}>
                Add
              </Button>
              <Form.ErrorList errors={errors} />
            </div>
          </>
        )}
      </Form.List>
      <div className="w100 tar mt15">
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </div>
    </Form>
  );
};
export default ShippingRuleList;
