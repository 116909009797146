import {gql} from 'graphql-tag';

export const CREATE_OR_UPDATE_PRODUCT_BRAND = gql`
  mutation createOrUpdateProductBrand($userInput: [ProductBrandInput]!) {
    createOrUpdateProductBrand(userInput: $userInput) {
      title
      id
      order
      brandfolderId
    }
  }
`;
export const DELETE_PRODUCT_BRAND = gql`
  mutation deleteProductBrand($id: ID!) {
    deleteProductBrand(id: $id)
  }
`;
