import {Menu} from 'antd';
import {IAuthStore} from 'core/auth';
import {Link} from 'react-router-dom';
import {getUrl} from 'utils/getUrl';
import {ISidebarItem} from './SidebarItems';

const {Item} = Menu;

/** Traversing routes and generating items for Sidebar  */
function getSidebarItemList(items: ISidebarItem[], auth: IAuthStore, currentRetailer: string | null) {
  return items
    .map(({url, title, icon, retailerUrl}) => {
      const retailerId = (auth.isRetailer && auth.userProfile?.retailerIDs?.[0]) || currentRetailer;
      const key = url.split('/')[1];

      return (
        <Item className="pl15" icon={icon} key={key}>
          {url.includes('http') ? (
            <a href={url} target="_blank" rel="noreferrer">
              {title}
            </a>
          ) : (
            <Link to={{pathname: getUrl(currentRetailer ? retailerUrl || url : url, {retailerId}) || '/404'}}>{title}</Link>
          )}
        </Item>
      );
    })
    .filter(Boolean);
}

export default getSidebarItemList;
