import {PlusOutlined} from '@ant-design/icons';
import {Button, Tooltip} from 'antd';
import {PageWrapper} from 'components/PageWrapper';
import AuthStore from 'core/auth/AuthStore';
import {IOrder, OrderTypeEnum} from 'core/models/orders/types';
import {getRetailer} from 'core/models/retailers/utils';
import {UserRolesEnum} from 'core/models/users/types';
import {pageUrls} from 'core/router/pages';
import {useContext, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import OrdersTable from 'shared/OrdersTable';
import {dataLayerEvents} from 'utils/dataLayer';
import {getUrl} from 'utils/getUrl';

function OrderList() {
  const history = useHistory();
  const {retailer, isLoading} = getRetailer();
  const {userProfile} = useContext(AuthStore);
  const isDisabled = !Boolean(retailer?.billingAddressId);
  const tooltipMassage = isDisabled ? 'To be able to create an order, configure please your billing address in the settings.' : '';

  useEffect(() => {
    if (!isLoading && retailer) {
      dataLayerEvents('view_orders_manage', retailer);
    }
  }, [retailer, isLoading]);

  const onBulkCreateClick = () => {
    history.push(getUrl(pageUrls.orderCreateBulk, {retailerId: retailer?.id}));
  };
  const onCreateClick = () => {
    history.push(getUrl(pageUrls.orderCreate, {retailerId: retailer?.id}));
  };
  const headerActions = (
    <div>
      <Tooltip placement="topLeft" title={tooltipMassage} arrowPointAtCenter>
        <Button size="large" type="primary" onClick={onCreateClick} icon={<PlusOutlined />} disabled={isDisabled}>
          Create
        </Button>
        <Button type="primary" size="large" className={'ml10'} icon={<PlusOutlined />} onClick={onBulkCreateClick} disabled={isDisabled}>
          Bulk Create
        </Button>
      </Tooltip>
    </div>
  );

  const onOrderClick = (order: IOrder) => {
    history.push(getUrl(pageUrls.orderView, {orderId: order.id, retailerId: retailer?.id}));
  };

  return (
    <PageWrapper pageTitle="Order Management" withRetailerSelector actions={headerActions}>
      <OrdersTable onOrderClick={onOrderClick} hide={userProfile?.role === UserRolesEnum.Manager} />
    </PageWrapper>
  );
}

export default OrderList;
