import {PageWrapper} from '../../../components/PageWrapper';
import ProductCreate from './components/ProductCreate';

const ProductCreate1 = () => {
  return (
    <PageWrapper>
      <ProductCreate />
    </PageWrapper>
  );
};

export default ProductCreate1;
