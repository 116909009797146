import {Divider, Row} from 'antd';
import {N} from 'components/N';
import AuthStore from 'core/auth';
import React, { useContext } from 'react';
import FooterButton from './components/FooterButton';
import OrderSummaryScheduler from './OrderSummaryScheduler';

export interface IOrderSummaryFooter {
  /** Price for all items without discounts*/
  subtotal: number;
  /** Order total price */
  amount: number;
  /** Order total discount */
  discount?: number;
  /** Name of the current available action  */
  actionTitle: string;
  /** Function that will be called on button click*/
  onAction: () => any;
  isActionDisabled?: boolean;
  shippingPrice: number;
  limitMessage: any;
  recommendationThreshold: number | null;
  priceRange: number | null;
}

const OrderSummaryFooter: React.FC<IOrderSummaryFooter> = React.memo(
  ({
    actionTitle,
    onAction,
    amount,
    discount,
    isActionDisabled,
    subtotal,
    shippingPrice,
    limitMessage,
    recommendationThreshold,
    priceRange
  }) => {
  const {isRetailer} = useContext(AuthStore);

    return (
      <>
        <Divider />
        <h4 className="mb10">
          <Row justify="space-between">
            <span>Subtotal</span>
            <span className="cdb">
              <N value={subtotal} type="price" />
            </span>
          </Row>
        </h4>
        {discount! > 0 && (
          <h4 className="mb10">
            <Row justify="space-between">
              <span>Discount</span>
              <span className="cdb">
                <N value={-1 * discount!} type="price" />
              </span>
            </Row>
          </h4>
        )}
        <h4 className="mb10">
          <Row justify="space-between">
            <span>Shipping</span>
            <span className="cdb">${shippingPrice}</span>
          </Row>
        </h4>
        <Divider />
        <h2 className="text-lg font-semibold">
          <Row justify="space-between">
            <span>Total</span>
            <span>
              <N value={amount} type="price" />
            </span>
          </Row>
        </h2>
        {limitMessage && <span className="c-red">{limitMessage}</span>}
        {actionTitle === 'Place order' && !isRetailer && <OrderSummaryScheduler />}
        <FooterButton
          subtotal={subtotal}
          recommendationThreshold={recommendationThreshold}
          actionTitle={actionTitle}
          discount={discount}
          onAction={onAction}
          isActionDisabled={isActionDisabled}
          priceRange={priceRange}
        />
      </>
    );
  }
);

export default OrderSummaryFooter;
