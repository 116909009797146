export enum CarrierNameEnum {
  Fedex = 'FedEx',
  UPS = 'UPS',
  DHL = 'DHL Express'
}

export interface ShipmentEvent {
  status: string;
  date: string;
  location: string;
}

export interface IShipment {
  id: string;
  orderId: string;
  trackingNumber: string;
  carrierName?: CarrierNameEnum,
  estimatedDeliveryDate?: string;
  deliveredAt?: string;
  events?: ShipmentEvent[];
}