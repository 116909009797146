import {EnvironmentOutlined, FieldNumberOutlined} from '@ant-design/icons';
import {Divider, Row} from 'antd';

import {IQueryEnum, makeQueries} from 'core/api';
import {IAddress} from 'core/models/addresses/types';

import {IProductItem} from 'core/models/productItems/types';
import {IProduct} from 'core/models/products/types';
import {getRetailer} from 'core/models/retailers/utils';
import {OrderStore} from 'core/stores/OrderStore';

import OrderItem from './OrderItem';
import '../style.less';

interface IData {
  addresses: Map<string, IAddress>;
  products: Map<string, IProduct>;
  productItems: Map<string, IProductItem>;
}
export default function OrderDetails() {
  const {retailer} = getRetailer();

  const {order, actualOrder, delayedOrder, actualOrderItems, delayedOrderItems} = OrderStore.getState();
  const {
    data: {addresses, products = new Map(), productItems = new Map()}
  } = makeQueries<IData>([
    {type: IQueryEnum.GetAddresses, variables: {retailerId: retailer?.id}, mapKey: 'id'},

    {type: IQueryEnum.GetProducts, mapKey: 'id'},
    {type: IQueryEnum.GetProductItems, variables: {retailerId: retailer?.id}, mapKey: 'id', isEnabled: Boolean(retailer)}
  ]);

  return (
    <>
      {delayedOrderItems.size > 0 && actualOrderItems?.size > 0 && (
        <h2 className={'tac text-lg font-semibold'}>Due to lack of items, Your order has been split into 2.</h2>
      )}
      <Row align="middle" className="mt15">
        <EnvironmentOutlined style={{fontSize: 15}} className="mr10" />
        <h3 className="text-md font-semibold"> Shipping Address:</h3>
      </Row>
      <h4 className="f-light mt10 c-darkblue">{addresses?.get(order?.shippingAddressId!)?.address}</h4>
      <Row align="middle" className="mt15">
        <EnvironmentOutlined style={{fontSize: 15}} className="mr10" />
        <h3 className="text-md font-semibold">Billing address</h3>
      </Row>
      <h4 className="f-light mt10 c-darkblue">{addresses.get(retailer?.billingAddressId!)?.address}</h4>

      <Divider />

      <Row align="middle" className="mt15">
        <FieldNumberOutlined style={{fontSize: 16}} className="mr10" />
        <h3 className="text-md font-semibold">Order Reference:</h3>
      </Row>

      {actualOrderItems?.size > 0 && (
        <>
          <h4 className="mt10 c-darkblue">{delayedOrderItems?.size > 0 ? actualOrder.reference : order.reference}</h4>
          <OrderItem productItems={productItems} warehouse={order.warehouse} orderItems={actualOrderItems} products={products} order={actualOrder} />
          <Divider />
        </>
      )}
      {delayedOrderItems?.size > 0 && (
        <>
          <Row align="middle" className="mt15">
            <FieldNumberOutlined style={{fontSize: 16}} className="mr10" />
            <h3 className="text-md font-semibold">Order Reference:</h3>
          </Row>
          <h4 className="mt10 c-darkblue">{actualOrderItems?.size > 0 ? delayedOrder.reference : order.reference}</h4>
          {actualOrderItems?.size > 0 && (
            <>
              <h3 className={'tac text-md font-semibold'}>Split Order 2 of 2 (out of stock items)</h3>
              <h5 className={'tac c-blue'}>Items will ship upon availability</h5>
            </>
          )}

          <OrderItem productItems={productItems} warehouse={order.warehouse} order={delayedOrder} orderItems={delayedOrderItems} products={products} />
        </>
      )}
    </>
  );
}
