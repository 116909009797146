import {useContext, useEffect, useState} from 'react';
import moment from 'moment-timezone';
import {Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, message, Row, Select, Upload} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {IMutationEnum, IQueryEnum, makeMutation, makeQueries} from 'core/api';
import {IGroup} from 'core/models/groups/types';
import {IProductItem} from 'core/models/productItems/types';
import {IProduct} from 'core/models/products/types';
import {PageWrapper} from '../../../components/PageWrapper';
import {UnitType, BannerVariants} from 'core/models/promoBuilder/types';
import {useHistory} from 'react-router-dom';
import {pageUrls} from 'core/router/pages';
import {getUrl} from 'utils/getUrl';
import AuthStore from 'core/auth';
import {normalizeFile} from 'utils/normalizeFile';
import {UserAddOutlined} from '@ant-design/icons';

interface IData {
  groups: Map<string, IGroup>;
  products: Map<string, IProduct>;
  productItems: Map<string, IProductItem>;
}

function PromoCreate() {
  const [form] = Form.useForm();
  const [useQuantity, setUseQuantity] = useState(false);
  const {token} = useContext(AuthStore);
  const [dateTime, setDateTime] = useState<any>();
  const [groupItems, setGroupItems] = useState<any[]>([]);
  const history = useHistory();
  const [bannerImage, setBannerImage] = useState('');
  const [isUploaderVisible, setIsUploaderVisible] = useState();
  const UPLOADER_TYPES = ['ORDER_TYPE', 'ORDER_REVIEW'];

  const {
    data: {groups, products, productItems},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetGroups, mapKey: 'id'},
    {type: IQueryEnum.GetProducts, mapKey: 'id'},
    {type: IQueryEnum.GetProductItems, mapKey: 'id'}
  ]);

  const mutation = makeMutation({
    type: IMutationEnum.CreateOrUpdatePromo,
    loadingMsg: 'Creating promo...',
    successMsg: 'Promo successfully Created!'
  });

  useEffect(() => {
    if (mutation.isSuccess) {
      history.push(getUrl(pageUrls.promoBuilderList, {}));
    }
  }, [history, mutation]);

  const onGroupChange = (groupId: string) => {
    const currentGroup: IGroup = groups?.get(groupId)!;
    let items: any = [];
    if (currentGroup.type === 'Products') {
      items = currentGroup?.productIds?.map(id => products?.get(id));
    } else {
      items = currentGroup?.productItemIds?.map(id => productItems?.get(id));
    }
    setGroupItems(items);
  };

  const onUseQuantityChange = () => {
    setUseQuantity(!useQuantity)
  }

  const handleSubmit = (values: any) => {
    let promoBuilderInput: any = {isActive: true};
    for (let key in values) {
      if (key.startsWith('quantity_') && values[key] > 0) {
        const [, getKey] = key.split('quantity_');
        promoBuilderInput.defaultItemsAvailable = {...promoBuilderInput.defaultItemsAvailable, [getKey]: values[key]};
        promoBuilderInput.currentItemsAvailable = {...promoBuilderInput.defaultItemsAvailable, [getKey]: values[key]};
      } else if (key === 'expDate') {
        promoBuilderInput[key] = dateTime;
      } else if (!key.startsWith('quantity_')) {
        promoBuilderInput[key] = values[key];
      }
    }
    promoBuilderInput.promoBanner = {...values.promoBanner, image: bannerImage};

    mutation.mutate({promoBuilderInput});
  };

  const uploaderProps = {
    name: 'file',
    accept: '.png, .svg, .webp',
    action: `${process.env.REACT_APP_API_URL}/files`,
    headers: {
      authorization: `Bearer ${token}`
    },
    onChange({file}: any) {
      if (file.status === 'error') {
        message.error(`${file.name} file upload failed.`);
      }
      if (file?.response?.fileUrl) {
        setBannerImage(file?.response?.fileUrl);
      }
      if (file.status === 'removed') {
        setBannerImage('');
      }
    }
  };

  const onDateTimeChange = (value: any) => {
    setDateTime(moment.tz(value.format('YYYY-MM-DD HH:mm'), 'America/New_York'));
  };

  const isShowUploader = (values: BannerVariants[] | undefined) => values?.some(value => UPLOADER_TYPES.includes(value));
  const setValue = (types: any) => setIsUploaderVisible(types);

  return (
    <PageWrapper pageTitle={'Promo Builder Create'}>
      <Card loading={isLoading}>
        <Form layout="vertical" form={form} name="filter" onFinish={handleSubmit}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name={'offerTitle'} label={'Title'} rules={[{required: true}]}>
                <Input placeholder="Promo title" />
              </Form.Item>
              <Form.Item name={'offerDescription'} label={'Description'}>
                <TextArea rows={4} placeholder="Promo description" />
              </Form.Item>
              <Form.Item name={'groupId'} label={'Select Product Group'} rules={[{required: true}]}>
                <Select placeholder="Product group" onChange={onGroupChange}>
                  {groups &&
                    Array.from(groups?.values())?.map(g => (
                      <Select.Option key={g.id} value={g.id}>
                        {g.title}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              <Row>
                <Form.Item name={'discount'} label={'Discount'} className="mr15" rules={[{required: true}]}>
                  <InputNumber placeholder="0" min={0} />
                </Form.Item>
                <Form.Item name={'unit'} label={'Unit'} rules={[{required: true}]}>
                  <Select>
                    <Select.Option value={UnitType.PERCENT}>%</Select.Option>
                    <Select.Option value={UnitType.USD}>$</Select.Option>
                  </Select>
                </Form.Item>
              </Row>
                <Form.Item
                  name={'expDate'}
                  label="Eastern Time (ET)"
                  rules={[{required: true}]}
                >
                  <DatePicker onChange={onDateTimeChange} showTime format="YYYY-MM-DD HH:mm" className="mr15" />
                </Form.Item>
              <Checkbox onChange={onUseQuantityChange}>Set quantity</Checkbox>
          {useQuantity && (
                groupItems?.map(item => (
                  <Form.Item name={`quantity_${item.id}`} key={item.id} label={item?.title} initialValue={0}>
                    <InputNumber min={0} />
                  </Form.Item>
                )))}
              <Row justify="end" className="mt15">
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
              </Row>
            </Col>
            <Col span={12}>
              <Form.Item
                rules={[{required: true, message: 'Please select variant'}]}
                name={['promoBanner', 'types']}
                label="Promo Placement"
              >
                <Checkbox.Group onChange={setValue} className="mb10">
                  <Row>
                    <Col span={24}>
                      <Checkbox value={BannerVariants.TOP_BAR}>Nano bar</Checkbox>
                    </Col>
                    {/* <Col span={24}>
                      <Checkbox value={BannerVariants.ORDER_TYPE}>Show order type banner?</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value={BannerVariants.ORDER_REVIEW}>Show order review banner?</Checkbox>
                    </Col> */}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
              {isShowUploader(isUploaderVisible) && (
                <Form.Item
                  label="Banner"
                  rules={[{required: true, message: 'Please upload image'}]}
                  valuePropName="fileList"
                  getValueFromEvent={normalizeFile}
                >
                  <Upload.Dragger {...uploaderProps} maxCount={1} name="files">
                    {bannerImage ? (
                      <img src={bannerImage} alt="logo" height="100" className="w100" />
                    ) : (
                      <>
                        <UserAddOutlined style={{fontSize: '50px'}} />
                        <p className="ant-upload-text">Drag & Drop your logo</p>
                      </>
                    )}
                  </Upload.Dragger>
                  <h5>*Accepted formats: .png .svg .webp</h5>
                </Form.Item>
              )}
            </Col>
          </Row>
        </Form>
      </Card>
    </PageWrapper>
  );
}

export default PromoCreate;
