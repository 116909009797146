import {gql} from 'graphql-tag';

export const GET_SHIPPING_RULES = gql`
  query getShippingRules {
    getShippingRules {
      id
      orderType
      groupId
      priceRange
      order
      quantityRange
      price
      retailerId
      recommendationThreshold
    }
  }
`;
