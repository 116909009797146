import {Card, Tabs} from 'antd';
import {PageWrapper} from 'components/PageWrapper';
import {IQueryEnum, makeQueries} from 'core/api';
import {IPriceList} from 'core/models/priceLists/types';
import {IProductBrand} from 'core/models/productBrands/types';
import {IProductCategory} from 'core/models/productCategories/types';
import {ISupplier} from 'core/models/suppliers/types';

import ProductTab from './components/ProductTab';
const {TabPane} = Tabs;
interface IData {
  productBrands: IProductBrand[];
  productCategories: IProductCategory[];
  priceLists: IPriceList[];
  suppliers: ISupplier[];
}

export enum IMethodEnum {
  ProductBrand = 'ProductBrand',
  ProductCategory = 'ProductCategory',
  PriceList = 'PriceList',
  Supplier = 'Supplier'
}

function GeneralSettings() {
  const {
    data: {productCategories = [], productBrands = [], priceLists = [], suppliers = []},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetProductCategories},
    {type: IQueryEnum.GetProductBrands},
    {type: IQueryEnum.GetPriceLists},
    {type: IQueryEnum.GetSuppliers}
  ]);
  const variants = [
    {method: IMethodEnum.ProductBrand, value: productBrands, tabName: 'Product Brand'},
    {method: IMethodEnum.ProductCategory, value: productCategories, tabName: 'Product Categories'},
    {method: IMethodEnum.PriceList, value: priceLists, tabName: 'Product Price List'},
    {method: IMethodEnum.Supplier, value: suppliers, tabName: 'Product Supplier'}
  ];
  return (
    <PageWrapper pageTitle='General Settings' topMenuType='settings'>
      <Card loading={isLoading}>
        <Tabs tabPosition={'left'}>
          {variants.map((product, index) => (
            <TabPane tab={product.tabName} key={index + 1}>
              <ProductTab products={product.value} type={product.method} />
            </TabPane>
          ))}
        </Tabs>
      </Card>
    </PageWrapper>
  );
}

export default GeneralSettings;
