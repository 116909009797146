import {Col, Row} from 'antd';
import {PageWrapper} from 'components/PageWrapper';
import {getRetailer} from 'core/models/retailers/utils';
import {useEffect} from 'react';
import {dataLayerEvents} from 'utils/dataLayer';
import BuyingSettings from './components/BuyingSettings';
import ProductAssignment from './components/ProductAssignment';

function RetailerBuyingProfile() {
  const {retailer, isLoading} = getRetailer();

  useEffect(() => {
    if (!isLoading && retailer) {
      dataLayerEvents('view_retailers_buyingprofile', retailer);
    }
  }, [retailer, isLoading]);

  return (
    <PageWrapper pageTitle={retailer?.name} withRetailerSelector topMenuType={'retailers'}>
      <Row>
        <Col xs={6} className="pr15">
          <BuyingSettings />
        </Col>
        <Col xs={18}>
          <ProductAssignment />
        </Col>
      </Row>
    </PageWrapper>
  );
}

export default RetailerBuyingProfile;
