import {gql} from 'graphql-tag';

export const CREATE_GROUP = gql`
  mutation createGroup($title: String!, $description: String, $type: String!, $productIds: [String], $productItemIds: [String]) {
    createGroup(title: $title, description: $description, type: $type, productIds: $productIds, productItemIds: $productItemIds) {
      id
      title
      description
      type
      productIds
      productItemIds
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation updateGroup($id: ID!, $title: String!, $description: String, $type: String!, $productIds: [String], $productItemIds: [String]) {
    updateGroup(id: $id, title: $title, description: $description, type: $type, productIds: $productIds, productItemIds: $productItemIds) {
      id
      title
      description
      type
      productIds
      productItemIds
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation deleteGroup($id: ID!) {
    deleteGroup(id: $id)
  }
`;
