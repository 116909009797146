import {gql} from 'graphql-tag';

export const GET_TASKS = gql`
  query getTasks($retailerId: ID) {
    getTasks(retailerId: $retailerId) {
      id
      asanaId
      title
      description
      modifiedAt
      retailerId
      status
    }
  }
`;
