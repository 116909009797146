/* eslint-disable react-hooks/exhaustive-deps */
import {Button, Col, DatePicker, Drawer, Form, Input, Row, Select} from 'antd';
import {Icon, IconColorEnum, IconTypeEnum} from 'components/Icon';
import moment from 'moment';
import {useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import OrderFilter from './OrderFilter';

const {Option} = Select;

// FIXME: Move dates to constants
// FIXME: Fix layout when date filter is missing
export enum DrawerFilterTypeEnum {
  OrderFilter = 'OrderFilter'
}

export interface ITableFilterConfig {
  fulltextPlaceholder: string;
  fulltextParams: string[];
  dateFilter?: boolean;
  fullDateFilter?: boolean;
  drawerFilterType?: DrawerFilterTypeEnum;
}

export interface ITableFilter {
  config: ITableFilterConfig;
  fullText: string;
  setFulltext: React.Dispatch<React.SetStateAction<string>>;
}

function getDrawerFilter(filterType: DrawerFilterTypeEnum) {
  if (filterType === DrawerFilterTypeEnum.OrderFilter) return <OrderFilter />;
}

function TableFilter({config, setFulltext, fullText}: ITableFilter) {
  const history = useHistory();
  const params = useMemo(() => new URLSearchParams(history.location.search), [history.location.search]);
  const [form] = Form.useForm();

  /** Handle Fulltext Filter */
  const handleFulltextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFulltext(e.target.value);
  };

  /** Handle Date Filter */
  const [days, setDays] = useState<number | undefined>();

  function handleFilterUpdate() {
    params?.set('page', '1');
    history.replace({
      pathname: history.location.pathname,
      search: params.toString()
    });
  }

  function setDayFromParam(daysCount: number) {
    const date = moment().subtract(daysCount, 'days').format('MM-DD-YYYY');
    if (daysCount > 0) {
      params?.set('dateFrom', date);
    } else {
      params?.delete('dateFrom');
    }
    params?.set('page', '1');

    handleFilterUpdate();
  }

  function handleDateFromFilterChange(date?: moment.Moment | null) {
    if (date) {
      params?.set('dateFrom', date.format('MM-DD-YYYY'));
    } else {
      params?.delete('dateFrom');
    }
    handleFilterUpdate();
  }

  function handleDateToFilterChange(date?: moment.Moment | null) {
    if (date) {
      params?.set('dateTo', date.format('MM-DD-YYYY'));
    } else {
      params?.delete('dateTo');
    }
    handleFilterUpdate();
  }

  useEffect(() => {
    if (config.dateFilter && days === undefined && params.get('page')) {
      const dateFrom = params.get('dateFrom');
      const momentDateFrom = moment(new Date(dateFrom!));
      let dayDiff = 0;

      if (dateFrom && momentDateFrom.isValid()) {
        const diff = moment().diff(momentDateFrom, 'days');
        if (diff === 7 || diff === 31 || diff === 92 || diff === 183 || diff === 365) {
          dayDiff = diff;
        }
      }

      handleDateFilterChange(dayDiff);
    }
  }, [params]);

  function handleDateFilterChange(daysCount: number) {
    setDays(daysCount);
    setDayFromParam(daysCount);
  }

  /** Handle Drawer Filter */
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  function closeDrawer() {
    setIsDrawerOpen(false);
  }

  function openDrawer() {
    setIsDrawerOpen(true);
  }

  const handleFilterChange = (values: any) => {
    Object.entries(values).forEach(([key, val]) => {
      if ((Array.isArray(val) && val.length > 0) || (!Array.isArray(val) && Boolean(val))) {
        params?.set(key, `${val}`);
      } else {
        params.delete(key);
      }
    });

    handleFilterUpdate();
    closeDrawer();
  };

  const onReset = () => {
    const keys = form.getFieldsValue();
    let newValues: any = {};
    for (const key in keys) {
      newValues[key] = null;
    }
    form.setFieldsValue(newValues);
  };

  return (
    <>
      <Row className="bg-white mb15 b b-r4 table-filter" align="middle">
        <Col span={config.dateFilter || config.fullDateFilter ? 18 : 24}>
          <Input
            size="large"
            placeholder={config.fulltextPlaceholder}
            allowClear
            value={fullText}
            onChange={handleFulltextChange}
            prefix={<Icon type={IconTypeEnum.Search} color={IconColorEnum.Black} />}
          />
        </Col>
        {config.dateFilter && (
          <Col span={config.drawerFilterType ? 4 : 6}>
            <Select size="large" className="w100 table-filter-select" value={days} onChange={handleDateFilterChange}>
              <Option value={0}>All Time</Option>
              <Option value={7}>Last 7 days</Option>
              <Option value={31}>Last Month</Option>
              <Option value={92}>Last 3 Months</Option>
              <Option value={183}>Last 6 Months</Option>
              <Option value={365}>Last Year</Option>
            </Select>
          </Col>
        )}
        {config.fullDateFilter && (
          <Col span={config.drawerFilterType ? 4 : 6}>
            <DatePicker
              className="w-1/2 h-[50px]"
              size="large"
              value={params.get('dateFrom') ? moment(params.get('dateFrom')) : undefined}
              onChange={handleDateFromFilterChange}
              format="MM/DD/YYYY"
              placeholder="Date From"
            />
            <DatePicker
              className="w-1/2 h-[50px]"
              size="large"
              value={params.get('dateTo') ? moment(params.get('dateTo')) : undefined}
              onChange={handleDateToFilterChange}
              format="MM/DD/YYYY"
              placeholder="Date To"
            />
          </Col>
        )}
        {config.drawerFilterType && (
          <Col span={2} onClick={openDrawer}>
            <Button className="w-full  table-filter-button">
              <Icon className="mt-2" type={IconTypeEnum.Filter} color={IconColorEnum.Black} />
            </Button>
          </Col>
        )}
        <Drawer title="Filters" placement="right" onClose={closeDrawer} visible={isDrawerOpen} width={500}>
          <Form layout="vertical" form={form} name="filter" onFinish={handleFilterChange}>
            {config?.drawerFilterType && getDrawerFilter(config?.drawerFilterType)}
            <Form.Item wrapperCol={{span: 24}} className="tar">
              <Row justify="space-between">
                <Button onClick={onReset} className="c-darkblue" type="text" htmlType="button">
                  Clear All
                </Button>
                <Button type="primary" htmlType="submit">
                  Apply filters
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Drawer>
      </Row>
    </>
  );
}

export default TableFilter;
