import {Avatar, Badge, Button, Col, List, notification, Popover, Row} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import {IMutationEnum, IQueryEnum, makeMutation, makeQueries} from 'core/api';
import {INotification} from 'core/models/notifications/types';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Icon, IconColorEnum, IconTypeEnum} from '../../../components/Icon';

interface IData {
  notifications: INotification[];
}

const Notification = React.memo(() => {
  const [oldNotifications, setOldNotifications] = useState<Map<string, INotification> | undefined>();
  const history = useHistory();

  const deleteMutation = makeMutation({
    type: IMutationEnum.DeleteNotification
  });

  const {
    data: {notifications},
    isLoading
  } = makeQueries<IData>([{type: IQueryEnum.GetNotifications, pollingInterval: 1000 * 10}]);

  useEffect(() => {
    if (notifications && notifications?.length !== oldNotifications?.size) {
      setOldNotifications(
        new Map(
          notifications.map(notificationItem => {
            if (oldNotifications && !oldNotifications.has(notificationItem.id)) {
              notification.info({message: notificationItem.title, description: notificationItem.description, duration: 1});
            }
            return [notificationItem.id, notificationItem];
          })
        )
      );
    }
  }, [oldNotifications, notifications]);

  const handleRedirect = (notificationItem: INotification) => () => {
    if (notificationItem.redirectUrl) {
      history.push(notificationItem.redirectUrl);
    }
  };

  const deleteNotification = (notificationItem: INotification) => () => {
    deleteMutation.mutate({id: notificationItem.id});
  };

  const content = (
    <List
      loading={isLoading}
      dataSource={notifications}
      className="notification-box"
      renderItem={notificationItem => (
        <List.Item>
          <Row className="w100">
            <Col span={2}>
              <Avatar className="bg-green" style={{verticalAlign: 'middle'}} size="small">
                U
              </Avatar>
            </Col>
            <Col span={15} className={notificationItem.redirectUrl ? 'cursor-pointer' : ''} onClick={handleRedirect(notificationItem)}>
              <h4 className="m0 mb5">{notificationItem.title}</h4>
              <span>{notificationItem.description}</span>
            </Col>
            <Col span={7} className="tar">
              <Button onClick={deleteNotification(notificationItem)} icon={<DeleteOutlined style={{color: IconColorEnum.Red}} />} />
            </Col>
          </Row>
        </List.Item>
      )}
    />
  );

  return (
    <Popover content={content} placement="bottomRight" className="mr15" arrowPointAtCenter>
      <Badge count={notifications?.length}>
        <Icon type={IconTypeEnum.Notification} color={IconColorEnum.White} />
      </Badge>
    </Popover>
  );
});

export default Notification;
