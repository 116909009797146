import {gql} from 'graphql-tag';

export const GET_NOTIFICATIONS = gql`
  query getNotifications {
    getNotifications {
      id
      title
      description
      createdAt
      redirectUrl
    }
  }
`;