export enum GroupTypesEnum {
  Products = 'Products',
  ProductItems = 'Product Items'
}

export interface IGroupInput {
  title: string;
  description: string;
  type: GroupTypesEnum;
  productIds?: [string];
  productItemIds?: [string];
}

export interface IGroup extends IGroupInput {
  id: string;
}