/* eslint-disable react-hooks/exhaustive-deps */
import {PlusOutlined} from '@ant-design/icons';
import EnvironmentOutlined from '@ant-design/icons/lib/icons/EnvironmentOutlined';
import {Button, Card, Input, List} from 'antd';
import {Icon, IconTypeEnum, IconColorEnum} from 'components/Icon';
import {PageWrapper} from 'components/PageWrapper';
import {IQueryEnum, makeQueries} from 'core/api';
import AuthStore from 'core/auth';
import {IAddress} from 'core/models/addresses/types';
import {getRetailer} from 'core/models/retailers/utils';
import {pageUrls} from 'core/router/pages';
import {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {dataLayerEvents} from 'utils/dataLayer';
import {getUrl} from 'utils/getUrl';
import AddressCard from './components/AddressCard';
import GoogleMap from './components/GoogleMap';
import constants from '../../../../constants';
import './style.less';
interface IData {
  addresses: IAddress[];
}

function RetailerAddressList() {
  const history = useHistory();
  const {isRetailer} = useContext(AuthStore);
  const {retailer, isLoading: isRetailerLoading} = getRetailer();
  const [filter, setFilter] = useState('');

  useEffect(() => {
    if (!isRetailerLoading && retailer) {
      dataLayerEvents('view_retailers_addresses', retailer);
    }
  }, [retailer, isRetailerLoading]);

  // Store, that will be showed on the map
  const [selectedAddress, setSelectedAddress] = useState<IAddress | undefined>();

  const {
    data: {addresses},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetStates},
    {type: IQueryEnum.GetAddresses, variables: {retailerId: retailer?.id}, isEnabled: Boolean(retailer)}
  ]);

  useEffect(() => {
    if (addresses && !selectedAddress) {
      setSelectedAddress(addresses[0]);
    }
  }, [addresses, selectedAddress]);

  const onCreateClick = () => {
    history.push(getUrl(pageUrls.retailerAddressCreate, {retailerId: retailer?.id}));
  };

  function handleFilterChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFilter(e.target.value);
  }

  const headerActions = (
    <Button type="primary" icon={<PlusOutlined />} onClick={onCreateClick}>
      Create
    </Button>
  );

  const filteredAddresses = filter.length > 0 ? addresses.filter(a => a.address.toLowerCase().includes(filter.toLowerCase())) : addresses;

  return (
    <PageWrapper
      pageTitle={retailer?.name}
      topMenuType={'retailers'}
      withRetailerSelector
      actions={!isRetailer ? headerActions : undefined}
    >
      <Card>
        <Input
          className="mb10"
          size="large"
          placeholder={'Search for an address...'}
          allowClear
          value={filter}
          onChange={handleFilterChange}
          prefix={<Icon type={IconTypeEnum.Search} color={IconColorEnum.Black} />}
        />
        <div className="retail-stores d-flex">
          <List
            className="w55"
            grid={{gutter: 30, column: 1}}
            loading={isLoading}
            dataSource={filteredAddresses}
            pagination={{pageSize: constants.pageSize, hideOnSinglePage: true, showSizeChanger: false}}
            renderItem={(address: IAddress) => (
              <List.Item key={address.id}>
                <AddressCard address={address} selectedAddressId={selectedAddress?.id} setAddress={setSelectedAddress} />
              </List.Item>
            )}
          />

          <div className="map-container">
            <GoogleMap icon={EnvironmentOutlined} address={selectedAddress?.address} />
          </div>
        </div>
      </Card>
    </PageWrapper>
  );
}

export default RetailerAddressList;
