import GoogleMapReact, {Coords} from 'google-map-react';
import {useState, useEffect} from 'react';
import Geocode from 'react-geocode';

Geocode.setApiKey(process.env.REACT_APP_MAP_API_KEY!);
// Geocode.enableDebug(true);

const GoogleMap = (props: any) => {
  const [location, setLocation] = useState<Coords>({lat: 0, lng: 0});

  const Icon = props.icon;
  const IconComponent = (iconProps: any) => <Icon style={iconProps.styles} />;

  useEffect(() => {
    let isMounted = true;
    if (props.address) {
      Geocode.fromAddress(props.address).then(
        response => {
          const {lat, lng} = response.results[0].geometry.location;
          if (isMounted) setLocation({lat, lng});
        },
        error => {
          // console.error(error);
        }
      );
    }
    return () => {
      isMounted = false;
    };
  }, [props.address]);

  return (
    <GoogleMapReact bootstrapURLKeys={{key: process.env.REACT_APP_MAP_API_KEY!}} center={location} defaultZoom={10} onClick={props.onClick}>
      <IconComponent
        lat={location.lat}
        lng={location.lng}
        styles={{...props.styles, fontSize: '26px', transform: 'translate(-50%,-50%)'}}
      />
    </GoogleMapReact>
  );
};

export default GoogleMap;
