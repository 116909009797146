import {makeQueries, IQueryEnum} from 'core/api';
import {IProductBrand} from 'core/models/productBrands/types';
import {IProductCategory} from 'core/models/productCategories/types';
import {IProduct} from 'core/models/products/types';
import {memo} from 'react';
import {getClassName} from 'utils/getClassName';
import constants from '../../constants';

interface ProductListItemProps {
  product: IProduct;
  activeProductId?: string;
  handleProductClick: (product: IProduct) => () => void;
}

interface IData {
  productBrands?: IProductBrand[];
  productCategories?: IProductCategory[];
}

const ProductListItem = memo(({product, activeProductId, handleProductClick}: ProductListItemProps) => {
  const {
    data: {productBrands = [], productCategories = []}
  } = makeQueries<IData>([{type: IQueryEnum.GetProductBrands}, {type: IQueryEnum.GetProductCategories}]);

  return (
    <li
      onClick={handleProductClick(product)}
      className={getClassName(
        activeProductId === product.id
          ? 'ring-2 ring-offset-2 ring-cyan-500'
          : 'focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-cyan-500',
        'relative bg-white px-1 py-2 rounded-md cursor-pointer flex flex-col justify-between'
      )}
    >
      <p className="mb-2 block text-xs font-medium text-gray-900 truncate pointer-events-none text-center">{product.title}</p>
      <div className={'group block w-full aspect-w-10 aspect-h-7 overflow-hidden'}>
        <img
          src={product.images?.[0] || constants.image.fallback}
          alt={product.title}
          className={getClassName(activeProductId === product.id ? '' : 'group-hover:opacity-50', 'object-cover rounded-lg mx-auto')}
        />
      </div>
      {product.archived ? (
        <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800 mx-auto mt-2">
          Archived
        </span>
      ) : (
        <p className="mt-2 text-xs font-medium truncate pointer-events-none flex justify-between 2xl:px-4">
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-indigo-100 text-indigo-800 mr-1">
            {productBrands.find(b => b.id === product.productBrandId)?.title || 'No Brand'}
          </span>
          <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
            {productCategories.find(b => b.id === product.productCategoryId)?.title || 'No Category'}
          </span>
        </p>
      )}
    </li>
  );
});

export default ProductListItem;
