import {IAttachments, IReturn} from 'core/models/returns/types';
import create from 'zustand';

interface IReturnStore {
  allowedStep: number;
  setAllowedStep: (step: number) => void;

  returnItem: Partial<IReturn>;
  updateReturn: (returnFields: Partial<IReturn>) => void;
  updateAttachments: (attachments: IAttachments) => void;
}

const ReturnStore = create<IReturnStore>(set => ({
  allowedStep: 0,
  setAllowedStep: (step: number) => set({allowedStep: step}),
  returnItem: {},
  updateReturn: returnFields => {
    set(({returnItem}) => ({
      returnItem: {...returnItem, ...returnFields}
    }));
  },
  updateAttachments: attachments => {
    set(({returnItem}) => ({
      returnItem: {...returnItem, attachments: {...attachments}}
    }));
  }
}));

export {ReturnStore};
