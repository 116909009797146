import {BookmarkIcon} from '@heroicons/react/24/solid';
import {Form, Select} from 'antd';
import {IMutationEnum, IQueryEnum, makeMutation, makeQueries} from 'core/api';
import {ISupplier} from 'core/models/suppliers/types';
import {useEffect} from 'react';

const {Option} = Select;

interface IData {
  suppliers: ISupplier[];
}
interface IProps {
  productId: string;
  setAddSupplierShown: (isShown: boolean) => void;
}

const ProductAddSupplier = ({productId, setAddSupplierShown}: IProps) => {
  const mutation = makeMutation({
    type: IMutationEnum.CreateProductItemGroup,
    loadingMsg: 'Creating product item...',
    successMsg: 'Product item created.'
  });

  const {
    data: {suppliers = []},
    isLoading
  } = makeQueries<IData>([{type: IQueryEnum.GetSuppliers}]);

  useEffect(() => {
    if (mutation.isSuccess) {
      setAddSupplierShown(false);
    }
  }, [mutation, setAddSupplierShown]);

  function onSubmit(value: any) {
    mutation.mutate({
      productId,
      ...value
    });
  }

  return (
    <div className="flex flex-column bg-slate-50 rounded-md p-4 mb-4">
      <Form className="w-full" name="select-supplier" onFinish={onSubmit} layout="inline">
        <Form.Item name="supplierId" className="w-full" rules={[{required: true, message: 'Please select supplier!'}]}>
          <Select placeholder={'Select Supplier'} className="w-full" loading={isLoading} allowClear>
            {suppliers?.map((supplier: any) => (
              <Option key={supplier.id} value={supplier.id}>
                {supplier.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div className="w-full text-right">
          <button
            type="submit"
            className="mt-2 cursor-pointer inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-gray-800 hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
          >
            <BookmarkIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            Create
          </button>
        </div>
      </Form>
    </div>
  );
};

export default ProductAddSupplier;
