import {gql} from 'graphql-tag';

export const CREATE_TASK = gql`
  mutation createTask($taskInput: TaskInput) {
    createTask(taskInput: $taskInput) {
      id
      title
      description
      modifiedAt
      retailerId
      status
    }
  }
`;
