
import { Result } from 'antd';
import { memo, useEffect } from 'react';
import {sendErrorViaSlack} from '../../../utils/sendErrorViaSlack';

const ErrorBoundary = memo(() => {
  useEffect(() => {
    sendErrorViaSlack('Front-End', 'Error: 500');
  }, []);

  return <Result status="500" title="500" subTitle="Sorry, we are having some problems. We already aware of it, and are trying to solve it ASAP" />;
});

export default ErrorBoundary;
