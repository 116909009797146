import {Tag} from 'antd';
import {IProductItemTitleEnum} from '../productItems/types';
import {ReturnIssueTypeEnum, ReturnStateEnum} from './types';

export const ReturnIssueTypeRenderer = (state: ReturnIssueTypeEnum) => {
  let tagColor;
  let finalState: string = state;
  switch (state) {
    case ReturnIssueTypeEnum.Warranty:
      tagColor = 'gold';
      break;
    case ReturnIssueTypeEnum.Comfort:
      tagColor = 'blue';
      break;
    case ReturnIssueTypeEnum.Damaged:
      tagColor = 'red';
      finalState = 'Damaged Upon Arrival';
      break;
    default:
      return state;
  }
  return <Tag color={tagColor}>{finalState}</Tag>;
};

export const ReturnStateRenderer = (state: string) => {
  let tagColor;
  switch (state) {
    case ReturnStateEnum.Created:
      tagColor = 'cyan';
      break;
    case ReturnStateEnum.Approved:
      tagColor = 'green';
      break;
    case ReturnStateEnum.Rejected:
      tagColor = 'red';
      break;
    default:
      return state;
  }
  return <Tag color={tagColor}>{state}</Tag>;
};
export const productItemRenderer = (title: any) => {
  let tagColor;
  switch (title) {
    case IProductItemTitleEnum.Full:
      tagColor = 'cyan';
      break;
    case IProductItemTitleEnum.Twin:
      tagColor = 'green';
      break;
    case IProductItemTitleEnum.Queen:
      tagColor = 'cyan';
      break;
    case IProductItemTitleEnum.XL:
      tagColor = 'orange';
      break;
    case IProductItemTitleEnum.King:
      tagColor = 'volcano';
      break;
    default:
      return title;
  }
  return <Tag color={tagColor}>{title}</Tag>;
};
