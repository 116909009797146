import {Table as AntdTable} from 'antd';
import {ColumnType, ExpandableConfig, TablePaginationConfig} from 'antd/lib/table/interface';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import constants from '../../constants';
import TableFilter, {ITableFilterConfig} from './components/TableFilter';
import './style.less';

/** Table component props */
export interface ITable<EntityType> {
  columns: IColumn<EntityType>[];
  data?: EntityType[];
  loading?: boolean;
  /** Default true */
  pagination?: boolean;
  expandable?: ExpandableConfig<EntityType>;
  rowClassName?: string;
  onRowClick?: (row: EntityType) => void;
  setFilter?: (values: any) => void;
  /** Overwrite default empty element */
  empty?: JSX.Element;
  filterConfig?: ITableFilterConfig;
}

export interface IColumn<EntityType> extends ColumnType<EntityType> {
  hide?: boolean;
}

/** Table component */
const Table = <EntityType extends {id: string}>({
  empty,
  data = [],
  columns,
  loading,
  pagination = true,
  expandable,
  onRowClick,
  rowClassName,
  filterConfig
}: ITable<EntityType>) => {
  /** Handle page query changes */
  const history = useHistory();
  const params = useMemo(() => new URLSearchParams(history.location.search), [history.location.search]);
  const pageFromUrl = Number(params?.get('page') || 1);
  const [currentPage, setCurrentPage] = useState(pageFromUrl);

  const setPageParam = useCallback(
    (page: string) => {
      params?.set('page', page);
      setCurrentPage(Number(page));
      history.replace({
        pathname: history.location.pathname,
        search: params.toString()
      });
    },
    [params, history]
  );

  useEffect(() => {
    if (pageFromUrl !== currentPage) {
      setCurrentPage(pageFromUrl);
    }
  }, [currentPage, pageFromUrl]);

  function handleTableChange({current}: any) {
    setPageParam(current);
  }

  const paginationConfig: TablePaginationConfig = {
    pageSize: constants.pageSize,
    hideOnSinglePage: true,
    showSizeChanger: false,
    current: currentPage ? currentPage : 1
  };

  /** Handle Fulltext Search */
  const [fullText, setFullText] = useState('');
  const compareValues = useCallback((item: any, key: string) => item[key]?.toLowerCase().includes(fullText.toLowerCase()), [fullText]);

  const filteredData = useMemo(
    () => filterConfig && fullText && data?.filter(i => filterConfig.fulltextParams!.some(p => compareValues(i, p))),
    [filterConfig, fullText, data, compareValues]
  );

  return (
    <>
      {filterConfig && <TableFilter config={filterConfig} fullText={fullText} setFulltext={setFullText} />}
      <AntdTable
        locale={empty && {emptyText: empty}}
        loading={loading}
        columns={columns}
        expandable={expandable}
        dataSource={filteredData || data}
        rowKey={record => record.id}
        pagination={pagination && paginationConfig}
        rowClassName={`${rowClassName || ''} ${onRowClick ? 'cursor-pointer' : ''}`}
        onRow={record => ({
          onClick: () => onRowClick && onRowClick(record)
        })}
        onChange={handleTableChange}
      />
    </>
  );
};

export default Table;
