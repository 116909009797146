/* eslint-disable react-hooks/exhaustive-deps */
import {UploadOutlined} from '@ant-design/icons';
import {Button, Card, Form, message, Upload} from 'antd';
import AuthStore from 'core/auth';
import {ReturnStore} from 'core/stores/ReturnStore';
import {memo, useContext, useEffect, useState} from 'react';
import {IReturnsCreateStep} from '../ReturnCreate';
import {normalizeFile} from 'utils/normalizeFile';
import {IAttachments, ReturnIssueTypeEnum} from 'core/models/returns/types';

const uploadSetting = (token: string, setImg: (img: []) => void) => ({
  name: 'file',
  multiple: true,
  action: `${process.env.REACT_APP_API_URL}/files`,
  headers: {
    authorization: `Bearer ${token}`
  },
  onChange({file, fileList}: any) {
    if (file.status === 'error') {
      message.error(`${file.name} file upload failed.`);
    }
    if (file?.response?.fileUrl) {
      setImg(fileList);
    }
    if (file.status === 'removed') {
      const filteredImages = fileList.filter((item: any) => item.uid !== file.uid);
      setImg(filteredImages);
    }
  }
});

const handleAttachment = (values: any, attachment: string) =>
  values?.[attachment]?.length > 0 ? values?.[attachment]?.map((attach: any) => attach.response?.fileUrl) : null;

const ReturnsStep3: React.FC<IReturnsCreateStep> = memo(({prevStep, nextStep, formLayout}) => {
  const [form] = Form.useForm();
  const {token} = useContext(AuthStore);
  const {returnItem, updateAttachments} = ReturnStore.getState();
  const [receiptImg, setReceiptImg] = useState<string[]>([]);
  const [lawTagImg, setLawTagImg] = useState<string[]>([]);
  const [bolImg, setBolImg] = useState<string[]>([]);
  const [damagedProductImg, setDamagedProductImg] = useState<string[]>([]);

  useEffect(() => {
    if (!receiptImg?.length && returnItem?.attachments?.receipt) {
      setReceiptImg(returnItem.attachments.receipt);
    }
    if (!damagedProductImg?.length && returnItem?.attachments?.damagedProduct) {
      setDamagedProductImg(returnItem.attachments.damagedProduct);
    }
    if (!lawTagImg?.length && returnItem?.attachments?.lawTag) {
      setLawTagImg(returnItem.attachments.lawTag);
    }
    if (!bolImg?.length && returnItem?.attachments?.bol) {
      setBolImg(returnItem.attachments.bol);
    }
  }, [returnItem.attachments]);

  function onSubmit(values: any) {
    const receipt = handleAttachment(values, 'receipt');
    const damagedProduct = handleAttachment(values, 'damagedProduct');
    const lawTag = handleAttachment(values, 'lawTag');
    const bol = handleAttachment(values, 'bol');

    updateAttachments({receipt, damagedProduct, lawTag, bol});
    nextStep();
  }

  const uploaderReceiptProps = uploadSetting(token, setReceiptImg);
  const uploaderDamagedProductProps = uploadSetting(token, setDamagedProductImg);
  const uploaderLawTagProps = uploadSetting(token, setLawTagImg);
  const uploaderBoLProps = uploadSetting(token, setBolImg);

  function normalizeInitialValues() {
    const initialValues =
      returnItem?.attachments &&
      Object.keys(returnItem?.attachments).reduce((obj: any, value: any, objIndex: any) => {
        if (value) {
          obj[value] = returnItem?.attachments?.[value]?.map((item: any, index: number) => ({
            uid: index,
            name: item?.split('.com/')[1],
            response: {fileUrl: returnItem?.attachments?.[value as keyof IAttachments]?.[index]}
          }));
        }

        return obj;
      }, {});
    return initialValues;
  }

  return (
    <Card>
      <Form {...formLayout} layout="vertical" form={form} name="create-return" onFinish={onSubmit} initialValues={normalizeInitialValues()}>
        {returnItem.issueType !== ReturnIssueTypeEnum.Damaged && (
          <Form.Item
            label="Upload customer receipt"
            name="receipt"
            getValueFromEvent={normalizeFile}
            valuePropName="fileList"
            rules={[{required: true}]}
          >
            <Upload {...uploaderReceiptProps} name="files">
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </Form.Item>
        )}
        <Form.Item
          valuePropName="fileList"
          label="Upload picture of cut off law tag showing date"
          name="lawTag"
          getValueFromEvent={normalizeFile}
          rules={[{required: true}]}
        >
          <Upload {...uploaderLawTagProps} name="files">
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </Form.Item>

        {(returnItem.issueType === ReturnIssueTypeEnum.Warranty || returnItem.issueType === ReturnIssueTypeEnum.Damaged) && (
          <Form.Item
            valuePropName="fileList"
            label="Upload picture of damaged mattress"
            name="damagedProduct"
            getValueFromEvent={normalizeFile}
            rules={[{required: true}]}
          >
            <Upload {...uploaderDamagedProductProps} name="files">
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </Form.Item>
        )}
        {returnItem.issueType === ReturnIssueTypeEnum.Damaged && (
          <Form.Item
            label="Upload Bill of Landing"
            name="bol"
            getValueFromEvent={normalizeFile}
            valuePropName="fileList"
            rules={[{required: true}]}
          >
            <Upload {...uploaderBoLProps} name="files">
              <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
          </Form.Item>
        )}

        <Form.Item wrapperCol={{span: 14}} className="tar">
          <Button htmlType="button" onClick={prevStep} className="mr10">
            Back
          </Button>
          <Button htmlType="submit" type="primary">
            Next
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
});

export default ReturnsStep3;
