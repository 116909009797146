import {gql} from 'graphql-tag';

export const GET_TASK_COMMENTS = gql`
  query getTaskComments($taskId: ID) {
    getTaskComments(taskId: $taskId) {
      id
      text
      createdBy
      createdAt
    }
  }
`;
