export interface IReturn {
  id: string;
  retailerId: string;
  createdBy: string;
  orderSO?: string;
  state: ReturnStateEnum;
  productItemId: string;
  issueType: ReturnIssueTypeEnum;
  customer?: ICustomerInfo;
  quantity: number;
  description: string;
  isPickedByRetailer?: boolean;
  wholeSalePrice?: number;
  comments?: string;
  orderReference?: string;
  reference?: string;
  attachments: IAttachments;
  processedBy: string;
  processedReason: string;
  createdById: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

interface IObjectKeys {
  [key: string]: string[] | null;
}
export interface IAttachments extends IObjectKeys {
  receipt: string[] | null;
  damagedProduct: string[] | null;
  lawTag: string[];
  bol: string[];
}

export enum ReturnIssueTypeEnum {
  Comfort = 'Comfort',
  Warranty = 'Warranty',
  Damaged = 'Damaged'
}

export interface ICustomerInfo {
  name?: string;
  receiptNumber?: string;
  purchaseDate: string;
}

export enum ReturnStateEnum {
  Created = 'Created',
  Approved = 'Approved',
  Rejected = 'Rejected'
}
