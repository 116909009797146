export const pageUrls = {
  /** User profile settings */
  profile: '/profile',
  /** OKTA callback url */
  callback: '/auth/callback',
  /** Homepage */
  dashboard: '/dashboard',
  /** Clients/Store settings section */
  retailerList: '/retailer/list',
  retailerGeneral: '/retailer/:retailerId/general',
  retailerBuyingProfile: '/retailer/:retailerId/buying-profile',
  retailerShippingProfile: '/retailer/:retailerId/shipping-profile',
  retailerStoreList: '/retailer/:retailerId/store/list',
  retailerStoreCreate: '/retailer/:retailerId/store/create',
  retailerAddressList: '/retailer/:retailerId/address/list',
  retailerAddressCreate: '/retailer/:retailerId/address/create',
  retailerUserList: '/retailer/:retailerId/user/list',
  retailerUserView: '/retailer/:retailerId/user/view/:userId',
  retailerUserCreate: '/retailer/:retailerId/user/create',
  /** Orders section */
  orderRetailerList: '/order/retailer-list',
  orderList: '/order/:retailerId/list',
  orderCreate: '/order/:retailerId/create',
  orderCreateBulk: '/order/:retailerId/create-bulk',
  orderView: '/order/:retailerId/:orderId/view',
  /** Request a credit section */
  returnRetailerList: '/return/retailer-list',
  returnListAll: '/return/list',
  returnList: '/returns/:retailerId/list',
  returnCreate: '/returns/:retailerId/create',
  returnView: '/returns/:retailerId/view/:returnId',
  /** Invoice section */
  invoiceRetailerList: '/invoice/retailer-list',
  invoiceList: '/invoice/:retailerId/list',
  invoiceView: '/invoice/:retailerId/view/:invoiceId',
  /** Product Catalog section */
  productListAll: '/product/list',
  productList: '/product/:retailerId/list',
  productCreate: '/product/create',
  productView: '/product/view/:productId',
  productGroupList: '/product-group/list',
  productGroupCreate: '/product-group/create',
  productGroupView: '/product-group/view/:groupId',
  /** Promo Builder */
  promoBuilderList: '/promo/list',
  promoCreate: '/promo/create',
  promoEdit: '/promo/:promoId/edit',
  /** Marketing Assets */
  assetList: '/asset/list',
  assetView: '/asset/view/:assetId',
  /** Reports pages */
  reports: '/reports',
  /** Retailer settings status page */
  status: '/status',
  /** App Settings */
  settingsUserList: '/settings/user/list',
  settingsUserCreate: '/settings/user/create',
  settingsUserCreateBulk: '/settings/user/create-bulk',
  settingsUserView: '/settings/user/view/:userId',
  settingsGeneral: '/settings/general',
  settingsDiscounts: '/settings/discounts',
  settingsShipping: '/settings/shipping',
  /** Support page */
  support: '/support',
  termsAndConditions: '/static/terms-and-conditions'
};
