import {InfoCircleOutlined} from '@ant-design/icons';
import {Button, Col, Row, Tooltip} from 'antd';
import Countdown from 'antd/lib/statistic/Countdown';
import {IQueryEnum, makeQueries} from 'core/api';
import {BannerVariants, IPromoBuilder} from 'core/models/promoBuilder/types';
import {getRetailer} from 'core/models/retailers/utils';
import moment from 'moment-timezone';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Icon, IconColorEnum, IconTypeEnum} from '../../../components/Icon';
import './style.less';

interface IData {
  activePromo: IPromoBuilder;
}

const PromoBanner = () => {
  const {retailer} = getRetailer();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(true);
  const isDisabled = !Boolean(retailer?.billingAddressId);
  const tooltipMassage = isDisabled ? 'To be able to create an order, configure please your billing address in the settings.' : '';

  const {
    data: {activePromo}
  } = makeQueries<IData>([{type: IQueryEnum.GetActivePromo}]);

  const isSameOrAfter = activePromo?.expDate && moment(activePromo?.expDate).isSameOrAfter();
  const isPromoActive = activePromo?.promoBanner?.types.includes(BannerVariants.TOP_BAR) && isSameOrAfter;

  const [transitionStage, setTransitionStage] = useState('');

  useEffect(() => {
    if (isOpen) {
      setTransitionStage('fadeIn');
    }
  }, [isOpen]);

  function closeBar() {
    setIsOpen(false);
  }

  function onOrderCreate() {
    history.push(`/order/${retailer?.id}/create`);
  }

  const expiresInDays = moment(activePromo?.expDate).diff(moment(), 'days');

  return (
    <>
      {isPromoActive && isOpen && (
        <Row justify="space-around" align="middle" className={`bg-yellow ${transitionStage}`}>
          <Col span={17} className="pl15">
            <h2 className="text-lg font-semibold">{activePromo?.offerTitle}</h2>
          </Col>
          <Col span={3}>
            {expiresInDays > 2 ? (
              <Row align='middle'>
                <Countdown title="Expires in:" value={activePromo?.expDate} format="DD" className="mr5" suffix="Days" />
              </Row>
            ) : (
              <Countdown title="Expires in:" value={activePromo?.expDate} format="HH:mm:ss" />
            )}
          </Col>
          <Col span={2}>
            <Tooltip title={tooltipMassage} arrowPointAtCenter>
              <Button type="primary" className="ml15" onClick={onOrderCreate} disabled={isDisabled}>
                Create Order
              </Button>
            </Tooltip>
          </Col>
          <Col span={2} className="tar">
            <Row align="middle" justify="end">
              <Tooltip title={activePromo.offerDescription} arrowPointAtCenter className="mr15" placement="bottomRight">
                <InfoCircleOutlined style={{fontSize: 21}} />
              </Tooltip>
              <span className="cursor-pointer mr15" onClick={closeBar}>
                <Icon type={IconTypeEnum.Close} color={IconColorEnum.Black} />
              </span>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default PromoBanner;
