import {Select, Form, Button, Input} from 'antd';
import {PageWrapper} from 'components/PageWrapper';
import {IMutationEnum, IQueryEnum, makeQueries, makeMutation} from 'core/api';
import {IProduct} from 'core/models/products/types';
import {IProductItem} from 'core/models/productItems/types';
import {pageUrls} from 'core/router/pages';
import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
const {Option} = Select;

interface IData {
  products?: IProduct[];
  productItems?: IProductItem[];
}

function ProductGroupCreate() {
  const history = useHistory();
  const [type, setType] = useState<any>('Products');
  const [productIds, setProductIds] = useState<any>([]);
  const [productItemIds, setProductItemIds] = useState<any>([]);
  const [form] = Form.useForm();
  const {
    data: {products = [], productItems = []},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetProducts},
    {type: IQueryEnum.GetProductItems},
  ]);

  const mutation = makeMutation({
    type: IMutationEnum.CreateGroup,
    loadingMsg: 'Creating group...',
    successMsg: 'Group created.'
  });

  useEffect(() => {
    if (mutation.isSuccess) {
      history.push(pageUrls.productGroupList);
    }
  }, [mutation, history]);

  function submitGroup(v: any) {
    const { title, description, type, productIds, productItemIds } = v;
    const groupInput = {
      title,
      description,
      type,
      productIds: type === 'Products' ? productIds : null,
      productItemIds: type === 'Product Items' ? productItemIds : null
    };

    mutation.mutate({...groupInput});
  }

  const getProductTitle = (pIId: string) => {
    const product = products.find(({id}) => id === pIId);
    return product?.title;
  }

  return (
    <PageWrapper pageTitle='Create Product Group' topMenuType={'catalog'} breadcrumbs={[{url: pageUrls.productGroupList, title: `Product Groups`}]}>
      <Form layout="vertical" form={form} name="create-group" onFinish={submitGroup} className={'ant-col ant-col-14'}>
        <Form.Item name={'title'} label="Group name:" rules={[{required: true}]}>
          <Input />
        </Form.Item>
        <Form.Item label="Description:" name={'description'} >
          <Input.TextArea size="large" placeholder="Add description" />
        </Form.Item>
        <Form.Item name={'type'} label="Select type:" rules={[{required: true}]} initialValue={'Products'}>
          <Select allowClear style={{width: '100%'}} value={type} onChange={setType} loading={isLoading}>
              <Option key={'Products'} value={'Products'}>Products</Option>
              <Option key={'Product Items'} value={'Product Items'}>Product Items</Option>
          </Select>
        </Form.Item>
        {type === 'Products' ? (
        <Form.Item name={'productIds'} label="Select Products:" rules={[{required: type === 'Products'}]}>
          <Select 
            optionFilterProp="children"
            mode="multiple" 
            allowClear 
            style={{width: '100%'}} 
            value={productIds} 
            onChange={setProductIds} 
            loading={isLoading}
          >
            {products?.map(p => (
              <Option key={p.id} value={p.id}>
                {p.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        ) : (
        <Form.Item name={'productItemIds'} label="Select Product Items:" rules={[{required: type === 'ProductItems'}]}>
          <Select 
            optionFilterProp="children"
            mode="multiple" 
            allowClear 
            style={{width: '100%'}} 
            value={productItemIds} 
            onChange={setProductItemIds} 
            loading={isLoading}
          >
            {productItems?.map(p => (
              <Option key={p.id} value={p.id}>
                {getProductTitle(p.productId)} "{p.title}" : {p.sku}
              </Option>
            ))}
          </Select>
        </Form.Item>
        )}
        <div className="w100 tar pt15">
          <Button type="primary" htmlType="submit">
            Create group
          </Button>
        </div>
      </Form>
    </PageWrapper>
  );
}

export default ProductGroupCreate;
