import {gql} from 'graphql-tag';

export const CREATE_OR_UPDATE_ORDER = gql`
  mutation createOrUpdateOrder($orderInput: OrderInput!) {
    createOrUpdateOrder(orderInput: $orderInput) {
      id
      totalAmount
      subtotalAmount
      shippingPrice
      relatedOrderId
      type
      totalQuantity
      shippingAddressId
      billingAddressId
      updatedAt
      scheduledAt
      state
      reference
      number
      discount
    }
  }
`;
export const CREATE_RELATED_ORDER = gql`
  mutation createRelatedOrder($orderInput: OrderInputs!) {
    createRelatedOrder(orderInput: $orderInput) {
      id
      totalAmount
      subtotalAmount
      shippingPrice
      relatedOrderId
      type
      totalQuantity
      shippingAddressId
      billingAddressId
      updatedAt
      scheduledAt
      state
      reference
      number
      discount
    }
  }
`;


export const CANCEL_ORDER = gql`
  mutation cancelOrder($id: ID!) {
    cancelOrder(id: $id) {
      id
      totalAmount
      subtotalAmount
      shippingPrice
      type
      totalQuantity
      shippingAddressId
      billingAddressId
      updatedAt
      scheduledAt
      state
      reference
      number
      discount
    }
  }
`;

export const CREATE_ORDERS = gql`
  mutation createOrders($file: Upload, $retailerId: ID, $type: String) {
    createOrders(file: $file, retailerId: $retailerId, type: $type)
  }
`;
