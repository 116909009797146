import {Col, Collapse, Row, Select} from 'antd';
import {OrderSummary} from '../OrderSummary';
import {makeQueries, IQueryEnum} from 'core/api';
import {IProductBrand} from 'core/models/productBrands/types';
import {IProductCategory} from 'core/models/productCategories/types';
import {IProductItem} from 'core/models/productItems/types';
import {IProduct} from 'core/models/products/types';
import {useState} from 'react';
import ProductList from './ProductList';
import {getRetailer} from 'core/models/retailers/utils';

const {Panel} = Collapse;
const {Option} = Select;

interface IData {
  productBrands?: Map<string, IProductBrand>;
  productCategories?: Map<string, IProductCategory>;
  products: IProduct[];
  productItems: Map<string, IProductItem>;
}

function OrdersCreateProducts({nextStep, orderType}: any) {
  const {retailer} = getRetailer()!;
  const [brands, setBrands] = useState<string[]>(retailer?.productBrandIds || []);

  const {
    data: {products, productBrands, productCategories, productItems},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetProductBrands, mapKey: 'id'},
    {type: IQueryEnum.GetProductCategories, mapKey: 'id'},
    {type: IQueryEnum.GetProducts},
    {type: IQueryEnum.GetProductItems, variables: {retailerId: retailer?.id}, mapKey: 'id', isEnabled: Boolean(retailer)},
    {type: IQueryEnum.GetStocks}
  ]);

  function onProductConfirm() {
    window.dataLayer?.push({eventType: 'action', event: 'products_added', retailerName: retailer?.name || null});
    nextStep();
  }

  if (isLoading) return <div>Loading</div>;

  const filteredProducts = products?.filter(product => brands?.includes(product.productBrandId));

  return (
    <Row>
      <Col xs={15} xxl={18} className="temp-class">
        {retailer && retailer.productBrandIds?.length > 1 && (
          <>
            <h3 className="mb-2 text-md">Select Brands:</h3>
            <Select
              mode="multiple"
              size="large"
              allowClear
              loading={isLoading}
              className="w100"
              placeholder="Select brand"
              value={brands}
              onChange={setBrands}
            >
              {retailer?.productBrandIds?.map(brandId => (
                <Option key={brandId} value={brandId}>
                  {productBrands?.get(brandId)?.title}
                </Option>
              ))}
            </Select>
          </>
        )}
        <Collapse className="mt10" accordion defaultActiveKey={[retailer?.productCategoryIds?.[0] || '']}>
          {retailer?.productCategoryIds?.map(catId => (
            <Panel header={productCategories?.get(catId)?.title} key={catId}>
              <ProductList
                products={filteredProducts.filter(product => product.productCategoryId === catId)}
                productItems={productItems}
                orderType={orderType}
              />
            </Panel>
          ))}
        </Collapse>
      </Col>
      <Col xs={9} xxl={6}>
        <OrderSummary actionTitle="Continue To Shipping" onAction={onProductConfirm} />
      </Col>
    </Row>
  );
}

export default OrdersCreateProducts;
