import {Collapse, Timeline} from 'antd';
import {IOrder, OrderStateEnum} from 'core/models/orders/types';
import {Animation, AnimationEnum} from '../../../components/Animation';
import moment from 'moment';
import {CarrierNameEnum, IShipment} from 'core/models/shipments/types';
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';

export function getOrderStatus(order?: IOrder, shipment?: IShipment, estimatedDate?: string, cancelOrder?: () => void) {
  switch (order?.state) {
    case OrderStateEnum.Scheduled:
      return <div className='text-center text-2xl mt-10'>Scheduled for <span className='font-semibold text-cyan-700'>{moment(order.scheduledAt).format('MM/DD/YYYY')}</span></div>
    case OrderStateEnum.Created:
    case OrderStateEnum.Processing:
      return (
        <div className="w100 tac">
          <h3 className="text-md font-semibold"> We are processing your order</h3>
          <Animation className="mt15" width={15} type={AnimationEnum.GuyWorking} autoplay loop />
          {cancelOrder && (
            <button
              type="button"
              onClick={cancelOrder}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel Order
            </button>
          )}
        </div>
      );
    case OrderStateEnum.Fulfilling:
      return (
        <div className="w100 tac">
          <h3 className="text-md font-semibold"> {estimatedDate}</h3>
          <Animation className="mt15" width={15} type={AnimationEnum.Collecting} autoplay loop />
        </div>
      );
    case OrderStateEnum.Delivering:
    case OrderStateEnum.Done:
      return getTrackingComponent(shipment);
    case OrderStateEnum.Canceled:
      return <h3> Your order has been canceled.</h3>;
    default:
      return <LoadingOutlined />;
  }
}

function getTrackingComponent(shipment?: IShipment) {
  return (
    <Collapse className="mt10" accordion expandIconPosition="right">
      <Collapse.Panel
        header={
          <>
            {shipment ? (
              <>
                <h4>
                  <span className="mr5">Carrier:</span>
                  <strong>{shipment.carrierName || "Can't identify the carrier"}</strong>
                </h4>
                <h4>
                  <span className="mr5">Tracking number:</span>
                  <strong>{shipment.trackingNumber}</strong>
                </h4>
                {shipment.estimatedDeliveryDate && (
                  <h4>
                    <span className="mr5">Estimated delivery date:</span>
                    <strong>{moment(Number(shipment.estimatedDeliveryDate)).format('MM/DD/YYYY')}</strong>
                  </h4>
                )}
              </>
            ) : (
              <div className="w100 tac">
                <LoadingOutlined />
              </div>
            )}
          </>
        }
        key={1}
      >
        <Timeline>
          {Boolean(shipment?.events?.length) ? (
            shipment?.events
              ?.filter(e => Boolean(e.date))
              ?.sort((a, b) => (moment(a.date).isBefore(moment(b.date)) ? -1 : 1))
              .map((event, i) => (
                <Timeline.Item key={i}>
                  <div>
                    <h4 className="mb0">{event.status}</h4>
                    <h5 className="mb0 f-light c-darkblue">{event.location}</h5>
                    <h5 className="mb0 f-light c-darkblue">{moment(event.date).format('MM/DD/YYYY')}</h5>
                  </div>
                </Timeline.Item>
              ))
          ) : (
            <>
              Sorry, we couldn't find any information about order location. Please, try using official carrier website.
              {shipment?.carrierName === CarrierNameEnum.Fedex && (
                <p>
                  <a
                    className="c-blue"
                    target={'_blank'}
                    rel="noreferrer"
                    href={`https://fedex.com/fedextrack/?trknbr=${shipment.trackingNumber}`}
                  >
                    Track here
                  </a>
                </p>
              )}
            </>
          )}
        </Timeline>
      </Collapse.Panel>
    </Collapse>
  );
}
