import axios from 'axios';
import qs from 'qs';
import constants from '../constants';

/** Send error to Slack */
export const sendErrorViaSlack = async (source: string, error: any) => {    
  const {url, channel, token} = constants.slack;
  if (url && channel && token) {
    try {
        const args = {
            token,
            channel, 
            text: `*Source:* "${source}".\n${error}`,
          };
          axios.post(url, qs.stringify(args));
    } catch (e) {
      console.error(`Error while sending error via Slack:\n ${e}`);
    }
  }
};