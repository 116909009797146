import LinkOutlined from '@ant-design/icons/lib/icons/LinkOutlined';
import {Button, Card, Divider, Form, Input, Row, Typography} from 'antd';
import {PageWrapper} from 'components/PageWrapper';
import {IMutationEnum, IQueryEnum, makeMutation, makeQueries} from 'core/api';
import {IModelEnum} from 'core/api/graphql/types';
import AuthStore from 'core/auth';
import {IProductItem} from 'core/models/productItems/types';
import {IProduct} from 'core/models/products/types';
import {getRetailer} from 'core/models/retailers/utils';
import {IReturn, ReturnIssueTypeEnum, ReturnStateEnum} from 'core/models/returns/types';
import {ReturnIssueTypeRenderer, ReturnStateRenderer} from 'core/models/returns/utils';
import moment from 'moment';
import {useContext, useEffect} from 'react';
import {useQueryClient} from 'react-query';
import {useParams} from 'react-router-dom';
import {dataLayerEvents} from 'utils/dataLayer';

const {Text} = Typography;

interface IData {
  returns: Map<string, IReturn>;
  products: Map<string, IProduct>;
  productItems: Map<string, IProductItem>;
}

function ReturnView() {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const {isAdmin} = useContext(AuthStore);
  const {retailer, isLoading: isRetailerLoading} = getRetailer();
  const {retailerId, returnId} = useParams<{retailerId: string; returnId: string}>();

  const mutation = makeMutation({
    type: IMutationEnum.UpdateReturn,
    keyVars: {retailerId},
    loadingMsg: 'Updating return...',
    successMsg: 'Return updated.'
  });

  const {
    data: {returns, products, productItems},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetReturns, variables: {retailerId}, mapKey: 'id'},
    {type: IQueryEnum.GetProducts, mapKey: 'id'},
    {type: IQueryEnum.GetAllProductItems, variables: {retailerId}, mapKey: 'id'}
  ]);

  useEffect(() => {
    if (mutation.isSuccess) {
      /** Item may be removed from Buying Profile, therefore reset needed  */
      queryClient.invalidateQueries([IModelEnum.Returns]);
    }
  }, [mutation.isSuccess, queryClient]);

  useEffect(() => {
    if (!isRetailerLoading && retailer) {
      dataLayerEvents('view_returns_details', retailer);
    }
  }, [retailer, isRetailerLoading]);

  const retailerReturn = returns?.get(returnId);

  function getField(name: string, value?: string | JSX.Element) {
    return (
      <h3 className="d-flex text-md font-semibold">
        <span className="mr5">{name}:</span>
        <span className="f-light">{value}</span>
      </h3>
    );
  }

  function getOrderItemInfo() {
    const productItem = productItems?.get(retailerReturn?.productItemId!);
    const product = products?.get(productItem?.productId!);

    return (
      <span>
        {product ? (
          <span>
            {product.title} (Size: {productItem?.title})
          </span>
        ) : (
          'Product is not available anymore'
        )}
      </span>
    );
  }

  function handleReject() {
    const processedReason = form.getFieldValue('processedReason');
    if (Boolean(processedReason)) {
      mutation.mutate({returnUpdate: {id: retailerReturn!.id, state: ReturnStateEnum.Rejected, processedReason}});
    }
  }

  function handleApprove() {
    const processedReason = form.getFieldValue('processedReason');
    mutation.mutate({returnUpdate: {id: retailerReturn!.id, state: ReturnStateEnum.Approved, processedReason}});
  }

  return (
    <PageWrapper>
      <Card
        title={<h2 className="text-lg font-semibold">Your request for a credit</h2>}
        extra={ReturnStateRenderer(retailerReturn?.state!)}
        loading={isLoading}
      >
        {getField('Retailer', retailer?.name)}
        {getField('Created By', retailerReturn?.createdBy)}
        {getField('Created At', moment(Number(retailerReturn?.createdAt)).format('MM/DD/YYYY'))}
        {getField('Issue Type', ReturnIssueTypeRenderer(retailerReturn?.issueType!))}
        {getField('Order #SO', retailerReturn?.orderSO)}
        {getField('Reference', retailerReturn?.reference)}
        <Divider />
        {retailerReturn?.issueType === ReturnIssueTypeEnum.Damaged ? (
          <>
            {getField('Order Reference (PO#)', retailerReturn?.orderReference)}
            {getField('Date Shipment Received', retailerReturn?.customer?.purchaseDate)}
          </>
        ) : (
          <>
            {getField('Customer Name', retailerReturn?.customer?.name)}
            {getField('Reciept Number', retailerReturn?.customer?.receiptNumber)}
            {getField('Customer Purchase Date', retailerReturn?.customer?.purchaseDate)}
          </>
        )}
        <Divider />
        {getField('Product', getOrderItemInfo())}
        {getField('Quantity', retailerReturn?.quantity.toString())}
        {getField('Problem description', retailerReturn?.description)}
        <Divider />
        {retailerReturn?.attachments?.damagedProduct &&
          getField(
            'Damaged product photo',
            <div>
              {retailerReturn?.attachments?.damagedProduct.map((damagedProduct, index) => (
                <a key={index} className="ml10" target="_blank" rel="noreferrer" href={damagedProduct?.replaceAll('#', '%23')}>
                  <Text className="w100 c-darkblue text-underline">
                    {damagedProduct?.split('.com/')[1]}
                    <LinkOutlined className="ml10" />
                  </Text>
                </a>
              ))}
            </div>
          )}
        {getField(
          'Law Tag photo',
          <div>
            {retailerReturn?.attachments?.lawTag?.map((lawTag, index) => (
              <a key={index} className="ml10" target="_blank" rel="noreferrer" href={lawTag?.replaceAll('#', '%23')}>
                <Text className="w100 c-darkblue text-underline">
                  {lawTag?.split('.com/')[1]}
                  <LinkOutlined className="ml10" />
                </Text>
              </a>
            ))}
          </div>
        )}
        {retailerReturn?.attachments?.damagedProduct &&
          getField(
            'Bill of Landing photo',
            <div>
              {retailerReturn?.attachments?.bol?.map((bill, index) => (
                <a key={index} className="ml10" target="_blank" rel="noreferrer" href={bill?.replaceAll('#', '%23')}>
                  <Text className="w100 c-darkblue text-underline">
                    {bill?.split('.com/')[1]}
                    <LinkOutlined className="ml10" />
                  </Text>
                </a>
              ))}
            </div>
          )}
        {retailerReturn?.attachments?.receipt &&
          getField(
            'Receipt photo',
            <div>
              {retailerReturn?.attachments?.receipt.map((receipt, index) => (
                <a key={index} className="ml10" target="_blank" rel="noreferrer" href={receipt?.replaceAll('#', '%23')}>
                  <Text className="w100 c-darkblue text-underline">
                    {receipt?.split('.com/')[1]}
                    <LinkOutlined className="ml10" />
                  </Text>
                </a>
              ))}
            </div>
          )}
        <Divider />
        {retailerReturn?.issueType !== ReturnIssueTypeEnum.Damaged &&
          getField('Picked up and disposed of by retailer', retailerReturn?.isPickedByRetailer ? 'Yes' : 'No')}
        {getField('Comments', retailerReturn?.comments || '-')}
        <Divider />
        {retailerReturn?.state === ReturnStateEnum.Created
          ? isAdmin && (
              <Form layout="vertical" form={form} className="tac">
                <Row justify="center" className="w100">
                  <Form.Item label="Reason:" className="w50" name="processedReason" rules={[{required: true}]}>
                    <Input.TextArea />
                  </Form.Item>
                </Row>
                <Row justify="center">
                  <Button onClick={handleReject} type="primary" danger htmlType="submit" className="mb15 mr15">
                    Reject
                  </Button>
                  <Button onClick={handleApprove} type="primary">
                    Approve
                  </Button>
                </Row>
              </Form>
            )
          : retailerReturn?.processedReason && getField('Resident Comment', retailerReturn?.processedReason)}

        {retailerReturn?.state === ReturnStateEnum.Approved && isAdmin && (
          <Form layout="vertical" form={form} className="tac">
            <Row justify="center" className="w100">
              <Form.Item label="Reason:" className="w50" name="processedReason" rules={[{required: true}]}>
                <Input.TextArea />
              </Form.Item>
            </Row>
            <Row justify="center">
              <Button onClick={handleReject} type="primary" danger htmlType="submit" className="mb15 mr15">
                Reject
              </Button>
            </Row>
          </Form>
        )}

        {retailerReturn?.state === ReturnStateEnum.Rejected && isAdmin && (
          <Row justify="center" className="text-center">
            <Button onClick={handleApprove} type="primary">
              Approve
            </Button>
          </Row>
        )}
      </Card>
    </PageWrapper>
  );
}

export default ReturnView;
