export interface IProductItem {
  id: string;
  productId: string;
  title: string;
  sku?: string;
  price?: number;
  unit?: IUnitEnum;
  discount?: number;
  outOfStock: boolean;
  finalDiscountUSD?: number;
  discountStartDate?: string;
  discountEndDate?: string;
  productItemGroupId: string;
  type: ITypeEnum;
  fMunit: IUnitEnum;
  fMdiscount?: number;
  fMprice?: number;
  fMfinalDiscountUSD?: number;
  RXHAlert: number;
  PHXAlert: number;
}

export enum IUnitEnum {
  PERCENT = 'PERCENT',
  USD = 'USD'
}
export enum IProductItemTitleEnum {
  Full = 'Full',
  Twin = 'Twin',
  Queen = 'Queen',
  XL = 'XL',
  King = 'King'
}
export enum ITypeEnum {
  ORDER = 'ORDER',
  FLOOR_MODELS = 'FLOOR_MODELS'
}
