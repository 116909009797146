import {gql} from 'graphql-tag';

export const GET_ORDER_ITEMS = gql`
  query getOrderItems($orderId: ID, $retailerId: ID) {
    getOrderItems(orderId: $orderId, retailerId: $retailerId) {
      id
      fulfilId
      amount
      price
      quantity
      productId
      productItemId
    }
  }
`;

export const TOTAL_PRODUCTS_REPORT = gql`
  query totalProductsReport($retailerId: ID) {
    totalProductsReport(retailerId: $retailerId) {
      labels
      values
    }
  }
`;