import {PageWrapper} from 'components/PageWrapper';
import {Table} from 'components/Table';
import {IColumn} from 'components/Table/Table';
import {IQueryEnum, makeQueries} from 'core/api';
import {IProductItem} from 'core/models/productItems/types';
import {IProduct} from 'core/models/products/types';
import {IRetailer} from 'core/models/retailers/types';
import {getRetailer} from 'core/models/retailers/utils';
import {IReturn} from 'core/models/returns/types';
import {productItemRenderer, ReturnIssueTypeRenderer, ReturnStateRenderer} from 'core/models/returns/utils';
import {pageUrls} from 'core/router/pages';
import moment from 'moment';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {dataLayerEvents} from 'utils/dataLayer';
import {getUrl} from 'utils/getUrl';

interface IData {
  returns: IReturn[];
  products: Map<string, IProduct>;
  productItems: Map<string, IProductItem>;
  retailers: Map<string, IRetailer>;
}
function ReturnListAll() {
  const {retailer, isLoading: isRetailerLoading} = getRetailer();
  const [filteredReturns, setFilteredReturns] = useState<IReturn[]>();
  const history = useHistory();

  useEffect(() => {
    if (!isRetailerLoading && retailer) {
      dataLayerEvents('view_returns_all');
    }
  }, [retailer, isRetailerLoading]);

  const {
    data: {returns, products, productItems, retailers},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetReturns},
    {type: IQueryEnum.GetProducts, mapKey: 'id'},
    {type: IQueryEnum.GetProductItems, mapKey: 'id'},
    {type: IQueryEnum.GetRetailers, mapKey: 'id'}
  ]);

  const columns: IColumn<IReturn>[] = [
    {
      title: 'Created At',
      width: '7%',
      render: (_, row) => moment(Number(row.createdAt)).format('MM/DD/YYYY')
    },
    {
      title: 'Retailer',
      dataIndex: 'retailerId',
      width: '30%',
      render: retailerId => retailers?.get(retailerId)?.name
    },
    {
      title: 'Reference',
      dataIndex: 'reference',
      width: '7%'
    },
    {
      title: 'State',
      dataIndex: 'state',
      width: '7%',
      render: ReturnStateRenderer
    },
    {
      title: 'Issue Type',
      dataIndex: 'issueType',
      width: '7%',
      render: ReturnIssueTypeRenderer
    },
    {
      title: 'Product',
      width: '42%',
      render: (_, row) => {
        const productItem = productItems?.get(row?.productItemId!);
        const product = products?.get(productItem?.productId!);
        return (
          <div>
            <span className="pr5">{productItemRenderer(product?.title)}</span>
            <span>{productItemRenderer(productItem?.title)}</span>
          </div>
        );
      }
    }
  ];

  const onClick = ({id, retailerId}: IReturn): void => {
    history.push(getUrl(pageUrls.returnView, {retailerId, returnId: id}));
  };
  const handleChangeRetailer = (id: string): void => {
    const retailerReturns = returns.filter(r => r.retailerId === id);
    if (id) {
      return setFilteredReturns(retailerReturns);
    }
    return setFilteredReturns(returns);
  };
  const returnsData = filteredReturns ? filteredReturns : returns;

  return (
    <PageWrapper withRetailerSelector handleChangeRetailer={handleChangeRetailer} pageTitle="All Returns">
      <Table data={returnsData} columns={columns} onRowClick={onClick} loading={isLoading} />
    </PageWrapper>
  );
}

export default ReturnListAll;
