import {Switch} from 'antd';
import {IMutationEnum, IQueryEnum, makeMutation, makeQueries} from 'core/api';
import {IProductItemGroups} from 'core/models/productItemGroups/types';
import {IProduct} from 'core/models/products/types';
import {getRetailer} from 'core/models/retailers/utils';
import {ISupplier} from 'core/models/suppliers/types';
import {useMemo} from 'react';

interface IIsAssignedRenderer {
  product: IProduct;
}

interface IData {
  productItemGroups: IProductItemGroups[];
  suppliers: Map<string, ISupplier>;
}

const IsAssignedRenderer = ({product}: IIsAssignedRenderer) => {
  const {retailer} = getRetailer();

  const {
    data: {productItemGroups, suppliers},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetProductItemGroups, isEnabled: Boolean(retailer)},
    {type: IQueryEnum.GetSuppliers, mapKey: 'id'}
  ]);

  const mutation = makeMutation({
    type: IMutationEnum.RetailerUpdate,
    loadingMsg: '(Un)Assigning the product...',
    successMsg: `Product is (un)Assigned.`
  });

  const filteredProductItemGroups = useMemo(
    () => productItemGroups?.filter(ig => ig.productId === product.id),
    [productItemGroups, product]
  );

  const onAssignProductToggle = (checked: boolean) => {
    let selectedGroupIds = retailer?.groupIds ? [...retailer?.groupIds] : [];

    if (checked) {
      /** Logic defines preferred  item group, based on supplier */
      const primarySupplier = filteredProductItemGroups?.map(ig => suppliers.get(ig.supplierId)!).sort((a, b) => a.order - b.order)[0];
      const primaryProductItemGroup = filteredProductItemGroups.find(ig => ig.supplierId === primarySupplier?.id);
      selectedGroupIds = [...selectedGroupIds, primaryProductItemGroup?.id!];
    } else {
      const groupIdsSet = new Set(selectedGroupIds.map(productItemGroupId => productItemGroupId));
      const selectedProductItemGroup = filteredProductItemGroups?.find(ig => retailer?.groupIds.some(id => ig.id === id));
      groupIdsSet.delete(selectedProductItemGroup?.id!);
      selectedGroupIds = Array.from(groupIdsSet.values());
    }

    mutation.mutate({
      retailerUpdate: {
        id: retailer!.id,
        groupIds: selectedGroupIds
      }
    });
  };

  if (isLoading) return <div />;

  const isAssigned = Boolean(retailer?.groupIds.some(igId => filteredProductItemGroups.some(ig => ig.id === igId)));

  return <Switch checked={isAssigned} onChange={onAssignProductToggle} />;
};

export default IsAssignedRenderer;
