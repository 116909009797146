import { Image } from 'antd';
import { IAsset } from 'core/brandfolder/types';
import { useState } from 'react';
import { getClassName } from 'utils/getClassName';

export default function AssetCover({asset}: {asset?: IAsset}) {
  const [loaded, setLoaded] = useState(false);

  function handleImageLoaded() {
    setLoaded(true);
  }

  if (!asset) return <div />;

  let assetRendered = <div />;
  let loaderText = 'Loading...';

  if (asset.type === 'Image') {
    assetRendered = (
      <Image
        src={asset.url}
        preview={false}
        key={`${asset.id}-url`}
        alt={asset.name}
        className={getClassName(loaded ? 'block' : 'hidden', 'object-cover rounded-md hover:rounded-md')}
        onLoad={handleImageLoaded}
        fallback={asset.thumbnailUrl}
      />
    );
    loaderText = 'Loading high quality picture...';
  }

  if (asset.type === 'Video') {
    assetRendered = (
      <video
        src={asset.url}
        onCanPlay={handleImageLoaded}
        autoPlay
        controls
        className={getClassName(loaded ? 'block' : 'hidden', 'object-cover rounded-md hover:rounded-md w-full h-auto')}
      />
    );
    loaderText = 'Loading video...';
  }

  if (asset.type === 'Document') {
    loaderText = '';
  }

  return (
    <div key={asset.id}>
      <div className={loaded ? 'hidden relative' : 'block relative'}>
        <img
          src={asset.thumbnailUrl}
          key={`${asset.id}-thumbnail`}
          alt={asset.name}
          className={'object-cover rounded-md hover:rounded-md w-full h-auto'}
        />
        {loaderText && (
          <span className="absolute top-5 left-5 px-3 py-0.5 rounded-full text-sm font-medium bg-red-300 text-red-800">{loaderText}</span>
        )}
      </div>
      {assetRendered}
    </div>
  );
}
