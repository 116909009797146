import {gql} from 'graphql-tag';

export const GET_PAYMENTS = gql`
  query getPayments($retailerId: ID!) {
    getPayments(retailerId: $retailerId) {
      id
      retailerId
      amount
      cardName
      addressId
      state
      provider
      providerReference
      date
      type
    }
  }
`;
