import {gql} from 'graphql-tag';

export const CREATE_SHIPPING_RULE = gql`
  mutation createOrUpdateShippingRules($shippingRuleInput: [ShippingRuleInput]) {
    createOrUpdateShippingRules(shippingRuleInput: $shippingRuleInput) {
      id
      orderType
      groupId
      priceRange
      order
      quantityRange
      price
      retailerId
      recommendationThreshold
    }
  }
`;

export const DELETE_SHIPPING_RULE = gql`
  mutation deleteShippingRule($id: ID!) {
    deleteShippingRule(id: $id)
  }
`;
