import {Card, Col, Row, Select} from 'antd';
import {IQueryEnum, makeQueries} from 'core/api';
import {IOrder, OrderTypeEnum} from 'core/models/orders/types';
import {getRetailer} from 'core/models/retailers/utils';
import {IAddress} from 'core/models/addresses/types';
import {OrderStore} from 'core/stores/OrderStore';
import {OrderSummary} from '../OrderSummary';
import {Animation, AnimationEnum} from 'components/Animation';
import OrderCreateAddress from './OrderCreateAddress';
import './style.less';
import {IState} from 'core/models/states/types';

interface IData {
  addresses: IAddress[];
  orders: Map<string, IOrder>;
  states: Map<string, IState>;
}

function OrdersCreateShipping({nextStep}: any) {
  const {retailer} = getRetailer();
  const {order, setShippingAddress, setWarehouse} = OrderStore.getState();

  const {
    data: {states, addresses},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetStates, mapKey: 'id'},
    {type: IQueryEnum.GetAddresses, variables: {retailerId: retailer?.id}},
    {type: IQueryEnum.GetOrders, variables: {retailerId: retailer?.id}, mapKey: 'id', isEnabled: Boolean(retailer)}
  ]);

  function onConfirmShipping() {
    nextStep();
    window.dataLayer?.push({eventType: 'action', event: 'set_shipping_details', retailerName: retailer?.name || null});
  }

  const onShippingAddressChange = (id: string) => {
    const address = addresses?.find(a => a.id === id);
    if (address?.stateId) {
      const state = states?.get(address?.stateId);
      if (state) {
        console.log(state)
        setWarehouse(state.warehouse);
      }
    }

    setShippingAddress(id);
  };

  function onAddressCreate(address: IAddress) {
    setShippingAddress(address.id);
    onConfirmShipping();
  }

  return (
    <Row className="order-shipping">
      <Col xs={15} xxl={18}>
        <h2 className='text-lg font-semibold'>Shipping Information </h2>
        <Card className="mb15">
          {order.type === OrderTypeEnum.DROPSHIPMENT ? (
            <>
              <OrderCreateAddress onClick={onAddressCreate} />
            </>
          ) : (
            <>
              <h3 className="c-darkblue text-md font-semibold">Shipping address:</h3>
              <Select
                onChange={onShippingAddressChange}
                showSearch
                value={order.shippingAddressId}
                style={{width: '100%'}}
                placeholder="Shipping address"
                loading={isLoading}
                className={'mb15'}
                optionFilterProp="children"
                filterOption={(input, option: any) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {addresses
                  ?.filter(address => address.isShippingAddress)
                  ?.map(address => (
                    <Select.Option key={address.id} value={address.id}>
                      {address.address}
                    </Select.Option>
                  ))}
              </Select>
              <Animation className="mt15" width={25} type={AnimationEnum.MattressShipped} autoplay />
            </>
          )}
        </Card>
      </Col>
      <Col xs={9} xxl={6}>
        <OrderSummary
          actionTitle="Review order"
          onAction={onConfirmShipping}
          isActionDisabled={!Boolean(order.shippingAddressId) || !Boolean(retailer?.billingAddressId)}
        />
      </Col>
    </Row>
  );
}

export default OrdersCreateShipping;
