import {gql} from 'graphql-tag';

export const GET_ANALYTICS= gql`
  query getAnalyticsReports($type: AnalyticsReportType, $filters: AnalyticsFilters) {
    getAnalyticsReports(type: $type, filters: $filters) {
      id
      data
    }
  }
`;
