import {Col, Row} from 'antd';
import {PageWrapper} from 'components/PageWrapper';
import AuthStore from 'core/auth';
import {getRetailer, getRetailerId} from 'core/models/retailers/utils';
import {IQueryEnum, makeQueries} from 'core/api';
import {useContext, useEffect} from 'react';
import SimpleLineChart from './components/SimpleLineChart';
import SimpleBarChart from './components/SimpleBarChart';
import CustomActiveShapePieChart from './components/CustomActiveShapePieChart';
import {dataLayerEvents} from 'utils/dataLayer';

function Dashboard() {
  const {isRetailer} = useContext(AuthStore);
  const retailerId = getRetailerId();
  const {retailer, isLoading} = getRetailer();

  interface IData {
    totalSalesReport: [];
    totalProductsReport: [];
  }

  const {
    data: {totalSalesReport, totalProductsReport}
  } = makeQueries<IData>([
    {type: IQueryEnum.TotalSalesReport, variables: {retailerId}},
    {type: IQueryEnum.TotalProductsReport, variables: {retailerId}}
  ]);

  useEffect(() => {
    if (!isLoading && retailer) {
      dataLayerEvents('view_dashboard', retailer);
    }
  }, [isLoading, retailer]);

  return (
    <PageWrapper>
      {isRetailer ? (
        <Row>
          <Col span={14} className="pr15">
            {totalSalesReport && <SimpleLineChart valueIsPrice data={totalSalesReport} name="Total Sales Orders" />}
            {totalSalesReport && <SimpleLineChart data={totalSalesReport} name="Total Count for Orders" />}
          </Col>
          <Col span={10}>
            {totalProductsReport && (
              <>
                <SimpleBarChart data={totalProductsReport} />
                <CustomActiveShapePieChart data={totalProductsReport} />
              </>
            )}
          </Col>
        </Row>
      ) : (
        <Row>
          <Col span={14} className="pr15">
            {totalSalesReport && <SimpleLineChart valueIsPrice data={totalSalesReport} name="Total Sales Orders" />}
            {totalSalesReport && <SimpleLineChart data={totalSalesReport} name="Total Count for Orders" />}
          </Col>
          <Col span={10}>
            {totalProductsReport && (
              <>
                <SimpleBarChart data={totalProductsReport} />
                <CustomActiveShapePieChart data={totalProductsReport} />
              </>
            )}
          </Col>
        </Row>
      )}
    </PageWrapper>
  );
}

export default Dashboard;
