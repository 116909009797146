import {Button, Card, Radio, RadioChangeEvent, Row, Space} from 'antd';
import {IQueryEnum, makeQueries} from 'core/api';
import AuthStore from 'core/auth';
import {IGroup} from 'core/models/groups/types';
import {OrderTypeEnum} from 'core/models/orders/types';
import {BannerVariants, IPromoBuilder} from 'core/models/promoBuilder/types';
import {getRetailer, getRetailerId} from 'core/models/retailers/utils';
import {IShippingRule} from 'core/models/shippingRules/types';
import {UserRolesEnum} from 'core/models/users/types';
import {OrderStore} from 'core/stores/OrderStore';
import {useContext, useState} from 'react';
import PromoImage from 'shared/PromoImage';

interface IData {
  shippingRules: IShippingRule[];
  groups: IGroup[];
  activePromo: IPromoBuilder;
}

function OrdersCreateType({nextStep, setType}: any) {
  const initOrder = OrderStore(({initOrder}) => initOrder);
  const {retailer} = getRetailer();

  const retailerId = getRetailerId();
  const {doesHaveRole} = useContext(AuthStore);
  const isAccessGranted = doesHaveRole([UserRolesEnum.Admin, UserRolesEnum.Sales]);
  const [orderType, setOrderType] = useState<OrderTypeEnum>(OrderTypeEnum.ORDER);
  const {
    data: {shippingRules = [], groups = [], activePromo}
  } = makeQueries<IData>([{type: IQueryEnum.GetShippingRules}, {type: IQueryEnum.GetGroups}, {type: IQueryEnum.GetActivePromo}]);

  function onClick() {
    const rules = shippingRules.filter(rule => rule?.orderType === orderType).sort((a, b) => a.order - b.order);

    initOrder(retailerId!, orderType, rules, groups);
    nextStep();
    window.dataLayer?.push({
      eventType: 'action',
      event: 'select_order_type',
      orderType,
      retailerName: retailer?.name || null
    });
  }

  function onTypeChange(e: RadioChangeEvent) {
    setOrderType(e.target.value);
    setType(e.target.value);
  }

  return (
    <Card title="What type of order do you want to create?">
      <Radio.Group value={orderType} onChange={onTypeChange}>
        <Space direction="vertical">
          <Radio value={OrderTypeEnum.ORDER}>Order</Radio>
          <Radio value={OrderTypeEnum.DROPSHIPMENT}>Direct Ship Orders</Radio>
          {isAccessGranted && <Radio value={OrderTypeEnum.FLOOR_MODELS}>Floor Models</Radio>}
        </Space>
      </Radio.Group>

      <PromoImage activePromo={activePromo} type={BannerVariants.ORDER_TYPE} />

      <Row className="mt15" justify="end">
        <Button type="primary" onClick={onClick}>
          Next
        </Button>
      </Row>
    </Card>
  );
}

export default OrdersCreateType;
