import {PageWrapper} from 'components/PageWrapper';
import {InboxOutlined} from '@ant-design/icons';
import {Button, Card, Form, Upload, Alert} from 'antd';
import {useEffect, useState} from 'react';
import {normalizeFile} from 'utils/normalizeFile';
import {IMutationEnum, makeMutation} from 'core/api';
import {useParams} from 'react-router-dom';
import {Icon, IconColorEnum, IconTypeEnum} from 'components/Icon';
import {OrderTypeEnum} from 'core/models/orders/types';

function OrderCreateBulk() {
  const EXAMPLE_DROPSHIPPING_CSV_LINK = 'https://resident-wholesale-portal.s3.amazonaws.com/CSV_DROPSHIPPMENT_EXAMPLE.csv';
  const [form] = Form.useForm();
  const [file, setFile] = useState<any>(null);
  const [fileErrors, setErrors] = useState<string[]>();
  const {retailerId} = useParams<{retailerId: string}>();

  const isDisabled = !Boolean(file) || (file && Object.keys(file).length <= 0);
  const showErrors = fileErrors && fileErrors.length > 0 && Object.keys(file).length > 0;

  const mutation = makeMutation({
    type: IMutationEnum.CreateOrders,
    loadingMsg: 'Creating orders...',
    successMsg: 'Orders are created.'
  });

  useEffect(() => {
    if (mutation.isError) {
      setErrors(mutation?.error?.response?.errors[0]?.extensions?.errors);
    }
  }, [mutation.isError, mutation?.error]);

  const handleUploaderChange = ({file}: any) => {
    setFile(file);
    if (file.status === 'removed') {
      setErrors([]);
      setFile({});
    }
  };

  const onSubmit = ({type}: any) => {
    mutation.mutate({file, retailerId, type: OrderTypeEnum.DROPSHIPMENT});
  };

  return (
    <PageWrapper pageTitle={'Create Bulk Direct Shipment Orders'}>
      <Card>
        <Form layout="vertical" form={form} name="create-return" onFinish={onSubmit} className="w50">
          <div>
            <a href={EXAMPLE_DROPSHIPPING_CSV_LINK} download>
              <span className="c-darkblue f-light mr5 text-uppercase">Download CSV template</span>
              <Icon type={IconTypeEnum.Download} color={IconColorEnum.Blue} />
            </a>
          </div>
          <Form.Item className={'mt10'} label="Upload files" name="attachments" valuePropName="fileList" getValueFromEvent={normalizeFile}>
            <Upload.Dragger beforeUpload={() => false} accept=".csv" maxCount={1} name="files" onChange={handleUploaderChange}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Upload the CSV file</p>
            </Upload.Dragger>
          </Form.Item>
          <>
            {showErrors && (
              <div>
                <Alert
                  message="Error"
                  description={
                    <ul className="p10">
                      {fileErrors.map((err, i) => (
                        <li key={i}>{err}</li>
                      ))}
                    </ul>
                  }
                  type="error"
                  showIcon
                />
              </div>
            )}
          </>
          <Form.Item className="tar mt10">
            <Button htmlType="submit" type="primary" disabled={isDisabled}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </PageWrapper>
  );
}

export default OrderCreateBulk;
