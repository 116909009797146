import {Table} from 'components/Table';
import {IColumn} from 'components/Table/Table';
import {IQueryEnum, makeQueries} from 'core/api';
import {IProductItemGroups} from 'core/models/productItemGroups/types';
import {IProduct} from 'core/models/products/types';
import {getRetailer} from 'core/models/retailers/utils';
import {ISupplier} from 'core/models/suppliers/types';
import {memo, useMemo} from 'react';
import IsAssignedRenderer from './IsAssignedRenderer';
import ProductsItemSettings from './ProductsItemSettings';

interface IData {
  products: IProduct[];
  suppliers: Map<string, ISupplier>;
  productItemGroups: IProductItemGroups[];
}

const ProductAssignment = memo(() => {
  const {retailer} = getRetailer();

  const {
    data: {products, productItemGroups, suppliers},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetProductItemGroups, isEnabled: Boolean(retailer)},
    {type: IQueryEnum.GetProducts},
    {type: IQueryEnum.GetSuppliers, mapKey: 'id'}
  ]);

  function getProductItemGroups(product: IProduct) {
    return productItemGroups?.filter(ig => ig.productId === product.id);
  }

  function getSupplier(_: any, product: IProduct) {
    const filteredProductItemGroups = getProductItemGroups(product);
    const selectedProductItemGroup = filteredProductItemGroups?.find(ig => retailer?.groupIds?.some(id => ig.id === id));
    return <h4 className="f-light c-darkblue mb0">{suppliers?.get(selectedProductItemGroup?.supplierId!)?.title}</h4>;
  }

  const columns: IColumn<IProduct>[] = [
    {
      title: 'Product title',
      dataIndex: 'title',
      render: title => <h4 className="mb0">{title}</h4>,
      width: '55%'
    },
    {
      title: 'Supplier',
      render: getSupplier,
      width: '30%'
    },
    {
      title: 'Is Assigned',
      render: (_, product) => <IsAssignedRenderer product={product} />,
      width: '15%'
    }
  ];

  const data = useMemo(
    () =>
      retailer?.priceListId &&
      products?.filter(
        p =>
          !p.archived &&
          retailer?.productCategoryIds?.includes(p.productCategoryId) &&
          retailer?.productBrandIds?.includes(p.productBrandId)
      ),
    [retailer, products]
  );

  return (
    <Table
      data={data || []}
      loading={isLoading}
      filterConfig={{fulltextParams: ['title'], fulltextPlaceholder: 'Search for a product'}}
      columns={columns}
      expandable={{
        expandedRowRender: product => <ProductsItemSettings product={product} />,
        rowExpandable: product => {
          const filteredProductItemGroups = getProductItemGroups(product);
          return Boolean(retailer?.groupIds?.some(igId => filteredProductItemGroups?.some(ig => ig.id === igId)));
        }
      }}
    />
  );
});

export default ProductAssignment;
