import {Button, Card, Form, Input} from 'antd';
import {makeQueries, IQueryEnum} from 'core/api';
import {IGroup} from 'core/models/groups/types';
import {OrderTypeEnum} from 'core/models/orders/types';
import {getRetailer, getRetailerId} from 'core/models/retailers/utils';
import {IShippingRule} from 'core/models/shippingRules/types';
import {OrderStore} from 'core/stores/OrderStore';
import {useEffect} from 'react';

interface IData {
  shippingRules: IShippingRule[];
  groups: IGroup[];
}

function OrdersCreateReference({nextStep}: any) {
  const [form] = Form.useForm();
  const {setOrderReference, order, initOrder} = OrderStore.getState();
  const retailerId = getRetailerId();
  const {retailer} = getRetailer()!;

  const {
    data: {shippingRules = [], groups = []}
  } = makeQueries<IData>([{type: IQueryEnum.GetShippingRules}, {type: IQueryEnum.GetGroups}]);

  useEffect(() => {
    if (!order.type && retailerId && shippingRules.length >= 0) {
      const rules = shippingRules.filter(rule => rule?.orderType === OrderTypeEnum.ORDER).sort((a, b) => a.order - b.order);

      initOrder(retailerId, OrderTypeEnum.ORDER, rules, groups);
    }
  }, [order, initOrder, retailerId, groups, shippingRules]);

  function onClick({reference}: {reference: string}) {
    const finalReference = order.type === OrderTypeEnum.FLOOR_MODELS ? `Floor Models - ${reference}` : reference;
    setOrderReference(finalReference);
    window.dataLayer?.push({eventType: 'action', event: 'set_reference', retailerName: retailer?.name || null});
    nextStep();
  }

  return (
    <Card title="Set order reference">
      <Form form={form} name="product-edit" initialValues={{reference: order.reference || ''}} onFinish={onClick}>
        <Form.Item
          rules={[{required: true, message: 'Order reference is missing!'}]}
          className="w40"
          name="reference"
          label="Reference number:"
        >
          <Input addonBefore={order.type === OrderTypeEnum.FLOOR_MODELS && 'Floor Models - '} />
        </Form.Item>
        <Form.Item className="tar mt15">
          <Button type="primary" htmlType="submit">
            Next
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default OrdersCreateReference;
