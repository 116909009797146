import { Button, Form, InputNumber, Select } from 'antd';
import { makeMutation, IMutationEnum } from 'core/api';
import { IUnitEnum } from 'core/models/productItems/types';
import { IProduct, IWholesaleDiscount } from 'core/models/products/types';

interface WholesaleDiscountFormProps {
  product: IProduct;
}

function WholesaleDiscountForm({product}: WholesaleDiscountFormProps) {
  const [form] = Form.useForm();

  const mutationUpdate = makeMutation({
    type: IMutationEnum.UpdateProduct,
    loadingMsg: 'Saving discount...',
    successMsg: 'Discount updated.'
  });


  function onSave(wholesaleDiscount: IWholesaleDiscount) {
    mutationUpdate.mutate({
      productUpdate: {
        id: product?.id,
        wholesaleDiscount
      }
    });
  }

  return (
    <div className="ml-12" key={product.id}>
      <Form onFinish={onSave} layout="vertical" name={`discount_${product.id}`} form={form} className="flex flex-row gap-10 items-center">
        <Form.Item name="minItemsInCart" label="Minimum units required:" initialValue={product.wholesaleDiscount?.minItemsInCart}>
          <InputNumber className="w-full" />
        </Form.Item>
        <Form.Item name="discount" label="Discount:" initialValue={product.wholesaleDiscount?.discount}>
          <InputNumber step={1} />
        </Form.Item>
        <Form.Item name="unit" label="Unit:" initialValue={product.wholesaleDiscount?.unit}>
          <Select>
            <Select.Option value={IUnitEnum.PERCENT}>%</Select.Option>
            <Select.Option value={IUnitEnum.USD}>$</Select.Option>
          </Select>
        </Form.Item>
        <Button className="mt-2" type="primary" htmlType="submit">
          Save
        </Button>
      </Form>
    </div>
  );
}

export default WholesaleDiscountForm;
