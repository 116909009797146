import PrinterOutlined from '@ant-design/icons/lib/icons/PrinterOutlined';
import {Card, Image} from 'antd';
import {N} from 'components/N';
import {IQueryEnum, makeQueries} from 'core/api';
import {IAddress} from 'core/models/addresses/types';
import {IInvoice} from 'core/models/invoices/types';
import {IOrderItem} from 'core/models/orderItems/types';
import {IOrder, OrderStateEnum} from 'core/models/orders/types';
import {IProductItem} from 'core/models/productItems/types';
import {IProduct} from 'core/models/products/types';
import {IShipment} from 'core/models/shipments/types';
import {pageUrls} from 'core/router/pages';
import moment from 'moment';
import {useParams} from 'react-router-dom';
import {getUrl} from 'utils/getUrl';
import residentLogo from '../../../assets/resident-logo.webp';
import './style.less';

interface IData {
  orders: Map<string, IOrder>;
  invoices: Map<string, IInvoice>;
  productItems: Map<string, IProductItem>;
  shipments: IShipment;
  addresses: Map<string, IAddress>;
  orderItems: IOrderItem[];
  products: Map<string, IProduct>;
}
const InvoiceView = () => {
  const {retailerId, invoiceId} = useParams<{retailerId: string; invoiceId: string}>();

  const {
    data: {orders, invoices, productItems, addresses, products}
  } = makeQueries<IData>([
    {type: IQueryEnum.GetOrders, variables: {retailerId}, mapKey: 'id'},
    {type: IQueryEnum.GetInvoices, variables: {retailerId}, mapKey: 'id'},
    {type: IQueryEnum.GetProductItems, mapKey: 'id'},
    {type: IQueryEnum.GetAddresses, variables: {retailerId}, mapKey: 'id'},
    {type: IQueryEnum.GetProducts, mapKey: 'id'}
  ]);
  const invoice = invoices?.get(invoiceId);
  const order = orders?.get(invoice?.orderId || '');
  const {
    data: {shipments: shipment, orderItems},
    isLoading
  } = makeQueries<IData>([
    {
      type: IQueryEnum.GetShipment,
      variables: {orderId: invoice?.orderId},
      isEnabled: Boolean(order?.state === OrderStateEnum.Delivering || order?.state === OrderStateEnum.Done)
    },
    {type: IQueryEnum.GetOrderItems, variables: {orderId: order?.id, retailerId}}
  ]);
  const shipTo = addresses?.get(order?.shippingAddressId!);
  const billTo = addresses?.get(order?.billingAddressId!);

  const handlePrint = () => {
    window.print();
  };


  function getTrackingLink() {
    if (shipment?.trackingNumber?.startsWith('B2')) return 'https://mysctrackandtrace.dhl.com/cview/search?c=US&lang=en&num=' + shipment.trackingNumber;
    if (shipment?.carrierName === 'FedEx') return 'https://www.fedex.com/fedextrack/?trknbr=' + shipment.trackingNumber;

    return getUrl(pageUrls.orderView, {orderId: order?.id, retailerId})
  }

  const isShippingInvoice = order?.shippingPrice! > 0 && order?.shippingPrice === invoice?.totalAmount;

  return (
    <Card loading={isLoading}>
      {invoice?.orderId ? (
        <>
          <div className="invoice-header">
            <PrinterOutlined style={{fontSize: 24}} onClick={handlePrint} className="invoice-header-print-icon" />
            <div className="invoice-header_leftSide">
              <Image alt="Resident logo" height={50} width={300} style={{objectFit: 'cover'}} src={residentLogo} />
              <address className={'invoice-header_leftSide_address'}>
                <p>340 S Lemon Ave # 9599 </p>
                <p>Walnut CA 91789 </p>
                <p>United States</p>
                <p>
                  Phone: <strong>8888632827</strong>
                </p>
              </address>
            </div>
            <div className="invoice-header_rightSide">
              <h2 className="invoice-header_rightSide-title text-lg font-semibold">{isShippingInvoice ? 'Shipping Invoice' : 'Invoice'}</h2>
              <div>
                <p>
                  Invoice <strong className={'ml15'}>{invoice?.number}</strong>
                </p>
                <p>
                  Date: <strong className={'ml15'}>{moment(invoice?.createDate).format('MM/DD/YYYY')}</strong>
                </p>
              </div>
            </div>
          </div>
          <div className={'invoice-bill-Ship-Address'}>
            <div className={'invoice-billTo'}>
              Bill to
              <div className={'address-border'}>
                <strong>{billTo?.address}</strong>
              </div>
            </div>
            <div className={'invoice-shipTo'}>
              Ship to
              <div className={'address-border'}>
                <strong>{shipTo?.address}</strong>
              </div>
            </div>
          </div>
          <table className={'invoice-table'}>
            <thead>
              <tr>
                <th scope="col">Order</th>
                <th scope="col">Carrier</th>
                <th scope="col">Tracking number</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{height: 70}}>
                <td>
                  {order?.number} {`(${order?.reference})`}
                </td>
                <td>{shipment?.carrierName}</td>
                <td>
                  <a rel="noreferrer" target="_blank" href={getTrackingLink()} className="underline text-cyan-700 hover:text-cyan-800">
                    {shipment?.trackingNumber}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <table className={'invoice-table'}>
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Product</th>
                <th scope="col">Size</th>
                <th scope="col">Quantity</th>
                <th scope="col">Unit Price</th>
                <th scope="col">Amount</th>
              </tr>
            </thead>
            <tbody>
              {isShippingInvoice ? (
                <tr key={'shipping'}>
                  <td>{1}</td>
                  <td>Shipping</td>
                  <td>-</td>
                  <td>1</td>
                  <td>
                    <N value={order?.shippingPrice} type="price" />
                  </td>
                  <td>
                    <N value={order?.shippingPrice} type="price" />
                  </td>
                </tr>
              ) : (
                orderItems?.map((item: IOrderItem, index: number) => {
                  const product = products?.get(item.productId!);
                  const productItem = productItems?.get(item.productItemId!);

                  if ((!productItem?.title && !product?.title && !item.price) || (!item.price && !item.amount)) {
                    return null;
                  }
                  return (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{product?.title}</td>
                      <td>{productItem?.title}</td>
                      <td>{item.quantity}</td>
                      <td>
                        <N value={item.price} type="price" />
                      </td>
                      <td>
                        <N value={item.amount} type="price" />
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
            <tfoot></tfoot>
          </table>
          <table className={'invoice-price-table'}>
            <tbody>
              <tr>
                <th>Total</th>
                <td>
                  <N value={invoice?.totalAmount} type="price" />
                </td>
              </tr>
            </tbody>
          </table>
        </>
      ) : (
        <h2 className="tac text-lg font-semibold">Order is missing.</h2>
      )}
    </Card>
  );
};
export default InvoiceView;
