import {Button, Popconfirm} from 'antd';
export interface IFooterButtonProps {
  actionTitle: string;
  /** Function that will be called on button click*/
  onAction: () => any;
  isActionDisabled?: boolean;
  recommendationThreshold: number | null;
  priceRange: number | null;
  subtotal: number;
  discount?: number;
}

export default function FooterButton({
  recommendationThreshold,
  actionTitle,
  isActionDisabled,
  onAction,
  subtotal,
  priceRange,
  discount = 0
}: IFooterButtonProps) {
  const isThreshold = recommendationThreshold ? subtotal > recommendationThreshold : false;
  const neededAmount = priceRange ? priceRange - subtotal - discount + 1 : 0;
  return (
    <>
      {isThreshold && actionTitle === 'Place order' && neededAmount > 0 ? (
        <Popconfirm
          placement="top"
          title={`Add $${neededAmount} worth of items and get free shipping`}
          onCancel={onAction}
          okText="Add more items"
          cancelText="Place Order"
        >
          <Button disabled={isActionDisabled} className="w100 mt10" type="primary">
            {actionTitle}
          </Button>
        </Popconfirm>
      ) : (
        <Button disabled={isActionDisabled} className="w100 mt10" onClick={onAction} type="primary">
          {actionTitle}
        </Button>
      )}
    </>
  );
}
