import {Col, Divider, Image, List, Row, Space} from 'antd';
import AnnouncementModal from 'app/components/AnnouncementModal';
import {N} from 'components/N';

import {IOrderItem} from 'core/models/orderItems/types';
import {IProductItem} from 'core/models/productItems/types';
import {IProduct} from 'core/models/products/types';
import {IOrderExtended} from 'core/stores/OrderStore';

import '../style.less';

interface IProps {
  products: Map<string, IProduct>;
  warehouse?: string;
  orderItems: Map<string, IOrderItem>;
  productItems: Map<string, IProductItem>;
  order: IOrderExtended;
}

export default function OrderItem({orderItems, productItems, products, order, warehouse}: IProps) {
  const orderItemsArray = Array.from(orderItems.values());

  function getStockAlerts() {
    const stockAlerts = orderItemsArray.map(item => {
      const productItem = productItems?.get(item.productItemId);

      return {
        productId: item.productId,
        productItemId: item.productItemId,
        stockAlert: warehouse === 'PHX' ? productItem?.PHXAlert : productItem?.RXHAlert
      };
    });

    console.log(stockAlerts);

    return stockAlerts.filter(stockAlert => stockAlert.stockAlert! > 0);
  }

  return (
    <>
      {getStockAlerts().length > 0 && (
        <AnnouncementModal
          type="warning"
          modalKey={order.reference!}
          title="Stock Alert"
          description={
            <ul>
              {getStockAlerts().map(a => {
                console.log(a);
                const productItem = productItems?.get(a.productItemId!);
                const product = products?.get(a?.productId!);
                return (
                  <li key={productItem!.id} className="text-sm text-left">
                    <span className="font-bold">
                      {product?.title} ({productItem?.title})
                    </span>{' '}
                    will be shipped in <span className="font-semibold text-red-500">{a.stockAlert}</span> days.
                  </li>
                );
              })}
            </ul>
          }
        />
      )}
      <List
        dataSource={orderItemsArray}
        renderItem={item => {
          const product = products?.get(item.productId!);
          const productItem = productItems?.get(item.productItemId);

          const stockAlertValue = warehouse === 'PHX' ? productItem?.PHXAlert : productItem?.RXHAlert;

          return (
            <List.Item>
              <Row justify="space-between" align="middle">
                <Col span={18}>
                  <Space align="center">
                    <Image
                      className="pt10 pb10"
                      width="150px"
                      placeholder={<img className="pt10 pb10" width="150" alt={product?.title} src={`${product?.images[0]}&width=50`} />}
                      alt={product?.title}
                      src={`${product?.images[0]}`}
                    />
                    <div className="pt10 pl15">
                      <h2 className="text-lg font-semibold">{product?.title}</h2>
                      <h3 className="text-md font-semibold">{productItem?.title}</h3>
                    </div>
                  </Space>
                </Col>
                <Col span={6} className="pt10">
                  <div className="pl10 pt10">
                    <Row>
                      <h4 className="m0">SKU:</h4>
                      <h4 className="f-light c-darkblue m0 ml5">{productItem?.sku}</h4>
                    </Row>
                    <Row>
                      <h4 className="m0">Price:</h4>
                      <h4 className="f-light c-darkblue m0 ml5">
                        <N value={item?.price} type="price" />
                      </h4>
                    </Row>
                    <Row>
                      <h4 className="m0">Quantity:</h4>
                      <h4 className="f-light c-darkblue m0 ml5">{item?.quantity}</h4>
                    </Row>
                    <Row>
                      <h4 className="m0">Amount:</h4>
                      <h4 className="f-light">
                        <N value={item?.amount} type="price" className="f-light c-darkblue m0 ml5" />
                      </h4>
                    </Row>
                  </div>
                </Col>
              </Row>
              {stockAlertValue! > 0 && (
                <h3 className="font-semibold text-red-500">
                  <span className="bg-red-200 text-red-600 px-2 py-1 rounded-md mr-2 mt-1">Warning</span> This item will be shipped
                  approximately in {stockAlertValue} days
                </h3>
              )}
            </List.Item>
          );
        }}
      />
      <Divider />
      <Row justify="space-between">
        <span>Subtotal:</span>
        <span>
          <N value={order?.subtotalAmount!} type="price" />
        </span>
      </Row>
      <Row justify="space-between" className="mt10">
        <span>Delivery:</span>
        <span>${order?.shippingPrice || 0}</span>
      </Row>
      {!!order?.discount && (
        <Row justify="space-between" className="mt10">
          <span>Discount:</span>
          <span>
            <N value={order?.discount * -1} type="price" />
          </span>
        </Row>
      )}
      <Row justify="space-between" className="mt10">
        <h2 className="text-lg font-semibold">Total</h2>
        <h2 className="text-lg font-semibold">
          <N value={order?.totalAmount!} type="price" />
        </h2>
      </Row>
    </>
  );
}
