export interface IIsActive {
  isActive?: boolean;
}

/** Adding flag "isActive" to all items in array */
export function addIsActive<T extends {id: string}>(items: T[], activeItems: string[]): Array<T & IIsActive> {
  return items.map(i => {
    return {...i, isActive: Boolean(activeItems.find(id => id === i.id))};
  });
}
