export interface IShippingPlans {
  id: string;
  region: string;
  planDay: string;
  shipDay: string;
  orderCutOffDay: string;
  orderCutOffTime: string;
}

export enum RegionsEnum {
  MidAtlantic = 'Mid-Atlantic',
  NewEngland = 'New England',
  Yankee = 'Yankee',
  Florida = 'Florida',
  Southeast = 'Southeast',
  EMDW = 'E MDW',
  CMDW = 'C MDW',
  SMDW = 'S MDW',
  UMDW = 'U MDW',
  UCAN = 'UCAN',
  PNW = 'PNW',
  NCAL = 'N CAL',
  SCALAZ = 'S CAL/AZ'
}

export enum DaysOfTheWeekEnum {
  Sunday = 'Sunday',
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday'
}
