import {UserAddOutlined} from '@ant-design/icons';
import {Button, Card, Col, Form, Input, message, Radio, Row, Select, Upload} from 'antd';
import {PageWrapper} from 'components/PageWrapper';
import {IMutationEnum, IQueryEnum, makeMutation, makeQueries} from 'core/api';
import AuthStore from 'core/auth';
import {IAddress} from 'core/models/addresses/types';
import {getRetailer} from 'core/models/retailers/utils';
import {IUser, UserRolesEnum} from 'core/models/users/types';
import {useContext, useEffect, useState} from 'react';
import {dataLayerEvents} from 'utils/dataLayer';
import {normalizeFile} from 'utils/normalizeFile';

interface IData {
  addresses: IAddress[];
  users: IUser[];
}

function RetailerGeneral() {
  const {retailer, isLoading} = getRetailer();
  const {isRetailer, token} = useContext(AuthStore);
  const [logoUrl, setLogoUrl] = useState(retailer?.logoUrl);

  const [form] = Form.useForm();

  useEffect(() => {
    if (retailer) {
      form.setFields([
        {
          name: 'name',
          value: retailer.name
        },
        {
          name: 'withCreditLimit',
          value: retailer.withCreditLimit
        },
        {
          name: 'website',
          value: retailer.website
        },
        {
          name: 'salesManagerId',
          value: retailer.salesManagerId
        },
        {
          name: 'notes',
          value: retailer.notes
        },
        {
          name: 'website',
          value: retailer.website
        },
        {
          name: 'facebook',
          value: retailer.facebook
        },
        {
          name: 'instagram',
          value: retailer.instagram
        },
        {
          name: 'twitter',
          value: retailer.twitter
        },
        {
          name: 'youtube',
          value: retailer.youtube
        },
        {
          name: 'billingAddressId',
          value: retailer.billingAddressId
        }
      ]);
    }
    if (retailer?.logoUrl) {
      setLogoUrl(retailer.logoUrl);
    }
  }, [retailer, isLoading, form]);

  /** Prefetching data for better UX */
  const {
    data: {addresses, users}
  } = makeQueries<IData>([
    {type: IQueryEnum.GetAddresses, variables: {retailerId: retailer?.id}, isEnabled: Boolean(retailer)},
    {
      type: IQueryEnum.GetUsers,
      variables: {roles: [UserRolesEnum.Admin, UserRolesEnum.Sales]}
    }
  ]);

  useEffect(() => {
    if (!isLoading && retailer) {
      dataLayerEvents('view_retailers_general', retailer);
    }
  }, [isLoading, retailer]);

  const mutation = makeMutation({
    type: IMutationEnum.RetailerUpdate,
    loadingMsg: 'Updating retailer...',
    successMsg: 'Retailer updated.'
  });

  const handleSubmit = (values: any) => {
    mutation.mutate({
      retailerUpdate: {
        id: retailer?.id,
        ...values,
        billingAddressId: values.billingAddressId ? values.billingAddressId : null,
        logoUrl
      }
    });
  };

  const formLayout = {
    labelCol: {span: 12},
    wrapperCol: {span: 20}
  };

  const uploaderProps = {
    name: 'file',
    accept: '.png, .svg, .webp',
    action: `${process.env.REACT_APP_API_URL}/files`,
    headers: {
      authorization: `Bearer ${token}`
    },
    onChange({file}: any) {
      if (file.status === 'error') {
        message.error(`${file.name} file upload failed.`);
      }
      if (file?.response?.fileUrl) {
        setLogoUrl(file?.response?.fileUrl);
      }
      if (file.status === 'removed') {
        setLogoUrl('');
      }
    }
  };

  return (
    <PageWrapper pageTitle="Profile Management" withRetailerSelector topMenuType={'retailers'}>
      <Card>
        <Form {...formLayout} layout="vertical" form={form} onFinish={handleSubmit}>
          <Row>
            <Col span={12} className="pr15">
              <Form.Item label="Retailer name:" name={'name'} className="mt10 mb10 w100" initialValue={retailer?.name}>
                <Input size="large" placeholder="Enter name here" />
              </Form.Item>
              {!isRetailer && (
                <Form.Item label="Sales manager:" name="salesManagerId" initialValue={retailer?.salesManagerId}>
                  <Select placeholder="Select Sales Manager" allowClear>
                    {users
                      ?.filter(u => Boolean(u.fulfilId))
                      ?.map(user => (
                        <Select.Option key={user.id} value={user.id}>
                          {user.firstName} {user.lastName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
              <Form.Item
                label="Logo"
                className="w60"
                valuePropName="fileList"
                getValueFromEvent={normalizeFile}
                rules={[{required: false}]}
              >
                <Upload.Dragger {...uploaderProps} maxCount={1} name="files">
                  {logoUrl ? (
                    <img src={logoUrl} alt="logo" height="100" className="w100" />
                  ) : (
                    <>
                      <UserAddOutlined style={{fontSize: '50px'}} />
                      <p className="ant-upload-text">Drag & Drop your logo</p>
                    </>
                  )}
                </Upload.Dragger>
                <h5>*Accepted formats: .png .svg .webp</h5>
              </Form.Item>
              {!isRetailer && (
                <Form.Item label="Retailer has credit limit:" name="withCreditLimit" initialValue={retailer?.withCreditLimit}>
                  <Radio.Group size="large">
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              )}
              <Form.Item label="Billing address:" name="billingAddressId" initialValue={retailer?.billingAddressId}>
                <Select placeholder="Billing address" allowClear>
                  {addresses
                    ?.filter(a => !a.hidden)
                    ?.map(address => (
                      <Select.Option key={address.id} value={address.id}>
                        {address.address}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
              {!isRetailer && (
                <Form.Item label="Notes:" name={'notes'} className="mt10 mb10" initialValue={retailer?.notes}>
                  <Input.TextArea size="large" placeholder="Add some notes here. It is not visible for the retailer." />
                </Form.Item>
              )}
            </Col>
            <Col span={12} className="pl15">
              <Form.Item label="Website:" name={'website'} className="mt10 mb10" initialValue={retailer?.website}>
                <Input size="large" placeholder="Past website link here" />
              </Form.Item>
              <Form.Item label="Facebook:" name={'facebook'} className="mt10 mb10" initialValue={retailer?.facebook}>
                <Input size="large" placeholder="Past facebook link here" />
              </Form.Item>
              <Form.Item label="Instagram:" name={'instagram'} className="mt10 mb10" initialValue={retailer?.instagram}>
                <Input size="large" placeholder="Past instagram link here" />
              </Form.Item>
              <Form.Item label="Twitter:" name={'twitter'} className="mt10 mb10" initialValue={retailer?.twitter}>
                <Input size="large" placeholder="Past twitter link here" />
              </Form.Item>
              <Form.Item label="Youtube:" name={'youtube'} className="mt10 mb10" initialValue={retailer?.youtube}>
                <Input size="large" placeholder="Past youtube link here" />
              </Form.Item>
            </Col>
            <Form.Item wrapperCol={{span: 22}} className="w100 tar mt15">
              <Button type="primary" htmlType="submit">
                Update Settings
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Card>
    </PageWrapper>
  );
}

export default RetailerGeneral;
