import moment from 'moment';
import {useEffect, useState} from 'react';
import {MinusCircleOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import {Button, Form, TimePicker, Popconfirm, Select, Card} from 'antd';
import {IMutationEnum, makeMutation, IQueryEnum, makeQueries} from 'core/api';
import {IShippingPlans, RegionsEnum, DaysOfTheWeekEnum} from 'core/models/shippingPlans/types';
import Table, {IColumn} from 'components/Table/Table';

interface IData {
  shippingPlans: IShippingPlans[];
}

const ShippingPlansRules = () => {
  const [form] = Form.useForm();
  const [rules, setRules] = useState([{id: ''}]);
  const [editable, setEditable] = useState(false);
  const [fieldsTouched, setFieldsTouched] = useState(false);
  const format = 'HH:mm';
  const {
    data: {shippingPlans = []},
    isLoading
  } = makeQueries<IData>([{type: IQueryEnum.GetShippingPlans}]);

  const mutation = makeMutation({
    type: IMutationEnum.CreateOrUpdateShippingPlan,
    loadingMsg: 'Creating...',
    successMsg: 'Created!'
  });

  const mutationDelete = makeMutation({
    type: IMutationEnum.DeleteShippingPlan,
    loadingMsg: 'Deleting...',
    successMsg: 'Deleted!'
  });

  useEffect(() => {
    setRules(shippingPlans);
  }, [form, shippingPlans]);

  useEffect(() => {
    if (mutation.isSuccess) {
      setEditable(false);
      setFieldsTouched(false);
      form.resetFields()
    }
  }, [mutation.isSuccess, form]);

  const onFinish = (values: any) => {
    const shippingPlanInput = values.fields.map((v: any) => {
      return {...v, orderCutOffTime: moment(v.orderCutOffTime).format(format)};
    });

    mutation.mutate({ shippingPlanInput });
  };

  const onDelete = (remove: (index: number | number[]) => void, index: number) => () => {
    const id = rules[index]?.id;
    if (id && editable) {
      mutationDelete.mutate({
        id
      });
    }

    remove(index);
    setFieldsTouched(false);
  };

  const onEdit = () => {
    setEditable(true);
    const formattedShippingPlans = shippingPlans.map((plan) => {
      return {...plan, orderCutOffTime: moment(plan.orderCutOffTime, format)}
    });
    
    form.setFieldsValue({fields: formattedShippingPlans});
  };

  const onCancel = () => {
    setEditable(false);
    setFieldsTouched(false);
    form.resetFields();
  };

  const columns: IColumn<IShippingPlans>[] = [
    {
      title: 'Region',
      dataIndex: 'region'
    },
    {
      title: 'Plan day',
      dataIndex: 'planDay'
    },
    {
      title: 'Ship Day',
      dataIndex: 'shipDay'
    },
    {
      title: 'Order Cut Off (EST)',
      render: (text: any, record: any) => <span>{record.orderCutOffDay}, {record.orderCutOffTime}</span>,
    },
  ];

  return (
    <Card 
      loading={isLoading} 
      title={<>
        <div>Shipping Rules</div>
        <div style={{fontSize: '10px'}}>{shippingPlans ? 'Shipping rules by region.' : 'Set up shipping rules for your regions.'}</div>
      </>}
      className="mb15"
      extra={(!editable && shippingPlans.length > 0) && [
        <EditOutlined
          key="edit"
          onClick={onEdit}
          className={'cursor-pointer pr10'}
          style={{fontSize: 20}}
        />
      ]}
      >
    {shippingPlans.length > 0 && !editable && (
      <Table columns={columns} data={shippingPlans} />
    )}
    <Form layout="vertical" form={form} onFinish={onFinish} onFieldsChange={() => setFieldsTouched(true)}>
      <Form.List name={'fields'}>
        {(fields, {add, remove}, {errors}) => (
          <>
            {fields.map((field: any, i: number) => (
              <div key={`${field.name}-${i}`}>
                <div className={'d-flex b b-r4 mb15 pt15 pl15'}>
                  <div className={'d-flex w20 mr15'}>
                      <Form.Item label="Region" name={[i, 'region']} className={'w100'} rules={[{required: true}]}>
                        <Select placeholder="Select region">
                        {(Object.keys(RegionsEnum) as Array<keyof typeof RegionsEnum>).sort().map((region) => (
                          <Select.Option value={RegionsEnum[region]} key={region}>
                            {RegionsEnum[region]}
                          </Select.Option>
                        ))}
                        </Select>
                      </Form.Item>
                  </div>
                  <div className={'d-flex w15 mr15'}>
                      <Form.Item label="Plan Day" name={[i, 'planDay']} className={'w100'} rules={[{required: true}]}>
                        <Select placeholder="Select day">
                        {(Object.keys(DaysOfTheWeekEnum) as Array<keyof typeof DaysOfTheWeekEnum>).map((day) => (
                          <Select.Option value={day} key={day}>
                            {day}
                          </Select.Option>
                        ))}
                        </Select>
                      </Form.Item>
                  </div>
                  <div className={'d-flex w15 mr15'}>
                      <Form.Item label="Ship Day" name={[i, 'shipDay']} className={'w100'} rules={[{required: true}]}>
                        <Select placeholder="Select day">
                        {(Object.keys(DaysOfTheWeekEnum) as Array<keyof typeof DaysOfTheWeekEnum>).map((day) => (
                          <Select.Option value={day} key={day}>
                            {day}
                          </Select.Option>
                        ))}
                        </Select>
                      </Form.Item>
                  </div>
                  <div className={'d-flex w15 mr15'}>
                      <Form.Item label="Order Cut Off Day" name={[i, 'orderCutOffDay']} className={'w100'} rules={[{required: true}]}>
                        <Select placeholder="Select day">
                        {(Object.keys(DaysOfTheWeekEnum) as Array<keyof typeof DaysOfTheWeekEnum>).map((day) => (
                          <Select.Option value={day} key={day}>
                            {day}
                          </Select.Option>
                        ))}
                        </Select>
                      </Form.Item>
                  </div>
                  <div className={'d-flex w15 mr15'}>
                      <Form.Item label="Order Cut Off Time" name={[i, 'orderCutOffTime']} className={'w100'} rules={[{required: true}]}>
                        <TimePicker format={format} minuteStep={5} />
                      </Form.Item>
                  </div>
                  <div className={'d-flex w10 mr15'}>
                    <Popconfirm
                      title="Are you sure you want to delete this rule?"
                      onConfirm={onDelete(remove, field.name)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        type="ghost"
                        style={{display: 'block', marginTop: 29, background: '#ff4242', color: 'white'}}
                        icon={<MinusCircleOutlined />}
                      >
                        Remove
                      </Button>
                    </Popconfirm>
                  </div>
                </div>
              </div>
            ))}
            <div>
              <Button type="dashed" onClick={() => add()} className="w100 mt15" icon={<PlusOutlined />}>
                Add
              </Button>
              <Form.ErrorList errors={errors} />
            </div>
          </>
        )}
      </Form.List>
      {(editable || fieldsTouched) && (
        <div className="w100 tar mt15">
          <Button htmlType="button" onClick={onCancel} className="mr15">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      )}
    </Form>
    </Card>
  );
};

export default ShippingPlansRules;
