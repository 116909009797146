import {PageWrapper} from 'components/PageWrapper';
import {Tooltip, Button, Tag} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {IQueryEnum, makeQueries} from 'core/api';
import {IGroup} from 'core/models/groups/types';
import {pageUrls} from 'core/router/pages';
import {useHistory} from 'react-router-dom';
import Table, {IColumn} from 'components/Table/Table';
import {getUrl} from 'utils/getUrl';

interface IData {
  groups?: IGroup[];
}

function ProductGroupList() {
  const history = useHistory();
  const {
    data: {groups = []},
    isLoading
  } = makeQueries<IData>([{type: IQueryEnum.GetGroups}]);

  const onCreateClick = () => {
    history.push(pageUrls.productGroupCreate);
  };

  const headerActions = (
    <div>
      <Tooltip placement="topLeft" arrowPointAtCenter overlay={false}>
        <Button type="primary" onClick={onCreateClick} icon={<PlusOutlined />}>
          Create group
        </Button>
      </Tooltip>
    </div>
  );

  const columns: IColumn<IGroup>[] = [
    {
      title: 'Title',
      dataIndex: 'title',
      width: '35%'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: '50%'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (type: string) => <Tag color={type === 'Products' ? 'blue' : 'green'}>{type}</Tag>,
      width: '15%'
    }
  ];

  const handleRowClick = ({id}: any): void => {
    history.push(getUrl(pageUrls.productGroupView, {groupId: id}));
  };

  return (
    <PageWrapper pageTitle="Product Groups" topMenuType={'catalog'} actions={headerActions} breadcrumbs={[]}>
      <Table
        data={groups}
        filterConfig={{fulltextParams: ['title', 'description'], fulltextPlaceholder: 'Search for a group'}}
        columns={columns}
        loading={isLoading}
        onRowClick={handleRowClick}
      />
    </PageWrapper>
  );
}

export default ProductGroupList;
