import {Image} from 'antd';
import {IAsset} from 'core/brandfolder/types';
import {useEffect, useState} from 'react';
import {getClassName} from 'utils/getClassName';

export default function AssetCover({asset}: {asset: IAsset}) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
  }, [asset]);

  function handleImageLoaded() {
    setLoaded(true);
  }

  if (asset.type !== 'Image') {
    return (
      <img
        src={asset.thumbnailUrl}
        alt="video poster"
        className="object-cover rounded-md hover:rounded-md  w-full h-auto"
      />
    );
  }

  return (
    <div key={asset.id}>
      <div className={loaded ? 'hidden relative' : 'block relative'}>
        <img
          src={asset.thumbnailUrl}
          key={`${asset.id}-thumbnail`}
          alt={asset.name}
          className={'object-cover rounded-md hover:rounded-md w-full h-auto'}
        />
        <span className="absolute top-5 left-5 px-3 py-0.5 rounded-full text-sm font-medium bg-red-300 text-red-800">
          Loading high quality picture...
        </span>
      </div>
      <Image
        src={asset.url}
        key={`${asset.id}-url`}
        alt={asset.name}
        className={getClassName(loaded ? 'block' : 'hidden', 'object-cover rounded-md hover:rounded-md')}
        onLoad={handleImageLoaded}
        fallback={asset.thumbnailUrl}
      />
    </div>
  );
}
