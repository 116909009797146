import {Tag} from 'antd';
import {InvoiceStateEnum} from './types';

export const invoiceStateRenderer = (state: InvoiceStateEnum) => {
  let tagColor;
  switch (state) {
    case InvoiceStateEnum.Posted:
      tagColor = 'cyan';
      break;
    case InvoiceStateEnum.Paid:
      tagColor = 'geekblue';
      break;
    default:
      return state;
  }
  return <Tag color={tagColor}>{state}</Tag>;
};
