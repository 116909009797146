import {gql} from 'graphql-tag';

export const UPDATE_PRODUCT = gql`
  mutation updateProduct($productUpdate: ProductUpdate!) {
    updateProduct(productUpdate: $productUpdate) {
      id
      productCategoryId
      productBrandId
      title
      description
      images
      productItemIds
      wholesaleDiscount {
        unit
        minItemsInCart
        discount
      }
      archived
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation createProduct($productInput: ProductInput!) {
    createProduct(productInput: $productInput) {
      id
      productCategoryId
      productBrandId
      title
      description
      images
      productItemIds
    }
  }
`;

export const ARCHIVE_PRODUCT = gql`
  mutation archiveProduct($id: ID, $archived: Boolean) {
    archiveProduct(id: $id, archived: $archived) {
      id
      productCategoryId
      productBrandId
      title
      description
      images
      productItemIds
      archived
      wholesaleDiscount {
        unit
        minItemsInCart
        discount
      }
    }
  }
`;
