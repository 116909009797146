import {DocumentNode} from 'graphql';
import {IModelEnum} from '../types';

export interface IMutation {
  /** Mutation type */
  type: IMutationEnum;
  /** Variables that has been used for fetching query (Used for optimistic updates) */
  keyVars?: object;
  /** Message that will be shown right after mutation started*/
  loadingMsg?: string;
  /** Message that will be shown after successful mutation */
  successMsg?: string;
}

export interface IMutationDef {
  /** Graphql mutation schema */
  gql: DocumentNode;
  /** Model type that you are mutating. (Used for optimistic updates) */
  model: IModelEnum;
  type: 'createOrUpdate' | 'delete' | 'simple';
  /** Parameter that will be used to extract data from input */
  inputKey?: string;
  /** Should be passed as true if you are mutating in bulk */
  isBulk?: boolean;
}

export enum IMutationEnum {
  CreateOrUpdatePriceListItems = 'createOrUpdatePriceListItems',
  RetailerUpdate = 'updateRetailer',
  CreateTask = 'createTask',
  CreateTaskComment = 'createTaskComment',
  CreateUser = 'createUser',
  CreateUsers = 'createUsers',
  UpdateUser = 'updateUser',
  UpdateAddress = 'updateAddress',
  CreateAddress = 'createAddress',
  DeleteUser = 'deleteUser',
  CreateProduct = 'createProduct',
  UpdateProduct = 'updateProduct',
  DeleteNotification = 'deleteNotification',
  CreateProductItem = 'createProductItem',
  UpdateProductItems = 'updateProductItems',
  UpdateProductItemsPriceSettings = 'updateProductItemsPriceSettings',
  AddProductsToRetailer = 'addProductsToRetailer',
  CreateOrUpdateOrder = 'createOrUpdateOrder',
  CancelOrder = 'cancelOrder',
  CreateReturn = 'createReturn',
  UpdateReturn = 'updateReturn',
  createOrUpdatePriceList = 'createOrUpdatePriceList',
  createOrUpdateProductCategory = 'createOrUpdateProductCategory',
  createOrUpdateProductBrand = 'createOrUpdateProductBrand',
  createOrUpdateSupplier = 'createOrUpdateSupplier',
  deletePriceList = 'deletePriceList',
  deleteProductCategory = 'deleteProductCategory',
  deleteProductBrand = 'deleteProductBrand',
  deleteSupplier = 'deleteSupplier',
  CreateOrders = 'createOrders',
  CreateGroup = 'createGroup',
  UpdateGroup = 'updateGroup',
  DeleteGroup = 'deleteGroup',
  createOrUpdateShippingRules = 'createOrUpdateShippingRules',
  deleteShippingRule = 'deleteShippingRule',
  ArchiveProduct = 'archiveProduct',
  ArchiveProductItemGroup = 'archiveProductItemGroup',
  CreateProductItemGroup = 'CreateProductItemGroup',
  CreateOrUpdateShippingPlan = 'createOrUpdateShippingPlan',
  DeleteShippingPlan = 'deleteShippingPlan',
  CreateOrUpdatePromo = 'createOrUpdatePromo',
  createRelatedOrder = 'createRelatedOrder',
  CreateAnalyticsRecord = 'createAnalyticsRecord',
}
