import {IOrderItem} from 'core/models/orderItems/types';

export const createOrder = (
  order: any,
  actualOrder: any,
  delayedOrder: any,
  orderInput: any,
  delayedOrderItems: Map<string, IOrderItem>,
  actualOrderItems: Map<string, IOrderItem>,
  orderItems: Map<string, IOrderItem>
) => {
  let orderItemsInput;
  let delayedItemsInput;
  let actualOrderInput;
  let delayedOrderInput;

  if (actualOrderItems.size > 0) {
    orderItemsInput = Array.from(actualOrderItems.values()).map(({quantity, productId, productItemId}) => ({
      quantity,
      productId,
      productItemId
    }));
    actualOrderInput = {
      ...orderInput,
      reference: actualOrder.reference
    };
  }
  if (delayedOrderItems.size > 0) {
    delayedItemsInput = Array.from(delayedOrderItems.values()).map(({quantity, productId, productItemId}) => ({
      quantity,
      productId,
      productItemId
    }));
    delayedOrderInput = {
      ...orderInput,
      reference: delayedOrder.reference
    };
  }
  if (delayedOrderItems.size === 0 && actualOrderItems.size === 0) {
    orderItemsInput = Array.from(orderItems.values()).map(({quantity, productId, productItemId}) => ({
      quantity,
      productId,
      productItemId
    }));
    actualOrderInput = {
      ...orderInput,
      reference: order.reference
    };
  }

  return {orderItemsInput, delayedItemsInput, actualOrderInput, delayedOrderInput};
};
