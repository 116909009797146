import {
  BookOutlined,
  GlobalOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  TagsOutlined,
  TeamOutlined,
  ToolOutlined,
  PercentageOutlined
} from '@ant-design/icons';
import {UserRolesEnum} from 'core/models/users/types';
import {pageUrls} from 'core/router/pages';

export const retailersMenuItems = [
  {
    url: pageUrls.retailerGeneral,
    title: 'General',
    key: 'general',
    Icon: ToolOutlined
  },
  {
    url:  pageUrls.retailerBuyingProfile,
    title: 'Buying Profile',
    key: 'buying-profile',
    Icon: ShoppingCartOutlined,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales]
  },
  {
    url: pageUrls.retailerShippingProfile,
    title: 'Shipping Profile',
    key: 'shipping-profile',
    Icon: GlobalOutlined,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales]
  },
  {
    url: pageUrls.retailerStoreList,
    title: 'Stores',
    key: 'store',
    Icon: ShopOutlined
  },
  {
    url:pageUrls.retailerAddressList,
    title: 'Addresses',
    key: 'address',
    Icon: GlobalOutlined,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales]
  },
  {
    url: pageUrls.retailerUserList,
    title: 'Users',
    key: 'user',
    Icon: TeamOutlined
  },
  {
    url: pageUrls.orderList,
    title: 'Orders',
    key: 'order',
    Icon: TagsOutlined
  }
];

export const settingsMenuItems = [
  {
    url: pageUrls.settingsUserList,
    title: 'Users',
    key: 'user',
    Icon: TeamOutlined,
    allowedRoles: [UserRolesEnum.Admin]
  },
  {
    url: pageUrls.settingsGeneral,
    title: 'General',
    key: 'general',
    Icon: ToolOutlined
  },
  {
    url: pageUrls.settingsDiscounts,
    title: 'Wholesale Discounts',
    key: 'discounts',
    Icon: PercentageOutlined
  },
  {
    url: pageUrls.settingsShipping,
    title: 'Shipping',
    key: 'shipping',
    Icon: GlobalOutlined
  }
];

export const catalogMenuItems = [
  {
    url: pageUrls.productListAll,
    title: 'Product Catalog',
    key: 'product/',
    Icon: BookOutlined
  },
  {
    url: pageUrls.productGroupList,
    title: 'Product Groups',
    key: 'product-group/',
    Icon: TeamOutlined,
    allowedRoles: [UserRolesEnum.Admin]
  }
];
