import {gql} from 'graphql-tag';

export const GET_ADDRESSES = gql`
  query getAddresses($retailerId: ID!) {
    getAddresses(retailerId: $retailerId) {
      id
      name
      email
      address
      phone
      zip
      stateId
      city
      addressLine1
      addressLine2
      hidden
      isStore
      isShippingAddress
      isWarehouse
    }
  }
`;
