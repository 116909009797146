import {gql} from 'graphql-tag';

export const ARCHIVE_PRODUCT_ITEM_GROUP = gql`
  mutation archiveProductItemGroup($id: ID!, $archived: Boolean) {
    archiveProductItemGroup(id: $id, archived: $archived) {
      id
      supplierId
      productId
      archived
    }
  }
`;
export const CREATE_PRODUCT_ITEM_GROUP = gql`
  mutation createProductItemGroup($productId: ID!, $supplierId: ID!) {
    createProductItemGroup(productId: $productId, supplierId: $supplierId) {
      id
      supplierId
      productId
      archived
    }
  }
`;
