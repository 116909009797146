import {gql} from 'graphql-tag';

export const GET_PRODUCT_ITEM_GROUPS = gql`
  query getProductItemGroups {
    getProductItemGroups {
      id
      supplierId
      productId
      archived
    }
  }
`;
