import {Divider, Layout, Menu as AntdMenu} from 'antd';
import {Icon, IconColorEnum, IconTypeEnum} from 'components/Icon';
import AuthStore from 'core/auth';
import {getRetailers} from 'core/models/retailers/utils';
import { pageUrls } from 'core/router/pages';
import {memo, useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import getSidebarItemList from './getSidebarItemList';
import SidebarHeader from './SidebarHeader';
import {getSidebarItems} from './SidebarItems';
import './styles.less'

const {Sider} = Layout;

const Sidebar = memo(() => {
  const history = useHistory();
  const auth = useContext(AuthStore);
  const {logoutFromRetailer, isRetailerExperience, isRetailer} = auth;
  const [selectedKey, setSelectedKey] = useState(history.location.pathname.split('/')[1]);
  const [currentRetailer, setCurrentRetailer] = useState(localStorage.getItem('retailerId'));
  const retailers = getRetailers();
  const isCurrentRetailerView = !isRetailerExperience && !isRetailer;

  useEffect(() => {
    history.listen(({pathname}) => {
      const [, route, retailerId] = pathname.split('/');

      if (retailerId && isCurrentRetailerView && retailers?.get(retailerId)) {
        localStorage.setItem('retailerId', retailerId);
        setCurrentRetailer(retailerId);
      }
      setSelectedKey(route);
    });
  }, [isCurrentRetailerView, history, retailers]);

  const exitCurrentRetailer = () => {
    localStorage.removeItem('retailerId');
    setCurrentRetailer(null);
    history.push(pageUrls.retailerList);
  };

  return (
    <Sider theme="light" width={220}>
      <SidebarHeader />
      <AntdMenu selectedKeys={[selectedKey]} theme="light" mode="inline">
        {getSidebarItemList(getSidebarItems(auth), auth, currentRetailer)}
      </AntdMenu>
      <Divider />
      {isRetailerExperience && (
        <div className='retail-experience' onClick={logoutFromRetailer}>
          <Icon type={IconTypeEnum.Exit} color={IconColorEnum.Blue} />
          <p className="c-darkblue exit-retailer-view">Exit Retailer view</p>
        </div>
      )}
      {currentRetailer && !isRetailerExperience && !isRetailer &&(
        <div className='retail-experience' onClick={exitCurrentRetailer}>
          <Icon type={IconTypeEnum.Exit} color={IconColorEnum.Blue} />
          <p className="c-darkblue exit-retailer-view">Exit Retailer</p>
        </div>
      )}
    </Sider>
  );
});

export default Sidebar;
