import {DeleteOutlined} from '@ant-design/icons';
import {IRetailer} from 'core/models/retailers/types';
import {UserRolesEnum} from 'core/models/users/types';
import {memo} from 'react';
import {Table} from 'components/Table';
import {Select} from 'antd';
import {IMutationEnum, makeMutation} from 'core/api';
import {IconColorEnum} from 'components/Icon';
import {IColumn} from 'components/Table/Table';

export interface IRetailerAssignment {
  retailers?: IRetailer[];
  isLoading: boolean;
  retailerIds?: string[] | [];
  userOktaId: string;
}

const RetailerAssignment: React.FC<IRetailerAssignment> = memo(({retailers, isLoading, retailerIds = [], userOktaId}) => {
  const {Option} = Select;

  const mutation = makeMutation({
    type: IMutationEnum.UpdateUser,
    keyVars: {roles: [UserRolesEnum.Sales, UserRolesEnum.Admin]},
    loadingMsg: `Updating user...`,
    successMsg: `User updated.`
  });

  const assignRetailer = (retailerId: string) => {
    let newAssignedRetailers = retailerIds ? [...retailerIds] : [];

    newAssignedRetailers = [...newAssignedRetailers, retailerId];

    mutation.mutate({
      userUpdate: {
        oktaId: userOktaId,
        retailerIds: newAssignedRetailers
      }
    });
  };

  const removeRetailer = (retailerId: string) => () => {
    let newAssignedRetailers = retailerIds ? [...retailerIds] : [];

    const foundIndex = newAssignedRetailers.findIndex(id => id === retailerId)!;
    newAssignedRetailers.splice(foundIndex, 1);

    mutation.mutate({
      userUpdate: {
        oktaId: userOktaId,
        retailerIds: newAssignedRetailers
      }
    });
  };

  const columns: IColumn<IRetailer>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '80%'
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      align: 'center',
      render: (_, row) => <DeleteOutlined style={{fontSize: 17, color: IconColorEnum.Red}} onClick={removeRetailer(row.id)} />,
      width: '20%'
    }
  ];

  return (
    <>
      <Select
        allowClear
        size="large"
        placeholder="Search for a retailer"
        className="mb15 w100"
        showSearch
        filterOption={(input, option: any) => {
          const label = option?.children as string;
          return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
        notFoundContent={null}
        onSelect={assignRetailer}
      >
        {retailers
          ?.filter(r => !retailerIds?.includes(r.id))
          .map(r => (
            <Option key={r.id} value={r.id}>
              {r.name}
            </Option>
          ))}
      </Select>
      <Table
        data={retailers?.filter(r => retailerIds?.includes(r.id))}
        columns={columns}
        loading={isLoading}
        rowClassName={'cursor-pointer'}
      />
    </>
  );
});

export default RetailerAssignment;
