// FIXME: move to styled-components (less variables)
export enum IconColorEnum {
  Black = '#000',
  White = '#fff',
  Blue = '#546E7A',
  Red = 'red',
  Grey = '#a7a7a7'
}

export enum IconTypeEnum {
  Assets = 'AssetsSvg',
  Catalog = 'CatalogSvg',
  Clients = 'ClientsSvg',
  Close = 'CloseSvg',
  Download = 'DownloadSvg',
  Exit = 'ExitSvg',
  Filter = 'FilterSvg',
  Insights = 'InsightsSvg',
  Logo = 'LogoSvg',
  Car = 'CarSvg',
  Notification = 'NotificationSvg',
  Orders = 'OrdersSvg',
  Pdf = 'PdfSvg',
  Print = 'PrintSvg',
  Search = 'SearchSvg',
  Settings = 'SettingsSvg',
  Store = 'StoreSvg'
}
