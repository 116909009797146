import {Form, InputNumber, Row, Skeleton} from 'antd';
import {IPriceListItem} from 'core/models/priceListItems/types';
import {IProductItem} from 'core/models/productItems/types';

export interface IPriceSettingsItem {
  productItem: IProductItem;
  priceListItem?: IPriceListItem;
  isLoading: boolean;
}

const PriceSettingsItem: React.FC<IPriceSettingsItem> = ({productItem, priceListItem, isLoading}) => {
  return (
    <div key={productItem.id}>
      <Row justify="space-between" align="middle">
        <div>
          <h4 className="m0">{productItem.title}</h4>
        </div>
        {isLoading ? (
          <Skeleton.Button active />
        ) : (
          <Form.Item
            key={productItem.id}
            className="mb0"
            name={`${productItem.id}_${priceListItem?.id || ''}`}
            initialValue={priceListItem?.price || undefined}
          >
            <InputNumber />
          </Form.Item>
        )}
      </Row>
      <span className="c-darkblue">({productItem.sku})</span>
    </div>
  );
};

export default PriceSettingsItem;
