import {Button, List, Row, Select, Switch} from 'antd';
import {IMutationEnum, IQueryEnum, makeMutation, makeQueries} from 'core/api';
import {IPriceList} from 'core/models/priceLists/types';
import {IProductBrand} from 'core/models/productBrands/types';
import {IProductCategory} from 'core/models/productCategories/types';
import {getRetailer} from 'core/models/retailers/utils';
import {memo, useEffect, useState} from 'react';
import {addIsActive, IIsActive} from 'utils/addIsActive';
import './style.less';
interface IData {
  productBrands: IProductBrand[];
  productCategories: IProductCategory[];
  priceLists: IPriceList[];
}

const BuyingSettings = memo(() => {
  const {retailer} = getRetailer();

  const [priceListId, setPriceListId] = useState<string>();
  const [categories, setCategories] = useState<Array<IProductCategory & IIsActive>>();
  const [brands, setBrands] = useState<Array<IProductBrand & IIsActive>>();

  const {
    data: {productBrands, productCategories, priceLists},
    isLoading
  } = makeQueries<IData>([{type: IQueryEnum.GetProductCategories}, {type: IQueryEnum.GetProductBrands}, {type: IQueryEnum.GetPriceLists}]);

  const mutation = makeMutation({
    type: IMutationEnum.RetailerUpdate,
    loadingMsg: 'Updating retailer settings...',
    successMsg: "Retailer's settings updated!"
  });

  /** Setting default values */
  useEffect(() => {
    if (!retailer) return;

    if (priceLists && !priceListId) {
      setPriceListId(retailer.priceListId);
    }
    if (productCategories && !categories) {
      setCategories(addIsActive(productCategories, retailer.productCategoryIds || []));
    }
    if (productBrands && !brands) {
      setBrands(addIsActive(productBrands, retailer.productBrandIds || []));
    }
  }, [productBrands, productCategories, priceLists, retailer, brands, categories, priceListId]);

  /** Update buying profile if retailer changed */
  useEffect(() => {
    if (!retailer || !priceLists || !productCategories || !productBrands) return;

    setPriceListId(retailer.priceListId);
    setCategories(addIsActive(productCategories, retailer.productCategoryIds || []));
    setBrands(addIsActive(productBrands, retailer.productBrandIds || []));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailer]);

  const onCategoryToggle = (categoryIndex: number) => (checked: boolean) => {
    const newCategories = [...categories!];
    newCategories[categoryIndex] = {...newCategories[categoryIndex], isActive: checked};
    setCategories(newCategories);
  };

  const onBrandToggle = (brandIndex: number) => (checked: boolean) => {
    const newBrands = [...brands!];
    newBrands[brandIndex] = {...newBrands[brandIndex], isActive: checked};
    setBrands(newBrands);
  };

  const onPriceListToggle = (priceListId: string) => {
    setPriceListId(priceListId);
  };

  const onSettingsSave = () => {
    if (retailer) {
      mutation.mutate({
        retailerUpdate: {
          id: retailer.id,
          priceListId,
          productCategoryIds: categories?.filter(category => category.isActive).map(cat => cat.id),
          productBrandIds: brands?.filter(brand => brand.isActive).map(brand => brand.id)
        }
      });
    }
  };

  return (
    <>
      <div className={'priceList-title'}>Choose Price List</div>
      <Select className="mb10 w100" value={priceListId} onChange={onPriceListToggle}>
        {priceLists?.map(priceList => (
          <Select.Option key={priceList.id} value={priceList.id}>
            {priceList.title}
          </Select.Option>
        ))}
      </Select>
      <List
        className="mb10"
        size="small"
        loading={isLoading || !categories}
        header={<div>Choose Categories</div>}
        bordered
        dataSource={categories}
        renderItem={(item, i) => (
          <List.Item key={item.id} actions={[<Switch checked={item.isActive} onChange={onCategoryToggle(i)} />]}>
            {item.title}
          </List.Item>
        )}
      />
      <List
        className="mb10"
        size="small"
        loading={isLoading || !brands}
        header={<div>Choose Brands</div>}
        bordered
        dataSource={brands}
        renderItem={(item, i) => (
          <List.Item key={item.id} actions={[<Switch checked={item.isActive} onChange={onBrandToggle(i)} />]}>
            {item.title}
          </List.Item>
        )}
      />
      <Row justify="end" className="mt15">
        <Button type="primary" onClick={onSettingsSave}>
          Save
        </Button>
      </Row>
    </>
  );
});

export default BuyingSettings;
