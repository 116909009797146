import {useHistory} from 'react-router-dom';
import moment from 'moment-timezone';
import {Table} from 'components/Table';
import {Button, Tag, Tooltip} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {IQueryEnum, makeQueries} from 'core/api';
import {GroupTypesEnum, IGroup} from 'core/models/groups/types';
import {IPromoBuilder} from 'core/models/promoBuilder/types';
import {PageWrapper} from '../../../components/PageWrapper';
import {UnitType} from 'core/models/promoBuilder/types';
import {getUrl} from 'utils/getUrl';
import {pageUrls} from 'core/router/pages';
import {IColumn} from 'components/Table/Table';
import {IUser, UserRolesEnum} from 'core/models/users/types';
import {IProduct} from 'core/models/products/types';
import {IProductItem} from 'core/models/productItems/types';

interface IData {
  promoBuilderList: IPromoBuilder[];
  groups: Map<string, IGroup>;
  products: Map<string, IProduct>;
  productItems: Map<string, IProductItem>;
  users: Map<string, IUser>;
}

function PromoBuilderList() {
  const history = useHistory();

  const {
    data: {promoBuilderList, users, groups, productItems, products},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetPromoBuilderList},
    {type: IQueryEnum.GetProducts, mapKey: 'id'},
    {type: IQueryEnum.GetGroups, mapKey: 'id'},
    {type: IQueryEnum.GetProductItems, mapKey: 'id'},
    {type: IQueryEnum.GetUsers, variables: {roles: [UserRolesEnum.Sales, UserRolesEnum.Admin]}, mapKey: 'id'}
  ]);

  const onCreateClick = () => {
    history.push(getUrl(pageUrls.promoCreate, {}));
  };

  const onPromoClick = (promo: IPromoBuilder) => {
    history.push(getUrl(pageUrls.promoEdit, {promoId: promo.id}));
  };

  const headerActions = (
    <Button type="primary" onClick={onCreateClick} icon={<PlusOutlined />}>
      Create
    </Button>
  );

  function renderProductGroupTooltip(groupId: string) {
    const group = groups?.get(groupId)!;
    let tooltipText = '';
    if (group) {
      if (group.type === GroupTypesEnum.Products) {
        const groupProducts = group.productIds?.map(productId => {
          const product = products.get(productId);
          return product?.title;
        });
        tooltipText = groupProducts ? groupProducts?.join(', ') : '';
      } else {
        const groupProducts = group.productItemIds?.map(productItemid => {
          const productItem = productItems.get(productItemid);
          const product = products.get(productItem?.productId!);
          return `${product?.title} - ${productItem?.title}`;
        });
        tooltipText = groupProducts ? groupProducts?.join(', ') : '';
      }
      // return groups.get(groupId)
      return (
        <Tooltip title={tooltipText} placement="bottom">
          {group.title}
        </Tooltip>
      );
    }
    return '';
  }

  const columns: Array<IColumn<IPromoBuilder>> = [
    {
      title: 'Title',
      width: '35%',
      dataIndex: 'offerTitle'
    },
    {
      title: 'Product Group',
      width: '15%',
      dataIndex: 'groupId',
      render: renderProductGroupTooltip
    },
    {
      title: 'Discount',
      width: '10%',
      dataIndex: 'discount',
      render: (_, row) => (
        <p>
          {row?.discount} {row?.unit === UnitType.USD ? '$' : '%'}
        </p>
      )
    },
    {
      title: 'Expiration Date',
      width: '20%',
      dataIndex: 'expDate',
      render: (_, row) => <p>{row?.expDate && moment(row?.expDate).tz('America/New_York').format('YYYY-MM-DD HH:mm')}</p>
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      width: '10%',
      render: (_, row) => <Tag color={row?.isActive ? 'green' : 'red'}>{row?.isActive ? 'Active' : 'Inactive'}</Tag>
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      width: '10%',
      render: (userId: string) => {
        const user = users?.get(userId);
        return user ? user.email : 'User not found';
      }
    }
  ];

  return (
    <PageWrapper pageTitle={'Promo Builder List'} actions={headerActions}>
      <Table data={promoBuilderList} columns={columns} loading={isLoading} onRowClick={onPromoClick} />
    </PageWrapper>
  );
}

export default PromoBuilderList;
