import {gql} from 'graphql-tag';

export const CREATE_SHIPPING_PLAN = gql`
  mutation createOrUpdateShippingPlan($shippingPlanInput: [ShippingPlanInput]) {
    createOrUpdateShippingPlan(shippingPlanInput: $shippingPlanInput) {
      id
      region
      planDay
      shipDay
      orderCutOffDay
      orderCutOffTime
    }
  }
`;

export const DELETE_SHIPPING_PLAN = gql`
  mutation deleteShippingPlan($id: ID!) {
    deleteShippingPlan(id: $id)
  }
`;
