import {Menu} from 'antd';
import {IconColorEnum} from 'components/Icon';
import AuthStore from 'core/auth';
import React, {useContext} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {getUrl} from 'utils/getUrl';
import {catalogMenuItems, retailersMenuItems, settingsMenuItems} from './TopMenuVariants';

export interface ITopMenuProps {
  type?: 'retailers' | 'catalog' | 'settings';
}

const TopMenu: React.FC<ITopMenuProps> = React.memo(({type}) => {
  const history = useHistory();
  const params = useParams();
  const {doesHaveRole} = useContext(AuthStore);

  const onItemClick = (url: string) => () => {
    history.push(getUrl(url, params));
  };

  const getMenuItems = () => {
    switch (type) {
      case 'retailers':
        return retailersMenuItems;
      case 'settings':
        return settingsMenuItems;
      default:
        return catalogMenuItems;
    }
  };

  const menuItems = getMenuItems();

  if (!type) return <div />;

  function getSelectedKey() {
    return menuItems.find(item => history.location.pathname.includes(item.key))?.key || '';
  }

  return (
    <Menu selectedKeys={[getSelectedKey()]} mode="horizontal" style={{height: 46}} className="mb-4 rounded-lg">
      {getMenuItems().map(
        ({url, title, Icon, allowedRoles, key}: any) =>
          (!allowedRoles || doesHaveRole(allowedRoles)) && (
            <Menu.Item
              key={key}
              onClick={onItemClick(url)}
              icon={<Icon style={{fontSize: 20, color: IconColorEnum.Blue}} />}
            >
              {title}
            </Menu.Item>
          )
      )}
    </Menu>
  );
});

export default TopMenu;
