import {Button, Col, List, Row} from 'antd';
import {InputNumber} from 'components/InputNumber';
import {N} from 'components/N';
import {IQueryEnum, makeQueries} from 'core/api';
import {IOrderItem} from 'core/models/orderItems/types';
import {IProductItem} from 'core/models/productItems/types';
import {IProduct} from 'core/models/products/types';
import {Stock} from 'core/models/stocks/types';
import {OrderStore} from 'core/stores/OrderStore';
import {actionEnum, dataLayerEcommerceActions} from 'utils/dataLayer';

export interface IOrderSummaryItemProps {
  orderItem: IOrderItem;
  product?: IProduct;
  serenity?: IProduct;
  productItem?: IProductItem;
  serenityProductItems?: IProductItem[];
  productBrand?: string;
  productCategory?: string;
  typeOrder?: boolean;
}

const bundlableProducts = [
  'b1a9e83c-4213-431d-a3fb-b7cf5ed6781d',
  'b6f5168f-9915-49ff-801f-5f4cfd0ce3cf',
  'e804b0b6-a55a-4217-99fa-8cc7b70ee3ca',
  'f428559a-0991-40b9-8717-0928e7e8d33a',
  '99fbfbf6-af57-4f11-9e1c-ec0313f12d0e',
  '9bd06494-50b8-49c4-9533-a7176c5009f8',
  '34a0540d-d2a5-4550-bf0f-40dc4874a931',
  'ae70a3fd-8e8f-40b8-98be-592ba5c5ec30'
];

interface IData {
  stocks: Stock[];
}

const OrderSummaryItem: React.FC<IOrderSummaryItemProps> = ({
  orderItem,
  product,
  productItem,
  serenity,
  serenityProductItems,
  productBrand,
  productCategory,
  typeOrder
}) => {
  const [updateOrderItem, order] = OrderStore(({updateOrderItem, order, orderItems}) => [updateOrderItem, order, orderItems]);
  const addSerenity = OrderStore(({addOrderItem}) => addOrderItem);
  const serenityProductItem = serenityProductItems?.find(item => item?.title === productItem?.title);
  const {
    data: {stocks = []}
  } = makeQueries<IData>([{type: IQueryEnum.GetStocks}]);

  const handleAddSerenity = () => {
    dataLayerEcommerceActions(actionEnum.addToCard, serenity!, serenityProductItem!, productBrand, productCategory);

    addSerenity(serenity!, serenityProductItem!);
  };
  if (!product || !productItem) return <div>Something went wrong</div>;

  const handleInputChange = (quantity: number, action?: string) => {
    if (action === 'Increment') {
      dataLayerEcommerceActions(actionEnum.addToCard, product, productItem, productBrand, productCategory);
    }
    if (action === 'Decrement') {
      dataLayerEcommerceActions(actionEnum.removeFromCard, product, productItem, productBrand, productCategory);
    }
    const productItemStock = stocks.find(stock => stock.productItemId === productItem.id && stock.warehouse === order.warehouse);

    updateOrderItem(productItem, quantity, productItemStock);
  };

  const priceFields = () => {
    return (
      <Col span={5}>
        <Row justify="end">
          <N className="c-darkblue" value={orderItem.price} type="price" />
        </Row>
          {orderItem.discount > 0 && (
          <Row justify="end">
            <N className="c-red" value={-orderItem.discount} type="price" toFixed={1} />
          </Row>
        )}
      </Col>
    );
  };

  return (
    <List.Item className={orderItem.hasPromo ? 'bg-light-yellow pl5 pr5' : 'pl5 pr5'}>
      <Row justify="space-between">
        <Col span={10}>
          <h3 className='text-md font-semibold'>{product.title}</h3>
          <h4 className="c-darkblue">{productItem.title}</h4>
        </Col>
        <Col span={9}>
          <Row justify="end">
            <InputNumber onChange={handleInputChange} value={orderItem.quantity} />
          </Row>
        </Col>
        {priceFields()}
      </Row>
      <Row>
        <h5 className="c-darkblue mt-2">SKU:&nbsp;{productItem.sku}</h5>
      </Row>
      {bundlableProducts.includes(product.id) && serenityProductItem && (
        <Button onClick={handleAddSerenity} size="small" className="mt-2" type="primary">
          Add Serenity Bundle
        </Button>
      )}
    </List.Item>
  );
};

export default OrderSummaryItem;
