import {gql} from 'graphql-tag';

export const CREATE_OR_UPDATE_PRICE_LISTS = gql`
  mutation createOrUpdatePriceLists($userInput: [PriceListsInput]!) {
    createOrUpdatePriceLists(userInput: $userInput) {
      title
      id
      order
    }
  }
`;
export const DELETE_PRICE_LISTS = gql`
  mutation deletePriceLists($id: ID!) {
    deletePriceLists(id: $id)
  }
`;
