import {UserRolesEnum} from 'core/models/users/types';
import AssetList from 'pages/assets/asset-list';
import AssetView from 'pages/assets/asset-view';
import {Dashboard} from 'pages/Dashboard';
import {InvoiceList} from 'pages/Invoices/InvoiceList';
import {InvoiceRetailerList} from 'pages/Invoices/InvoiceRetailerList';
import {InvoiceView} from 'pages/Invoices/InvoiceView';
import {OrderCreate} from 'pages/Orders/OrderCreate';
import {OrderCreateBulk} from 'pages/Orders/OrderCreateBulk';
import {OrderList} from 'pages/Orders/OrderList';
import {OrderRetailerList} from 'pages/Orders/OrderRetailerList';
import {OrderView} from 'pages/Orders/OrderView';
import {ProductCreate} from 'pages/Products/ProductCreate';
import ProductGroupCreate from 'pages/Products/product-group-create';
import ProductGroupList from 'pages/Products/product-group-list';
import ProductGroupView from 'pages/Products/product-group-view';
import ProductListAll from 'pages/Products/product-list-all';
import ProductView from 'pages/Products/product-view';
import ReportMarketingAssets from 'pages/Reports/report-marketing-assets';
import {Profile} from 'pages/Profile';
import {RetailerAddressCreate} from 'pages/Retailers/RetailerAddresses/RetailerAddressCreate';
import {RetailerAddressList} from 'pages/Retailers/RetailerAddresses/RetailerAddressList';
import {RetailerBuyingProfile} from 'pages/Retailers/RetailerBuyingProfile';
import {RetailerList} from 'pages/Retailers/RetailerList';
import {RetailerShippingProfile} from 'pages/Retailers/RetailerShippingProfile';
import {RetailerStoreCreate} from 'pages/Retailers/RetailerStores/RetailerStoreCreate';
import {RetailerStoreList} from 'pages/Retailers/RetailerStores/RetailerStoreList';
import {RetailerUserCreate} from 'pages/Retailers/RetailerUsers/RetailerUserCreate';
import {RetailerUserList} from 'pages/Retailers/RetailerUsers/RetailerUserList';
import RetailerUserView from 'pages/Retailers/retailer-user-view';
import {RetailerGeneral} from 'pages/Retailers/RetailerGeneral';
import {ReturnCreate} from 'pages/Returns/ReturnCreate';
import {ReturnList} from 'pages/Returns/ReturnList';
import {ReturnListAll} from 'pages/Returns/ReturnListAll';
import {ReturnRetailerList} from 'pages/Returns/ReturnRetailerList';
import {ReturnView} from 'pages/Returns/ReturnView';
import {SettingsGeneral} from 'pages/Settings/SettingsGeneral';
import {SettingsShipping} from 'pages/Settings/SettingsShipping';
import {SettingsUserCreate} from 'pages/Settings/SettingsUsers/SettingsUserCreate';
import {SettingsUserCreateBulk} from 'pages/Settings/SettingsUsers/SettingsUserCreateBulk';
import {SettingsUserList} from 'pages/Settings/SettingsUsers/SettingsUserList';
import {SettingsUserView} from 'pages/Settings/SettingsUsers/SettingsUserView';
import {Status} from 'pages/Status';
import {Support} from 'pages/Support';
import {pageUrls} from './pages';
import {IRoute} from './types';
import {ProductList} from 'pages/Products/ProductList';
import {PromoCreate} from 'pages/PromoBuilder/PromoCreate';
import {PromoBuilderList} from 'pages/PromoBuilder/PromoList';
import {PromoEdit} from 'pages/PromoBuilder/PromoEdit';
import {TermsAndConditions} from 'pages/Static/TermsAndConditions';
import SettingsGlobalDiscounts from 'pages/Settings/settings-global-discounts';

const routes: IRoute[] = [
  {
    url: pageUrls.profile,
    Component: Profile,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales, UserRolesEnum.Owner, UserRolesEnum.Manager, UserRolesEnum.Returns]
  },
  {
    url: pageUrls.dashboard,
    Component: Dashboard,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales, UserRolesEnum.Owner]
  },
  {
    url: pageUrls.retailerList,
    Component: RetailerList,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales]
  },
  {
    url: pageUrls.retailerGeneral,
    Component: RetailerGeneral,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales, UserRolesEnum.Owner]
  },
  {
    url: pageUrls.retailerBuyingProfile,
    Component: RetailerBuyingProfile,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales]
  },
  {
    url: pageUrls.retailerShippingProfile,
    Component: RetailerShippingProfile,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales]
  },
  {
    url: pageUrls.retailerStoreList,
    Component: RetailerStoreList,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales, UserRolesEnum.Owner]
  },
  {
    url: pageUrls.retailerStoreCreate,
    Component: RetailerStoreCreate,
    allowedRoles: [UserRolesEnum.Owner]
  },
  {
    url: pageUrls.retailerAddressList,
    Component: RetailerAddressList,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales]
  },
  {
    url: pageUrls.retailerAddressCreate,
    Component: RetailerAddressCreate,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales]
  },
  {
    url: pageUrls.retailerUserList,
    Component: RetailerUserList,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales, UserRolesEnum.Owner]
  },
  {
    url: pageUrls.retailerUserCreate,
    Component: RetailerUserCreate,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales, UserRolesEnum.Owner]
  },
  {
    url: pageUrls.retailerUserView,
    Component: RetailerUserView,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales, UserRolesEnum.Owner]
  },
  {
    url: pageUrls.orderRetailerList,
    Component: OrderRetailerList,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales]
  },
  {
    url: pageUrls.orderList,
    Component: OrderList,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales, UserRolesEnum.Owner]
  },
  {
    url: pageUrls.orderView,
    Component: OrderView,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales, UserRolesEnum.Owner]
  },
  {
    url: pageUrls.orderCreate,
    Component: OrderCreate,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales, UserRolesEnum.Owner]
  },
  {
    url: pageUrls.orderCreateBulk,
    Component: OrderCreateBulk,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales, UserRolesEnum.Owner]
  },
  {
    url: pageUrls.invoiceRetailerList,
    Component: InvoiceRetailerList,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales]
  },
  {
    url: pageUrls.invoiceList,
    Component: InvoiceList,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales, UserRolesEnum.Owner]
  },
  {
    url: pageUrls.invoiceView,
    Component: InvoiceView,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales, UserRolesEnum.Owner]
  },
  {
    url: pageUrls.returnListAll,
    Component: ReturnListAll,
    allowedRoles: [UserRolesEnum.Admin]
  },
  {
    url: pageUrls.returnRetailerList,
    Component: ReturnRetailerList,
    allowedRoles: [UserRolesEnum.Sales]
  },
  {
    url: pageUrls.returnList,
    Component: ReturnList,
    allowedRoles: [UserRolesEnum.Sales, UserRolesEnum.Owner, UserRolesEnum.Returns]
  },
  {
    url: pageUrls.returnCreate,
    Component: ReturnCreate,
    allowedRoles: [UserRolesEnum.Sales, UserRolesEnum.Owner, UserRolesEnum.Returns]
  },
  {
    url: pageUrls.returnView,
    Component: ReturnView,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales, UserRolesEnum.Owner, UserRolesEnum.Returns]
  },
  {
    url: pageUrls.assetList,
    Component: AssetList,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales, UserRolesEnum.Owner, UserRolesEnum.Manager]
  },
  {
    url: pageUrls.assetView,
    Component: AssetView,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales, UserRolesEnum.Owner, UserRolesEnum.Manager]
  },
  {
    url: pageUrls.productListAll,
    Component: ProductListAll,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales]
  },
  {
    url: pageUrls.productList,
    Component: ProductList,
    allowedRoles: [UserRolesEnum.Owner]
  },
  {
    url: pageUrls.productCreate,
    Component: ProductCreate,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales]
  },
  {
    url: pageUrls.productView,
    Component: ProductView,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales]
  },
  {
    url: pageUrls.productGroupList,
    Component: ProductGroupList,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales]
  },
  {
    url: pageUrls.productGroupCreate,
    Component: ProductGroupCreate,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales]
  },
  {
    url: pageUrls.productGroupView,
    Component: ProductGroupView,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales]
  },
  {
    url: pageUrls.reports,
    Component: ReportMarketingAssets,
    allowedRoles: [UserRolesEnum.Admin]
  },
  {
    url: pageUrls.status,
    Component: Status,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales]
  },
  {
    url: pageUrls.settingsGeneral,
    Component: SettingsGeneral,
    allowedRoles: [UserRolesEnum.Admin]
  },
  {
    url: pageUrls.settingsUserList,
    Component: SettingsUserList,
    allowedRoles: [UserRolesEnum.Admin]
  },
  {
    url: pageUrls.settingsUserView,
    Component: SettingsUserView,
    allowedRoles: [UserRolesEnum.Admin]
  },
  {
    url: pageUrls.settingsDiscounts,
    Component: SettingsGlobalDiscounts,
    allowedRoles: [UserRolesEnum.Admin]
  },
  {
    url: pageUrls.settingsUserCreate,
    Component: SettingsUserCreate,
    allowedRoles: [UserRolesEnum.Admin]
  },
  {
    url: pageUrls.settingsUserCreateBulk,
    Component: SettingsUserCreateBulk,
    allowedRoles: [UserRolesEnum.Admin]
  },
  {
    url: pageUrls.settingsShipping,
    Component: SettingsShipping,
    allowedRoles: [UserRolesEnum.Admin]
  },
  {
    url: pageUrls.support,
    Component: Support,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales, UserRolesEnum.Owner, UserRolesEnum.Manager, UserRolesEnum.Returns]
  },
  {
    url: pageUrls.promoBuilderList,
    Component: PromoBuilderList,
    allowedRoles: [UserRolesEnum.Admin]
  },
  {
    url: pageUrls.promoCreate,
    Component: PromoCreate,
    allowedRoles: [UserRolesEnum.Admin]
  },
  {
    url: pageUrls.promoEdit,
    Component: PromoEdit,
    allowedRoles: [UserRolesEnum.Admin]
  },
  {
    url: pageUrls.termsAndConditions,
    Component: TermsAndConditions,
    allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales, UserRolesEnum.Owner, UserRolesEnum.Manager, UserRolesEnum.Returns]
  }
];

export default routes;
