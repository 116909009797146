/* eslint-disable react-hooks/exhaustive-deps */
import FileOutlined from '@ant-design/icons/lib/icons/FileOutlined';
import {Row, Card, Button, Divider, Typography} from 'antd';
import {makeQueries, IQueryEnum, makeMutation, IMutationEnum} from 'core/api';
import AuthStore from 'core/auth';
import {IProductItem} from 'core/models/productItems/types';
import {IProduct} from 'core/models/products/types';
import {getRetailer} from 'core/models/retailers/utils';
import {ReturnIssueTypeEnum} from 'core/models/returns/types';
import {ReturnIssueTypeRenderer} from 'core/models/returns/utils';
import {pageUrls} from 'core/router/pages';
import {ReturnStore} from 'core/stores/ReturnStore';
import {memo, useContext, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {getUrl} from 'utils/getUrl';

const {Text} = Typography;

interface IData {
  products: Map<string, IProduct>;
  productItems: Map<string, IProductItem>;
}

const ReturnsCreate6: React.FC = memo(() => {
  const {userProfile} = useContext(AuthStore);
  const {returnItem} = ReturnStore.getState();
  const {retailer} = getRetailer();
  const history = useHistory();

  const {
    data: {productItems, products},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetAllProductItems, variables: {retailerId: returnItem.retailerId}, mapKey: 'id'},
    {type: IQueryEnum.GetProducts, mapKey: 'id'}
  ]);

  const mutation = makeMutation({
    type: IMutationEnum.CreateReturn,
    keyVars: {retailerId: retailer?.id},
    loadingMsg: 'Creating return...',
    successMsg: 'Return has been created.'
  });

  useEffect(() => {
    if (mutation.isSuccess && mutation.data)
      history.push(getUrl(pageUrls.returnView, {retailerId: retailer?.id, returnId: mutation.data.id}));
  }, [mutation.isSuccess]);

  function submitReturn() {
    window.dataLayer?.push({
      event: 'action_submit_return',
      retailerName: retailer?.name || null,
      type: returnItem?.issueType,
      productItemId: returnItem?.productItemId
    });

    mutation.mutate({
      returnInput: {
        ...returnItem,
        createdBy: `${userProfile?.firstName} ${userProfile?.lastName}`,
        userEmail: userProfile?.email
      }
    });
  }

  function getField(name: string, value?: string | JSX.Element) {
    return (
      <h3 className="text-md font-semibold">
        <span className="mr-2">{name}:</span>
        <span className="f-light">{value}</span>
      </h3>
    );
  }

  function getOrderItemInfo() {
    const productItem = productItems.get(returnItem?.productItemId!);
    const product = products?.get(productItem?.productId!);

    return (
      <span>
        {product ? (
          <span>
            {product.title} (Size: {productItem?.title})
          </span>
        ) : (
          'Product is not available anymore'
        )}
      </span>
    );
  }

  return (
    <Card title={<div className="tac">Request for a credit</div>} loading={isLoading}>
      {getField('Retailer', retailer?.name)}
      {getField('Contact Person', `${userProfile?.firstName} ${userProfile?.lastName} (${userProfile?.email})`)}
      {getField('Issue Type', ReturnIssueTypeRenderer(returnItem?.issueType!))}
      {getField('Order #SO', returnItem?.orderSO)}
      {getField('Reference', returnItem?.reference)}

      <Divider />
      {returnItem?.issueType === ReturnIssueTypeEnum.Damaged ? (
        <>
          {getField('Order Reference (#PO)', returnItem?.orderReference)}
          {getField('Date Shipment Received', returnItem?.customer?.purchaseDate)}
        </>
      ) : (
        <>
          {getField('Customer Name', returnItem?.customer?.name)}
          {getField('Reciept Number', returnItem?.customer?.receiptNumber)}
          {getField('Customer Purchase Date', returnItem?.customer?.purchaseDate)}
        </>
      )}
      <Divider />
      {getField('Product', getOrderItemInfo())}
      {getField('Quantity', returnItem.quantity?.toString())}
      {getField('Problem description', returnItem.description)}
      <Divider />
      {getField(
        'Attachments',
        <Row className="f-light mt10">
          {Object.values(returnItem?.attachments!)
            .filter(Boolean)
            .map((attachment, i) =>
              attachment?.map((item, index) => (
                <a className="mr15" target="_blank" rel="noreferrer" href={item?.replaceAll('#', '%23')} key={`${item}${index}`}>
                  <FileOutlined style={{fontSize: 40}} className="mr10" />
                  <h5>
                    <Text className="w100 c-darkblue" ellipsis={{tooltip: item?.split('.com/')[1]}}>
                      {item?.split('.com/')[1]}
                    </Text>
                  </h5>
                </a>
              ))
            )}
        </Row>
      )}
      <Divider />
      {returnItem?.issueType !== ReturnIssueTypeEnum.Damaged &&
        getField('Picked up and disposed of by retailer', returnItem.isPickedByRetailer ? 'Yes' : 'No')}
      {getField('Comments', returnItem.comments || '-')}
      <Divider />
      <div className="w100 tac mt15">
        <Button onClick={submitReturn} type="primary">
          Submit a return
        </Button>
      </div>
    </Card>
  );
});

export default ReturnsCreate6;
