import {Modal, Button, Row} from 'antd';
import {IMutationEnum, makeMutation} from 'core/api';
import AuthStore from 'core/auth';
import {pageUrls} from 'core/router/pages';
import moment from 'moment';
import {useContext} from 'react';
import {useHistory} from 'react-router-dom';
import useLocalStorage from 'utils/useLocalStorage';

function TermsModal() {
  const history = useHistory();
  const [acceptedTermsAt, setAcceptedTermsAt] = useLocalStorage<string>('acceptedTermsAt', '');
  const {userProfile, updateUserTermsAgreement,declinedTermsAt, setDeclinedTermsAt } = useContext(AuthStore);

  const mutation = makeMutation({
    type: IMutationEnum.UpdateUser
  });

  const isTermsPage = history.location.pathname === pageUrls.termsAndConditions;
  const isDeclineExpired = declinedTermsAt ? moment().isSameOrAfter(moment(declinedTermsAt).add(1, 'day')) : true;
  const isAccepted = acceptedTermsAt || !userProfile || (userProfile && Boolean(userProfile.acceptedTermsAt));

  const isModalVisible = !isAccepted && !isTermsPage && isDeclineExpired;

  function handleAgree() {
    const date = moment().toString();
    const userUpdate = {
      oktaId: userProfile?.id,
      acceptedTermsAt: date
    };
    setAcceptedTermsAt(date);
    updateUserTermsAgreement(date);
    mutation.mutate({userUpdate});
  }

  function handleCancel() {
    setDeclinedTermsAt(moment().toString());
  }

  return (
    <>
      <Modal width={'45%'} className="modal-promotion" closable={false} visible={isModalVisible} centered>
        <div className="tac">
          <h4>
            You must agree to the{' '}
            <a rel="noreferrer" className="c-blue" href={`${process.env.PUBLIC_URL}/static/terms-and-conditions`} target="_blank">
              Resident Connect App Terms and Conditions
            </a>{' '}
            to continue{' '}
          </h4>
          <Row className="mt15" justify="center">
            <Button onClick={handleCancel} className="mr15">
              Cancel
            </Button>
            <Button onClick={handleAgree} type="primary">
              I Agree
            </Button>
          </Row>
        </div>
      </Modal>
    </>
  );
}

export default TermsModal;
