import {Button, Col, Form, Row, Select} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {PageWrapper} from 'components/PageWrapper';
import {IMutationEnum, makeMutation} from 'core/api';
import AuthStore from 'core/auth';
import {getRetailer, getRetailerId} from 'core/models/retailers/utils';
import {useContext, useEffect} from 'react';
import {dataLayerEvents} from 'utils/dataLayer';

const Support: React.FC = () => {
  const [form] = Form.useForm();
  const {isRetailer} = useContext(AuthStore);
  const {retailer, isLoading} = getRetailer();
  const retailerId = getRetailerId();

  useEffect(() => {
    if (!isLoading && retailer) {
      dataLayerEvents('view_support', retailer);
    }
  }, [retailer, isLoading]);

  const mutation = makeMutation({
    type: IMutationEnum.CreateTask,
    keyVars: {retailerId},
    loadingMsg: 'Creating task for the support team...',
    successMsg: 'Task has been created.'
  });

  function onSubmit(data: any) {
    const description = `\
    <body>\
      <strong>${data.title}</strong>\
      <ul>\
        <li>${data.description}</li>\
      </ul>\
    </body>\
    `;

    mutation.mutate({
      taskInput: {
        title: data.title,
        description,
        retailerId
      }
    });
  }

  return (
    <PageWrapper pageTitle={'Support'}>
      <Row>
        <Col span={12}>
          <Form layout="vertical" form={form} name="create-return" onFinish={onSubmit}>
            <Form.Item name={'title'} label="Select a contact reason:" rules={[{required: true}]}>
              <Select size="large" placeholder="select reason">
                <Select.Option value={'Report a bug'}>Report a bug</Select.Option>
                <Select.Option value={'Submit a feature request'}>Submit a feature request</Select.Option>
                <Select.Option value={'Get help with your order'}>Get help with your order</Select.Option>
                <Select.Option value={'Other'}>Other</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="Describe your problem:" name="description" rules={[{required: true}]}>
              <TextArea showCount maxLength={500} autoSize={{minRows: 7, maxRows: 15}} placeholder="Describe your problem here please" />
            </Form.Item>
            <div className="w100 tar pt15">
              <Button type="primary" htmlType="submit">
                Send
              </Button>
            </div>
          </Form>
        </Col>
        <Col span={12} className="tar">
          {isRetailer && (
            <iframe width="500px" style={{height: '85vh', border: 'none'}} title="calendly" src="https://calendly.com/TrainingTeam" />
          )}
        </Col>
      </Row>
    </PageWrapper>
  );
};
export default Support;
