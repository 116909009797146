import {gql} from 'graphql-tag';

export const CREATE_PRODUCT_ITEM = gql`
  mutation createProductItem($productItem: ProductItemCreate!) {
    createProductItem(productItem: $productItem) {
      id
      title
      sku
      supplierId
    }
  }
`;

export const UPDATE_PRODUCT_ITEMS = gql`
  mutation updateProductItems($productItems: [ProductItemUpdate]!) {
    updateProductItems(productItems: $productItems) {
      id
      productId
      sku
      title
      price
      unit
      outOfStock
      discount
      finalDiscountUSD
      productItemGroupId
      discountStartDate
      discountEndDate
      RXHAlert
      PHXAlert
    }
  } 
`;

export const UPDATE_PRODUCT_ITEMS_PRICE_SETTINGS = gql`
  mutation updateProductItemsPriceSettings($productItems: [ProductItemPriceSettingsUpdate!]!) {
    updateProductItemsPriceSettings(productItems: $productItems) {
      id
      productId
      sku
      title
      price
      unit
      outOfStock
      discount
      finalDiscountUSD
      productItemGroupId
      discountStartDate
      discountEndDate
      RXHAlert
      PHXAlert
    }
  }
`;
