import Modal from 'components/Modal/Modal';
import {getRetailer} from 'core/models/retailers/utils';
import {memo, useEffect, useState} from 'react';
import useLocalStorage from 'utils/useLocalStorage';

export interface AnnouncementModalProps {
  modalKey: string;
  title?: string;
  description: string | JSX.Element;
  onAction?: () => void;
  actionText?: string;
  type: 'info' | 'success' | 'warning';
}

const AnnouncementModal = memo(({modalKey, title, onAction, actionText, description, type}: AnnouncementModalProps) => {
  const {retailer} = getRetailer();
  const [open, setOpen] = useState(false);
  const [isShown, setIsShown] = useLocalStorage(modalKey);

  useEffect(() => {
    if (!isShown) {
      setOpen(true);
      setIsShown(true);
    }
  }, []);

  return (
    <Modal isOpen={open} onClose={() => setOpen(false)} onAction={onAction} actionText={actionText} type={type} cancelText={"OK"}>
      <div className="mx-auto">
        <div className="flex flex-col items-center text-center">
          <h2 id="details-heading" className="text-xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {title || `Hey ${retailer?.name || ''}!`}
          </h2>
          <p className="mt-3 max-w-3xl text-lg text-gray-600 w-5/6">{description}</p>
        </div>
      </div>
    </Modal>
  );
});

export default AnnouncementModal;
