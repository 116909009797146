import {guyLaying} from 'assets/animations/guyLaying';
import {homeFlower} from 'assets/animations/homeFlower';
import {mattressShipped} from 'assets/animations/mattressShipped';
import {guyWorking} from 'assets/animations/guyWorking';
import {collecting} from 'assets/animations/collecting';

/** Enum with animation types */
export enum AnimationEnum {
  GuyLaying,
  HomeFlower,
  MattressShipped,
  GuyWorking,
  Collecting
}

/**
 * Returns Json with animation depending on TypeEnum
 * @param {AnimationEnum} animation
 */
export function getAnimation(animation: AnimationEnum): object {
  switch (animation) {
    case AnimationEnum.HomeFlower:
      return homeFlower;
    case AnimationEnum.GuyLaying:
      return guyLaying;
    case AnimationEnum.GuyWorking:
      return guyWorking;
    case AnimationEnum.Collecting:
      return collecting;
    case AnimationEnum.MattressShipped:
      return mattressShipped;
    default:
      throw new Error('Animation not supported');
  }
}
