import {makeQueries, IQueryEnum} from 'core/api';
import AuthStore from 'core/auth';
import {getRetailer, getRetailerId} from 'core/models/retailers/utils';
import {useContext} from 'react';

/** Prefetching data, for better user experience */
function DataPrefetcher() {
  const {isRetailer, isManager, isReturns} = useContext(AuthStore);
  const {retailer} = getRetailer();
  const retailerId = getRetailerId();

  /** Common queries */
  makeQueries([
    {type: IQueryEnum.GetProductBrands},
    {type: IQueryEnum.GetProductCategories, isEnabled: !isManager && !isReturns},
    {type: IQueryEnum.GetRetailers, isEnabled: !isManager && !isReturns},
    {type: IQueryEnum.GetProducts, isEnabled: !isManager && !isReturns},
    {type: IQueryEnum.GetSuppliers, isEnabled: !isManager && !isReturns},
    {type: IQueryEnum.GetStates, isEnabled: !isManager && !isReturns},
    {type: IQueryEnum.GetProductItemGroups, isEnabled: !isManager && !isReturns}
  ]);

  /** Admin && Sales queries */
  makeQueries([
    {type: IQueryEnum.GetPriceLists, isEnabled: !isRetailer},
    {type: IQueryEnum.GetProductItems, isEnabled: !isRetailer}
  ]);

  /** Retailer queries */
  makeQueries(
    !isManager && !isReturns
      ? [
          {type: IQueryEnum.GetAddresses, variables: {retailerId}, isEnabled: Boolean(isRetailer)},
          {type: IQueryEnum.GetOrders, variables: {retailerId}, isEnabled: Boolean(isRetailer)},
          {
            type: IQueryEnum.GetProductItems,
            variables: {retailerId},
            isEnabled: Boolean(isRetailer && retailer?.priceListId)
          },
          {
            type: IQueryEnum.GetPriceListItems,
            variables: {retailerId},
            isEnabled: Boolean(isRetailer && retailer?.priceListId)
          }
        ]
      : []
  );

  return null;
}

export default DataPrefetcher;
