export enum UnitType {
  USD = 'USD',
  PERCENT = 'PERCENT'
}

export enum OfferType {
  ExpirationDate = 'Expiration date',
  ItemsQuantity = 'Items quantity'
}

export enum BannerVariants {
  TOP_BAR = 'TOP_BAR',
  ORDER_TYPE = 'ORDER_TYPE',
  ORDER_REVIEW = 'ORDER_REVIEW'
}
interface IPromoBanner {
  types: BannerVariants[];
  image: string;
}

export interface IPromoBuilder {
  id: string;
  groupId: string;
  offerTitle: string;
  offerDescription?: string;
  discount?: number;
  unit: UnitType;
  expDate?: string;
  isActive: boolean;
  defaultItemsAvailable?: any;
  currentItemsAvailable?: any;
  promoBanner?: IPromoBanner;
  createdBy: string;
}
