/* eslint-disable react-hooks/exhaustive-deps */
import {PlusOutlined} from '@ant-design/icons';
import EnvironmentOutlined from '@ant-design/icons/lib/icons/EnvironmentOutlined';
import {Button, List} from 'antd';
import {PageWrapper} from 'components/PageWrapper';
import {IQueryEnum, makeQueries} from 'core/api';
import AuthStore from 'core/auth';
import {IAddress} from 'core/models/addresses/types';
import {getRetailer} from 'core/models/retailers/utils';
import {pageUrls} from 'core/router/pages';
import {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {dataLayerEvents} from 'utils/dataLayer';
import {getUrl} from 'utils/getUrl';
import StoreCard from './components/StoreCard';
import GoogleMap from './components/GoogleMap';
import './style.less';

interface IData {
  addresses: IAddress[];
}

function RetailerStoreList() {
  const history = useHistory();
  const {isRetailer} = useContext(AuthStore);
  const {retailer, isLoading: isRetailerLoading} = getRetailer();

  useEffect(() => {
    if (!isRetailerLoading && retailer) {
      dataLayerEvents('view_retailers_stores', retailer);
    }
  }, [retailer, isRetailerLoading]);

  // Store, that will be showed on the map
  const [selectedAddress, setSelectedAddress] = useState<IAddress | undefined>();

  const {
    data: {addresses},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetStates},
    {type: IQueryEnum.GetAddresses, variables: {retailerId: retailer?.id}, isEnabled: Boolean(retailer)}
  ]);

  const getAddresses = () => {
    return addresses?.filter(s => s.isShippingAddress);
  };

  useEffect(() => {
    if (addresses && !selectedAddress) setSelectedAddress(getAddresses()[0]);
  }, [addresses, selectedAddress]);

  const onCreateClick = () => {
    history.push(getUrl(pageUrls.retailerStoreCreate, {retailerId: retailer?.id}));
  };

  const headerActions = (
    <Button type="primary" icon={<PlusOutlined />} onClick={onCreateClick}>
      Create
    </Button>
  );

  return (
    <PageWrapper pageTitle="Store Management" withRetailerSelector topMenuType="retailers" actions={isRetailer ? headerActions : undefined}>
      <div className="retail-stores d-flex">
        <List
          className="w55"
          grid={{gutter: 30, column: 1}}
          loading={isLoading}
          dataSource={getAddresses()}
          pagination={{pageSize: 10}}
          renderItem={(address: IAddress) => (
            <List.Item key={address.id}>
              <StoreCard address={address} selectedAddressId={selectedAddress?.id} setAddress={setSelectedAddress} />
            </List.Item>
          )}
        />
        <div className="map-container">
          <GoogleMap icon={EnvironmentOutlined} address={selectedAddress?.address} />
        </div>
      </div>
    </PageWrapper>
  );
}

export default RetailerStoreList;
