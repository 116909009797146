export enum IModelEnum {
  Tasks = 'tasks',
  TaskComments = 'taskComments',
  Retailers = 'retailers',
  Returns = 'returns',
  Notifications = 'notifications',
  Addresses = 'addresses',
  Users = 'users',
  Orders = 'orders',
  Shipments = 'shipments',
  OrderTracking = 'orderTracking',
  OrderItems = 'orderItems',
  Products = 'products',
  Payments = 'payments',
  Suppliers = 'suppliers',
  States = 'states',
  ProductItems = 'productItems',
  ProductCategories = 'productCategories',
  ProductBrands = 'productBrands',
  PriceLists = 'priceLists',
  PriceListItems = 'priceListItems',
  TotalSalesReport = 'totalSalesReport',
  TotalProductsReport = 'totalProductsReport',
  Invoices = 'invoices',
  Groups = 'groups',
  ShippingRules = 'shippingRules',
  ProductItemGroups = 'productItemGroups',
  ShippingPlans = 'shippingPlans',
  PromoBuilder = 'promoBuilderList',
  ActivePromo = 'activePromo',
  Stocks = 'stocks',
  Logs = 'logs',
  Analytics = 'analytics',
}
