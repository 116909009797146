import {GraphQLClient, Variables, RequestDocument} from 'graphql-request';

const gql = new GraphQLClient(`${process.env.REACT_APP_API_URL!}/graphql`);

export function makeGQLRequest(httpLink: string, document: RequestDocument, variables?: Variables) {
  gql.setEndpoint(`${process.env.REACT_APP_API_URL!}/graphql/${httpLink}`);
  return gql.request(document, variables);
}

export default gql;
