import {Button, Col, DatePicker, Divider, Form, InputNumber, Row, Select, Tabs} from 'antd';
import {Table} from 'components/Table';
import {IColumn} from 'components/Table/Table';
import {IMutationEnum, IQueryEnum, makeMutation, makeQueries} from 'core/api';
import {IProductItem, IUnitEnum, ITypeEnum} from 'core/models/productItems/types';
import {IProduct} from 'core/models/products/types';
import {getRetailer} from 'core/models/retailers/utils';
import {ISupplier} from 'core/models/suppliers/types';
import moment from 'moment';
import {memo, useEffect, useState} from 'react';
import {IProductItemGroups} from 'core/models/productItemGroups/types';

interface IProductsItemSettings {
  product: IProduct;
}

interface IData {
  suppliers: Map<string, ISupplier>;
  productItems: [IProductItem];
  productItemGroups: [IProductItemGroups];
}

const ProductsItemSettings = memo(({product}: IProductsItemSettings) => {
  const {retailer} = getRetailer();
  const [form] = Form.useForm();
  const [supplierId, setSupplierId] = useState<string>('');
  const [selectedProductItemGroup, setSelectedProductItemGroup] = useState<IProductItemGroups | undefined>();
  const [type, setType] = useState<string>(ITypeEnum.ORDER);

  const {
    data: {suppliers, productItems, productItemGroups}
  } = makeQueries<IData>([
    {type: IQueryEnum.GetSuppliers, mapKey: 'id'},
    {type: IQueryEnum.GetProductItems, variables: {retailerId: retailer?.id}, isEnabled: Boolean(retailer)},
    {type: IQueryEnum.GetProductItemGroups, variables: {retailerId: retailer?.id}}
  ]);

  const filteredProductItemGroups = productItemGroups?.filter(ig => ig.productId === product.id).filter(ig => ig.archived === false);
  const currentProductItems = productItems?.filter(item => selectedProductItemGroup?.id === item?.productItemGroupId);

  useEffect(() => {
    if (!selectedProductItemGroup && retailer && filteredProductItemGroups?.length > 0) {
      const selectedProductItemGroup = filteredProductItemGroups?.find(ig => retailer.groupIds.some(id => ig.id === id));
      setSelectedProductItemGroup(selectedProductItemGroup);
      setSupplierId(selectedProductItemGroup?.supplierId!);
    }
  }, [retailer, product, filteredProductItemGroups, selectedProductItemGroup]);

  const updateDiscountMutation = makeMutation({
    type: IMutationEnum.UpdateProductItemsPriceSettings,
    keyVars: {retailerId: retailer?.id},
    loadingMsg: 'Updating Discount Settings...',
    successMsg: 'Discount Settings updated.'
  });

  const updateSupplierMutation = makeMutation({
    type: IMutationEnum.RetailerUpdate,
    loadingMsg: 'Updating Supplier...',
    successMsg: `Supplier updated.`
  });

  function handleDiscountUpdate(values: any) {

    const data = product?.productItemIds?.map(id => {
      if (type === 'ORDER') {
        // const minimumQuantity = values[`${id}_minimumQuantity`];
        const discount = values[`${id}_discount`];
        const unit = values[`${id}_unit`];
        const discountStartDate = values[`${id}_discountStartDate`]?.toISOString();
        const discountEndDate = values[`${id}_discountEndDate`]?.toISOString();
  
        return {id, retailerId: retailer?.id, discount, unit, discountStartDate, discountEndDate, type};
      } else {
        const discount = values[`${id}_fMdiscount`];
        const unit = values[`${id}_fMunit`];
  
        return {id, retailerId: retailer?.id, discount, unit, type};
      }
    });

    updateDiscountMutation.mutate({productItems: data});
  }

  const handleSupplierChange = (newSupplierId: string) => {
    const selectedProductItemGroup = filteredProductItemGroups.find(ig => ig.supplierId === newSupplierId);
    setSelectedProductItemGroup(selectedProductItemGroup);
    setSupplierId(newSupplierId);
  };

  const handleSupplierUpdate = () => {
    const groupIds = retailer?.groupIds ? [...retailer?.groupIds] : [];

    const newProductItemGroupId = filteredProductItemGroups.find(ig => ig.supplierId === supplierId);
    const oldProductItemGroupIndex = groupIds.findIndex(id => filteredProductItemGroups.find(ig => ig.id === id)?.id === id);

    groupIds[oldProductItemGroupIndex] = newProductItemGroupId?.id!;

    updateSupplierMutation.mutate({retailerUpdate: {id: retailer!.id, groupIds}});
  };

  const orderColumns: IColumn<IProductItem>[] = [
    {
      title: 'Item title',
      dataIndex: 'title',
      width: '15%'
    },
    {
      title: 'Price($)',
      render: (_, row) => <p>{row.price! + row.finalDiscountUSD!}</p>,
      width: '12%'
    },
    // {
    //   title: 'Minimum items in cart ',
    //   render: (_, row) => (
    //     <Form.Item name={`${row.id}_minimumQuantity`}>
    //       <InputNumber />
    //     </Form.Item>
    //   ),
    //   width: '11%'
    // },
    {
      title: 'Discount',
      render: (_, row) => (
        <Form.Item name={`${row.id}_discount`} initialValue={row.discount}>
          <InputNumber />
        </Form.Item>
      ),
      width: '8%'
    },
    {
      title: 'Unit',
      render: (_, row) => (
        <Form.Item name={`${row.id}_unit`} initialValue={row.unit}>
          <Select className="w100">
            <Select.Option value={IUnitEnum.PERCENT}>%</Select.Option>
            <Select.Option value={IUnitEnum.USD}>$</Select.Option>
          </Select>
        </Form.Item>
      ),
      width: '10%'
    },
    {
      title: 'Start date',
      render: (_, row) => (
        <Form.Item name={`${row.id}_discountStartDate`} initialValue={row.discountStartDate ? moment(row.discountStartDate) : undefined}>
          <DatePicker format={'MM/DD/YYYY'} />
        </Form.Item>
      ),
      width: '22%'
    },
    {
      title: 'End date',
      render: (_, row) => (
        <Form.Item name={`${row.id}_discountEndDate`} initialValue={row.discountEndDate ? moment(row.discountEndDate) : undefined}>
          <DatePicker  format={'MM/DD/YYYY'} />
        </Form.Item>
      ),
      width: '22%'
    }
  ];

  const floorModelsColumns: IColumn<IProductItem>[] = [
    {
      title: 'Item title',
      dataIndex: 'title',
      width: '13%'
    },
    {
      title: 'Price($)',
      render: (_, row) => <p>{row.fMprice! + row.fMfinalDiscountUSD!}</p>,
      width: '12%'
    },
    {
      title: 'Discount',
      render: (_, row) => (
        <Form.Item name={`${row.id}_fMdiscount`} initialValue={row.fMdiscount || 100}>
          <InputNumber />
        </Form.Item>
      ),
      width: '13%'
    },
    {
      title: 'Unit',
      render: (_, row) => (
        <Form.Item name={`${row.id}_fMunit`} initialValue={row.fMunit || IUnitEnum.PERCENT}>
          <Select className="w100">
            <Select.Option value={IUnitEnum.PERCENT}>%</Select.Option>
            <Select.Option value={IUnitEnum.USD}>$</Select.Option>
          </Select>
        </Form.Item>
      ),
      width: '12%'
    }
  ];

  const onTabChange = (key: string) => {
    setType(key);
  };

  console.log(currentProductItems)

  return (
    <Form form={form} name={`${product.id}-productItems`} onFinish={handleDiscountUpdate}>
      <Tabs activeKey={type} onChange={onTabChange}>
        <Tabs.TabPane tab={'Order'} key={ITypeEnum.ORDER}>
          <Row>
            <Col offset={1} span={23}>
              <Table<IProductItem> data={currentProductItems} columns={orderColumns} pagination={false} />
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab={'Floor Models'} key={ITypeEnum.FLOOR_MODELS}>
          <Row>
            <Col offset={1} span={23}>
              <Table<IProductItem> data={currentProductItems} columns={floorModelsColumns} pagination={false} />
            </Col>
          </Row>
        </Tabs.TabPane>
      </Tabs>
      <Form.Item wrapperCol={{span: 24}} className="tar mt15">
        <Button type="primary" htmlType="submit">
          {type === 'ORDER' ? 'Save Order Discounts' : 'Save Floor Models Discounts'}
        </Button>
      </Form.Item>
      <Divider />
      <Row align="bottom" className="mb15">
        <Col offset={1} span={15}>
          <h4>Pick a supplier:</h4>
          <Select className="w70" value={supplierId} onChange={handleSupplierChange}>
            {filteredProductItemGroups
              ?.map(group => suppliers.get(group.supplierId))
              .sort((a, b) => a!.order - b!.order)
              .map(supplier => {
                return (
                  <Select.Option value={supplier!.id} key={supplier!.id}>
                    {supplier!.title}
                  </Select.Option>
                );
              })}
          </Select>
        </Col>
        <Col offset={1} span={7} className="tar">
          <Button type="primary" htmlType="button" onClick={handleSupplierUpdate}>
            Save Supplier
          </Button>
        </Col>
      </Row>
    </Form>
  );
});

export default ProductsItemSettings;
