import {gql} from 'graphql-tag';

export const GET_GROUPS = gql`
  query getGroups {
    getGroups {
      id
      title
      description
      type
      productIds
      productItemIds
    }
  }
`;
