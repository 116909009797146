import {TrashIcon} from '@heroicons/react/24/solid';
import Modal from 'components/Modal/Modal';
import {PageWrapper} from 'components/PageWrapper';
import {IMutationEnum, IQueryEnum, makeMutation, makeQueries} from 'core/api';
import AuthStore from 'core/auth';
import {getRetailer, getRetailerId} from 'core/models/retailers/utils';
import {IUser, UserRolesEnum} from 'core/models/users/types';
import {pageUrls} from 'core/router/pages';
import UserActivity from 'partials/retailer-user-view/UserActivity';
import UserInfo from 'partials/retailer-user-view/UserInfo';
import {memo, useContext, useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {dataLayerEvents} from 'utils/dataLayer';
import {getUrl} from 'utils/getUrl';

interface IData {
  users: IUser[];
}
const RetailerUserView = memo(() => {
  const {userId} = useParams<{userId: string}>();
  const history = useHistory();
  const retailerId = getRetailerId();
  const {retailer, isLoading: isRetailerLoading} = getRetailer();
  const {isRetailer} = useContext(AuthStore);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const mutation = makeMutation({
    type: IMutationEnum.DeleteUser,
    loadingMsg: 'Deleting user...',
    successMsg: 'User deleted.'
  });

  useEffect(() => {
    if (!isRetailerLoading && retailer) {
      dataLayerEvents('view_retailers_user_detail', retailer);
    }
    if (mutation.isSuccess) {
      history.push(getUrl(pageUrls.retailerUserList, {retailerId}));
    }
  }, [mutation, retailer, isRetailerLoading, history, retailerId]);

  function onDeleteClick() {
    mutation.mutate({id: userId});
  }

  const {
    data: {users}
  } = makeQueries<IData>([
    {
      type: IQueryEnum.GetUsers,
      variables: {roles: [UserRolesEnum.Owner, UserRolesEnum.Manager, UserRolesEnum.Returns], retailerId}
    }
  ]);

  const user = users?.find(user => user.id === userId);
  const team = users?.filter(user => user.id !== userId);

  return (
    <PageWrapper
      pageTitle="User details"
      breadcrumbs={
        isRetailer
          ? [{url: getUrl(pageUrls.retailerUserList, {retailerId}), title: `Users of "${retailer?.name}"`}]
          : [
              {url: pageUrls.retailerList, title: 'Clients'},
              {url: getUrl(pageUrls.retailerUserList, {retailerId}), title: `Users of "${retailer?.name}"`}
            ]
      }
      topMenuType={'retailers'}
      actions={
        <button
          onClick={() => setDeleteModalOpen(true)}
          className="cursor-pointer inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          <TrashIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
          Delete
        </button>
      }
    >
      <Modal
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        title={'Deactivate account'}
        type="warning"
        onAction={onDeleteClick}
        actionText="Deactivate"
      >
        Are you sure you want to deactivate this account? This action cannot be undone.
      </Modal>
      <div className="flex-row-reverse relative z-0 flex overflow-hidden justify-end bg-white rounded-lg py-4">
        <UserInfo profile={user} team={team} />
        {!isRetailer && <UserActivity user={user} />}
      </div>
    </PageWrapper>
  );
});

export default RetailerUserView;
