import {PageWrapper} from 'components/PageWrapper';
import {getRetailer} from 'core/models/retailers/utils';
import {pageUrls} from 'core/router/pages';
import {useEffect} from 'react';
import RetailersTable from 'shared/RetailersTable';
import {dataLayerEvents} from 'utils/dataLayer';

function InvoiceRetailerList() {
  const {retailer, isLoading} = getRetailer();

  useEffect(() => {
    if (!isLoading && retailer) {
      dataLayerEvents('view_invoices_retailers', retailer);
    }
  }, [isLoading, retailer]);

  return (
    <PageWrapper pageTitle='Invoices'>
      <RetailersTable redirectLink={pageUrls.invoiceList} />
    </PageWrapper>
  );
}

export default InvoiceRetailerList;
