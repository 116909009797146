import {IOktaUser, UserRolesEnum} from 'core/models/users/types';
import {createContext} from 'react';

/** Header interface */
export interface IAuthStore {
  userProfile?: IOktaUser;
  token: string;
  isAdmin: boolean;
  assignedRetailerId?: string;
  isSales: boolean;
  isOwner: boolean;
  isManager: boolean;
  isReturns: boolean;
  isRetailer: boolean;
  declinedTermsAt: string;
  setDeclinedTermsAt: (date: string) => void;
  isRetailerExperience: boolean;
  doesHaveRole: (roles: UserRolesEnum[]) => boolean;
  isAuthorized: (retailerId: string) => boolean;
  updateUserTermsAgreement: (acceptedTermsAt: string) => void;
  logout: () => void;
  loginAsRetailer: (retailerId: string) => void;
  logoutFromRetailer: () => void;
}

// @ts-ignore
const AuthStore = createContext<IAuthStore>({});

export default AuthStore;
