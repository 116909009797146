/* This example requires Tailwind CSS v2.0+ */
import {Listbox, Transition} from '@headlessui/react';
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/24/solid';
import {Fragment} from 'react';
import {getClassName} from 'utils/getClassName';

export interface ISelectOption<T> {
  value: T;
  label: string;
}

export interface ISelectProps<T> {
  options: ISelectOption<T>[];
  onChange?: (value: T) => void;
  value?: T;
  label?: string;
  className?: string;
}

export default function Select<T>({options, onChange, value, label, className}: ISelectProps<T>) {
  function handleChange(option: any) {
    if (onChange)  onChange(option?.value);
  }

  return (
    <div className={className || ''}>
    <Listbox value={value} onChange={handleChange}>
      {({open}) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-gray-700">{label}</Listbox.Label>
          <div className="mt-1 relative">
            <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-cyan-500 focus:border-cyan-500 sm:text-sm">
              <span className="block truncate h-5">{options.find(o => o.value === value)?.label}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {options.map(option => (
                  <Listbox.Option
                    key={option.label}
                    className={({active}) =>
                      getClassName(
                        active ? 'text-white bg-cyan-600' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-3 pr-9'
                      )
                    }
                    value={option}
                  >
                    {({selected, active}) => (
                      <>
                        <span className={getClassName(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>{option.label}</span>

                        {selected ? (
                          <span
                            className={getClassName(
                              active ? 'text-white' : 'text-cyan-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
    </div>
  );
}
