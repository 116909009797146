import moment from 'moment';

export const thirtyDaysAgo = moment().subtract(30, 'days');

export const getFilterData = (params: any) => {
  if (params.toString().length === 0) return {};

  const res: any = {};

  for (var pair of params.entries()) {
    if (pair[0] !== 'page') res[pair[0]] = pair[1];
  }

  return res;
};
