import {PageWrapper} from 'components/PageWrapper';
import {Table} from 'components/Table';
import {IColumn} from 'components/Table/Table';
import {IQueryEnum, makeQueries} from 'core/api';
import {IProductItemGroups} from 'core/models/productItemGroups/types';
import {IProduct} from 'core/models/products/types';
import {ISupplier} from 'core/models/suppliers/types';
import WholesaleDiscountForm from 'partials/settings-global-discounts/WholesaleDiscountForm';

interface IData {
  products: IProduct[];
  suppliers: Map<string, ISupplier>;
  productItemGroups: IProductItemGroups[];
}

function SettingsGlobalDiscounts() {
  const {
    data: {products = []},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetProductItemGroups},
    {type: IQueryEnum.GetProducts},
    {type: IQueryEnum.GetSuppliers, mapKey: 'id'}
  ]);

  const columns: IColumn<IProduct>[] = [
    {
      title: 'Product title',
      dataIndex: 'title',
      render: title => <h4 className="mb0">{title}</h4>,
      width: '55%'
    },
    {
      title: 'Discount',
      shouldCellUpdate: () => true,
      dataIndex: 'wholesaleDiscount',
      render: wholesaleDiscount =>
        wholesaleDiscount?.discount > 0 && (
          <span>
            {'If items in cart >='}
            {wholesaleDiscount?.minItemsInCart}, {wholesaleDiscount?.discount}
            {wholesaleDiscount?.unit === 'USD' ? '$' : '%'} OFF
          </span>
        ),
      width: '45%'
    }
  ];


  return (
    <>
      <PageWrapper pageTitle="Wholesale Discounts" topMenuType="settings">
        <Table
          data={[...products]}
          loading={isLoading}
          filterConfig={{fulltextParams: ['title'], fulltextPlaceholder: 'Search for a product'}}
          columns={columns}
          expandable={{expandedRowRender: product => <WholesaleDiscountForm product={product} />}}
        />
      </PageWrapper>
    </>
  );
}

export default SettingsGlobalDiscounts;
