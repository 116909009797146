import {gql} from 'graphql-tag';

export const CREATE_USER = gql`
  mutation createUser($userInput: UserCreateInput!) {
    createUser(userInput: $userInput) {
      id
      firstName
      lastName
      email
      retailerIds
      storeIds
      role
      fulfilId
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($userUpdate: UserUpdateInput!) {
    updateUser(userUpdate: $userUpdate) {
      id
      oktaId
      firstName
      lastName
      email
      retailerIds
      storeIds
      role
      fulfilId
      acceptedTermsAt
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;

export const CREATE_USERS = gql`
  mutation createUsers($file: Upload) {
    createUsers(file: $file)
  }
`;