import {Select, Form, Button, Input, Tooltip} from 'antd';
import {PageWrapper} from 'components/PageWrapper';
import {IMutationEnum, IQueryEnum, makeQueries, makeMutation} from 'core/api';
import {IProduct} from 'core/models/products/types';
import {IProductItem} from 'core/models/productItems/types';
import {IGroup} from 'core/models/groups/types';
import {pageUrls} from 'core/router/pages';
import {useState, useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
const {Option} = Select;

interface IData {
  products?: IProduct[];
  productItems?: IProductItem[];
  groups?: IGroup[];
}

function EditGroup() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [productItemIds, setProductItemIds] = useState<any>([]);
  const [productIds, setProductIds] = useState<any>([]);
  const {
    data: {groups = [], products = [], productItems = []},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetGroups},
    {type: IQueryEnum.GetProducts},
    {type: IQueryEnum.GetProductItems},
  ]);

  const {groupId} = useParams<{groupId: string}>();
  const group = groups.find(({id}) => id === groupId);

  const mutationEdit = makeMutation({
    type: IMutationEnum.UpdateGroup,
    loadingMsg: 'Updating group...',
    successMsg: 'Group updated.'
  });

  const mutationDelete = makeMutation({
    type: IMutationEnum.DeleteGroup,
    loadingMsg: 'Deleting group...',
    successMsg: 'Group deleted.'
  });

  useEffect(() => {
    if (mutationEdit.isSuccess || mutationDelete.isSuccess) {
      history.push(pageUrls.productGroupView);
    }
  }, [mutationEdit, mutationDelete, history]);

  function updateGroup(v: any) {
    const { title, description, productIds, productItemIds } = v;
    const { type }: any = group;
    const groupUpdate = {
      id: groupId,
      title,
      description,
      type,
      productIds: type === 'Products' ? productIds : null,
      productItemIds: type === 'Product Items' ? productItemIds : null
    };

    mutationEdit.mutate({...groupUpdate});
  }

  const getProductTitle = (pIId: string) => {
    const product = products.find(({id}) => id === pIId);
    return product?.title;
  }

  function onDeleteClick() {
    mutationDelete.mutate({id: groupId});
  }

  const headerActions = (
    <div>
      <Tooltip placement="topLeft" arrowPointAtCenter overlay={false}>
        <Button type="primary" danger onClick={onDeleteClick} >
          Delete
        </Button>
      </Tooltip>
    </div>
  );

  return (
    <PageWrapper pageTitle='Product Group' topMenuType={'catalog'} actions={headerActions} breadcrumbs={[{url: pageUrls.productGroupList, title: `Product Groups`}]}>
      <Form layout="vertical" form={form} name="edit-group" onFinish={updateGroup} className={'ant-col ant-col-14'}>
        <Form.Item name={'title'} label="Group name:" rules={[{required: true}]} initialValue={group?.title}>
          <Input />
        </Form.Item>
        <Form.Item label="Description:" name={'description'} initialValue={group?.description}>
          <Input.TextArea size="large" placeholder="Add description" />
        </Form.Item>
        {group?.type === 'Products' ? (
        <Form.Item name={'productIds'} label="Select Products:" rules={[{required: group?.type === 'Products'}]} initialValue={group?.productIds}>
          <Select
            optionFilterProp="children"
            mode="multiple" 
            allowClear 
            style={{width: '100%'}} 
            value={productIds} 
            onChange={setProductIds} 
            loading={isLoading}
          >
            {products?.map(p => (
              <Option key={p.id} value={p.id}>
                {p.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        ) : (
        <Form.Item name={'productItemIds'} label="Select Product Items:" rules={[{required: false}]}  initialValue={group?.productItemIds}>
          <Select
            optionFilterProp="children"
            mode="multiple" 
            allowClear 
            style={{width: '100%'}} 
            value={productItemIds} 
            onChange={setProductItemIds} 
            loading={isLoading}
          >
            {productItems?.map(p => (
              <Option key={p.id} value={p.id}>
                {getProductTitle(p.productId)} "{p.title}" : {p.sku}
              </Option>
            ))}
          </Select>
        </Form.Item>
        )}
        <div className="w100 tar pt15">
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </PageWrapper>
  );
}

export default EditGroup;
