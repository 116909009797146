import {gql} from 'graphql-tag';

export const GET_SUPPLIERS = gql`
  query getSuppliers {
    getSuppliers {
      id
      title
      order
    }
  }
`;