import {gql} from 'graphql-tag';

export const UPDATE_ADDRESS = gql`
  mutation updateAddress($addressUpdate: AddressUpdate) {
    updateAddress(addressUpdate: $addressUpdate) {
      id
      name
      email
      address
      phone
      zip
      stateId
      city
      addressLine1
      addressLine2
      hidden
      isStore
      isShippingAddress
      isWarehouse
    }
  }
`;
export const CREATE_ADDRESS = gql`
  mutation createAddress($addressInput: AddressCreate) {
    createAddress(addressInput: $addressInput) {
      id
      name
      email
      address
      phone
      zip
      stateId
      city
      addressLine1
      addressLine2
      hidden
      isStore
      isShippingAddress
      isWarehouse
    }
  }
`;
