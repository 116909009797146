import {gql} from 'graphql-tag';

export const GET_PROMO_BUILDER_LIST = gql`
  query getPromoBuilderList {
    getPromoBuilderList {
      id
      groupId
      offerTitle
      offerDescription
      discount
      unit
      expDate
      isActive
      defaultItemsAvailable
      currentItemsAvailable
      promoBanner
      createdBy
    }
  }
`;

export const GET_ACTIVE_PROMO = gql`
  query getActivePromo {
    getActivePromo {
      id
      groupId
      offerTitle
      offerDescription
      discount
      unit
      expDate
      isActive
      defaultItemsAvailable
      currentItemsAvailable
      promoBanner
      createdBy
    }
  }
`;
