import {gql} from 'graphql-tag';

export const GET_STATES = gql`
  query getStates {
    getStates {
      id
      name
      region
      warehouse
    }
  }
`;
