import {PageWrapper} from 'components/PageWrapper';
import {getRetailer} from 'core/models/retailers/utils';
import {pageUrls} from 'core/router/pages';
import {useEffect} from 'react';
import RetailersTable from 'shared/RetailersTable';
import {dataLayerEvents} from 'utils/dataLayer';

function ReturnRetailerList() {
  const {retailer, isLoading} = getRetailer();

  useEffect(() => {
    if (!isLoading && retailer) {
      dataLayerEvents('view_returns_retailers', retailer);
    }
  }, [retailer, isLoading]);

  return (
    <PageWrapper pageTitle='Returns'>
      <RetailersTable redirectLink={pageUrls.returnList} />
    </PageWrapper>
  );
}

export default ReturnRetailerList;
