import {Disclosure} from '@headlessui/react';
import {FunnelIcon} from '@heroicons/react/24/solid';
import {IQueryEnum, makeQueries} from 'core/api';
import {IProductBrand} from 'core/models/productBrands/types';
import {IProductCategory} from 'core/models/productCategories/types';

interface ProductFilterProps {
  nameFilter: string;
  setNameFilter: (name: string) => void;
  brandsFilter: string[];
  setBrandsFilter: (brands: string[]) => void;
  categoriesFilter: string[];
  setCategoriesFilter: (category: string[]) => void;
}

interface IData {
  productBrands?: IProductBrand[];
  productCategories?: IProductCategory[];
}

export default function ProductFilter({
  brandsFilter,
  setBrandsFilter,
  nameFilter,
  setNameFilter,
  categoriesFilter,
  setCategoriesFilter
}: ProductFilterProps) {
  const {
    data: {productBrands = [], productCategories = []}
  } = makeQueries<IData>([{type: IQueryEnum.GetProductBrands}, {type: IQueryEnum.GetProductCategories}]);

  function handleApply(e: any) {
    e.preventDefault();
    const form: any = document.forms[0];

    const name = form.elements.fulltext.value;
    const selectedBrands = Array.from(form.elements.brands)
      .filter((e: any) => e.checked)
      .map((e: any) => e.value);
    const selectedCategories = Array.from(form.elements.categories)
      .filter((e: any) => e.checked)
      .map((e: any) => e.value);

      console.log(name)

    setNameFilter(name);
    setBrandsFilter(selectedBrands);
    setCategoriesFilter(selectedCategories);
  }

  function handleReset() {
    setNameFilter('');
    setBrandsFilter([]);
    setCategoriesFilter([]);
  }

  const activeFilterCount = [nameFilter?.length > 0, brandsFilter?.length > 0, categoriesFilter?.length > 0].filter(Boolean).length;

  return (
    <Disclosure
      as="section"
      aria-labelledby="filter-heading"
      className="relative z-10 border-t border-b border-gray-200 grid items-center bg-white rounded-md"
    >
      <div className="relative col-start-1 row-start-1 py-4">
        <div className="max-w-7xl mx-auto flex space-x-6 divide-x divide-gray-200 text-sm px-4 sm:px-6 lg:px-8">
          <div>
            <Disclosure.Button className="group text-gray-700 hover:text-gray-900 font-medium flex items-center">
              <FunnelIcon className="flex-none w-5 h-5 mr-2 text-cyan-700 group-hover:text-cyan-800" aria-hidden="true" />
              {activeFilterCount} Filters
            </Disclosure.Button>
          </div>
          <div className="pl-6">
            <Disclosure.Button type="button" onClick={handleReset} className="text-cyan-700 underline hover:text-cyan-900">
              Clear all
            </Disclosure.Button>
          </div>
        </div>
      </div>
      <Disclosure.Panel className="border-t border-gray-200 py-6 max-w-7xl mx-auto flex flex-col">
        <form id="product-filter-form" name="product-filter-form" onSubmit={handleApply}>
          <div className="flex flex-row content-between w-full mb-6 px-4 sm:px-6 lg:px-8">
            <div className="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-cyan-600 focus-within:border-cyan-600 w-full mr-8">
              <label
                htmlFor="fulltext"
                className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
              >
                Product Name
              </label>
              <input
                type="text"
                name="fulltext"
                defaultValue={nameFilter}
                className="block w-full border-0 p-0 outline-none text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                placeholder="Nectar 3.0 Lifestyle ..."
              />
            </div>
            <Disclosure.Button onClick={handleApply}>
              <input
                value="Apply"
                type="button"
                className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
              />
            </Disclosure.Button>
          </div>
          <div className="grid grid-cols-2 gap-x-4 px-4 text-sm sm:px-6 md:gap-x-6 lg:px-8">
            <div className="grid grid-cols-1 gap-y-10 auto-rows-min md:grid-cols-2 md:gap-x-6">
              <fieldset>
                <legend className="block font-medium">Brands</legend>
                <div className="pt-6 space-y-6 sm:pt-4 sm:space-y-4">
                  {productBrands.map(brand => (
                    <div key={brand.id} className="flex items-center text-base sm:text-sm">
                      <input
                        name="brands"
                        defaultValue={brand.id}
                        type="checkbox"
                        defaultChecked={brandsFilter?.includes(brand.id)}
                        className="flex-shrink-0 h-4 w-4 border-gray-300 rounded accent-cyan-600 cursor-pointer"
                      />
                      <label htmlFor={`brand-${brand.id}`} className="ml-3 min-w-0 flex-1 text-gray-600 cursor-pointer select-none">
                        {brand.title}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
              <fieldset>
                <legend className="block font-medium">Categories</legend>
                <div className="pt-6 space-y-6 sm:pt-4 sm:space-y-4">
                  {productCategories.map(category => (
                    <div key={category.id} className="flex items-center text-base sm:text-sm">
                      <input
                        name="categories"
                        defaultValue={category.id}
                        type="checkbox"
                        defaultChecked={categoriesFilter?.includes(category.id)}
                        className="flex-shrink-0 h-4 w-4 border-gray-300 rounded accent-cyan-600 cursor-pointer"
                      />
                      <label htmlFor={`type-${category.id}`} className="ml-3 min-w-0 flex-1 text-gray-600 cursor-pointer select-none">
                        {category.title}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div>
          </div>
        </form>
      </Disclosure.Panel>
    </Disclosure>
  );
}
