import {PageWrapper} from 'components/PageWrapper';
import {IInvoice} from 'core/models/invoices/types';
import {getRetailer} from 'core/models/retailers/utils';
import {pageUrls} from 'core/router/pages';
import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import InvoicesTable from 'shared/InvoicesTable';
import {dataLayerEvents} from 'utils/dataLayer';
import {getUrl} from 'utils/getUrl';

function InvoiceList() {
  const history = useHistory();
  const {retailer, isLoading} = getRetailer();

  useEffect(() => {
    if (!isLoading && retailer) {
      dataLayerEvents('view_invoices_manage', retailer);
    }
  }, [isLoading, retailer]);

  const onClick = (invoice: IInvoice) => {
    history.push(getUrl(pageUrls.invoiceView, {invoiceId: invoice.id, retailerId: retailer?.id}));
  };

  return (
    <PageWrapper pageTitle={retailer?.name} withRetailerSelector>
      <InvoicesTable onClick={onClick} />
    </PageWrapper>
  );
}

export default InvoiceList;
