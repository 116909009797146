import {LoginOutlined} from '@ant-design/icons';
import {ChevronRightIcon} from '@heroicons/react/24/solid';
import {Button, Row, Select} from 'antd';
import {IQueryEnum, makeQueries} from 'core/api';
import AuthStore from 'core/auth';
import {IRetailer} from 'core/models/retailers/types';
import {getRetailer} from 'core/models/retailers/utils';
import {pageUrls} from 'core/router/pages';
import React, {FC, memo, useContext} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
import TopMenu from 'shared/TopMenu/TopMenu';

interface IData {
  retailers: IRetailer[];
}

export interface IBreadcrumb {
  title: string;
  url: string;
}

export interface IPageWrapper {
  children: React.ReactNode;
  pageTitle?: string;
  topMenuType?: 'retailers' | 'catalog' | 'settings';
  actions?: JSX.Element;
  breadcrumbs?: IBreadcrumb[];
  withRetailerSelector?: boolean;
  handleChangeRetailer?: (id: string) => void;
}

const PageWrapper: FC<IPageWrapper> = memo(
  ({children, pageTitle, topMenuType, actions, withRetailerSelector, handleChangeRetailer, breadcrumbs}) => {
    const history = useHistory();
    const {retailerId} = useParams<{retailerId: string}>();
    const {isRetailer, loginAsRetailer} = useContext(AuthStore);
    const {retailer} = getRetailer();

    const {
      data: {retailers}
    } = makeQueries<IData>([{type: IQueryEnum.GetRetailers}]);

    function changeRetailer(id: string) {
      if (handleChangeRetailer) return handleChangeRetailer(id);
      history.push(history.location.pathname.replace(retailerId, id) + history.location.search);
    }

    const handleLoginAsRetailer = () => {
      if (retailer?.id) {
        loginAsRetailer(retailer.id);
        history.push(pageUrls.dashboard);
      }
    };

    const selectComponent = (
      <Select
        placeholder="Name"
        className="w70"
        allowClear
        showSearch
        defaultValue={retailer?.name}
        size="large"
        style={{fontWeight: 'bold'}}
        optionFilterProp="children"
        onSelect={changeRetailer}
        {...(handleChangeRetailer ? {onClear: () => handleChangeRetailer('')} : {})}
        filterOption={(input, option: any) => option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {retailers?.map(r => (
          <Select.Option key={r.id} value={r.id}>
            {r.name}
          </Select.Option>
        ))}
      </Select>
    );

    return (
      <div>
        <div>
          <div className="pt-4 pb-0">
            <div>
              {breadcrumbs && <nav className="hidden sm:flex" aria-label="Breadcrumb">
                <ol className="flex items-center">
                  {breadcrumbs?.map(breadcrumb => (
                    <li key={breadcrumb.title}>
                      <div className="flex">
                        <Link to={breadcrumb.url} className="text-sm font-medium text-gray-500 hover:text-gray-700">
                          {breadcrumb.title}
                        </Link>
                        <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                    </li>
                  ))}
                  {pageTitle && <li className="text-sm font-medium text-gray-500 hover:text-gray-700">{pageTitle}</li>}
                </ol>
              </nav>}
            </div>
            <div className="mb-6 md:flex md:items-center md:justify-between">
              <div className="flex-1 min-w-0">
                {!isRetailer && withRetailerSelector ? (
                  <Row align="middle" className="">
                    {selectComponent}
                    {!handleChangeRetailer && (
                      <Button size="large" type="primary" className="ml15" onClick={handleLoginAsRetailer} icon={<LoginOutlined />}>
                        Impersonate
                      </Button>
                    )}
                  </Row>
                ) : (
                  <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{pageTitle}</h2>
                )}
              </div>
              <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">{actions}</div>
            </div>
          </div>
          <TopMenu type={topMenuType} />
          <div>{children}</div>
        </div>
      </div>
    );
  }
);

export default PageWrapper;
