import {Row, Image} from 'antd';
import {BannerVariants, IPromoBuilder} from 'core/models/promoBuilder/types';
import moment from 'moment-timezone';

interface IProps {
  activePromo: IPromoBuilder;

  type: BannerVariants;
}
export default function PromoImage({activePromo, type}: IProps) {
  const isSameOrAfter = activePromo?.expDate && moment(activePromo?.expDate).isSameOrAfter();
  const isPromoActive = activePromo?.promoBanner?.types.includes(type) && isSameOrAfter;
  return (
    <>
      {isPromoActive && (
        <Row className="mt15">
          <Image
            height="100px"
            width="100%"
            src={activePromo?.promoBanner?.image}
            alt="promo banner"
            className={'w100'}
            preview={false}
            style={{objectFit: 'cover'}}
          />
        </Row>
      )}
    </>
  );
}
