/* eslint-disable react-hooks/exhaustive-deps */
import {useOktaAuth} from '@okta/okta-react';
import gql from 'core/api/graphql/client';
import {IOktaUser, UserRolesEnum} from 'core/models/users/types';
import {pageUrls} from 'core/router/pages';
import React, {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import TagManager from 'react-gtm-module';
import {getUrl} from 'utils/getUrl';
import constants from '../../constants';
import AuthStore from './AuthStore';
import useLocalStorage from 'utils/useLocalStorage';

/** Menu component */
const AuthProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const {authState, oktaAuth} = useOktaAuth();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState<IOktaUser>();
  const [declinedTermsAt, setDeclinedTermsAt] = useLocalStorage<string>('declinedTermsAt', '');


  /** Handle Login for non authenticated users */
  useEffect(() => {
    if (!authState.isPending && !authState.isAuthenticated) {
      oktaAuth.signInWithRedirect({originalUri: history.location.pathname + history.location.search});
    }
  }, [authState.isAuthenticated, authState.isPending]);

  useEffect(() => {
    if (authState.isAuthenticated && !userProfile) {
      /** Add authorization to gql requests */
      gql.setHeader('authorization', `Bearer ${authState.accessToken?.value!}`);
      /** Set userInfo */
      const user = {
        id: authState.accessToken?.claims?.uid,
        firstName: authState.accessToken?.claims?.firstName,
        lastName: authState.accessToken?.claims?.lastName,
        email: authState.accessToken?.claims?.sub,
        photoUrl: authState.accessToken?.claims?.photoUrl,
        realRole: getUserRole(authState.accessToken?.claims?.groups),
        role: getUserRole(authState.accessToken?.claims?.groups),
        retailerIDs: authState.accessToken?.claims?.retailerIDs,
        acceptedTermsAt: authState.accessToken?.claims?.acceptedTermsAt,
      };
      setUserProfile(user);
      oktaAuth.isAuthenticated();

      const tagManagerArgs = {
        gtmId: constants.gtmId!,
        dataLayer: {
          userId: user.id,
          userRole: user.role
        }
      };
      if (tagManagerArgs.gtmId) TagManager.initialize(tagManagerArgs);
    }
  }, [authState, oktaAuth, userProfile]);

  /** Handle Logout from application */
  const logout = async () => {
    window.dataLayer?.push({event: 'logout'});
    oktaAuth.signOut();
  };

  const getUserRole = (groups: string[]) => {
    if (groups.includes(UserRolesEnum.Owner)) return UserRolesEnum.Owner;
    if (groups.includes(UserRolesEnum.Manager)) return UserRolesEnum.Manager;
    if (groups.includes(UserRolesEnum.Returns)) return UserRolesEnum.Returns;
    if (groups.includes(UserRolesEnum.Admin)) return UserRolesEnum.Admin;
    if (groups.includes(UserRolesEnum.Sales)) return UserRolesEnum.Sales;
    throw new Error('User role is not supported.');
  };

  const isAdmin = userProfile?.role === UserRolesEnum.Admin;
  const isSales = userProfile?.role === UserRolesEnum.Sales;
  const isOwner = userProfile?.role === UserRolesEnum.Owner;
  const isManager = userProfile?.role === UserRolesEnum.Manager;
  const isReturns = userProfile?.role === UserRolesEnum.Returns;
  const isRetailer =
    userProfile?.role === UserRolesEnum.Owner ||
    userProfile?.role === UserRolesEnum.Returns ||
    userProfile?.role === UserRolesEnum.Manager;
  const assignedRetailerId = isRetailer ? userProfile?.retailerIDs![0] : '';

  const isAuthorized = (retailerId: string) => isAdmin || isSales || userProfile?.retailerIDs?.includes(retailerId) || false;
  const doesHaveRole = (roles: UserRolesEnum[]) => roles.includes(userProfile?.role!);

  const isRetailerExperience =
    authState.isAuthenticated && userProfile ? userProfile?.role !== getUserRole(authState.accessToken?.claims?.groups) : false;

  /** Impersonate retailer */
  const loginAsRetailer = useCallback(
    (retailerId: string) => {
      const user = {
        ...userProfile!,
        role: UserRolesEnum.Owner,
        retailerIDs: [retailerId]
      };
      setUserProfile(user);
      window.localStorage.setItem('retailerExperience', retailerId);
      window.scrollTo(-50, -50);
      window.dataLayer?.push({event: 'impersonate_retailer'});
    },
    [userProfile]
  );

  function updateUserTermsAgreement(acceptedTermsAt: string) {
    setUserProfile({...userProfile!, acceptedTermsAt});
  }

  /** Exit from impersonating a retailer  */
  const logoutFromRetailer = () => {
    const user = {
      ...userProfile!,
      role: getUserRole(authState.accessToken?.claims?.groups),
      retailerIDs: authState.accessToken?.claims?.retailerIDs
    };
    setUserProfile(user);
    history.push(getUrl(pageUrls.retailerList, {retailId: user.retailerIDs[0]}));
    window.localStorage.removeItem('retailerExperience');
    window.dataLayer?.push({event: 'personate_retailer'});
  };

  /** Handles impersonating a retailer after refresh */
  useEffect(() => {
    const retailerExperienceId = window.localStorage.getItem('retailerExperience');
    if (userProfile && !isRetailerExperience && Boolean(retailerExperienceId)) {
      loginAsRetailer(retailerExperienceId!);
    }
  }, [isRetailerExperience, userProfile, loginAsRetailer]);

  return (
    <AuthStore.Provider
      value={{
        token: authState.accessToken?.value!,
        isAdmin,
        declinedTermsAt,
        setDeclinedTermsAt,
        updateUserTermsAgreement,
        isAuthorized,
        logout,
        userProfile,
        isRetailer,
        isSales,
        isOwner,
        isManager,
        isReturns,
        assignedRetailerId,
        doesHaveRole,
        loginAsRetailer,
        logoutFromRetailer,
        isRetailerExperience
      }}
    >
      {children}
    </AuthStore.Provider>
  );
};

export default AuthProvider;
