import {OktaAuth} from '@okta/okta-auth-js';
import {LoginCallback, SecureRoute, Security} from '@okta/okta-react';
import {pageUrls} from 'core/router/pages';
import ReactDOM from 'react-dom';
import {QueryClient, QueryClientProvider} from 'react-query';
import {createWebStoragePersistor} from 'react-query/createWebStoragePersistor-experimental';
import {ReactQueryDevtools} from 'react-query/devtools';
import {persistQueryClient} from 'react-query/persistQueryClient-experimental';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {Application} from './app';
import constants from './constants';
import AuthProvider from './core/auth/AuthProvider';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { QueryParamProvider } from 'use-query-params';

declare global {
  interface Window {
    dataLayer: any;
  }
}

window.dataLayer = window.dataLayer || [];

const oktaAuth: OktaAuth = new OktaAuth(constants.oktaConfig);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      // After 60 seconds data will be refetched
      staleTime: 1000 * 60,
      // After 24 hours data will be removed & garbage collected
      cacheTime: 1000 * 60 * 60 * 24,
      refetchOnMount: true
    }
  }
});

/**
 * Persist data into local storage
 * Data "freshness" is described by cacheTime
 */
persistQueryClient({
  queryClient,
  persistor: createWebStoragePersistor({storage: window.localStorage})
});

ReactDOM.render(
    <BrowserRouter>
    <QueryParamProvider ReactRouterRoute={Route}>
      <Security oktaAuth={oktaAuth}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Switch>
              <Route path={pageUrls.callback} component={LoginCallback} />
              <SecureRoute component={Application} />
            </Switch>
          </AuthProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Security>
      </QueryParamProvider>
    </BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals();
