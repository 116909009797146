import {UserRolesEnum} from './types';

export function userRoleRenderer(role?: UserRolesEnum) {
  let tagColor;
  let finalRole: any = role;

  switch (role) {
    case UserRolesEnum.Admin:
      tagColor = 'green';
      break;
    case UserRolesEnum.Sales:
      tagColor = 'yellow';
      break;
    case UserRolesEnum.Owner:
      tagColor = 'green';
      break;
    case UserRolesEnum.Manager:
      tagColor = 'yellow';
      finalRole = 'Designer';
      break;
    case UserRolesEnum.Returns:
      tagColor = 'indigo';
      break;
    default:
      tagColor = 'grey';
  }
  return (
    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-${tagColor}-100 text-${tagColor}-800`}>
      {finalRole}
    </span>
  );
}
