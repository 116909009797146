import {memo} from 'react';
import { getClassName } from 'utils/getClassName';
import './styles.less'

export interface IInputNumber {
  /** Action on input value change. If prop not passed, input will be disabled */
  onChange: (value: number, action?: string) => any;
  /** Input Number className */
  className?: string;
  /** Default input value */
  defaultValue?: number;
  /** Input value */
  value: number;
}

/** Custom Input number component */
const InputNumber: React.FC<IInputNumber> = memo(({onChange, className, value}) => {
  const onIncrement = () => {
    onChange(value + 1, 'Increment');
  };

  const onDecrement = () => {
    if (value >= 1) {
      onChange(value - 1, 'Decrement');
    }
  };

  const onInputChange = ({target}: any) => {
    const newValue = target.value;
    if (newValue > 0) {
      onChange(Number(newValue));
    }
  };

  return (
    <div className={getClassName(className || '', 'input-number-wrapper')}>
      <button className="input-number-button" onClick={onDecrement} disabled={!Boolean(onChange)}>
        -
      </button>
      <input className="input-number-input" type="number" step="1" onChange={onInputChange} value={value} disabled={!Boolean(onChange)} />
      <button className="input-number-button" onClick={onIncrement} disabled={!Boolean(onChange)}>
        +
      </button>
    </div>
  );
});

export default InputNumber;
