import { Card } from "antd";

export function TermsAndConditions() {
  return (
    <Card title={<h1 className="tac text-xl font-semibold">Terms & Conditions</h1>}>
      <div className="mt-15 w70 m-auto">
        <div className="tac">
          <h2 className="text-lg font-semibold">Welcome to the Resident Connect App (“App”)</h2>
        </div>
        <p>
          The use of this app is subject to these terms and conditions (“Supplemental Terms”) which supplement the terms and conditions of
          the Retail Sales Agreement (“Agreement”) between your company and Resident.{' '}
        </p>
        <h5>
          READ THESE TERMS CAREFULLY BEFORE USING THIS APP. USING THIS APP INDICATES THAT YOU HAVE BOTH READ AND ACCEPT THESE TERMS. YOU
          CANNOT USE THIS WEBSITE IF YOU DO NOT ACCEPT THESE TERMS.
        </h5>
        <div>
          <p>By using the App, you agree to the following:</p>
          <ul>
            <li>You are at least 18 years of age;</li>
            <li>You are authorized by your company to use the App on behalf of your company;</li>
            <li>You will not share your login credentials or password with anyone;</li>
            <li>You will not attempt to modify, alter, or reverse-engineer the App or its code in any way;</li>
            <li>
              You will contact your Resident sales representative if you become of aware of any material errors between your systems and the
              App with regard to shipping information, inventory, or ordering quantities;
            </li>
            <li>Resident may treat orders you place using the App as Orders under the Agreement;</li>
            <li>The App is provided as-is and without warranty;</li>
            <li>
              Your ongoing and continued acceptance of these Supplemental Terms is a condition of Resident’s revocable grant of license to
              use the App, which may be revoked by Resident in its sole discretion at any time;
            </li>
            <li>
              To the extent the App allows access to other websites, other terms may apply, e.g.{' '}
              <a rel="noreferrer" className="c-blue" href="https://www.residenthome.com/terms-and-conditions/" target="_blank">
                Website Terms and Conditions
              </a>
              ,{' '}
              <a rel="noreferrer" className="c-blue" href="https://www.residenthome.com/privacy-policy/" target="_blank">
                Privacy Policy
              </a>
              , or other 3rd party terms and conditions.
            </li>
          </ul>
        </div>
      </div>
    </Card>
  );
}
