import {Button, Radio, RadioChangeEvent} from 'antd';
import {PageWrapper} from 'components/PageWrapper';
import {Table} from 'components/Table';
import {IColumn} from 'components/Table/Table';
import {IQueryEnum, makeQueries} from 'core/api';
import {IRetailer} from 'core/models/retailers/types';
import {IUser} from 'core/models/users/types';
import {pageUrls} from 'core/router/pages';
import moment from 'moment-timezone';
import {useEffect, useMemo, useState} from 'react';
import {CSVLink} from 'react-csv';
import {Link, useHistory} from 'react-router-dom';
import {getFilterData} from 'utils/filterHelper';
import {getUrl} from 'utils/getUrl';

const reportTypes = [
  {label: 'By Assets', value: 'ASSETS'},
  {label: 'By Retailers', value: 'ASSETS_BY_RETAILER'}
];

interface IData {
  analytics: any[];
  users: Map<string, IUser>;
  retailers: Map<string, IRetailer>;
}

function ReportMarketingAssets() {
  const [reportType, setReportType] = useState(reportTypes[0].value);
  const history = useHistory();
  const params = useMemo(() => new URLSearchParams(history.location.search), [history.location.search]);
  const [filterParams, setFilterParams] = useState<any>();

  useEffect(() => {
    const filterData = getFilterData(params);
    setFilterParams(filterData);
  }, [params]);

  const {
    data: {analytics, users, retailers},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetAnalyticsReports, variables: {type: reportType, filters: filterParams}, isEnabled: Boolean(filterParams)},
    {type: IQueryEnum.GetUsers, mapKey: 'id'},
    {type: IQueryEnum.GetRetailers, mapKey: 'id'}
  ]);

  const assetColumns: Array<IColumn<any>> = [
    {
      title: 'Asset',
      width: '50%',
      render: (_, record) => (
        <div>
          <Link
            className="font-bold text-cyan-700 hover:text-cyan-800"
            target="_blank"
            to={getUrl(pageUrls.assetView, {assetId: record.id})}
          >
            {record.name}
          </Link>
        </div>
      )
    },
    {
      title: 'Most Active Retailer',
      width: '10%',
      render: (_, record) =>
        retailers?.get(record.topRetailer.value)?.name
          ? `${retailers?.get(record.topRetailer.value)?.name} (${record.topRetailer.total})`
          : 'N/A'
    },
    {
      title: 'Most Active User',
      width: '10%',
      sorter: {
        compare: (a, b) => b.topUser.total - a.topUser.total
      },
      render: (_, record) =>
        `${users?.get(record.topUser.value)?.lastName} ${users?.get(record.topUser.value)?.firstName} (${record.topUser.total})`
    },
    {
      title: 'Total Actions',
      width: '9%',
      sorter: {
        compare: (a, b) => b.totalActions - a.totalActions
      },
      dataIndex: 'totalActions'
    },
    {
      title: 'Viewed',
      sorter: {
        compare: (a, b) => b.views - a.views
      },
      dataIndex: 'views',
      width: '7%'
    },
    {
      title: 'Permalink Click',
      sorter: {
        compare: (a, b) => b.permalinkClicks - a.permalinkClicks
      },
      dataIndex: 'permalinkClicks',
      width: '7%'
    },
    {
      title: 'Download Click',
      sorter: {
        compare: (a, b) => b.downloadClicks - a.downloadClicks
      },
      dataIndex: 'downloadClicks',

      width: '7%'
    }
  ];

  const assetByRetailerColumns: Array<IColumn<any>> = [
    {
      title: 'Retailer',
      width: '30%',
      dataIndex: 'name',
      render: name => (
        <div>
          <span className="font-bold">{name}</span>
        </div>
      )
    },
    {
      title: 'Most Viewed Asset',
      width: '30%',
      render: (_, record) => (
        <div>
          <Link
            className="font-bold text-cyan-700 hover:text-cyan-800"
            target="_blank"
            to={getUrl(pageUrls.assetView, {assetId: record.topAsset.id})}
          >
            {record.topAsset.name} ({record.topAsset.total})
          </Link>
        </div>
      )
    },
    {
      title: 'Total Actions',
      width: '10%',
      dataIndex: 'totalActions',
      sorter: {
        compare: (a, b) => b.totalActions - a.totalActions
      }
    },
    {
      title: 'Viewed',
      dataIndex: 'views',
      sorter: {
        compare: (a, b) => b.views - a.views
      },
      width: '10%'
    },
    {
      title: 'Permalink Click',
      dataIndex: 'permalinkClicks',
      sorter: {
        compare: (a, b) => b.permalinkClicks - a.permalinkClicks
      },
      width: '10%'
    },
    {
      title: 'Download Click',
      dataIndex: 'downloadClicks',
      sorter: {
        compare: (a, b) => b.downloadClicks - a.downloadClicks
      },
      width: '10%'
    }
  ];

  const analyticsData =
    reportType === 'ASSETS'
      ? analytics?.map(record => ({
          id: record.id,
          ...record.data
        }))
      : analytics?.map(record => ({
          id: record.id,
          name: retailers.get(record.id)?.name,
          ...record.data
        }));

  function getCSVData() {
    if (reportType === 'ASSETS') {
      const headers = [
        {label: 'Asset Name', key: 'name'},
        {label: 'Most Active User', key: 'mostActiveRetailer'},
        {label: 'Most Active User', key: 'mostActiveUser'},
        {label: 'Total Actions', key: 'totalActions'},
        {label: 'Viewed', key: 'views'},
        {label: 'Permanent Click', key: 'permanentClicks'},
        {label: 'Download Click', key: 'downloadClicks'}
      ];

      const csvData = analyticsData?.map(record => ({
        name: record.name,
        mostActiveRetailer: retailers?.get(record.topRetailer.value)?.name
          ? `${retailers?.get(record.topRetailer.value)?.name} (${record.topRetailer.total})`
          : 'N/A',
        mostActiveUser: `${users?.get(record.topUser.value)?.lastName} ${users?.get(record.topUser.value)?.firstName} (${
          record.topUser.total
        })`,
        totalActions: record.totalActions,
        views: record.views,
        permanentClicks: record.permalinkClicks,
        downloadClicks: record.downloadClicks
      }));

      return {
        data: csvData,
        headers,
        filename: `Assets_Report(ByAssets)_${moment().format('MM/DD/YYYY')}.csv`
      };
    } else {
      const headers = [
        {label: 'Retailer Name', key: 'retailerName'},
        {label: 'Asset Name', key: 'name'},
        {label: 'Total Actions', key: 'totalActions'},
        {label: 'Viewed', key: 'views'},
        {label: 'Permanent Click', key: 'permanentClicks'},
        {label: 'Download Click', key: 'downloadClicks'}
      ];

      const csvData = analyticsData?.map(record => ({
        retailerName: record.name,
        name: record.topAsset.name,
        totalActions: record.totalActions,
        views: record.views,
        permanentClicks: record.permalinkClicks,
        downloadClicks: record.downloadClicks
      }));

      return {
        data: csvData,
        headers,
        filename: `Assets_Report(ByRetailer)_${moment().format('MM/DD/YYYY')}.csv`
      };
    }
  }

  return (
    <PageWrapper
      pageTitle="Marketing Assets Report"
      actions={
        analyticsData && (
          <Button type="primary">
            <CSVLink {...getCSVData()}>Export to CSV</CSVLink>
          </Button>
        )
      }
    >
      <div className="mb-4">
        <Radio.Group
          name="section"
          value={reportType}
          onChange={(e: RadioChangeEvent) => setReportType(e.target.value)}
          buttonStyle="solid"
        >
          {reportTypes?.map(t => (
            <Radio.Button key={t.value} value={t.value} className="my-1">
              {t.label}
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>
      <Table
        data={analyticsData}
        columns={reportType === 'ASSETS' ? assetColumns : assetByRetailerColumns}
        loading={isLoading}
        filterConfig={{
          fulltextPlaceholder: reportType === 'ASSETS' ? 'Search for Asset' : 'Search for Retailer',
          fulltextParams: ['name'],
          fullDateFilter: true
        }}
      />
    </PageWrapper>
  );
}

export default ReportMarketingAssets;
