import {Card} from 'antd';
import {N} from 'components/N';
import {Tooltip, BarChart, YAxis, Bar, XAxis,ResponsiveContainer} from 'recharts';

const SimpleBarChart =({data}: any) => {
  return (
    <Card className="w100 tac mt15">
      <h2 className='text-lg font-semibold'>Total Products Ordered</h2>
      <ResponsiveContainer width='100%' height={400}>
      <BarChart   data={data} margin={{top: 5, right: 30, left: 20, bottom: 5}} layout="horizontal">
        <YAxis type="number" axisLine={false} tickLine={false} />
        <XAxis dataKey="labels" hide tickLine={false} />
        <Tooltip
          useTranslate3d
          content={({active, payload, label}) => (
            <div className="bg-white b b-r4 p10" style={{textAlign: 'left'}}>
              <h2 className="text-lg font-semibold">
                <N value={payload?.[0]?.value as number} />
              </h2>
              <h5 className="f-light c-darkblue mb0">{label}</h5>
            </div>
          )}
        />
        <Bar type="monotone" dataKey="values" stroke="#5a7a81" fill="#739ea8" barSize={6} />
      </BarChart>
      </ResponsiveContainer>
    </Card>
  );
};

export default SimpleBarChart;
