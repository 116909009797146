import {ArrowDownTrayIcon, LinkIcon} from '@heroicons/react/24/outline';
import {N} from 'components/N';
import { makeMutation, IMutationEnum } from 'core/api';
import {IAsset} from 'core/brandfolder/types';
import { getRetailer } from 'core/models/retailers/utils';
import moment from 'moment';

export default function AssetSidebar({asset}: {asset?: IAsset}) {
  const {retailer} = getRetailer();

  const analyticsMutation = makeMutation({
    type: IMutationEnum.CreateAnalyticsRecord
  });

  
  function copyPermaLinkToClipBoard() {
    navigator.clipboard.writeText(asset?.url!);
    sendAnalytics('asset_click_copy_permalink');
  }

  function sendAnalytics(event: string, customData: any = {}) {
    const analyticsCreateInput = {
      retailerId: retailer?.id,
      event,
      data: {
        id: asset?.id,
        name: asset?.name,
        thumbnailUrl: asset?.thumbnailUrl,
        ...customData
      }
    };
    analyticsMutation.mutate({analyticsCreateInput});
  }

  function handleDownload() {
    sendAnalytics('asset_click_download');
  }


  return (
    <aside className="hidden w-96 bg-white p-8 border-l border-gray-200 overflow-y-auto lg:block rounded-md">
      {asset ? (
        <div className="pb-16 space-y-6">
          <div className="border-b pb-4">
            <div className="flex justify-between text-sm font-medium align-middle">
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-cyan-100 text-black border border-cyan-700">
                {asset.brand}
              </span>
              <span className="text-gray-900">{moment(asset?.updatedAt).format('MMMM DD YYYY')}</span>
            </div>
            <button
              type="button"
              onClick={copyPermaLinkToClipBoard}
              className="mt-3 inline-flex items-center px-2 py-1 border border-transparent shadow-xs text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
            >
              <LinkIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              Copy Permalink
            </button>
          </div>
          {asset.attachments.map(attachment => (
            <div key={attachment.id} className="border-b pb-4">
              {attachment.thumbnail && <img src={attachment.thumbnail} alt="attachment thumbnail" className='mb-4 max-h-28 w-auto mx-auto rounded-xl' />}
              <div className="flex align-middle justify-between">
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-200 text-gray-900 uppercase">
                  {attachment.extension}
                </span>
                <div className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-200 text-gray-900 uppercase">
                  <N value={attachment.size.value} toFixed={1} />
                  &nbsp;{attachment.size.unit}
                </div>
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-200 text-gray-900 uppercase">
                  {attachment.width} X {attachment.height}
                </span>
              </div>
              <a
                href={attachment.url}
                onClick={handleDownload}
                download="test"
                target={'_blank'}
                rel="noopener noreferrer"
                className="mt-3 inline-flex items-center px-2 py-1 border border-transparent shadow-xs text-xs font-medium rounded-md text-gray-100 hover:text-gray-100 bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
              >
                <ArrowDownTrayIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Download / View
              </a>
            </div>
          ))}
          <div></div>
        </div>
      ) : (
        <div className="text-center">
          <h3 className="mt-2 text-sm font-medium text-gray-900">No asset selected</h3>
          <p className="mt-1 text-sm text-gray-500">Click on asset to view details.</p>
        </div>
      )}
    </aside>
  );
}
