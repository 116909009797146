import {gql} from 'graphql-tag';

export const GET_PRODUCTS = gql`
  query getProducts {
    getProducts {
      id
      productCategoryId
      productBrandId
      title
      description
      images
      wholesaleDiscount {
        unit
        minItemsInCart
        discount
      }
      productItemIds
      archived
    }
  }
`;
