import * as React from 'react';
import Lottie, {Options as LottieOptions} from 'react-lottie';
import {getClassName} from 'utils/getClassName';
import {AnimationEnum, getAnimation} from './AnimationEnum';

export interface IAnimation {
  /** Animation size in rems */
  width: number;
  /** if height will not be passed, width will be used */
  height?: number;
  /** Type of animation body */
  type: AnimationEnum;
  /** If true, animation will be played non-stop (default - false) */
  loop?: boolean;
  /** If true, animation will be after render (default - false) */
  autoplay?: boolean;
  /** Class name will be applied on parent div */
  className?: string;
  /** If true, animation will be paused after last frame */
  pause?: boolean;
}

/** Wrapper for React Lottie, provides animation */
export const Animation: React.FC<IAnimation> = (props: IAnimation) => {
  /** Basic options */
  const lottieOptions: LottieOptions = {
    animationData: getAnimation(props.type),
    loop: props.loop || false,
    autoplay: props.autoplay || false
  };

  return (
    <div
      className={getClassName(props.className || '', 'm-auto bg-transparent')}
      style={{width: `${props.width}rem`, height: `${props.height}rem`}}
    >
      <Lottie options={lottieOptions} speed={0.85} isClickToPauseDisabled isPaused={props.pause} />
    </div>
  );
};
