import React, {memo, useContext} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Result} from 'antd';
import AuthStore from '../auth';
import routes from './routes';
import { pageUrls } from './pages';
import { getRetailerId } from 'core/models/retailers/utils';
import { getUrl } from 'utils/getUrl';

/** Not found page */
const Page404: React.FC = () => {
  return <Result status="404" title="404" subTitle="Sorry, the page you visited does not exist." />;
};
/** Unauthorized page */
const Page403: React.FC = () => {
  return <Result status="403" title="403" subTitle="Sorry, you are not authorized to access this page." />;
};

const Router = memo(() => {
  const {doesHaveRole, isManager, isReturns} = useContext(AuthStore);
  const retailerId = getRetailerId()

  const routeItems = routes.map(({url, Component, allowedRoles}) => {
    const authorized = allowedRoles ? doesHaveRole(allowedRoles) : true;

    if (!authorized) {
      Component = Page403;
    }

    return <Route path={url} exact key={url} component={Component} />;
  });


  function getDefaultPage() {
    if(isManager) return pageUrls.assetList;
    if(isReturns) return getUrl(pageUrls.returnList, {retailerId});
    return pageUrls.dashboard;
  }

  return (
    <Switch>
      {routeItems}
      <Redirect exact from="/" to={getDefaultPage()} />
      <Route component={Page404} />
    </Switch>
  );
});

export default Router;
