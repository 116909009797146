import {Button, Carousel, Form, Input, Select, Image, Upload, message, Card, Row, Col} from 'antd';
import {IMutationEnum, IQueryEnum, makeMutation, makeQueries} from 'core/api';
import {IProductBrand} from 'core/models/productBrands/types';
import {IProductCategory} from 'core/models/productCategories/types';
import {useContext, useEffect, useRef, useState} from 'react';
import {PictureOutlined, ColumnWidthOutlined} from '@ant-design/icons';
import {CarouselRef} from 'antd/lib/carousel/index';
import AuthStore from 'core/auth';
import {normalizeFile} from 'utils/normalizeFile';
import constants from '../../../../constants';
import {getUrl} from 'utils/getUrl';
import {Buffer} from 'buffer';
import {ISupplier} from 'core/models/suppliers/types';
import {useHistory} from 'react-router-dom';
import {pageUrls} from 'core/router/pages';
import './style.less';

const {Option} = Select;
const {TextArea} = Input;

interface IData {
  productBrands: IProductBrand[];
  productCategories: IProductCategory[];
  suppliers: ISupplier[];
}
const ProductCreate = () => {
  const [form] = Form.useForm();
  const [picUrl, setPicUrl] = useState<string>();
  const [activeSlider, setActiveSlider] = useState<number>(0);
  const [currentPic, setCurrentPic] = useState<string>('');
  const [carouselImg, setCarouselImg] = useState<Array<string>>([]);
  const {token} = useContext(AuthStore);
  const slider = useRef<CarouselRef>(null);
  const history = useHistory();

  const MAX_IMG_LENGTH: number = 6;
  const IMAGE_TYPES: Array<string> = ['image/jpeg', 'image/png', 'image/svg', 'image/webp'];

  const {
    data: {productBrands = [], productCategories = []},
    isLoading
  } = makeQueries<IData>([{type: IQueryEnum.GetProductCategories}, {type: IQueryEnum.GetProductBrands}]);

  const mutation = makeMutation({
    type: IMutationEnum.CreateProduct,
    loadingMsg: 'Creating product...',
    successMsg: 'Product created.'
  });

  const onSave = (v: any) => {
    mutation.mutate({
      productInput: {
        ...v,
        images: carouselImg
      }
    });
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      if (mutation.data.id) {

        history.push(getUrl(pageUrls.productView, {productId: mutation.data.id}));
      }
    }
  }, [mutation, history]);

  /// carousel
  const onSlideChange = (currentSlide: number) => {
    setActiveSlider(currentSlide);
  };

  const goTo = (slideNumber: number) => {
    slider?.current?.goTo(slideNumber);
  };
  // const handleDelete = (index: number) => {
  //   const filteredArray = carouselImg.filter((item: string, i: number) => i !== index);
  //   setCarouselImg(filteredArray);
  // };

  //// uploader params
  const uploaderProps = {
    name: 'image',
    action: `${process.env.REACT_APP_API_URL}/files`,
    headers: {
      authorization: `Bearer ${token}`
    },
    onChange({file}: any) {
      if (file.status === 'error') {
        message.error(`${file.name} file upload failed.`);
      }
      if (file?.response?.fileUrl) {
        setPicUrl(file.response.fileUrl);
        const newArray = [...carouselImg, file.response.fileUrl];
        setCarouselImg(newArray);
      }
      if (file.status === 'removed') {
        const deleteImage = carouselImg.filter((img, index, arr) => index !== arr.indexOf(file?.response?.fileUrl));
        setPicUrl('');
        setCarouselImg(deleteImage);
      }
    },
    beforeUpload: (file: any) => {
      if (!IMAGE_TYPES.includes(file.type)) {
        message.error(`${file.name} is not a supported file`);
      }
      if (MAX_IMG_LENGTH - carouselImg.length === 0) {
        message.error('Max number of the pictures: 6');
      }
      return IMAGE_TYPES.includes(file.type) && MAX_IMG_LENGTH - carouselImg.length !== 0 ? true : Upload.LIST_IGNORE;
    }
  };

  //// drag n drop functions
  const dragStartHandler = (e: any, image: string) => {
    setCurrentPic(image);
  };
  const dragLeaveHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dragOverHandler = (e: any, image: any) => {
    e.preventDefault();
    e.stopPropagation();
    const curr = carouselImg.indexOf(image);
    const need = carouselImg.indexOf(currentPic);
    [carouselImg[need], carouselImg[curr]] = [carouselImg[curr], carouselImg[need]];
    setCarouselImg([...carouselImg]);
  };
  const dragEndHandler = (e: any) => {
    e.preventDefault();
  };
  const dragDropHandler = (e: any, image: string) => {
    e.preventDefault();
  };

  return (
    <>
      {' '}
      <Form form={form} name="product-create" onFinish={onSave} layout="vertical">
        <Card
          title="Product Management"
          extra={
            <Button type="primary" size="small" htmlType="submit" className="ml15">
              + Create
            </Button>
          }
        >
          <Row>
            <Col span={12} className="pr15 br">
              <Form.Item name="title" label="Title">
                <Input />
              </Form.Item>
              <Form.Item name="description" label="Description">
                <TextArea rows={8} />
              </Form.Item>
              <Row>
                <Col span={12} className="pr5">
                  <Form.Item name="productCategoryId" label="Select Category">
                    <Select className="w100" loading={isLoading} allowClear>
                      {productCategories?.map(cat => (
                        <Option key={cat.id} value={cat.id}>
                          {cat.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12} className="pl5">
                  <Form.Item name="productBrandId" label="Select Brand">
                    <Select className="w100" loading={isLoading} allowClear>
                      {productBrands?.map(brand => (
                        <Option key={brand.id} value={brand.id}>
                          {brand.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12} className="pl15">
              <Row>
                <Col span={14}>
                  <Carousel dots={false} ref={slider} afterChange={onSlideChange}>
                    {carouselImg.map((image: string) => (
                      <Image key={image} src={image} alt="product slider" className={'w100'} style={{objectFit: 'cover'}} />
                    ))}
                  </Carousel>
                </Col>
                <Col span={10} className="pl10 tac">
                  <Form.Item className="mb10" name="images" valuePropName="fileList" getValueFromEvent={normalizeFile}>
                    <Upload.Dragger multiple {...uploaderProps} maxCount={MAX_IMG_LENGTH - carouselImg?.length} name="files">
                      {picUrl ? (
                        <img src={picUrl} alt="product item" style={{maxWidth: '100%'}} />
                      ) : (
                        <>
                          <PictureOutlined style={{fontSize: '50px'}} />
                          <h3 className="text-md font-semibold ant-upload-text">"Drag & Drop"</h3>
                          <h5 className="ant-upload-text">or click here to upload</h5>
                          <h5 className="c-darkblue">Upload up to 6 pictures</h5>
                        </>
                      )}
                    </Upload.Dragger>
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle" className="mt10">
                <ColumnWidthOutlined className="mr10" style={{fontSize: 20}} />
                <h5 className="m0 f-light c-darkblue">Drag images to change their order</h5>
              </Row>
              <div className="mt15 d-flex w100">
                {carouselImg.map((image: string, index: number) => (
                  <Image
                    key={image}
                    onDragStart={e => dragStartHandler(e, image)}
                    onDragLeave={e => dragLeaveHandler(e)}
                    onDragOver={e => dragOverHandler(e, image)}
                    onDragEnd={e => dragEndHandler(e)}
                    onDrop={e => dragDropHandler(e, image)}
                    draggable
                    onClick={() => goTo(index)}
                    alt="product images list"
                    className={`${activeSlider === index && carouselImg?.length > 1 ? '' : 'p10'}`}
                    preview={false}
                    height={activeSlider === index && carouselImg?.length > 1 ? 70 : 60}
                    style={{objectFit: 'cover'}}
                    src={`${constants.image.resizer}?width=100&height=60&url=${Buffer.from(image).toString('base64')}`}
                    fallback={constants.image.fallback}
                  />
                ))}
              </div>
            </Col>
          </Row>
        </Card>
      </Form>
    </>
  );
};

export default ProductCreate;
