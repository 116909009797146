import {IAsset, IAssetAttachment, IAssets, IBrandfolderAsset} from './types';

function getAssetSize(sizeInBytes: number) {
  const sizeInKb = sizeInBytes / 1000;

  if (sizeInKb < 1000) return {value: sizeInKb, unit: 'Kb'};
  return {value: sizeInKb / 1000, unit: 'Mb'};
}

export function convertAssets({data, included, meta}: any): IAssets {
  const assets: IAsset[] = data.map((defaultAsset: IBrandfolderAsset) => convertAsset({data: defaultAsset, included}));
  return {assets, totalItems: meta.total_count};
}

export function convertAsset({data: defaultAsset, included}: any): IAsset {
  const attachments = defaultAsset.relationships.attachments.data.map((a: any) => {
    const attachment = included.find((i: any) => a.id === i.id);
    const finalAttachment: IAssetAttachment = {
      id: attachment.id,
      url: attachment.attributes.url,
      name: attachment.attributes.filename,
      extension: attachment.attributes.extension,
      mimetype: attachment.attributes.mimetype,
      size: getAssetSize(attachment.attributes.size),
      width: attachment.attributes.width,
      height: attachment.attributes.height,
      thumbnail: attachment.attributes.thumbnail_url
    };

    return finalAttachment;
  });

  const brand = defaultAsset.relationships.custom_field_values.data
    .map((v: any) => {
      const brand = included.find((i: any) => i.id === v.id && i.attributes?.key === 'Brands');
      return brand?.attributes?.value;
    })
    .filter(Boolean)[0];

  const asset: IAsset = {
    id: defaultAsset.id,
    name: defaultAsset.attributes.name,
    thumbnailUrl: defaultAsset.attributes.thumbnail_url,
    url: defaultAsset.attributes.cdn_url,
    size: attachments[0]?.size,
    dimensions: `${attachments[0]?.width} X ${attachments[0]?.height}`,
    extension: attachments[0]?.extension,
    type: getAssetType(attachments[0]?.extension),
    attachments: attachments,
    brand,
    updatedAt: defaultAsset.attributes.updated_at
  };
  return asset;
}

function getAssetType(extension: string) {
  switch (extension) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'svg':
    case 'tif':
      return 'Image';
    case 'mp4':
    case 'mov':
    case 'avi':
    case 'wmv':
    case 'flv':
    case 'm4v':
      return 'Video';
    case 'pdf':
      return 'Document';
    case 'woff2':
    case 'woff':
    case 'eot':
    case 'ttf':
    case 'otf':
      return 'Font';
    default:
      return 'Other';
  }
}

export type AssetExtension = 'tif' | 'jpg' | 'png' | 'pdf' | 'mp4' | 'jpeg' | 'svg' | 'mov' | 'woff2';
export type AssetType = 'Image' | 'Video' | 'Document' | 'Font';

export interface IAssetFilter {
  name?: string;
  section?: string;
  brands?: string[];
  extensions?: string[];
  types?: string[];
  categories?: string[];
}

/** Converts filter to brandfolder query string */
export function createSearchString(filter: IAssetFilter, allowedBrands?: string[]) {
  let searchStrings = [];

  if (filter.name) {
    searchStrings.push(`name:${filter.name}*`);
  }

  if (filter.section) {
    searchStrings.push(`section:${filter.section}`);
  }

  if (filter.brands && filter.brands.length > 0) {
    searchStrings.push(`custom_fields.Brands:(${filter.brands.join(' OR ')})`);
  } else {
    if (allowedBrands && allowedBrands.length > 0) {
      searchStrings.push(`custom_fields.Brands:(${allowedBrands.join(' OR ')})`);
    }
  }

  if (filter?.categories && filter.categories.length > 0) {
    searchStrings.push(`tags:(${getCategories(filter.categories).join(' OR ')})`);
  }

  if (filter.extensions && filter.extensions.length > 0) {
    searchStrings.push(`extensions:(${filter.extensions.join(' OR ')})`);
  }

  if (filter.types && filter.types.length > 0) {
    let extensions: AssetExtension[] = [];

    if (filter.types.includes('Image')) extensions = [...extensions, 'tif', 'jpg', 'png', 'jpeg', 'svg'];
    if (filter.types.includes('Video')) extensions = [...extensions, 'mp4', 'mov'];
    if (filter.types.includes('Document')) extensions = [...extensions, 'pdf'];
    if (filter.types.includes('Font')) extensions = [...extensions, 'woff2'];

    searchStrings.push(`extensions:(${extensions.join(' OR ')})`);
  }

  return searchStrings.join(' AND ');
}

function getCategories(categories: any) {
  if (categories.includes('Bed Frame')) {
    return categories.concat('Foundation');
  }
  return categories;
}
