import {Card, Tabs} from 'antd';
import {PageWrapper} from 'components/PageWrapper';
import {IQueryEnum, makeQueries} from 'core/api';
import {IGroup} from 'core/models/groups/types';
import {IPriceList} from 'core/models/priceLists/types';
import {getRetailer} from 'core/models/retailers/utils';
import {IShippingRule} from 'core/models/shippingRules/types';
import ShippingRuleList from './components/ShippingRuleList';

const {TabPane} = Tabs;
interface IData {
  shippingRules: IShippingRule[];
  groups: IGroup[];
  priceLists: IPriceList[];
}

export enum IMethodEnum {
  ProductBrand = 'ProductBrand',
  ProductCategory = 'ProductCategory',
  PriceList = 'PriceList',
  Supplier = 'Supplier'
}

function RetailerShippingProfile() {
  const {retailer} = getRetailer();
  const {
    data: {shippingRules = [], groups = []},
    isLoading
  } = makeQueries<IData>([{type: IQueryEnum.GetShippingRules}, {type: IQueryEnum.GetGroups}, {type: IQueryEnum.GetPriceLists}]);

  const variants = [
    {tabName: 'Floor Models', orderType: 'Floor Models'},
    {tabName: 'Order', orderType: 'Order'},
    {tabName: 'Direct Ship Orders', orderType: 'Dropshipment'}
  ];

  return (
    <PageWrapper withRetailerSelector pageTitle={retailer?.name} topMenuType='retailers'>
      <Card loading={isLoading}>
        <Tabs tabPosition={'left'}>
          {variants.map(({tabName, orderType}, index) => (
            <TabPane tab={tabName} key={index + 1}>
              <ShippingRuleList name={orderType} groups={groups} shippingRules={shippingRules} />
            </TabPane>
          ))}
        </Tabs>
      </Card>
    </PageWrapper>
  );
}

export default RetailerShippingProfile;
