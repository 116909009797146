import AuthStore from 'core/auth';
import {getRetailer, getRetailerId} from 'core/models/retailers/utils';
import {IUser} from 'core/models/users/types';
import { userRoleRenderer } from 'core/models/users/utils';
import {pageUrls} from 'core/router/pages';
import moment from 'moment';
import {useContext} from 'react';
import {Link} from 'react-router-dom';
import {getUrl} from 'utils/getUrl';
import {unixToFromNow} from 'utils/unixToFromNow';

interface IUserInfoProps {
  profile?: IUser;
  team?: IUser[];
}

export default function UserInfo({profile, team}: IUserInfoProps) {
  const retailerId = getRetailerId();
  const {retailer} = getRetailer();
  const {isRetailer} = useContext(AuthStore);

  const acceptedTermsAt: any = Number(profile?.acceptedTermsAt) || localStorage.getItem('acceptedTermsAt');

  return (
    <>
      <div className="h-full flex w-full max-w-2xl border-r border-gray-200">
        <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
          <div className="flex-1 relative z-0 flex overflow-hidden">
            <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last">
              {profile && (
                <article>
                  {/* Profile header */}
                  <div>
                    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                      <div className="sm:flex sm:items-start sm:space-x-5">
                        <div className="flex">
                          <img
                            className="h-24 w-24 rounded-full ring-4 ring-cyan-100 sm:h-32 sm:w-32"
                            src={profile.photoUrl || 'https://resident-wholesale-portal.s3.amazonaws.com/profile-placeholder.png'}
                            alt=""
                          />
                        </div>

                        <div className="sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                          <div className="mt-6 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                            <p className="mb-6 text-md">
                              {!isRetailer &&
                                (profile.lastSeen ? (
                                  <span className="mr-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Last seen {unixToFromNow(profile.lastSeen)}
                                  </span>
                                ) : (
                                  <span className="mr-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    Last seen Unknown/Not Activated
                                  </span>
                                ))}
                              {userRoleRenderer(profile?.role)}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="sm:block mt-6 min-w-0 flex-1">
                        <h1 className="text-2xl font-bold text-gray-900 truncate">
                          {profile.firstName} {profile.lastName}
                        </h1>
                        <p className="text-md font-semibold text-gray-500 truncate">{profile.email}</p>
                        <p className="text-md font-semibold text-gray-700 truncate">{retailer?.name}</p>
                      </div>
                    </div>
                  </div>

                  {/* Profile description */}
                  <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Okta Id</dt>
                        <dd className="mt-1 text-sm text-gray-900">{profile.oktaId}</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">T&C Accepted</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {acceptedTermsAt ? (
                            moment(acceptedTermsAt).format('DD MMMM YYYY')
                          ) : (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                              Not Accepted
                            </span>
                          )}
                        </dd>
                      </div>
                    </dl>
                  </div>

                  {/* Team member list */}
                  <div className="mt-8 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                    <h2 className="text-sm font-medium text-gray-500">Team members</h2>
                    <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
                      {team?.map(person => (
                        <div
                          key={person.id}
                          className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-cyan-500"
                        >
                          <div className="flex-shrink-0">
                            <img
                              className="h-10 w-10 rounded-full"
                              src={person.photoUrl || 'https://resident-wholesale-portal.s3.amazonaws.com/profile-placeholder.png'}
                              alt=""
                            />
                          </div>
                          <div className="flex-1 min-w-0">
                            <Link to={getUrl(pageUrls.retailerUserView, {retailerId, userId: person.id})}>
                              <span className="absolute inset-0" aria-hidden="true" />
                              <p className="text-sm font-medium text-gray-900">
                                {person.firstName} {person.lastName}
                              </p>
                              <p className="text-sm text-gray-500 truncate">{person.email}</p>
                              <p className="text-sm text-gray-500 truncate">{person.role}</p>
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </article>
              )}
            </main>
          </div>
        </div>
      </div>
    </>
  );
}
