import {DollarCircleOutlined} from '@ant-design/icons';
import {Button, Card, Divider, Drawer, Image, Row, Typography} from 'antd';
import {N} from 'components/N';
import {IQueryEnum, makeQueries} from 'core/api';
import {IProductItem} from 'core/models/productItems/types';
import {IProduct} from 'core/models/products/types';
import {getRetailer} from 'core/models/retailers/utils';
import {FC, memo, useState} from 'react';
import imgPlaceHolder from '../../../../assets/placeholder-image.png';
import constants from '../../../../constants';
import {Buffer} from 'buffer';
import './style.less';

const {Paragraph} = Typography;
export interface IProductCard {
  product: IProduct;
}

interface IData {
  productItems?: Map<string, IProductItem>;
}

const ProductList: FC<IProductCard> = memo(({product}) => {
  const [activeImage, setActiveImage] = useState(product?.images?.[0]);
  const [isPricingDrawerOpen, setIsPricingDrawerOpen] = useState(false);
  const {retailer} = getRetailer();
  const {
    data: {productItems}
  } = makeQueries<IData>([
    {type: IQueryEnum.GetProductItems, mapKey: 'id', variables: {retailerId: retailer?.id}, isEnabled: Boolean(retailer)}
  ]);

  const allowedProductItemIds = product?.productItemIds?.filter(id => {
    const productItem = productItems?.get(id);
    return retailer?.groupIds.includes(productItem?.productItemGroupId!);
  });

  function openDrawer() {
    setIsPricingDrawerOpen(true);
  }

  function closeDrawer() {
    setIsPricingDrawerOpen(false);
  }

  return (
    <div className="product-list-item-card-container">
      <Card
        hoverable
        style={{height: '100%'}}
        className="product-list-item-card"
        actions={[
          <div onClick={openDrawer}>
            <DollarCircleOutlined className="mr5" /> View Prices
          </div>
        ]}
        cover={
          <Image
            alt="example"
            height={300}
            preview={activeImage ? {src: activeImage} : false}
            style={{objectFit: 'cover', padding: 1}}
            src={
              activeImage
                ? `${constants.image.resizer}?width=500&height=300&url=${Buffer.from(activeImage).toString('base64')}`
                : imgPlaceHolder
            }
            fallback={imgPlaceHolder}
          />
        }
      >
        <div>
          <h3 className="text-md font-semibold mb-1">{product.title}</h3>
          <h4 className="mb-1">
            <Paragraph className="c-darkblue f-light" ellipsis={{rows: 2, tooltip: product.description}}>
              {product.description}
            </Paragraph>
          </h4>
          <div className="bg-white w100 mb10">
            {product.images?.map(image => (
              <Image
                key={image}
                alt="example"
                className={image === activeImage ? '' : 'p5'}
                onClick={() => setActiveImage(image)}
                preview={false}
                height={image === activeImage ? 40 : 35}
                style={{objectFit: 'cover'}}
                src={`${constants.image.resizer}?width=50&height=30&url=${Buffer.from(image).toString('base64')}`}
                fallback={imgPlaceHolder}
              />
            ))}
          </div>
          {isPricingDrawerOpen && (
            <Drawer
              key={product.id}
              title={
                <div className="w90">
                  <h3 className="text-md font-semibold">{product.title}</h3>
                </div>
              }
              placement="right"
              onClose={closeDrawer}
              visible={true}
              getContainer={false}
              className="pricing-drawer w100"
            >
              <div>
                {allowedProductItemIds?.map(productItemId => {
                  const productItem = productItems?.get(productItemId);

                  return (
                    <div key={productItemId}>
                      <Row justify="space-between">
                        <h4 className="mb0">
                          <span className="f-light">{productItem?.title}</span>
                          <p className="f-light c-darkblue mb0">{productItem?.sku}</p>
                        </h4>
                        <strong>
                          <N type="price" value={productItem?.price} />
                        </strong>
                      </Row>
                      <Divider className="mt5 mb5" />
                    </div>
                  );
                })}
              </div>
              <div className="expander" />
              <Button className="w100 mt15" onClick={closeDrawer} type="primary">
                Hide Prices
              </Button>
            </Drawer>
          )}
        </div>
      </Card>
    </div>
  );
});

export default ProductList;
