import {UserOutlined} from '@ant-design/icons';
import {Avatar, Col, Row, Layout, Dropdown, Menu, Image} from 'antd';
import React, {useContext} from 'react';
import {Icon, IconColorEnum, IconTypeEnum} from '../../../components/Icon';
import AuthStore from '../../../core/auth';
import Notification from './Notification';
import {useHistory} from 'react-router-dom';
import {pageUrls} from 'core/router/pages';
import './styles.less';
import {getRetailer} from 'core/models/retailers/utils';

const {Header: AntdHeader} = Layout;

const Header = React.memo(() => {
  const {logout, userProfile, isManager} = useContext(AuthStore);
  const {retailer} = getRetailer();
  const history = useHistory();

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => history.push(pageUrls.profile)}>
        <Row>
          <UserOutlined style={{fontSize: 18}} className="mr10" />
          Profile
        </Row>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2" onClick={logout}>
        <Row>
          <Icon className="mr10" color={IconColorEnum.Black} type={IconTypeEnum.Exit} />
          Logout
        </Row>
      </Menu.Item>
    </Menu>
  );

  return (
    <AntdHeader>
      <Row justify="space-between" align="middle">
        <Col span={6} className="header-company-icon">
          <Icon type={IconTypeEnum.Logo} onClick={() => history.push('/')} />
        </Col>
        <Col span={12}>
          <Row align="middle" justify="center">
            {retailer?.logoUrl && <Image src={retailer.logoUrl} height="36px" preview={false} />}
          </Row>
        </Col>
        <Col span={6}>
          <Row justify="end" align="middle" className="header-action-icon">
            {userProfile && !isManager && <Notification />}
            <Dropdown overlay={menu} trigger={['hover']}>
              <div>
                <Avatar
                  className="ml15"
                  icon={<UserOutlined />}
                  src={userProfile?.photoUrl && <img src={userProfile?.photoUrl} alt="User's avatar" />}
                />
              </div>
            </Dropdown>
          </Row>
        </Col>
      </Row>
    </AntdHeader>
  );
});

export default Header;
