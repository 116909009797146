import {List} from 'antd';
import {memo, FC, useState} from 'react';
import ProductListItem from './ProductListItem';
import ProductModal from './ProductModal';
import {IProductItem} from 'core/models/productItems/types';
import {IProduct} from 'core/models/products/types';
import {OrderStore} from 'core/stores/OrderStore';
import {getRetailer} from 'core/models/retailers/utils';
import {IQueryEnum, makeQueries} from 'core/api';
import { IProductBrand } from 'core/models/productBrands/types';
import { IProductCategory } from 'core/models/productCategories/types';
import { IGroup } from 'core/models/groups/types';
import { IPromoBuilder } from 'core/models/promoBuilder/types';
export interface IProductList {
  products: IProduct[];
  productItems: Map<string, IProductItem>;
  orderType: string;
}


interface IData {
  productBrands: Map<string, IProductBrand>;
  productCategories: Map<string, IProductCategory>;
  groups: Map<string, IGroup>;
  activePromo: IPromoBuilder;
}

const ProductList: FC<IProductList> = memo(({products, productItems, orderType}) => {
  const {retailer} = getRetailer();

  const addOrderItem = OrderStore(({addOrderItem}) => addOrderItem);
  const [selectedProduct, setSelectedProduct] = useState<{product: IProduct; productItem: IProductItem}>();

  function resetProduct() {
    setSelectedProduct(undefined);
  }

  const {
    data: {productBrands, productCategories, activePromo, groups}
  } = makeQueries<IData>([
    {type: IQueryEnum.GetProductBrands, mapKey: 'id'},
    {type: IQueryEnum.GetProductCategories, mapKey: 'id'},
    {type: IQueryEnum.GetActivePromo},
    {type: IQueryEnum.GetGroups, mapKey: 'id'}
  ]);

  function handleAddProduct(product: IProduct, productItem: IProductItem) {
    addOrderItem(product, productItem, activePromo, groups);
    if (selectedProduct) resetProduct();
  }

  const getProductBrandName = (id = selectedProduct?.product.productBrandId) => productBrands.get(id!)?.title;
  const getProductCategoryName = (id = selectedProduct?.product.productCategoryId) => productCategories.get(id!)?.title;

  const allowedProducts = products.filter(product => retailer?.productIds.includes(product.id));

  return (
    <>
      <ProductModal
        product={selectedProduct?.product}
        defaultProductItem={selectedProduct?.productItem}
        resetProduct={resetProduct}
        onAdd={handleAddProduct}
        productItems={productItems}
        productBrand={getProductBrandName()!}
        productCategory={getProductCategoryName()!}
      />
      <List
        size="small"
        dataSource={allowedProducts}
        renderItem={item => (
          <ProductListItem
            key={item.id}
            orderType={orderType}
            product={item}
            productItems={productItems}
            onProductDetailClick={setSelectedProduct}
            onAdd={handleAddProduct}
            productBrand={getProductBrandName(item.productBrandId)!}
            productCategory={getProductCategoryName(item.productCategoryId)!}
            activePromo={activePromo}
            groups={groups}
          />
        )}
      />
    </>
  );
});

export default ProductList;
