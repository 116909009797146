import AntdIcon from '@ant-design/icons';
import React, {lazy} from 'react';
import {Suspense} from 'react';
import {IconColorEnum, IconTypeEnum} from './IconEnums';

export interface IIcon {
  /** If children passed, loader will be overlay only child component */
  type?: IconTypeEnum;
  /** Icon color (default: 'white') */
  color?: IconColorEnum;
  /** Action on icon click */
  onClick?: () => any;
  /** Antd Icon className */
  className?: string;
}

/** Icon component */
const Icon: React.FC<IIcon> = React.memo(({type, color, onClick, className}) => {
  // Conditional import to exclude importing all icons in single file
  const icon = lazy(() => import(`./icons/${type}`));

  return (
    <Suspense fallback={<div style={{width: 25, height: 25}} />}>
      <AntdIcon component={icon} onClick={onClick} className={className} style={{fill: color || 'white'}} />
    </Suspense>
  );
});

export default Icon;
