import {LoginOutlined} from '@ant-design/icons';
import {IconColorEnum} from 'components/Icon';
import {N} from 'components/N';
import {Table} from 'components/Table';
import {IQueryEnum, makeQueries} from 'core/api';
import {IRetailer} from 'core/models/retailers/types';
import {getAvailablePercent, getAvailablePercentColor} from 'core/models/retailers/utils';
import AuthStore from 'core/auth';
import {useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {getUrl} from 'utils/getUrl';
import {pageUrls} from 'core/router/pages';
import {IColumn} from 'components/Table/Table';

export interface IRetailersTableProps {
  redirectLink: string;
}

const RetailersTable: React.FC<IRetailersTableProps> = ({redirectLink}) => {
  const history = useHistory();
  const {loginAsRetailer} = useContext(AuthStore);

  const {
    data: {retailers},
    isLoading
  } = makeQueries<any>([{type: IQueryEnum.GetRetailers}]);

  const onRetailerClick = (retailer: IRetailer) => () => {
    history.push(getUrl(redirectLink, {retailerId: retailer.id}));
  };

  const handleLoginAsRetailer = (retailer: IRetailer) => () => {
    loginAsRetailer(retailer.id);
    history.push(pageUrls.dashboard);
  };

  const columns: IColumn<IRetailer>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '60%',
      render: (_, row) => (
        <h4 className="cursor-pointer" onClick={onRetailerClick(row)}>
          {row.name}
        </h4>
      )
    },
    {
      title: 'Account balance',
      render: (_, row) => (
        <h4>
          <N value={row.creditAvailable} type="price" />
          <span className={getAvailablePercentColor(row)} style={{marginLeft: 5}}>
            (<N value={getAvailablePercent(row)} type="percent" />)
          </span>
        </h4>
      ),
      width: '15%'
    },
    {
      title: 'Stores count',
      dataIndex: 'storesCount',
      sorter: {
        compare: (a, b) => b.storesCount - a.storesCount
      },
      render: (storesCount: number) => <N value={storesCount} />,
      width: '15%'
    },
    {
      title: 'Total sales',
      dataIndex: 'totalSalesAmount',
      sorter: {
        compare: (a, b) => b.totalSalesAmount - a.totalSalesAmount
      },
      defaultSortOrder: 'ascend',
      render: (totalSalesAmount: number) => <N value={totalSalesAmount} type="price" />,
      width: '15%'
    },
    {
      title: 'Impersonate',
      render: (_, row) => <LoginOutlined onClick={handleLoginAsRetailer(row)} style={{fontSize: 20, color: IconColorEnum.Black}} />,
      align: 'center',
      width: '10%'
    }
  ];

  return (
    <Table
      data={retailers}
      filterConfig={{fulltextPlaceholder: '"Search for a retailer', fulltextParams: ['name', 'email']}}
      columns={columns}
      loading={isLoading}
    />
  );
};

export default RetailersTable;
