/* eslint-disable react-hooks/rules-of-hooks */
import {message} from 'antd';
import {useMutation, useQueryClient} from 'react-query';
import {makeGQLRequest} from '../client';

import uuid from 'react-uuid';
import {IMutation} from './types';
import {
  getMutationDef,
  onBulkCreateOrUpdateMutate,
  onCreateOrUpdateBulkError,
  onCreateOrUpdateError,
  onCreateOrUpdateMutate,
  onDeleteError,
  onDeleteMutate
} from './utils';

function makeMutation({type, keyVars, successMsg, loadingMsg}: IMutation) {
  const queryClient = useQueryClient();

  const mutationDef = getMutationDef(type);

  const messageKey = uuid();

  return useMutation<any, any, any, any>(
    async variables => {
      const data = await makeGQLRequest(type, mutationDef.gql, variables);
      return data[type];
    },
    {
      onMutate: (variables: any) => {
        /** Extract specific object name if needed */
        const inputVars = mutationDef.inputKey ? variables[mutationDef.inputKey] : variables;

        if (loadingMsg) message.loading({content: loadingMsg, key: messageKey, duration: 0});

        if (mutationDef.type === 'simple') return;

        if (mutationDef.type === 'delete') {
          return onDeleteMutate(queryClient, inputVars, mutationDef.model, keyVars);
        }

        return mutationDef.isBulk
          ? onBulkCreateOrUpdateMutate(queryClient, inputVars, mutationDef.model, keyVars)
          : onCreateOrUpdateMutate(queryClient, inputVars, mutationDef.model, keyVars);
      },
      onSuccess: () => {
        if (successMsg) message.success({content: successMsg, key: messageKey});
        queryClient.invalidateQueries(keyVars ? [mutationDef.model, keyVars] : [mutationDef.model]);
      },
      onError: (error, _, context) => {
        message.error({content: `Error: Mutation ${type} cannot be completed`, key: messageKey});
        console.error(error);

        if (mutationDef.type === 'simple') return;

        if (mutationDef.type === 'delete') {
          return onDeleteError(queryClient, context, mutationDef.model, keyVars);
        }

        return mutationDef.isBulk
          ? onCreateOrUpdateBulkError(queryClient, context, mutationDef.model, keyVars)
          : onCreateOrUpdateError(queryClient, context, mutationDef.model, keyVars);
      }
    }
  );
}

export default makeMutation;
