import {Button, Col, Form, Input, Row, Select} from 'antd';
import {IMutationEnum, IQueryEnum, makeMutation, makeQueries} from 'core/api';
import { IAddress } from 'core/models/addresses/types';
import {getRetailerId} from 'core/models/retailers/utils';
import {IState} from 'core/models/states/types';
import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
const {Option} = Select;

interface IData {
  states: IState[];
}

interface IProps {
  onClick: (address: IAddress) => void;
}

const OrderCreateAddress: React.FC<IProps> = ({onClick}) => {
  const [form] = Form.useForm();
  const history = useHistory();

  const retailerId = getRetailerId();

  const {
    data: {states}
  } = makeQueries<IData>([{type: IQueryEnum.GetStates}]);

  const mutation = makeMutation({
    type: IMutationEnum.CreateAddress,
    keyVars: {retailerId},
    loadingMsg: 'Creating address...',
    successMsg: 'Address successfully Created!'
  });

  useEffect(() => {
    if (mutation.isSuccess) {
      onClick(mutation.data);
    }
  }, [mutation, retailerId, history, onClick]);

  const onSaveClick = (data: any) => {
    mutation.mutate({
      addressInput: {
        retailerId,
        ...data
      }
    });
  };

  return (
      <Form layout="vertical" form={form} name="create-user" onFinish={onSaveClick}>
        <Form.Item name="name" label="Name" rules={[{required: true}]}>
          <Input />
        </Form.Item>
        <Form.Item name="addressLine1" label="Address 1" rules={[{required: true}]}>
          <Input />
        </Form.Item>
        <Form.Item name="addressLine2" label="Address 2">
          <Input />
        </Form.Item>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name={'stateId'} className="mb0" label="State" rules={[{required: true}]}>
              <Select>
                {states.map(state => (
                  <Option key={state.id} name={state.name} value={state.id}>
                    {state.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="city" label="City" rules={[{required: true}]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="zip" label="Zip" rules={[{required: true}]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="phone" label="Phone" rules={[{required: true}]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="email" label="Email">
          <Input />
        </Form.Item>
        <Form.Item className="tar pt15">
          <Button type="primary" htmlType="submit">
            Create Customer Address
          </Button>
        </Form.Item>
      </Form>
  );
};

export default OrderCreateAddress;
