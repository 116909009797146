export enum InvoiceStateEnum {
  Posted = 'posted',
  Paid = 'paid'
}

export interface IInvoice {
  id: string;
  retailerId: string;
  storeId: string;
  orderId: string;
  createDate: Date;
  number: string;
  totalAmount: number;
  state: string;
}
