import {gql} from 'graphql-tag';

export const GET_PRICE_LISTS = gql`
  query getPriceLists {
    getPriceLists {
      id
      title
      order
    }
  }
`;