import {Button, Card, DatePicker, Form, Input, Radio} from 'antd';
import {ReturnStore} from 'core/stores/ReturnStore';
import {memo, useState} from 'react';
import {IReturnsCreateStep} from '../ReturnCreate';
import {getRetailerId} from 'core/models/retailers/utils';
import {ReturnIssueTypeEnum} from 'core/models/returns/types';
import moment from 'moment';

const ReturnsStep1: React.FC<IReturnsCreateStep> = memo(({nextStep, prevStep, formLayout}) => {
  const [form] = Form.useForm();
  const retailerId = getRetailerId();
  const {updateReturn, returnItem} = ReturnStore.getState();
  const [damaged, setDamaged] = useState(returnItem.issueType === ReturnIssueTypeEnum.Damaged);

  function onSubmit(values: any) {
    const {issueType, name, receiptNumber, purchaseDate, orderReference, reference, orderSO} = values;

    if (damaged) {
      updateReturn({issueType, retailerId, orderReference, reference, orderSO, customer: {purchaseDate: purchaseDate.format('MM/DD/YYYY')}});
    } else {
      const customer = {
        name,
        receiptNumber,
        purchaseDate: purchaseDate.format('MM/DD/YYYY')
      };

      updateReturn({customer, issueType, retailerId, reference, orderSO});
    }

    nextStep();
  }

  function handleIssueTypeChange({target: {value}}: any) {
    setDamaged(value === 'Damaged');
  }

  return (
    <Card>
      <Form
        {...formLayout}
        layout="vertical"
        form={form}
        name="create-return-step-2"
        onFinish={onSubmit}
        initialValues={{
          issueType: returnItem?.issueType,
          name: returnItem?.customer?.name,
          receiptNumber: returnItem.customer?.receiptNumber,
          purchaseDate: returnItem.customer ? moment(returnItem.customer.purchaseDate) : null,
          orderReference: returnItem?.orderReference,
          reference: returnItem?.reference,
          orderSO: returnItem?.orderSO
        }}
      >
        <Form.Item name="issueType" label="Issue Type" rules={[{required: true}]}>
          <Radio.Group onChange={handleIssueTypeChange}>
            <Radio value={'Comfort'}>Comfort exchange</Radio>
            <Radio value={'Warranty'}>Warranty</Radio>
            <Radio value={'Damaged'}>Damaged Upon Arrival</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="orderSO" label="Order #SO" rules={[{required: true}]}>
          <Input />
        </Form.Item>
        <Form.Item name="reference" label="Unique reference" rules={[{required: true}]} tooltip={'Unique "Request for credit" identifier in free form'}>
          <Input />
        </Form.Item>
        {!damaged && (
          <>
            <Form.Item name="name" label="Customer Name" rules={[{required: true}]}>
              <Input />
            </Form.Item>
            <Form.Item name="receiptNumber" label="Receipt Number" rules={[{required: true}]}>
              <Input />
            </Form.Item>
            <Form.Item name="purchaseDate" label="Customer Purchase Date" rules={[{required: true}]}>
              <DatePicker format="MM/DD/YYYY" />
            </Form.Item>
          </>
        )}
        {damaged && (
          <>
            <Form.Item name="orderReference" label="Order Reference (PO#)" rules={[{required: true}]}>
              <Input />
            </Form.Item>
            <Form.Item name="purchaseDate" label="Date Shipment Received" rules={[{required: true}]}>
              <DatePicker format="MM/DD/YYYY" />
            </Form.Item>
          </>
        )}
        <Form.Item wrapperCol={{span: 14}} className="tar">
          <Button htmlType="button" onClick={prevStep} className="mr10">
            Back
          </Button>
          <Button htmlType="submit" type="primary">
            Next
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
});

export default ReturnsStep1;
