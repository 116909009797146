/* eslint-disable react-hooks/rules-of-hooks */
import {message} from 'antd';
import AuthStore from 'core/auth';
import {useContext} from 'react';
import {useQueries} from 'react-query';
import {makeGQLRequest} from '../client';
import {IQuery, IMakeQueries} from './types';
import {getModel, getQueryGQL} from './utils';

function makeQueries<DataObject>(queries: IQuery[]): IMakeQueries<DataObject> {
  const {logout} = useContext(AuthStore);

  const results = useQueries(
    queries.map(({type, variables, isEnabled, pollingInterval}) => ({
      queryKey: variables ? [getModel(type), variables] : [getModel(type)],
      queryFn: async (): Promise<string> => {
        const data = await makeGQLRequest(type, getQueryGQL(type), variables);
        return data?.[type];
      },
      refetchInterval: pollingInterval,
      onError: (error: any) => {
        if (error?.response?.errors[0]?.message === 'auth-failed') {
          logout();
        } else {
          message.error({content: `Error: Query "${type}" can't be loaded.`, key: type});
          console.error(error);
        }
      },
      enabled: isEnabled === false ? isEnabled : true
    }))
  );

  const data: any = {};

  results.forEach((result: any, i) => {
    const query = queries[i];
    if (result.data) {
      if (query.mapKey) {
        data[getModel(query.type)] = new Map(result.data.map((item: any) => [item?.[query.mapKey!], item]));
      } else {
        data[getModel(query.type)] = result.data;
      }
    } else {
      return [];
    }
  });

  return {
    data,
    isLoading: results.some(r => r.isLoading)
  };
}

export default makeQueries;
