import {PlusOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import {PageWrapper} from 'components/PageWrapper';
import {Table} from 'components/Table';
import {IColumn} from 'components/Table/Table';
import {IQueryEnum, makeQueries} from 'core/api';
import {IProductItem} from 'core/models/productItems/types';
import {IProduct} from 'core/models/products/types';
import {getRetailer} from 'core/models/retailers/utils';
import {IReturn} from 'core/models/returns/types';
import {productItemRenderer, ReturnIssueTypeRenderer, ReturnStateRenderer} from 'core/models/returns/utils';
import {pageUrls} from 'core/router/pages';
import moment from 'moment';
import {useEffect} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
import {dataLayerEvents} from 'utils/dataLayer';
import {getUrl} from 'utils/getUrl';

interface IData {
  returns: IReturn[];
  products: Map<string, IProduct>;
  productItems: Map<string, IProductItem>;
}
function ReturnList() {
  const params = useParams();
  const {retailer, isLoading: isRetailerLoading} = getRetailer();
  const history = useHistory();


  useEffect(() => {
    if (!isRetailerLoading && retailer) {
      dataLayerEvents('view_returns_manage', retailer);
    }
  }, [retailer, isRetailerLoading]);

  const {
    data: {returns, products, productItems},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetReturns, variables: {retailerId: retailer?.id}},
    {type: IQueryEnum.GetProducts, mapKey: 'id'},
    {type: IQueryEnum.GetAllProductItems, isEnabled: Boolean(retailer), variables: {retailerId: retailer!.id}, mapKey: 'id'}
  ]);

  const columns: IColumn<IReturn>[] = [
    {
      title: 'Created At',
      width: '10%',
      render: (_, row) => moment(Number(row.createdAt)).format('MM/DD/YYYY')
    },
    {
      title: 'Reference',
      dataIndex: 'reference',
      width: '10%'
    },
    {
      title: 'State',
      dataIndex: 'state',
      width: '10%',
      render: ReturnStateRenderer
    },
    {
      title: 'Issue Type',
      dataIndex: 'issueType',
      width: '10%',
      render: ReturnIssueTypeRenderer
    },
    {
      title: 'Product',
      width: '45%',
      render: (_, row) => {
        const productItem = productItems?.get(row?.productItemId!);
        const product = products?.get(productItem?.productId!);
        console.log(row?.productItemId!, productItem)
        return (
          <div>
            <span className="pr5">{productItemRenderer(product?.title)}</span>
            <span>{productItemRenderer(productItem?.title)}</span>
          </div>
        );
      }
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      width: '15%'
    }
  ];

  const onRowClick = ({id}: any): void => {
    history.push(getUrl(pageUrls.returnView, {retailerId: retailer?.id, returnId: id}));
  };

  const headerActions = (text = 'Create') => (
    <Link to={getUrl(pageUrls.returnCreate, params)}>
      <Button type="primary" icon={<PlusOutlined />}>
        {text}
      </Button>
    </Link>
  );

  return (
    <PageWrapper pageTitle="Your requests for a credit" withRetailerSelector actions={headerActions()}>
      <Table
        // filterConfig={{fulltextParams: ['firstName', 'lastName', 'email'], fulltextPlaceholder: 'Search for a return'}}
        data={returns}
        columns={columns}
        onRowClick={onRowClick}
        loading={isLoading}
        empty={<div className="p25">{headerActions('Submit Credit Request')}</div>}
      />
    </PageWrapper>
  );
}

export default ReturnList;
