import moment from 'moment-timezone';
import { IPromoBuilder } from 'core/models/promoBuilder/types';
import { IGroup } from 'core/models/groups/types';

const getPromoData = (price: number, unit: string, discount: any, limit?: any, limitVal?: any) => {
    let data: any = {};
    if (unit === 'USD') {
        data.price = price - discount;
    } else {
        data.price = price - ((price / 100) * discount);
    }
    if (limit) data[`${limit}`] = limitVal;

    return data;
}

export const getPromoDiscount = (productId: string, productItemId: string, productItemPrice: number, activePromo: IPromoBuilder, groups: Map<string, IGroup>) => {
    if (!activePromo) return false;
    const localTimeToNY = moment.tz(moment(), "America/New_York");
    const dateInFuture = moment.tz(activePromo?.expDate, "America/New_York").diff(localTimeToNY, 'minutes');
    if (dateInFuture && dateInFuture < 0) return false;
    if (!activePromo?.currentItemsAvailable) {
        const group = groups?.get(activePromo.groupId);
        if (group?.productIds?.includes(productId) || group?.productItemIds?.includes(productItemId)) {
            return getPromoData(productItemPrice, activePromo?.unit, activePromo?.discount);
        }
    } else {
        const currentItemsAvailable = Array.from(Object.keys(activePromo?.currentItemsAvailable));
        const findProduct = currentItemsAvailable?.includes(productId);
        const productlimit = findProduct && activePromo?.currentItemsAvailable[productId] !== 0 ? activePromo?.currentItemsAvailable[productId] : null;
        if (productlimit) return getPromoData(productItemPrice, activePromo?.unit, activePromo?.discount, 'productlimit', productlimit);
        const findProductItem = currentItemsAvailable?.includes(productItemId);
        const productItemlimit = findProductItem && activePromo?.currentItemsAvailable[productItemId] !== 0 ? activePromo?.currentItemsAvailable[productItemId] : null;
        if (productItemlimit) return getPromoData(productItemPrice, activePromo?.unit, activePromo?.discount, 'productItemlimit', productItemlimit);
    }
};
