import {Card} from 'antd';
import {N} from 'components/N';
import {memo} from 'react';
import {Tooltip, PieChart, Pie, Cell,ResponsiveContainer} from 'recharts';

const COLORS = ['#c6dee3', '#89b8c1', '#729da7', '#09323a', '#7278a8'];

const CustomActiveShapePieChart = memo(({data}: any) => {
  const totalCount = data.map(({values}: any) => values).reduce((acc: number, value: number) => value + acc, 0);

  return (
    <Card className="w100 tac mt15">
      <h2 className='text-lg font-semibold'>Composition of Orders</h2>
      <ResponsiveContainer width='100%' height={300}>

      <PieChart margin={{top: 5, right: 30, left: 20, bottom: 5}}>
        <Tooltip
          useTranslate3d
          content={({active, payload, label}) => (
            <div className="bg-white b b-r4 p10" style={{textAlign: 'left'}}>
              <h2 className="text-lg font-semibold">
                <N value={(Number(payload?.[0]?.value) * 100) / totalCount} type="percent" /> <N value={payload?.[0]?.value as number} />
              </h2>
              <h5 className="f-light c-darkblue mb0">{payload?.[0]?.name}</h5>
            </div>
          )}
        />
        <Pie data={data} dataKey="values" nameKey="labels" cx="50%" cy="50%" innerRadius={80} outerRadius={150}>
          {data.map((entry: any, index: number) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
      </ResponsiveContainer >

    </Card>
  );
});

export default CustomActiveShapePieChart;
