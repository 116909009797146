import {gql} from 'graphql-tag';

export const GET_SHIPPING_PLANS = gql`
  query getShippingPlans {
    getShippingPlans {
      id
      region
      planDay
      shipDay
      orderCutOffDay
      orderCutOffTime
    }
  }
`;
