import {gql} from 'graphql-tag';

export const CREATE_OR_UPDATE_SUPPLIERS = gql`
  mutation createOrUpdateSuppliers($userInput: [SupplierInput]) {
    createOrUpdateSuppliers(userInput: $userInput) {
      title
      id
      order
    }
  }
`;
export const DELETE_SUPPLIERS = gql`
  mutation deleteSuppliers($id: ID!) {
    deleteSuppliers(id: $id)
  }
`;
