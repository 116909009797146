/* eslint-disable react-hooks/rules-of-hooks */
import {IQueryEnum, makeQueries} from 'core/api';
import AuthStore from 'core/auth';
import {useContext} from 'react';
import {useParams} from 'react-router-dom';
import {IRetailer} from './types';

export function getAvailablePercent(retailer: IRetailer) {
  return retailer.creditLimit ? Math.round((retailer.creditAvailable * 100) / retailer.creditLimit) : 0;
}

export function getAvailablePercentColor(retailer: IRetailer) {
  const percent = getAvailablePercent(retailer);
  if (percent <= 30) return 'c-red';
  if (percent >= 30 && percent <= 50) return 'c-yellow';
  return 'c-green';
}

/** Retrieving retailer id */
export function getRetailerId(): string | undefined {
  const {retailerId} = useParams<{retailerId: string}>();
  const {userProfile, isRetailer, assignedRetailerId} = useContext(AuthStore);

  if (userProfile) {
    return isRetailer ? assignedRetailerId : retailerId;
  }
}

export function getRetailer() {
  const retailerId = getRetailerId();
  const {
    data: {retailers},
    isLoading
  } = makeQueries<{
    retailers?: Map<string, IRetailer>;
  }>([{type: IQueryEnum.GetRetailers, mapKey: 'id', isEnabled: Boolean(retailerId)}]);

  return {retailer: retailers?.get(retailerId!), isLoading};
}

export function getRetailers() {
  const retailerId = getRetailerId();
  const {
    data: {retailers}
  } = makeQueries<{
    retailers?: Map<string, IRetailer>;
  }>([{type: IQueryEnum.GetRetailers, mapKey: 'id', isEnabled: Boolean(retailerId)}]);

  return retailers;
}
