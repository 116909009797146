import {CompassOutlined, EditOutlined} from '@ant-design/icons';
import {Button, Card, Checkbox, Col, Form, Input, Radio, Row, Select, Space, Tag} from 'antd';
import {IconColorEnum} from 'components/Icon';
import {IMutationEnum, IQueryEnum, makeMutation, makeQueries} from 'core/api';
import AuthStore from 'core/auth';
import {IAddress} from 'core/models/addresses/types';
import {getRetailerId} from 'core/models/retailers/utils';
import {IState} from 'core/models/states/types';
import {memo, useContext, useEffect, useState} from 'react';

const {Option} = Select;

export interface IAddressCard {
  isOpen?: boolean;
  address: IAddress;
  setAddress: (store: IAddress) => void;
  selectedAddressId?: string;
}
interface IData {
  states: IState[];
}

const AddressCard: React.FC<IAddressCard> = memo(({address, setAddress, selectedAddressId}) => {
  const retailerId = getRetailerId();
  const {isRetailer} = useContext(AuthStore);
  const {
    name,
    phone,
    email,
    address: fullAddress,
    addressLine1,
    addressLine2,
    zip,
    city,
    stateId,
    isStore,
    isShippingAddress,
    isWarehouse
  } = address;

  const {
    data: {states}
  } = makeQueries<IData>([{type: IQueryEnum.GetStates}]);

  const mutation = makeMutation({
    type: IMutationEnum.UpdateAddress,
    keyVars: {retailerId},
    loadingMsg: 'Updating address...',
    successMsg: 'Address successfully updated!'
  });

  const [isEditable, setIsEditable] = useState<Boolean>(false);
  const [isShippingAddressChecked, setIsShippingAddressChecked] = useState<Boolean>(false);
  const [form] = Form.useForm();

  const toggleEditButton = () => {
    setIsEditable(isOpen => !isOpen);
  };

  const updateStore = (values: Omit<IAddress, 'id'>) => {
    values.isStore = values.type === 'isStore';
    values.isWarehouse = values.type === 'isWarehouse';
    delete values.type;

    mutation.mutate({
      addressUpdate: {
        id: address.id,
        retailerId,
        ...values
      }
    });
    toggleEditButton();
  };

  useEffect(() => {
    if (isShippingAddress) {
      setIsShippingAddressChecked(isShippingAddress);
    }
  }, [isShippingAddress]);

  const renderHeader = () => {
    return isEditable ? (
      <>
        <Form.Item name={'name'} className="w100 mt10 mb10 mr10" initialValue={name}>
          <Input placeholder="Enter name here" />
        </Form.Item>
      </>
    ) : (
      <>
        <p className="w50 mt10 mb10">{name}</p>
      </>
    );
  };

  const onIsShippingAddress = (v: any) => {
    setIsShippingAddressChecked(v?.target?.checked);
  };
  const isShowType = isShippingAddressChecked;

  const renderForm = (): React.ReactElement => {
    let radioProps: any = {};
    if (isShippingAddress && (isWarehouse || isStore)) {
      radioProps.initialValue = isWarehouse ? 'isWarehouse' : 'isStore';
    }
    return (
      <>
        <Row>
          <Col span={14}>
            <Space direction="vertical" size="small" className="w100">
              <Form.Item name={'stateId'} className="mb0" initialValue={stateId} label="State">
                <Select>
                  {states.map(state => (
                    <Option key={state.id} value={state.id}>
                      {state.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name={'city'} className="mb0" initialValue={city} label="City">
                <Input placeholder="Enter city here" />
              </Form.Item>
              <Form.Item name={'addressLine1'} className="mb0" label="Address 1" initialValue={addressLine1}>
                <Input placeholder="Enter address here" />
              </Form.Item>
              <Form.Item name={'addressLine2'} className="mb0" initialValue={addressLine2} label="Address 2">
                <Input placeholder="Enter address here" />
              </Form.Item>
              <Form.Item className="mb0" name={'zip'} initialValue={zip} label="Zip">
                <Input placeholder="Enter zip here" />
              </Form.Item>
              <Form.Item className="mb0 mt10" valuePropName="checked" name="isShippingAddress" initialValue={isShippingAddress}>
                <Checkbox onChange={onIsShippingAddress}>Shipping Address</Checkbox>
              </Form.Item>
              {isShowType && (
                <Form.Item label="Type" name="type" {...radioProps} rules={[{required: true}]}>
                  <Radio.Group>
                    <Radio value={'isStore'}>Store</Radio>
                    <Radio value={'isWarehouse'}>Warehouse</Radio>
                  </Radio.Group>
                </Form.Item>
              )}
            </Space>
          </Col>
          <Col span={10}>
            <Row justify="end">
              <Space direction="vertical" size="small">
                <Form.Item className="mb0 w100" name={'email'} initialValue={email} label="Email">
                  <Input placeholder="Enter email here" />
                </Form.Item>
                <Form.Item className="mb0 w100" name={'phone'} label="Phone" initialValue={phone}>
                  <Input placeholder="Enter phone here" />
                </Form.Item>
              </Space>
            </Row>
          </Col>
        </Row>
        <div>
          <Form.Item className="tar mt10">
            <Space>
              <Button onClick={toggleEditButton} htmlType="button">
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Form.Item>
        </div>
      </>
    );
  };

  return (
    <Form layout="vertical" form={form} name={`${address.id}-store`} onFinish={updateStore}>
      <Card
        bordered={false}
        title={renderHeader()}
        extra={[
          <CompassOutlined
            onClick={() => setAddress(address)}
            key="location"
            className={'cursor-pointer pl10 pr10'}
            style={{fontSize: 20, color: address.id === selectedAddressId ? IconColorEnum.Grey : IconColorEnum.Black}}
          />,
          !isRetailer && (
            <EditOutlined
              key="edit"
              onClick={toggleEditButton}
              className={'cursor-pointer pr10'}
              style={{fontSize: 20, color: isEditable ? IconColorEnum.Grey : IconColorEnum.Black}}
            />
          )
        ]}
      >
        {isEditable ? (
          renderForm()
        ) : (
          <>
            <Row>
              <Col span={14}>
                <Space direction="vertical" size="small">
                  <span>{fullAddress}</span>
                </Space>
              </Col>
              <Col span={10} className="tar">
                <Space direction="vertical" size="small">
                  {email && <p>{email}</p>}
                  {phone && <p>{phone}</p>}
                </Space>
              </Col>
            </Row>
            <div className="mt10">{isStore && <Tag color="green">Store</Tag>}</div>
            <div className="mt10">{isWarehouse && <Tag color="red">Warehouse</Tag>}</div>
          </>
        )}
      </Card>
    </Form>
  );
});

export default AddressCard;
