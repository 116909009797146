import {gql} from 'graphql-tag';

export const GET_ORDERS = gql`
  query getOrders($retailerId: ID!, $filters: Filters) {
    getOrders(retailerId: $retailerId, filters: $filters) {
      id
      totalAmount
      subtotalAmount
      shippingPrice
      type
      totalQuantity
      shippingAddressId
      billingAddressId
      createOrderDate
      updatedAt
      state
      reference
      number
      discount
      scheduledAt
      relatedOrderId
    }
  }
`;

export const GET_ORDER = gql`
  query getOrderById($retailerId: ID!, $orderId: ID!) {
    getOrderById(retailerId: $retailerId, orderId: $orderId) {
      id
      totalAmount
      subtotalAmount
      shippingPrice
      totalQuantity
      shippingAddressId
      billingAddressId
      updatedAt
      state
      detail
      scheduledAt
      relatedOrderId
    }
  }
`;

export const GET_ORDER_TRACKING = gql`
  query getOrderTracking($retailerId: ID!, $orderId: ID!) {
    getOrderTracking(retailerId: $retailerId, orderId: $orderId) {
      service
      estimatedDeliveryDate
      isDelivered
      trackingNumber
      events {
        status
        location
        date
      }
    }
  }
`;

export const TOTAL_SALES_REPORT = gql`
  query totalSalesReport($retailerId: ID) {
    totalSalesReport(retailerId: $retailerId) {
      labels
      amount
      count
    }
  }
`;
