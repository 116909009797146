import {CREATE_OR_UPDATE_PRICE_LIST_ITEMS} from 'core/models/priceListItems/mutations';
import {UPDATE_PRODUCT, CREATE_PRODUCT, ARCHIVE_PRODUCT} from 'core/models/products/mutations';
import {UPDATE_RETAILER} from 'core/models/retailers/mutations';
import {QueryClient} from 'react-query';
import {IModelEnum} from '../types';
import {IMutationDef, IMutationEnum} from './types';
import uuid from 'react-uuid';
import {CREATE_OR_UPDATE_ORDER, CREATE_ORDERS, CREATE_RELATED_ORDER, CANCEL_ORDER} from 'core/models/orders/mutations';
import {UPDATE_PRODUCT_ITEMS, UPDATE_PRODUCT_ITEMS_PRICE_SETTINGS, CREATE_PRODUCT_ITEM} from 'core/models/productItems/mutations';
import {DELETE_NOTIFICATION} from 'core/models/notifications/mutations';
import {CREATE_USER, UPDATE_USER, CREATE_USERS, DELETE_USER} from 'core/models/users/mutations';
import {CREATE_TASK} from 'core/models/tasks/mutations';
import {CREATE_TASK_COMMENT} from 'core/models/taskComments/mutations';
import {UPDATE_ADDRESS, CREATE_ADDRESS} from 'core/models/addresses/mutations';
import {CREATE_RETURN, UPDATE_RETURN} from 'core/models/returns/mutations';
import {CREATE_OR_UPDATE_PRODUCT_CATEGORY, DELETE_PRODUCT_CATEGORY} from 'core/models/productCategories/mutations';
import {CREATE_OR_UPDATE_PRICE_LISTS, DELETE_PRICE_LISTS} from 'core/models/priceLists/mutations';
import {CREATE_OR_UPDATE_PRODUCT_BRAND, DELETE_PRODUCT_BRAND} from 'core/models/productBrands/mutations';
import {CREATE_OR_UPDATE_SUPPLIERS, DELETE_SUPPLIERS} from 'core/models/suppliers/mutations';
import {CREATE_GROUP, UPDATE_GROUP, DELETE_GROUP} from 'core/models/groups/mutations';
import {CREATE_SHIPPING_RULE, DELETE_SHIPPING_RULE} from 'core/models/shippingRules/mutations';
import {ARCHIVE_PRODUCT_ITEM_GROUP, CREATE_PRODUCT_ITEM_GROUP} from 'core/models/productItemGroups/mutations';
import {CREATE_SHIPPING_PLAN, DELETE_SHIPPING_PLAN} from 'core/models/shippingPlans/mutations';
import {CREATE_OR_UPDATE_PROMO} from 'core/models/promoBuilder/mutations';
import { CREATE_ANALYTICS } from 'core/models/analytics/mutations';

/**
 * @param type
 * @returns Full mutation definition based on Mutation type
 */
export function getMutationDef(type: IMutationEnum): IMutationDef {
  switch (type) {
    case IMutationEnum.CreateOrUpdatePriceListItems:
      return {
        gql: CREATE_OR_UPDATE_PRICE_LIST_ITEMS,
        model: IModelEnum.PriceListItems,
        inputKey: 'priceListItems',
        type: 'createOrUpdate',
        isBulk: true
      };
    case IMutationEnum.CreateReturn:
      return {
        gql: CREATE_RETURN,
        model: IModelEnum.Returns,
        inputKey: 'returnInput',
        type: 'createOrUpdate'
      };
    case IMutationEnum.UpdateReturn:
      return {
        gql: UPDATE_RETURN,
        model: IModelEnum.Returns,
        inputKey: 'returnUpdate',
        type: 'createOrUpdate'
      };
    case IMutationEnum.RetailerUpdate:
      return {
        gql: UPDATE_RETAILER,
        model: IModelEnum.Retailers,
        inputKey: 'retailerUpdate',
        type: 'createOrUpdate'
      };
    case IMutationEnum.CreateTask:
      return {
        gql: CREATE_TASK,
        model: IModelEnum.Tasks,
        inputKey: 'taskInput',
        type: 'createOrUpdate'
      };
    case IMutationEnum.CreateTaskComment:
      return {
        gql: CREATE_TASK_COMMENT,
        model: IModelEnum.TaskComments,
        inputKey: 'taskCommentInput',
        type: 'createOrUpdate'
      };
    case IMutationEnum.CreateUser:
      return {
        gql: CREATE_USER,
        model: IModelEnum.Users,
        inputKey: 'userInput',
        type: 'createOrUpdate'
      };
    case IMutationEnum.UpdateUser:
      return {
        gql: UPDATE_USER,
        model: IModelEnum.Users,
        inputKey: 'userUpdate',
        type: 'createOrUpdate'
      };
    case IMutationEnum.UpdateAddress:
      return {
        gql: UPDATE_ADDRESS,
        model: IModelEnum.Addresses,
        inputKey: 'addressUpdate',
        type: 'createOrUpdate'
      };
    case IMutationEnum.CreateAddress:
      return {
        gql: CREATE_ADDRESS,
        model: IModelEnum.Addresses,
        inputKey: 'addressInput',
        type: 'createOrUpdate'
      };
    case IMutationEnum.CreateProduct:
      return {
        gql: CREATE_PRODUCT,
        model: IModelEnum.Products,
        inputKey: 'productInput',
        type: 'createOrUpdate'
      };
    case IMutationEnum.UpdateProduct:
      return {
        gql: UPDATE_PRODUCT,
        model: IModelEnum.Products,
        inputKey: 'productUpdate',
        type: 'createOrUpdate'
      };
    case IMutationEnum.DeleteNotification:
      return {
        gql: DELETE_NOTIFICATION,
        model: IModelEnum.Notifications,
        type: 'delete'
      };
    case IMutationEnum.CreateProductItem:
      return {
        gql: CREATE_PRODUCT_ITEM,
        model: IModelEnum.ProductItems,
        inputKey: 'productItem',
        type: 'createOrUpdate'
      };
    case IMutationEnum.UpdateProductItems:
      return {
        gql: UPDATE_PRODUCT_ITEMS,
        model: IModelEnum.ProductItems,
        inputKey: 'productItems',
        type: 'createOrUpdate',
        isBulk: true
      };
    case IMutationEnum.UpdateProductItemsPriceSettings:
      return {
        gql: UPDATE_PRODUCT_ITEMS_PRICE_SETTINGS,
        model: IModelEnum.ProductItems,
        inputKey: 'productItems',
        type: 'createOrUpdate',
        isBulk: true
      };
    case IMutationEnum.CreateOrUpdateOrder:
      return {
        gql: CREATE_OR_UPDATE_ORDER,
        model: IModelEnum.Orders,
        inputKey: 'orderInput',
        type: 'createOrUpdate'
      };
    case IMutationEnum.createRelatedOrder:
      return {
        gql: CREATE_RELATED_ORDER,
        model: IModelEnum.Orders,
        inputKey: 'orderInput',
        type: 'createOrUpdate'
      };
    case IMutationEnum.CancelOrder:
      return {
        gql: CANCEL_ORDER,
        model: IModelEnum.Orders,
        type: 'simple'
      };
    case IMutationEnum.createOrUpdateProductCategory:
      return {
        gql: CREATE_OR_UPDATE_PRODUCT_CATEGORY,
        model: IModelEnum.ProductCategories,
        inputKey: 'userInput',
        type: 'createOrUpdate',
        isBulk: true
      };
    case IMutationEnum.createOrUpdatePriceList:
      return {
        gql: CREATE_OR_UPDATE_PRICE_LISTS,
        model: IModelEnum.PriceLists,
        inputKey: 'userInput',
        type: 'createOrUpdate',
        isBulk: true
      };
    case IMutationEnum.createOrUpdateProductBrand:
      return {
        gql: CREATE_OR_UPDATE_PRODUCT_BRAND,
        model: IModelEnum.ProductBrands,
        inputKey: 'userInput',
        type: 'createOrUpdate',
        isBulk: true
      };
    case IMutationEnum.createOrUpdateSupplier:
      return {
        gql: CREATE_OR_UPDATE_SUPPLIERS,
        model: IModelEnum.Suppliers,
        inputKey: 'userInput',
        type: 'createOrUpdate',
        isBulk: true
      };
    case IMutationEnum.deletePriceList:
      return {
        gql: DELETE_PRICE_LISTS,
        model: IModelEnum.PriceLists,
        type: 'delete'
      };
    case IMutationEnum.deleteProductBrand:
      return {
        gql: DELETE_PRODUCT_BRAND,
        model: IModelEnum.ProductBrands,
        type: 'delete'
      };
    case IMutationEnum.deleteProductCategory:
      return {
        gql: DELETE_PRODUCT_CATEGORY,
        model: IModelEnum.ProductCategories,
        type: 'delete'
      };
    case IMutationEnum.deleteSupplier:
      return {
        gql: DELETE_SUPPLIERS,
        model: IModelEnum.Suppliers,
        type: 'delete'
      };
    case IMutationEnum.CreateUsers:
      return {
        gql: CREATE_USERS,
        model: IModelEnum.Users,
        inputKey: 'usersInput',
        type: 'createOrUpdate'
      };
    case IMutationEnum.CreateOrders:
      return {
        gql: CREATE_ORDERS,
        model: IModelEnum.Orders,
        inputKey: 'ordersInput',
        type: 'createOrUpdate'
      };
    case IMutationEnum.DeleteUser:
      return {
        gql: DELETE_USER,
        model: IModelEnum.Users,
        type: 'delete'
      };
    case IMutationEnum.CreateGroup:
      return {
        gql: CREATE_GROUP,
        model: IModelEnum.Groups,
        type: 'createOrUpdate'
      };
    case IMutationEnum.UpdateGroup:
      return {
        gql: UPDATE_GROUP,
        model: IModelEnum.Groups,
        type: 'createOrUpdate'
      };
    case IMutationEnum.DeleteGroup:
      return {
        gql: DELETE_GROUP,
        model: IModelEnum.Groups,
        type: 'delete'
      };
    case IMutationEnum.createOrUpdateShippingRules:
      return {
        gql: CREATE_SHIPPING_RULE,
        model: IModelEnum.ShippingRules,
        inputKey: 'shippingRuleInput',
        type: 'createOrUpdate'
      };
    case IMutationEnum.deleteShippingRule:
      return {
        gql: DELETE_SHIPPING_RULE,
        model: IModelEnum.ShippingRules,
        type: 'delete'
      };
    case IMutationEnum.ArchiveProduct:
      return {
        gql: ARCHIVE_PRODUCT,
        model: IModelEnum.Products,
        type: 'createOrUpdate'
      };
    case IMutationEnum.ArchiveProductItemGroup:
      return {
        gql: ARCHIVE_PRODUCT_ITEM_GROUP,
        model: IModelEnum.ProductItemGroups,
        type: 'createOrUpdate'
      };
    case IMutationEnum.CreateProductItemGroup:
      return {
        gql: CREATE_PRODUCT_ITEM_GROUP,
        model: IModelEnum.ProductItemGroups,
        type: 'createOrUpdate'
      };
    case IMutationEnum.CreateOrUpdateShippingPlan:
      return {
        gql: CREATE_SHIPPING_PLAN,
        model: IModelEnum.ShippingPlans,
        inputKey: 'shippingPlanInput',
        type: 'createOrUpdate'
      };
    case IMutationEnum.DeleteShippingPlan:
      return {
        gql: DELETE_SHIPPING_PLAN,
        model: IModelEnum.ShippingPlans,
        type: 'delete'
      };
    case IMutationEnum.CreateOrUpdatePromo:
      return {
        gql: CREATE_OR_UPDATE_PROMO,
        model: IModelEnum.PromoBuilder,
        inputKey: 'promoBuilderInput',
        type: 'createOrUpdate'
      };
    case IMutationEnum.CreateAnalyticsRecord:
      return {
        gql: CREATE_ANALYTICS,
        model: IModelEnum.Analytics,
        inputKey: 'analyticsCreateInput',
        type: 'createOrUpdate'
      };
    default:
      throw new Error('Mutation is not implemented');
  }
}

export function onBulkCreateOrUpdateMutate<ObjectType extends {id?: string}>(
  queryClient: QueryClient,
  variables: any[],
  model: IModelEnum,
  keyVars?: object
) {
  const optimisticItems = variables.map(item => {
    if (item.id) return item;
    return {...item, id: uuid()};
  });

  queryClient.setQueryData<any>(keyVars ? [model, keyVars] : [model], (items: ObjectType[]) => {
    if (!items) {
      return [];
    }
    optimisticItems.forEach(optimisticItem => {
      const foundIndex = items.findIndex(x => x.id === optimisticItem.id);
      if (foundIndex >= 0) {
        items[foundIndex] = {...items[foundIndex], ...optimisticItem};
      } else {
        items.push(optimisticItem);
      }
    });
    return items;
  });
  return optimisticItems;
}

export function onCreateOrUpdateMutate<ObjectType extends {id: string}>(
  queryClient: QueryClient,
  variable: any,
  model: IModelEnum,
  keyVars?: object
) {
  const optimisticItem = {...variable};
  if (!optimisticItem.id) optimisticItem.id = uuid();

  queryClient.setQueryData<any>(keyVars ? [model, keyVars] : [model], (items: ObjectType[]) => {
    if (!items) {
      return [];
    }

    const foundIndex = items.findIndex(x => x.id === optimisticItem.id);
    if (foundIndex >= 0) {
      items[foundIndex] = {...items[foundIndex], ...optimisticItem};
    } else {
      items.push(optimisticItem);
    }

    return items;
  });

  return optimisticItem;
}

export function onCreateOrUpdateBulkError<ObjectType extends {id?: string}>(
  queryClient: QueryClient,
  optimisticItems: any[],
  model: IModelEnum,
  keyVars?: object
) {
  queryClient.setQueryData<any>(keyVars ? [model, keyVars] : [model], (items: ObjectType[]) => {
    optimisticItems.forEach(optimisticItem => {
      const foundIndex = items.findIndex(x => x.id === optimisticItem.id)!;
      items.splice(foundIndex, 1);
    });
  });
}

export function onCreateOrUpdateError<ObjectType extends {id: string}>(
  queryClient: QueryClient,
  optimisticItem: any,
  model: IModelEnum,
  keyVars?: object
) {
  queryClient.setQueryData<any>(keyVars ? [model, keyVars] : [model], (items: ObjectType[]) => {
    return items?.filter(x => x.id !== optimisticItem.id);
  });
}

export function onDeleteMutate<ObjectType extends {id: string}>(
  queryClient: QueryClient,
  variable: any,
  model: IModelEnum,
  keyVars?: object
) {
  const optimisticItem = {...variable};
  queryClient.setQueryData<any>(keyVars ? [model, keyVars] : [model], (items: ObjectType[]) => {
    return items?.filter(x => x.id !== optimisticItem.id);
  });
  return optimisticItem;
}
////fix
export function onDeleteError<ObjectType extends {id: string}>(
  queryClient: QueryClient,
  variable: any,
  model: IModelEnum,
  keyVars?: object
) {
  const optimisticItem = {...variable};
  queryClient.setQueryData<any>(keyVars ? [model, keyVars] : [model], (items: ObjectType[]) => {
    if (!items) {
      return [];
    }
    const foundIndex = items.findIndex(x => x.id === optimisticItem.id);
    if (foundIndex >= 0) {
      items[foundIndex] = {...items[foundIndex], ...optimisticItem};
    } else {
      items.push(optimisticItem);
    }
    return items;
  });
  return optimisticItem;
}
