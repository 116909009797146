import {UserAddOutlined} from '@ant-design/icons';
import {Button, Card, Form, Input, message, Upload} from 'antd';
import {PageWrapper} from 'components/PageWrapper';
import {IMutationEnum, makeMutation} from 'core/api';
import AuthStore from 'core/auth';
import {getRetailer} from 'core/models/retailers/utils';
import {pageUrls} from 'core/router/pages';
import moment from 'moment-timezone';
import {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {dataLayerEvents} from 'utils/dataLayer';
import {normalizeFile} from 'utils/normalizeFile';
import './style.less';

function Profile() {
  const {token, userProfile, logout, setDeclinedTermsAt} = useContext(AuthStore);
  const [form] = Form.useForm();
  const [photoUrl, setPhotoUrl] = useState(userProfile?.photoUrl);
  const {retailer, isLoading} = getRetailer();

  const acceptedTermsAt = userProfile?.acceptedTermsAt || localStorage.getItem('acceptedTermsAt');

  useEffect(() => {
    if (!isLoading && retailer) {
      dataLayerEvents('view_profile', retailer);
    }
  }, [retailer, isLoading]);

  const mutation = makeMutation({
    type: IMutationEnum.UpdateUser,
    loadingMsg: 'Updating profile...',
    successMsg: 'Profile updated.'
  });

  useEffect(() => {
    if (mutation.isSuccess && mutation.data) logout();
  }, [mutation, logout]);

  function onSubmit(values: any) {
    const {firstName, lastName} = values;
    const userUpdate = {
      oktaId: userProfile?.id,
      firstName,
      lastName,
      photoUrl
    };

    mutation.mutate({userUpdate});
  }

  function openTermsModal() {
    setDeclinedTermsAt('');
  }

  const uploaderProps = {
    name: 'file',
    action: `${process.env.REACT_APP_API_URL}/files`,
    headers: {
      authorization: `Bearer ${token}`
    },
    onChange({file}: any) {
      if (file.status === 'error') {
        message.error(`${file.name} file upload failed.`);
      }
      if (file?.response?.fileUrl) {
        setPhotoUrl(file?.response?.fileUrl);
      }
    }
  };
  const formLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 10}
  };

  return (
    <PageWrapper pageTitle={'Profile'}>
      <Form {...formLayout} layout="vertical" form={form} name="create-return" onFinish={onSubmit}>
        <Card>
          <Form.Item
            label="Avatar"
            name="avatar"
            className="w60 avatar-uploader"
            valuePropName="fileList"
            getValueFromEvent={normalizeFile}
            rules={[{required: false}]}
          >
            <Upload.Dragger {...uploaderProps} maxCount={1} name="files">
              {photoUrl ? (
                <img src={photoUrl} alt="avatar" className="w100" />
              ) : (
                <>
                  <UserAddOutlined style={{fontSize: '50px'}} />
                  <p className="ant-upload-text">Drag & Drop your photo</p>
                </>
              )}
            </Upload.Dragger>
          </Form.Item>
          <Form.Item
            name={'firstName'}
            label="Name"
            className="w100 mt10 mb10"
            initialValue={userProfile?.firstName}
            rules={[{required: true}]}
          >
            <Input placeholder="Enter name here" />
          </Form.Item>
          <Form.Item
            name={'lastName'}
            label="Surname"
            className="w100 mt10 mb10"
            initialValue={userProfile?.lastName}
            rules={[{required: true}]}
          >
            <Input placeholder="Enter surname here" />
          </Form.Item>
          <Form.Item name={'email'} label="Email" className="w100 mt10 mb10" initialValue={userProfile?.email} rules={[{required: true}]}>
            <Input placeholder="Enter email here" disabled />
          </Form.Item>
          <div>
            Resident Connect App{' '}
            <Link className="c-blue" to={pageUrls.termsAndConditions}>
              Terms & Conditions
            </Link>{' '}
            {Boolean(acceptedTermsAt) ? (
              <>
                accepted at <strong>{moment(acceptedTermsAt).format('MM/DD/YYYY')}</strong>
              </>
            ) : (
              <>
                are not accepted. Click{' '}
                <span className="c-blue text-underline cursor-pointer" onClick={openTermsModal}>
                  here
                </span>{' '}
                to accept
              </>
            )}
          </div>
          <Form.Item className="tar pt15">
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Card>
      </Form>
    </PageWrapper>
  );
}

export default Profile;
