import {gql} from 'graphql-tag';

export const CREATE_OR_UPDATE_PRODUCT_CATEGORY = gql`
  mutation createOrUpdateProductCategory($userInput: [ProductCategoriesInput]!) {
    createOrUpdateProductCategory(userInput: $userInput) {
      title
      id
      order
    }
  }
`;
export const DELETE_PRODUCT_CATEGORY = gql`
  mutation deleteProductCategory($id: ID!) {
    deleteProductCategory(id: $id)
  }
`;
