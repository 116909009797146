import {IStep} from 'components/Steps/Steps';
import {IQueryEnum, makeQueries} from 'core/api';
import {getRetailer} from 'core/models/retailers/utils';
import {UserRolesEnum} from 'core/models/users/types';
import {OrderStore} from 'core/stores/OrderStore';
import {useEffect, useState} from 'react';
import {dataLayerEvents} from 'utils/dataLayer';
import {PageWrapper} from '../../../components/PageWrapper';
import {Steps} from '../../../components/Steps';
import {OrdersCreateProducts} from './components/OrdersCreateProducts';
import {OrdersCreateReference} from './components/OrdersCreateReference';
import {OrdersCreateReview} from './components/OrdersCreateReview';
import {OrdersCreateShipping} from './components/OrdersCreateShipping';
import {OrdersCreateType} from './components/OrdersCreateType';

function OrderCreate() {
  const [allowedStep, setAllowedStep] = OrderStore(({allowedStep, setAllowedStep}) => [allowedStep, setAllowedStep]);
  const {retailer, isLoading} = getRetailer();

  useEffect(() => {
    if (!isLoading && retailer) {
      dataLayerEvents('view_orders_create', retailer);
    }
  }, [isLoading, retailer]);

  /**  Preload data for order creation */
  makeQueries([
    {type: IQueryEnum.GetProductBrands},
    {type: IQueryEnum.GetProductCategories},
    {type: IQueryEnum.GetProducts},
    {type: IQueryEnum.GetProductItems, variables: {retailerId: retailer?.id}, isEnabled: Boolean(retailer)}
  ]);

  const [step, setStep] = useState(allowedStep);
  const [type, setType] = useState();

  const nextStep = () => {
    setStep(step + 1);
    setAllowedStep(step + 1);
  };

  const onStepChange = (stepIndex: number) => {
    setStep(stepIndex);
  };

  const stepsContent: IStep[] = [
    {
      title: 'Type',
      content: <OrdersCreateType nextStep={nextStep} setType={setType}/>,
      allowedRoles: [UserRolesEnum.Admin, UserRolesEnum.Sales]
    },
    {
      title: 'Reference',
      content: <OrdersCreateReference nextStep={nextStep} />
    },
    {
      title: 'Products',
      content: <OrdersCreateProducts nextStep={nextStep} orderType={type}/>
    },
    {
      title: 'Shipping',
      content: <OrdersCreateShipping nextStep={nextStep} />
    },
    {
      title: 'Review',
      content: <OrdersCreateReview />
    }
  ];

  return (
    <PageWrapper pageTitle={retailer?.name}>
      <Steps maxAllowedStep={allowedStep} currentStep={step} onChange={onStepChange} steps={stepsContent} />
    </PageWrapper>
  );
}

export default OrderCreate;
