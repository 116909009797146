import {Switch} from '@headlessui/react';
import {DatePicker} from 'antd';
import {OrderStore} from 'core/stores/OrderStore';
import moment from 'moment-timezone';
import React, {useState} from 'react';
import {getClassName} from 'utils/getClassName';
import './style.less';

export interface IOrderSummary {}

const OrderSummaryScheduler: React.FC = React.memo(() => {
  const [order, setScheduledAt] = OrderStore(({order, setScheduledAt}) => [order, setScheduledAt]);
  const [enabled, setEnabled] = useState(Boolean(order.scheduledAt));

  function onToggle(value: boolean) {
    if (value) {
      setScheduledAt(moment(new Date()).set('hours', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', 0).toISOString());
      setEnabled(value);
    } else {
      setScheduledAt();
      setEnabled(value);
    }
  }

  return (
    <div>
      <div className="my-3 flex items-center">
        <Switch
          checked={enabled}
          onChange={onToggle}
          className={getClassName(
            enabled ? 'bg-cyan-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2'
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            className={getClassName(
              enabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          >
            <span
              className={getClassName(
                enabled ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
                'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
              )}
              aria-hidden="true"
            >
              <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                <path
                  d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span
              className={getClassName(
                enabled ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
                'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
              )}
              aria-hidden="true"
            >
              <svg className="h-3 w-3 text-cyan-600" fill="currentColor" viewBox="0 0 12 12">
                <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
              </svg>
            </span>
          </span>
        </Switch>
        <span className="ml-3 text-semibold">Schedule Order</span>
      </div>
      {enabled && (
        <div className="mb-3 flex flex-col">
          <DatePicker
            value={moment(order.scheduledAt)}
            onChange={date => setScheduledAt(date?.set('hours', 0).set('minutes', 0).set('seconds', 0).set('milliseconds', 0).toISOString())}
            format="MM/DD/YYYY"
          />
          <span className="text-sm text-gray-500 mt-1">At 12:01 PM Eastern Time</span>
        </div>
      )}
    </div>
  );
});

export default OrderSummaryScheduler;
