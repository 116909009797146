import {Form, Input, Radio, Card, Button} from 'antd';
import {IReturn} from 'core/models/returns/types';
import {ReturnStore} from 'core/stores/ReturnStore';
import {memo} from 'react';
import {IReturnsCreateStep} from '../ReturnCreate';
import {ReturnIssueTypeEnum} from 'core/models/returns/types';

const ReturnsStep4: React.FC<IReturnsCreateStep> = memo(({prevStep, nextStep, formLayout}) => {
  const [form] = Form.useForm();
  const {updateReturn, returnItem} = ReturnStore.getState();

  function onSubmit(values: Partial<IReturn>) {
    updateReturn(values);
    nextStep();
  }
  return (
    <Card>
      <Form
        {...formLayout}
        layout="vertical"
        form={form}
        name="create-return"
        onFinish={onSubmit}
        initialValues={{
          isPickedByRetailer: returnItem.isPickedByRetailer,
          wholeSalePrice: returnItem.wholeSalePrice,
          comments: returnItem.comments
        }}
      >
        {returnItem.issueType !== ReturnIssueTypeEnum.Damaged && (
          <Form.Item name="isPickedByRetailer" label="Picked up and disposed of by retailer" rules={[{required: true}]}>
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        )}
        <Form.Item name="comments" label="Additional comments">
          <Input.TextArea />
        </Form.Item>
        <Form.Item wrapperCol={{span: 14}} className="tar">
          <Button htmlType="button" onClick={prevStep} className="mr10">
            Back
          </Button>
          <Button htmlType="submit" type="primary">
            Review
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
});

export default ReturnsStep4;
