import {Card} from 'antd';
import {FC, memo} from 'react';
import {Animation, AnimationEnum} from 'components/Animation';

const OrderSummaryEmpty: FC = memo(() => {
  return (
    <Card title="Order Summary" className="ml15">
      <Animation width={15} type={AnimationEnum.HomeFlower} autoplay loop />
     <div className="tac">
     <h3 className="c-darkblue text-md mt-4">Add something to a cart! 😈</h3>
     </div>
    </Card>
  );
});

export default OrderSummaryEmpty;
