import {UserAddOutlined} from '@ant-design/icons';
import {Button, Card, Form, Input, message, Radio, Upload} from 'antd';
import {IMutationEnum, makeMutation} from 'core/api';
import AuthStore from 'core/auth';
import {getRetailer, getRetailerId} from 'core/models/retailers/utils';
import {pageUrls} from 'core/router/pages';
import {memo, useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {getUrl} from 'utils/getUrl';
import {normalizeFile} from 'utils/normalizeFile';
import './style.less';

const RetailersUserCreate: React.FC = memo(() => {
  const retailerId = getRetailerId();
  const history = useHistory();
  const [photoUrl, setPhotoUrl] = useState();
  const {token} = useContext(AuthStore);
  const [form] = Form.useForm();
  const {retailer} = getRetailer();

  const mutation = makeMutation({
    type: IMutationEnum.CreateUser,
    loadingMsg: 'Creating user...',
    successMsg: 'User created.'
  });

  useEffect(() => {
    if (mutation.isSuccess) {
      history.push(getUrl(pageUrls.retailerUserList, {retailerId}));
    }
  }, [mutation, retailerId, history]);

  const onSaveClick = (values: any) => {
    const {firstName, lastName, email, role} = values;
    window.dataLayer?.push({event: 'action_invite_user', retailerName: retailer?.name || null});

    mutation.mutate({
      userInput: {
        retailerIds: [retailerId],
        firstName,
        lastName,
        role,
        email: email.trim(),
        photoUrl,
        storeIds: []
      }
    });
  };

  const formLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 10}
  };

  const uploaderProps = {
    name: 'file',
    action: `${process.env.REACT_APP_API_URL}/files`,
    headers: {
      authorization: `Bearer ${token}`
    },
    onChange({file}: any) {
      if (file.status === 'error') {
        message.error(`${file.name} file upload failed.`);
      }
      if (file?.response?.fileUrl) {
        setPhotoUrl(file?.response?.fileUrl);
      }
    }
  };

  return (
    <Card>
      <Form {...formLayout} layout="vertical" form={form} name="create-user" onFinish={onSaveClick}>
        <Form.Item
          label="Avatar"
          name="avatar"
          className="w60 avatar-uploader"
          valuePropName="fileList"
          getValueFromEvent={normalizeFile}
          rules={[{required: false}]}
        >
          <Upload.Dragger {...uploaderProps} maxCount={1} name="files">
            {photoUrl ? (
              <img src={photoUrl} alt="avatar" className="w100" />
            ) : (
              <>
                <UserAddOutlined style={{fontSize: '100px'}} />
                <p className="ant-upload-text">Drag & Drop your photo</p>
              </>
            )}
          </Upload.Dragger>
        </Form.Item>
        <Form.Item name="firstName" label="First Name" rules={[{required: true}]}>
          <Input />
        </Form.Item>
        <Form.Item name="lastName" label="Last Name" rules={[{required: true}]}>
          <Input />
        </Form.Item>
        <Form.Item name="email" label="Email" rules={[{required: true}]}>
          <Input />
        </Form.Item>
        <Form.Item label="User role" name="role" initialValue={'Owner'} rules={[{required: true}]}>
          <Radio.Group name="role" buttonStyle="solid">
            <Radio value="Owner">Owner</Radio>
            <Radio value="Manager">Designer</Radio>
            <Radio value="Returns">Returns</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item className="tar pt15">
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
});

export default RetailersUserCreate;
