import {gql} from 'graphql-tag';

export const GET_PRICE_LIST_ITEMS = gql`
  query getPriceListItems($priceListId: ID, $retailerId: ID) {
    getPriceListItems(priceListId: $priceListId, retailerId: $retailerId) {
      id
      priceListId
      productItemId
      price
    }
  }
`;