import {Tag} from 'antd';
import {OrderStateEnum, OrderTypeEnum} from './types';

export const orderTypeRenderer = (state: OrderTypeEnum) => {
  let tagColor;
  switch (state) {
    case OrderTypeEnum.ORDER:
      tagColor = 'gold';
      break;
    case OrderTypeEnum.FLOOR_MODELS:
      tagColor = 'purple';
      break;
    case OrderTypeEnum.DROPSHIPMENT:
      tagColor = 'red';
      break;
    default:
      return state;
  }
  return <Tag color={tagColor}>{state}</Tag>;
};

export const orderStateRenderer = (state: OrderStateEnum) => {
  let tagColor;
  switch (state) {
    case OrderStateEnum.Scheduled:
      tagColor = 'cyan';
      break;
    case OrderStateEnum.Created:
      tagColor = 'cyan';
      break;
    case OrderStateEnum.Processing:
      tagColor = 'geekblue';
      break;
    case OrderStateEnum.Fulfilling:
      tagColor = 'orange';
      break;
    case OrderStateEnum.Delivering:
      tagColor = 'volcano';
      break;
    case OrderStateEnum.Done:
      tagColor = 'green';
      break;
    case OrderStateEnum.Canceled:
      tagColor = 'red';
      break;
    default:
      return state;
  }
  return <Tag color={tagColor}>{state}</Tag>;
};
