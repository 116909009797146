import {GroupTypesEnum, IGroup} from 'core/models/groups/types';
import {IOrderItem} from 'core/models/orderItems/types';
import {IOrder, OrderTypeEnum} from 'core/models/orders/types';
import {IProductItem} from 'core/models/productItems/types';
import {IProduct, IWholesaleDiscount} from 'core/models/products/types';
import {IShippingRule} from 'core/models/shippingRules/types';
import uuid from 'react-uuid';
import create from 'zustand';
import {getPromoDiscount} from 'utils/promoHelper';

export interface IOrderExtended extends IOrder {
  priceListId?: string;
  warehouse: string;
  recommendationThreshold: number | null;
  priceRange: null | number;
}

interface IProductItemStock {
  id?: string;
  count: number;
  productItemId: string;
  warehouse: string;
}

interface IOrderStore {
  allowedStep: number;
  setAllowedStep: (step: number) => void;
  groups: IGroup[];
  shippingRules: IShippingRule[];

  order: IOrderExtended;
  delayedOrder: IOrderExtended;
  actualOrder: IOrderExtended;
  initOrder: (retailerId: string, type: OrderTypeEnum, rules: IShippingRule[], groups: IGroup[], priceListId?: string) => void;
  setShippingAddress: (shippingAddressId: string) => void;
  setScheduledAt: (scheduledAt?: string) => void;
  setWarehouse: (warehouse: string) => void;
  setOrderReference: (reference: string) => void;
  splitOrder: (stocks: IProductItemStock[]) => void;

  orderItems: Map<string, IOrderItem>;
  actualOrderItems: Map<string, IOrderItem>;
  delayedOrderItems: Map<string, IOrderItem>;
  addOrderItem: (product: IProduct, productItem: IProductItem, activePromo?: any, groups?: any) => void;
  updateOrderItem: (productItemId: IProductItem, quantity: number, productItemStock?: IProductItemStock) => void;

  reset: () => void;
}

const isFloorModel = (type: OrderTypeEnum) => type === OrderTypeEnum.FLOOR_MODELS;

const OrderStore = create<IOrderStore>((set, get) => ({
  allowedStep: 0,
  setAllowedStep: (step: number) => set({allowedStep: step}),
  groups: [],
  shippingRules: [],
  order: {} as IOrderExtended,
  delayedOrder: {} as IOrderExtended,
  actualOrder: {} as IOrderExtended,
  initOrder: (retailerId, type, shippingRules, groups) =>
    set(() => ({
      order: {
        id: uuid(),
        retailerId,
        type,
        totalAmount: 0,
        totalQuantity: 0,
        subtotalAmount: 0,
        shippingAddressId: '',
        billingAddressId: '',
        createOrderDate: new Date().toISOString(),
        shippingPrice: 0,
        warehouse: '',
        recommendationThreshold: null,
        priceRange: null
      },
      orderItems: new Map(),
      shippingRules,
      groups
    })),

  setShippingAddress: (shippingAddressId: string) =>
    set(({order}) => ({
      order: {...order, shippingAddressId}
    })),
  setScheduledAt: (scheduledAt?: string) =>
    set(({order}) => ({
      order: {...order, scheduledAt}
    })),
  setWarehouse: (warehouse: string) =>
    set(({order}) => ({
      order: {...order, warehouse}
    })),
  setOrderReference: (reference: string) =>
    set(({order}) => ({
      order: {...order, reference: reference?.replace(' ', '-')}
    })),

  orderItems: new Map(),
  actualOrderItems: new Map(),
  delayedOrderItems: new Map(),
  addOrderItem: (product: IProduct, productItem: IProductItem, activePromo, groupsMap) => {
    set(({order, orderItems, shippingRules, groups}) => {
      let orderItem = orderItems.get(productItem.id);

      const defaultPrice = isFloorModel(order.type)
        ? productItem.fMprice! + (productItem.fMfinalDiscountUSD || 0)
        : productItem.price! + (productItem.finalDiscountUSD || 0);

      const promo = getPromoDiscount(
        product.id,
        productItem.id,
        productItem.price! + productItem.finalDiscountUSD!,
        activePromo,
        groupsMap
      );

      if (orderItem) {
        /** Increase order item quantity if already exist */
        orderItem.quantity = orderItem.quantity + 1;
        orderItem.amount = orderItem.amount + orderItem.price;
      } else {
        // Handle FloorModel order items
        if (isFloorModel(order.type)) {
          /** Add new orderItem to order */
          orderItem = {
            id: uuid(),
            productId: product.id,
            productItemId: productItem.id,
            price: defaultPrice,
            quantity: 1,
            discount: productItem.fMfinalDiscountUSD || 0,
            amount: defaultPrice,
            hasPromo: false,
            productlimit: null,
            productItemlimit: null
          };
          // Handle other order items
        } else {
          /** Add new orderItem to order */
          orderItem = {
            id: uuid(),
            productId: product.id,
            productItemId: productItem.id,
            price: productItem.price! + productItem.finalDiscountUSD!,
            quantity: 1,
            discount: promo ? productItem.price! + productItem.finalDiscountUSD! - promo.price : productItem.finalDiscountUSD || 0,
            amount: defaultPrice,
            hasPromo: Boolean(promo),
            wholesaleDiscount: product.wholesaleDiscount,
            productlimit: promo?.productlimit || null,
            productItemlimit: promo?.productItemlimit || null
          };
        }
      }

      orderItems.set(orderItem.productItemId, orderItem);

      // Set wholesale discount
      if (!isFloorModel(order.type)) {
        const orderItemsWithSameProduct = Array.from(orderItems.values()).filter(i => i.productId === orderItem!.productId);
        const totalOrderItemsQuantity = orderItemsWithSameProduct.reduce((pV, cV) => pV + cV.quantity, 0);

        if (totalOrderItemsQuantity >= product.wholesaleDiscount?.minItemsInCart) {
          orderItemsWithSameProduct.forEach(item => {
            const finalWholesaleDiscount = calculateWholesaleDiscount(
              totalOrderItemsQuantity,
              item.price,
              product.wholesaleDiscount,
              orderItem?.discount
            );

            item!.discount = promo
              ? productItem.price! + productItem.finalDiscountUSD! - promo.price
              : finalWholesaleDiscount || productItem.finalDiscountUSD || 0;

            orderItems.set(item.productItemId, item);
          });
        }
      }

      const orderItemsArray = Array.from(orderItems.values());

      if (orderItemsArray.length > 0) {
        order = calculateOrder(orderItemsArray, order, shippingRules, groups);
      }

      return {order, orderItems};
    });
  },
  splitOrder: (stocks: IProductItemStock[]) => {
    set(({order, orderItems, shippingRules, groups, actualOrderItems, delayedOrderItems, delayedOrder, actualOrder}) => {
      actualOrderItems.clear();
      delayedOrderItems.clear();
      const orderItemsArray = Array.from(orderItems.values());

      if (stocks.length > 0) {
        for (const orderItem of orderItemsArray) {
          const productItemStock = stocks.find(stock => stock.productItemId === orderItem.productItemId);
          if (!productItemStock) {
            actualOrderItems.set(orderItem.productItemId, orderItem);
          }
          if (productItemStock && (productItemStock.count === 0 || productItemStock.count < 0)) {
            delayedOrderItems.set(orderItem.productItemId, orderItem);
          }
          if (productItemStock && productItemStock?.count >= orderItem.quantity) {
            actualOrderItems.set(orderItem.productItemId, orderItem);
          }
          if (productItemStock && productItemStock.count > 0 && productItemStock.count < orderItem.quantity) {
            const delayedOrderItem = {...orderItem};
            const actualOrderItem = {...orderItem};

            delayedOrderItem.quantity = Math.abs(productItemStock.count - orderItem.quantity);

            delayedOrderItem.amount = delayedOrderItem.price * delayedOrderItem.quantity;

            actualOrderItem.quantity = productItemStock.count;
            actualOrderItem.amount = actualOrderItem.price * actualOrderItem.quantity;

            delayedOrderItems.set(orderItem.productItemId, delayedOrderItem);
            actualOrderItem.quantity !== 0 && actualOrderItems.set(orderItem.productItemId, actualOrderItem);
          }
        }
      } else {
        actualOrderItems = orderItems;
      }
      const actualOrdersArray = Array.from(actualOrderItems.values());
      const delayedOrderArray = Array.from(delayedOrderItems.values());

      if (delayedOrderArray.length > 0) {
        const isSingleOrder = actualOrderItems.size === 0 || delayedOrderItems.size === 0;

        delayedOrder = calculateOrder(delayedOrderArray, delayedOrder, shippingRules, groups, isSingleOrder);
        delayedOrder.reference = actualOrdersArray.length > 0 ? `${order.reference}-2-of-2` : order.reference;
      }
      if (actualOrdersArray.length > 0) {
        const isSingleOrder = delayedOrderItems.size === 0 ? true : actualOrderItems.size > 0 && delayedOrderItems.size > 0;
        actualOrder.reference = delayedOrderArray.length > 0 ? `${order.reference}-1-of-2` : order.reference;

        actualOrder = calculateOrder(actualOrdersArray, actualOrder, shippingRules, groups, isSingleOrder, orderItemsArray);

        if (isSingleOrder) {
          actualOrder.shippingPrice = order.shippingPrice;
          const discount = actualOrder?.discount || 0;
          actualOrder.totalAmount = actualOrder.subtotalAmount - discount + actualOrder.shippingPrice;
        }
      }
      return {delayedOrder, actualOrder, actualOrderItems, delayedOrderItems};
    });
  },
  updateOrderItem: (productItem: IProductItem, quantity: number, productItemStock) => {
    set(({order, orderItems, groups, shippingRules, actualOrderItems, delayedOrderItems, delayedOrder, actualOrder}) => {
      const orderItem = orderItems.get(productItem.id)!;
      const actual = actualOrderItems.get(productItem.id);
      const delayed = delayedOrderItems.get(productItem.id);

      if (quantity <= 0) {
        orderItems.delete(productItem.id);
        actualOrderItems.delete(productItem.id);
        delayedOrderItems.delete(productItem.id);
      } else {
        orderItem.quantity = quantity;

        orderItem.amount = orderItem.price * quantity;
        orderItems.set(orderItem.productItemId, orderItem);

        const actualOrderItem = actual ? actual : {...orderItem};
        const delayedOrderItem = delayed ? delayed : {...orderItem};

        if (!productItemStock) {
          if (actual) {
            actualOrderItem.quantity = quantity;
            actualOrderItem.amount = actualOrderItem.price * quantity;
          }
          actualOrderItems.set(productItem.id, actualOrderItem);
        }
        if (productItemStock && (productItemStock.count === 0 || productItemStock.count < 0)) {
          if (delayed) {
            delayedOrderItem.quantity = quantity;

            delayedOrderItem.amount = delayedOrderItem.price * quantity;
          }
          delayedOrderItems.set(productItem.id, delayedOrderItem);
        }
        if (productItemStock && productItemStock?.count >= quantity) {
          if (actual) {
            actualOrderItem.quantity = quantity;
            actualOrderItem.amount = actualOrderItem.price * quantity;
          }
          actualOrderItems.set(productItem.id, actualOrderItem);
        }
        if (productItemStock && productItemStock.count > 0 && productItemStock.count < quantity) {
          delayedOrderItem.quantity = Math.abs(productItemStock.count - orderItem.quantity);

          delayedOrderItem.amount = delayedOrderItem.price * delayedOrderItem.quantity;

          actualOrderItem.quantity = productItemStock.count;
          actualOrderItem.amount = actualOrderItem.price * actualOrderItem.quantity;

          delayedOrderItems.set(productItem.id, delayedOrderItem);
          actualOrderItem.quantity !== 0 && actualOrderItems.set(productItem.id, actualOrderItem);
        }

        if (productItemStock && productItemStock.count > 0 && orderItem.quantity - actualOrderItem.quantity < 1) {
          delayedOrderItems.delete(productItem.id);
        }
      }

      if (!isFloorModel(order.type) && !orderItem.hasPromo) {
        const orderItemsWithSameProduct = Array.from(orderItems.values()).filter(i => i.productId === orderItem!.productId);
        const totalOrderItemsQuantity = orderItemsWithSameProduct.reduce((pV, cV) => pV + cV.quantity, 0);

        orderItemsWithSameProduct.forEach(item => {
          const finalWholesaleDiscount = calculateWholesaleDiscount(
            totalOrderItemsQuantity,
            item.price,
            item.wholesaleDiscount,
            productItem.finalDiscountUSD
          );

          item!.discount = finalWholesaleDiscount;
          orderItems.set(item.productItemId, item);
        });
      }

      const orderItemsArray = Array.from(orderItems.values());
      const actualOrdersArray = Array.from(actualOrderItems.values());
      const delayedOrderArray = Array.from(delayedOrderItems.values());
      if (orderItemsArray.length > 0) {
        order = calculateOrder(orderItemsArray, order, shippingRules, groups);
      }
      if (delayedOrderArray.length > 0) {
        const isSingleOrder = actualOrderItems.size === 0 ? true : actualOrderItems.size > 0 && delayedOrderItems.size === 0;

        delayedOrder = calculateOrder(delayedOrderArray, delayedOrder, shippingRules, groups, isSingleOrder);
        delayedOrder.reference = actualOrdersArray.length > 0 ? `${order.reference}-2-of-2` : order.reference;
      }
      if (actualOrdersArray.length > 0) {
        const isSingleOrder = delayedOrderItems.size === 0 ? true : actualOrderItems.size > 0 && delayedOrderItems.size > 0;
        actualOrder.reference = delayedOrderArray.length > 0 ? `${order.reference}-1-of-2` : order.reference;

        actualOrder = calculateOrder(actualOrdersArray, actualOrder, shippingRules, groups, isSingleOrder, orderItemsArray);

        if (isSingleOrder) {
          actualOrder.shippingPrice = order.shippingPrice;
          const discount = actualOrder?.discount || 0;
          actualOrder.totalAmount = actualOrder.subtotalAmount - discount + actualOrder.shippingPrice;
        }
      }

      return {order, orderItems};
    });
  },

  reset: () =>
    set(() => ({
      order: {} as IOrderExtended,
      delayedOrder: {} as IOrderExtended,
      actualOrder: {} as IOrderExtended,
      orderItems: new Map(),
      actualOrderItems: new Map(),
      delayedOrderItems: new Map(),
      allowedStep: 0
    }))
}));

export {OrderStore};

function calculateOrder(
  orderItemsArray: IOrderItem[],
  order: IOrderExtended,
  shippingRules: IShippingRule[],
  groups: IGroup[],
  isSingleOrder: boolean = true,
  orderItemsSummary?: IOrderItem[]
) {
  const subtotal = orderItemsArray.map(i => i.amount).reduce((pv, cv) => pv + cv);
  const discount = orderItemsArray.map(i => i.discount * i.quantity).reduce((pv, cv) => pv + cv);

  const amount = subtotal - discount;
  const orderItems = orderItemsSummary ? orderItemsSummary : orderItemsArray;
  const shippingPrice = calculateShippingPrice(shippingRules, groups, orderItems, order, amount);

  order.priceRange = Array.isArray(shippingPrice.priceRange) ? shippingPrice.priceRange[1] : shippingPrice.priceRange;
  order.recommendationThreshold = shippingPrice.recommendationThreshold;
  order.shippingPrice = isSingleOrder ? shippingPrice?.price || 0 : 0;
  order.discount = discount;
  order.subtotalAmount = subtotal;
  order.totalAmount = !isFloorModel(order.type) ? amount + order.shippingPrice : amount;
  return order;
}

function calculateShippingPrice(
  shippingRules: IShippingRule[],
  groups: IGroup[],
  orderItemsArray: IOrderItem[],
  order: IOrderExtended,
  newOrderAmount: number
) {
  const retailerShippingRules = shippingRules.filter(rule => rule.retailerId === order.retailerId);
  let shippingRule = validateShippingRules(orderItemsArray, newOrderAmount, retailerShippingRules, groups);
  if (shippingRule.price !== null) return shippingRule;
  const sharedShippingRules = shippingRules.filter(rule => !rule.retailerId);
  shippingRule = validateShippingRules(orderItemsArray, newOrderAmount, sharedShippingRules, groups);
  return shippingRule;
}

// FIXME: Refactoring needed
function validateShippingRules(orderItemsArray: IOrderItem[], orderAmount: number, shippingRules: IShippingRule[], groups: IGroup[]) {
  let isProductsMatches, isProductItemsMatches;

  const totalQuantity = orderItemsArray.reduce((acc, item) => {
    return acc + item.quantity;
  }, 0);

  for (const rule of shippingRules) {
    const {quantityRange, priceRange, groupId} = rule;
    const [fromQuantity, toQuantity] = quantityRange;
    const [fromPrice, toPrice] = priceRange;
    const filteredGroup = groups.find(group => group.id === groupId);
    const isQuantityLowBound = fromQuantity !== null && fromQuantity >= 0 && totalQuantity >= fromQuantity;
    const isQuantityHighBound = toQuantity !== null && toQuantity >= 0 && totalQuantity <= toQuantity;
    const isPriceLowBound = fromPrice !== null && fromPrice >= 0 && orderAmount >= fromPrice;
    const isPriceHighBound = toPrice !== null && toPrice >= 0 && orderAmount <= toPrice;

    if (filteredGroup && filteredGroup.type === GroupTypesEnum.Products) {
      const productIds = orderItemsArray.map(item => item.productId) as string[];
      const {productIds: groupProductIds = [] as string[]} = filteredGroup;
      if (productIds.length) {
        isProductsMatches =
          groupProductIds.length >= productIds.length && productIds.every((productId: string) => groupProductIds.includes(productId));
      }
    } else if (filteredGroup && filteredGroup.type === GroupTypesEnum.ProductItems) {
      const productItemIds = orderItemsArray.map(item => item.productItemId) as string[];
      const {productItemIds: groupProductItemsIds = [] as string[]} = filteredGroup;
      if (productItemIds.length) {
        isProductItemsMatches =
          groupProductItemsIds.length >= productItemIds.length &&
          productItemIds.every(productItemId => groupProductItemsIds.includes(productItemId));
      }
    }

    if (isQuantityLowBound && isQuantityHighBound && isPriceLowBound && isPriceHighBound && (isProductsMatches || isProductItemsMatches)) {
      return rule;
    } else if (isQuantityLowBound && isQuantityHighBound && isPriceLowBound && isPriceHighBound && !filteredGroup) {
      return rule;
    } else if (
      (isQuantityLowBound && isQuantityHighBound && isPriceLowBound && !toPrice && (isProductsMatches || isProductItemsMatches)) ||
      (isPriceLowBound && isPriceHighBound && isQuantityLowBound && !toQuantity && (isProductsMatches || isProductItemsMatches))
    ) {
      return rule;
    } else if (
      (isQuantityLowBound && isQuantityHighBound && isPriceLowBound && !filteredGroup) ||
      (isPriceLowBound && isPriceHighBound && isQuantityLowBound && !filteredGroup)
    ) {
      return rule;
    } else if (
      (isQuantityLowBound && !toQuantity && !toPrice && (isProductsMatches || isProductItemsMatches)) ||
      (isPriceLowBound && !toPrice && !toQuantity && (isProductsMatches || isProductItemsMatches))
    ) {
      return rule;
    } else if (
      (isQuantityLowBound && !toQuantity && !toPrice && !fromPrice && !filteredGroup) ||
      (isPriceLowBound && !toPrice && !toQuantity && !fromQuantity && !filteredGroup)
    ) {
      return rule;
    } else if (
      (isQuantityLowBound && !toQuantity && !toPrice && !fromPrice && (isProductsMatches || isProductItemsMatches)) ||
      (isPriceLowBound && !toPrice && !toQuantity && !fromQuantity && (isProductsMatches || isProductItemsMatches))
    ) {
      return rule;
    } else if (
      (isQuantityLowBound && isQuantityHighBound && !toPrice && !fromPrice && (isProductsMatches || isProductItemsMatches)) ||
      (isPriceLowBound && isPriceHighBound && !toQuantity && !fromQuantity && (isProductsMatches || isProductItemsMatches))
    ) {
      return rule;
    } else if (!fromQuantity && !toQuantity && !fromPrice && !toPrice && !filteredGroup) {
      return rule;
    }
  }
  return {price: null, recommendationThreshold: null, priceRange: null};
}

function calculateWholesaleDiscount(quantity: number, price: number, wholesaleDiscount?: IWholesaleDiscount, currentDiscount?: number) {
  if (wholesaleDiscount && quantity >= wholesaleDiscount.minItemsInCart) {
    return wholesaleDiscount.unit === 'PERCENT' ? (price * wholesaleDiscount.discount) / 100 : wholesaleDiscount.discount;
  }
  return currentDiscount || 0;
}
