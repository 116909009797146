import {gql} from 'graphql-tag';

export const UPDATE_RETAILER = gql`
  mutation updateRetailer($retailerUpdate: RetailerUpdateInput!) {
    updateRetailer(retailerUpdate: $retailerUpdate) {
      id
      name
      ordersCount
      storesCount
      priceListId
      totalSalesAmount
      productCategoryIds
      productBrandIds
      creditAvailable
      creditLimit
      withCreditLimit
      website
      notes
      facebook
      instagram
      twitter
      youtube
      logoUrl
      billingAddressId
      salesManagerId
      productIds
      groupIds
    }
  }
`;
