import {Form, Input} from 'antd';
import {IMutationEnum, makeMutation} from 'core/api';
import {useEffect, useState} from 'react';

interface IProps {
  productId: string;
  supplierId: string;
  index: number;
  productItemGroupId: string;
}

export default function ProductItemForm({productId, supplierId, index, productItemGroupId}: IProps) {
  const [error, setError] = useState('');

  const mutation = makeMutation({
    type: IMutationEnum.CreateProductItem,
    loadingMsg: 'Creating product item...',
    successMsg: 'Product item created.'
  });
  useEffect(() => {
    if (mutation.isError) {
      setError(mutation?.error?.response?.errors[0]?.message);
    }
    if (mutation.isSuccess) {
      setError('');
    }
  }, [mutation]);

  const onSave = (v: any) => {
    mutation.mutate({
      productItem: {
        productId,
        supplierId,
        productItemGroupId,
        ...v
      }
    });
  };

  return (
    <div key={index}>
      <Form key={index} requiredMark={false} name={`select-sku-${index}`} onFinish={onSave} layout="inline">
        <div className="flex flex-row pt-6">
          <Form.Item className={'w35'} name="title" rules={[{required: true}]}>
            <Input placeholder={'Title'} />
          </Form.Item>
          <Form.Item className={'w35 mr0'} name="sku" rules={[{required: true}]}>
            <Input placeholder={'SKU'} />
          </Form.Item>

          <button
            type="submit"
            className="cursor-pointer inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-gray-800 hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
          >
            Create
          </button>
        </div>
        {error && <p className="text-red-500 mt-1 font-semibold">{error}</p>}
      </Form>
      <div className="border-b mt-6" />
    </div>
  );
}
