import {PencilSquareIcon} from '@heroicons/react/24/solid';
import {IQueryEnum, makeQueries} from 'core/api';
import {IProductBrand} from 'core/models/productBrands/types';
import {IProductCategory} from 'core/models/productCategories/types';
import {IProduct} from 'core/models/products/types';
import {pageUrls} from 'core/router/pages';
import {useHistory} from 'react-router-dom';
import {getUrl} from 'utils/getUrl';
import constants from '../../constants';
import ProductSettings from './ProductSettings';

interface IData {
  productBrands?: IProductBrand[];
  productCategories?: IProductCategory[];
}

export default function ProductSidebar({product}: {product?: IProduct}) {
  const history = useHistory();

  const {
    data: {productBrands = [], productCategories = []}
  } = makeQueries<IData>([{type: IQueryEnum.GetProductBrands}, {type: IQueryEnum.GetProductCategories}]);

  function onEditClick() {
    history.push(getUrl(pageUrls.productView, {productId: product!.id}));
  }

  return (
    <aside className="hidden w-96 2xl:w-full max-w-lg bg-white p-8 border-l border-gray-200 overflow-y-auto lg:block rounded-md">
      {product ? (
        <div className="pb-16 space-y-4">
          <div>
            <div className="block w-full aspect-w-10 aspect-h-7 overflow-hidden">
              <div key={product.id}>
                <img
                  src={product.images?.[0] || constants.image.fallback}
                  key={`${product?.id}-url`}
                  alt={product?.title}
                  className={'object-cover rounded-md hover:rounded-md mx-auto'}
                />
              </div>
            </div>
            <div className="mt-4 flex items-center justify-between">
              <h2 className="text-lg font-medium text-gray-900">
                <span className="sr-only">Details for </span>
                {product.title}
              </h2>
              <button
                type="button"
                onClick={onEditClick}
                className="ml-2 inline-flex items-center px-2 py-1 border border-transparent shadow-xs text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900 focus:outline-none"
              >
                <PencilSquareIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                Edit
              </button>
            </div>
          </div>
          <div className="border-b pb-4">
            {product.archived ? (
              <h3 className='text-md font-semibold'>
                This product is{' '}
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-red-200 text-red-500 border border-red-700">
                  Archived
                </span>
                .
              </h3>
            ) : (
              <div className="flex justify-between text-sm font-medium align-middle">
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-cyan-100 text-black border border-cyan-700">
                  {productBrands.find(b => b.id === product.productBrandId)?.title || 'No Brand'}
                </span>
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-yellow-100 text-black border border-yellow-700">
                  {productCategories.find(b => b.id === product.productCategoryId)?.title || 'No Category'}
                </span>
              </div>
            )}
          </div>
           {!product.archived && <ProductSettings product={product} />}
        </div>
      ) : (
        <div className="text-center">
          <h3 className="mt-2 text-sm font-medium text-gray-900">No product selected</h3>
          <p className="mt-1 text-sm text-gray-500">Click on product to view details.</p>
        </div>
      )}
    </aside>
  );
}
