/* eslint-disable react-hooks/exhaustive-deps */
import {Card, Col, Row, Select} from 'antd';
import {Animation, AnimationEnum} from 'components/Animation';
import {IMutationEnum, IQueryEnum, makeMutation, makeQueries} from 'core/api';
import AuthStore from 'core/auth';
import {IOrder} from 'core/models/orders/types';
import {IProductBrand} from 'core/models/productBrands/types';
import {IProductCategory} from 'core/models/productCategories/types';
import {IProductItem} from 'core/models/productItems/types';
import {IProduct} from 'core/models/products/types';
import {BannerVariants, IPromoBuilder} from 'core/models/promoBuilder/types';
import {getRetailer, getRetailerId} from 'core/models/retailers/utils';
import {Stock} from 'core/models/stocks/types';
import {IUser, UserRolesEnum} from 'core/models/users/types';
import {pageUrls} from 'core/router/pages';
import {OrderStore} from 'core/stores/OrderStore';
import {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import PromoImage from 'shared/PromoImage';
import {createOrder} from 'utils/createOrder';
import {actionEnum, dataLayerCheckoutOrPurchase} from 'utils/dataLayer';
import {getUrl} from 'utils/getUrl';
import {OrderSummary} from '../OrderSummary';
import OrderDetails from './components/OrderDetails';
import './style.less';

const {Option} = Select;

interface IData {
  users: IUser[];
  orders: Map<string, IOrder>;
  products: Map<string, IProduct>;
  productItems: Map<string, IProductItem>;
  productBrands: Map<string, IProductBrand>;
  productCategories: Map<string, IProductCategory>;
  activePromo: IPromoBuilder;
  stocks: Stock[];
}

function OrdersCreateReview() {
  const history = useHistory();
  const retailerId = getRetailerId();
  const {retailer} = getRetailer();

  const {order, orderItems, reset, delayedOrder, actualOrder, actualOrderItems, delayedOrderItems, splitOrder} = OrderStore.getState();

  const {isRetailer} = useContext(AuthStore);
  const [usersId, setUsersId] = useState<any>([]);

  const {
    data: {users, productBrands, productCategories, products, productItems, activePromo, stocks = []}
  } = makeQueries<IData>([
    {type: IQueryEnum.GetUsers, variables: {retailerId, roles: [UserRolesEnum.Owner, UserRolesEnum.Manager]}, isEnabled: !isRetailer},
    {type: IQueryEnum.GetOrders, variables: {retailerId}, mapKey: 'id', isEnabled: Boolean(retailerId)},
    {type: IQueryEnum.GetProductBrands, mapKey: 'id'},
    {type: IQueryEnum.GetProductCategories, mapKey: 'id'},
    {type: IQueryEnum.GetProducts, mapKey: 'id'},
    {type: IQueryEnum.GetProductItems, variables: {retailerId: retailer?.id}, mapKey: 'id', isEnabled: Boolean(retailer)},
    {type: IQueryEnum.GetActivePromo},
    {type: IQueryEnum.GetStocks}
  ]);

  const mutation = makeMutation({
    type: IMutationEnum.createRelatedOrder,
    keyVars: {retailerId},
    loadingMsg: 'Creating order...',
    successMsg: 'Order created.'
  });

  const orderItemsDataLayer = Array.from(orderItems.values()).map(({quantity, productId, productItemId, price, discount}) => {
    const product = products.get(productId!);
    const productBrandName = productBrands.get(product?.productBrandId!)?.title;
    const productCategoryName = productCategories.get(product?.productCategoryId!)?.title;
    const productItem = productItems.get(productItemId)?.title;

    return {
      quantity,
      item_name: product?.title,
      item_id: productId,
      item_variant: productItem,
      item_category: productCategoryName,
      item_brand: productBrandName,
      price,
      discount
    };
  });

  useEffect(() => {
    dataLayerCheckoutOrPurchase(actionEnum.beginCheckout, orderItemsDataLayer);
    const currentStocks = stocks.filter(stock => stock?.warehouse === order.warehouse);
    splitOrder(currentStocks);
  }, []);

  useEffect(() => {
    if (mutation.data) {
      const {data: createdOrder} = mutation;
      const ecommerceItems = {transaction_id: createdOrder?.id, value: order?.totalAmount, orderType: order?.type};

      dataLayerCheckoutOrPurchase(actionEnum.purchase, orderItemsDataLayer, ecommerceItems);

      history.push(getUrl(pageUrls.orderView, {retailerId, orderId: createdOrder.id}));
      reset();
    }
  }, [mutation, history, reset, retailerId]);

  function submitOrder() {
    const orderInput = {
      retailerId: order.retailerId,
      type: order.type,
      usersId,
      reference: actualOrder.reference,
      shippingAddressId: order.shippingAddressId!,
      billingAddressId: retailer?.billingAddressId!,
      priceListId: order.priceListId,
      scheduledAt: order.scheduledAt
    };
    const {orderItemsInput, delayedItemsInput, actualOrderInput, delayedOrderInput} = createOrder(
      order,
      actualOrder,
      delayedOrder,
      orderInput,
      delayedOrderItems,
      actualOrderItems,
      orderItems
    );

    mutation.mutate({
      orderInput: {
        actualOrderItems: orderItemsInput,
        delayedOrderItems: delayedItemsInput,
        actualOrder: actualOrderInput,
        delayedOrder: delayedOrderInput
      }
    });
  }

  return (
    <Row className="order-review">
      <Col xs={15} xxl={18}>
        <h2 className="text-lg font-semibold">Review Order Details</h2>
        <Card>
          <OrderDetails />
          {!isRetailer && (
            <>
              <h3 className="text-md font-semibold">Add users you want to notify</h3>
              <Select mode="multiple" allowClear style={{width: '100%'}} value={usersId} onChange={setUsersId}>
                {users?.map(u => (
                  <Option key={u.id} value={u.id}>
                    {u.firstName}&nbsp;{u.lastName}
                  </Option>
                ))}
              </Select>
            </>
          )}
          <div className="mt10">
            <PromoImage activePromo={activePromo} type={BannerVariants.ORDER_REVIEW} />
          </div>
          <Animation width={40} type={AnimationEnum.GuyLaying} autoplay loop />
        </Card>
      </Col>
      <Col xs={9} xxl={6}>
        <OrderSummary actionTitle="Place order" onAction={submitOrder} isActionDisabled={mutation.isLoading} />
      </Col>
    </Row>
  );
}

export default OrdersCreateReview;
