export enum OrderStateEnum {
  Scheduled = 'Scheduled',
  Created = 'Created',
  Processing = 'Processing',
  Fulfilling = 'Fulfilling',
  Delivering = 'Delivering',
  Done = 'Done',
  Canceled = 'Canceled'
}

export enum OrderTypeEnum {
  ORDER = 'Order',
  FLOOR_MODELS = 'Floor Models',
  DROPSHIPMENT = 'Dropshipment'
}

export interface IOrder {
  id: string;
  type: OrderTypeEnum;
  retailerId: string;
  relatedOrderId?: string;
  totalAmount: number;
  subtotalAmount: number;
  createOrderDate: String;
  discount?: number;
  totalQuantity: number;
  shippingAddressId: string;
  billingAddressId: string;
  updatedAt?: string;
  state?: OrderStateEnum;
  reference?: string;
  number?: string;
  orderItemIds?: string[];
  scheduledAt?: string;
  shippingPrice: number;
}

export interface OrderTracking {
  estimatedDeliveryDate: string;
  service: string;
  trackingNumber: string;
  events: OrderTrackEvent[];
}

export interface OrderTrackEvent {
  status: string;
  date: string;
  location: string;
  isDelivered: boolean;
}

export interface IOrderInput {
  retailerId: string;
  usersId: string[];
  type: string;
  reference?: string;
  orderItems: IOrderItemInput[];
  shippingAddressId: string;
  billingAddressId: string;
}

export interface IOrderItemInput {
  quantity: number;
  productItemId: string;
  productId?: string;
}
