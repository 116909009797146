import {PlusIcon} from '@heroicons/react/24/solid';
import {Card} from 'antd';
import {IQueryEnum, makeQueries} from 'core/api';
import {IProductItemGroups} from 'core/models/productItemGroups/types';
import {IProduct} from 'core/models/products/types';
import {useState} from 'react';
import {useParams} from 'react-router-dom';
import ProductAddSupplier from './ProductAddSupplier';
import ProductItemGroupCard from './ProductItemGroupCard';

interface IData {
  productItemGroups: IProductItemGroups[];
}

export default function ProductItemsSidebar({product}: {product?: IProduct}) {
  const {productId} = useParams<{productId: string}>();
  const [addSupplierShown, setAddSupplierShown] = useState(false);
  const {
    data: {productItemGroups = []}
  } = makeQueries<IData>([{type: IQueryEnum.GetProductItemGroups}]);

  const filteredProductItemGroups = productItemGroups?.filter(group => group.productId === productId);
  const notArchivedGroups = filteredProductItemGroups.filter(g => g.archived === false);

  const onAdd = () => {
    setAddSupplierShown(prevState => !prevState);
  };

  return (
    <Card
      className="hidden w-96 2xl:w-full max-w-lg bg-white border-gray-200 overflow-y-auto lg:block rounded-md"
      title="Items Management"
      extra={
        <button
          onClick={onAdd}
          className="ml-2 cursor-pointer inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-gray-800 hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
        >
          <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
          Add Supplier
        </button>
      }
    >
      {addSupplierShown && <ProductAddSupplier setAddSupplierShown={setAddSupplierShown} productId={productId} />}
      <div className='space-y-3'>
      {filteredProductItemGroups.map(productItemGroup => (
        <ProductItemGroupCard key={productItemGroup.id} productItemGroup={productItemGroup} showArchive={notArchivedGroups.length > 1} />
      ))}
      </div>
    </Card>
  );
}
