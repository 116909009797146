import {GET_ORDERS, GET_ORDER_TRACKING, TOTAL_SALES_REPORT} from 'core/models/orders/queries';
import {GET_PRICE_LIST_ITEMS} from 'core/models/priceListItems/queries';
import {GET_PRICE_LISTS} from 'core/models/priceLists/queries';
import {GET_PRODUCT_BRANDS} from 'core/models/productBrands/queries';
import {GET_PRODUCT_CATEGORIES} from 'core/models/productCategories/queries';
import {GET_PRODUCT_ITEMS, GET_ALL_PRODUCT_ITEMS} from 'core/models/productItems/queries';
import {GET_ORDER_ITEMS, TOTAL_PRODUCTS_REPORT} from 'core/models/orderItems/queries';
import {GET_PRODUCTS} from 'core/models/products/queries';
import {GET_RETAILERS} from 'core/models/retailers/queries';
import {GET_ADDRESSES} from 'core/models/addresses/queries';
import {GET_USERS} from 'core/models/users/queries';
import {IModelEnum} from '../types';
import {IQueryEnum} from './types';
import {GET_PAYMENTS} from 'core/models/payments/queries';
import {GET_NOTIFICATIONS} from 'core/models/notifications/queries';
import {GET_TASKS} from 'core/models/tasks/queries';
import {GET_TASK_COMMENTS} from 'core/models/taskComments/queries';
import {GET_SUPPLIERS} from 'core/models/suppliers/queries';
import {GET_STATES} from 'core/models/states/queries';
import {GET_RETURNS} from 'core/models/returns/queries';
import {GET_SHIPMENT} from 'core/models/shipments/queries';
import {GET_INVOICES} from 'core/models/invoices/queries';
import {GET_GROUPS} from 'core/models/groups/queries';
import {GET_SHIPPING_RULES} from 'core/models/shippingRules/queries';
import {GET_PRODUCT_ITEM_GROUPS} from 'core/models/productItemGroups/queries';
import {GET_SHIPPING_PLANS} from 'core/models/shippingPlans/queries';
import {GET_PROMO_BUILDER_LIST, GET_ACTIVE_PROMO} from 'core/models/promoBuilder/queries';
import {GET_STOCKS} from 'core/models/stocks/queries';
import { GET_LOGS } from 'core/models/logs/queries';
import { GET_ANALYTICS } from 'core/models/analytics/queries';

export function getQueryGQL(type: IQueryEnum) {
  switch (type) {
    case IQueryEnum.GetRetailers:
      return GET_RETAILERS;
    case IQueryEnum.GetNotifications:
      return GET_NOTIFICATIONS;
    case IQueryEnum.GetTasks:
      return GET_TASKS;
    case IQueryEnum.GetTaskComments:
      return GET_TASK_COMMENTS;
    case IQueryEnum.GetAddresses:
      return GET_ADDRESSES;
    case IQueryEnum.GetUsers:
      return GET_USERS;
    case IQueryEnum.GetOrders:
      return GET_ORDERS;
    case IQueryEnum.GetOrderTracking:
      return GET_ORDER_TRACKING;
    case IQueryEnum.GetOrderItems:
      return GET_ORDER_ITEMS;
    case IQueryEnum.GetProducts:
      return GET_PRODUCTS;
    case IQueryEnum.GetPayments:
      return GET_PAYMENTS;
    case IQueryEnum.GetShipment:
      return GET_SHIPMENT;
    case IQueryEnum.GetSuppliers:
      return GET_SUPPLIERS;
    case IQueryEnum.GetStates:
      return GET_STATES;
    case IQueryEnum.GetProductItems:
      return GET_PRODUCT_ITEMS;
    case IQueryEnum.GetProductCategories:
      return GET_PRODUCT_CATEGORIES;
    case IQueryEnum.GetProductBrands:
      return GET_PRODUCT_BRANDS;
    case IQueryEnum.GetPriceLists:
      return GET_PRICE_LISTS;
    case IQueryEnum.GetPriceListItems:
      return GET_PRICE_LIST_ITEMS;
    case IQueryEnum.GetAllProductItems:
      return GET_ALL_PRODUCT_ITEMS;
    case IQueryEnum.GetReturns:
      return GET_RETURNS;
    case IQueryEnum.TotalSalesReport:
      return TOTAL_SALES_REPORT;
    case IQueryEnum.TotalProductsReport:
      return TOTAL_PRODUCTS_REPORT;
    case IQueryEnum.GetInvoices:
      return GET_INVOICES;
    case IQueryEnum.GetGroups:
      return GET_GROUPS;
    case IQueryEnum.GetShippingRules:
      return GET_SHIPPING_RULES;
    case IQueryEnum.GetProductItemGroups:
      return GET_PRODUCT_ITEM_GROUPS;
    case IQueryEnum.GetShippingPlans:
      return GET_SHIPPING_PLANS;
    case IQueryEnum.GetPromoBuilderList:
      return GET_PROMO_BUILDER_LIST;
    case IQueryEnum.GetActivePromo:
      return GET_ACTIVE_PROMO;
    case IQueryEnum.GetStocks:
      return GET_STOCKS;
    case IQueryEnum.GetLogs:
      return GET_LOGS;
    case IQueryEnum.GetAnalyticsReports:
      return GET_ANALYTICS;
    default:
      throw new Error('Query is not implemented');
  }
}

export function getModel(type: IQueryEnum) {
  switch (type) {
    case IQueryEnum.GetRetailers:
      return IModelEnum.Retailers;
    case IQueryEnum.GetTasks:
      return IModelEnum.Tasks;
    case IQueryEnum.GetTaskComments:
      return IModelEnum.TaskComments;
    case IQueryEnum.GetNotifications:
      return IModelEnum.Notifications;
    case IQueryEnum.GetAddresses:
      return IModelEnum.Addresses;
    case IQueryEnum.GetUsers:
      return IModelEnum.Users;
    case IQueryEnum.GetOrders:
      return IModelEnum.Orders;
    case IQueryEnum.GetOrderTracking:
      return IModelEnum.OrderTracking;
    case IQueryEnum.GetOrderItems:
      return IModelEnum.OrderItems;
    case IQueryEnum.GetProducts:
      return IModelEnum.Products;
    case IQueryEnum.GetPayments:
      return IModelEnum.Payments;
    case IQueryEnum.GetSuppliers:
      return IModelEnum.Suppliers;
    case IQueryEnum.GetShipment:
      return IModelEnum.Shipments;
    case IQueryEnum.GetStates:
      return IModelEnum.States;
    case IQueryEnum.GetProductItems:
      return IModelEnum.ProductItems;
    case IQueryEnum.GetProductCategories:
      return IModelEnum.ProductCategories;
    case IQueryEnum.GetProductBrands:
      return IModelEnum.ProductBrands;
    case IQueryEnum.GetPriceLists:
      return IModelEnum.PriceLists;
    case IQueryEnum.GetPriceListItems:
      return IModelEnum.PriceListItems;
    case IQueryEnum.GetAllProductItems:
      return IModelEnum.ProductItems;
    case IQueryEnum.GetReturns:
      return IModelEnum.Returns;
    case IQueryEnum.TotalProductsReport:
      return IModelEnum.TotalProductsReport;
    case IQueryEnum.TotalSalesReport:
      return IModelEnum.TotalSalesReport;
    case IQueryEnum.GetInvoices:
      return IModelEnum.Invoices;
    case IQueryEnum.GetGroups:
      return IModelEnum.Groups;
    case IQueryEnum.GetShippingRules:
      return IModelEnum.ShippingRules;
    case IQueryEnum.GetProductItemGroups:
      return IModelEnum.ProductItemGroups;
    case IQueryEnum.GetShippingPlans:
      return IModelEnum.ShippingPlans;
    case IQueryEnum.GetPromoBuilderList:
      return IModelEnum.PromoBuilder;
    case IQueryEnum.GetActivePromo:
      return IModelEnum.ActivePromo;
    case IQueryEnum.GetStocks:
      return IModelEnum.Stocks;
    case IQueryEnum.GetLogs:
      return IModelEnum.Logs;
    case IQueryEnum.GetAnalyticsReports:
      return IModelEnum.Analytics;
    default:
      throw new Error('Query is not implemented');
  }
}
