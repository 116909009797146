import {withErrorBoundary} from '@sentry/react';
import {Layout} from 'antd';
import AuthStore from 'core/auth';
import {getRetailer} from 'core/models/retailers/utils';
import {useContext} from 'react';
import MainRouter from '../core/router';
import '../styles/root.less';
// import AnnouncementModal from './components/AnnouncementModal';
import DataPrefetcher from './components/DataPrefetcher';
import {ErrorBoundary} from './components/ErrorBoundary';
import {Header} from './components/Header';
import {PromoBanner} from './components/PromoBanner';
import {Sidebar} from './components/Sidebar';
import {TermsModal} from './components/TermsModal';

const {Content} = Layout;

function Application() {
  const {userProfile, isRetailer, isManager, isReturns} = useContext(AuthStore);
  const {retailer} = getRetailer();

  const isAppLoaded = userProfile && (!isRetailer || retailer);

  return (
    <>
      <TermsModal />
      <Layout className="root">
        {retailer?.id && !isManager && !isReturns && (
          <>
            <PromoBanner />
            {/* <AnnouncementModal /> */}
          </>
        )}
        {userProfile && <DataPrefetcher />}
        <Header />
        <Layout>
          <Sidebar />
          <Content>{isAppLoaded ? <MainRouter /> : <div>Loading...</div>}</Content>
        </Layout>
      </Layout>
    </>
  );
}
export default withErrorBoundary(Application, {fallback: <ErrorBoundary />});
