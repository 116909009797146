import {Input, List, Button} from 'antd';
import {Icon, IconColorEnum, IconTypeEnum} from 'components/Icon';
import {PageWrapper} from 'components/PageWrapper';
import {IQueryEnum, makeQueries} from 'core/api';
import {IProductBrand} from 'core/models/productBrands/types';
import {IProductCategory} from 'core/models/productCategories/types';
import {IProductItem} from 'core/models/productItems/types';
import {IProduct} from 'core/models/products/types';
import {getRetailer} from 'core/models/retailers/utils';
import {memo, useEffect, useState} from 'react';
import {CSVLink} from 'react-csv';
import {dataLayerEvents} from 'utils/dataLayer';
import ProductListItem from './components/ProductListItem';
import {useContext} from 'react';
import AuthStore from 'core/auth';
import './style.less';

interface IData {
  products?: IProduct[];
  productBrands?: Map<string, IProductBrand>;
  productCategories?: Map<string, IProductCategory>;
  productItems: Map<string, IProductItem>;
}

const ProductList = memo(() => {
  const {retailer, isLoading: isRetailerLoading} = getRetailer();
  const [fulltext, setFulltext] = useState('');
  const {isRetailer} = useContext(AuthStore);

  const {
    data: {products = [], productItems, productBrands, productCategories},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetProducts},
    {type: IQueryEnum.GetProductItems, variables: {retailerId: retailer?.id}, mapKey: 'id', isEnabled: !isRetailer},
    {type: IQueryEnum.GetProductBrands, mapKey: 'id'},
    {type: IQueryEnum.GetProductCategories, mapKey: 'id'}
  ]);

  useEffect(() => {
    if (!isRetailerLoading && retailer) {
      dataLayerEvents('view_assets', retailer);
    }
  }, [retailer, isRetailerLoading]);

  const allowedProducts = products.filter(
    product =>
      retailer?.productIds.includes(product.id) &&
      retailer.productBrandIds.includes(product.productBrandId) &&
      retailer.productCategoryIds.includes(product.productCategoryId)
  );
  const filteredProducts = fulltext ? allowedProducts.filter(p => p.title.toLowerCase().includes(fulltext.toLowerCase())) : allowedProducts;
  const getCSVData = () => {
    const headers = [
      {label: 'Product', key: 'product'},
      {label: 'Size', key: 'size'},
      {label: 'SKU', key: 'sku'},
      {label: 'Brand', key: 'brand'},
      {label: 'Category', key: 'category'},
      {label: 'Price', key: 'price'}
    ];
    let csvData: any = [];

    filteredProducts?.forEach(product => {
      const allowedProductItemIds = product?.productItemIds?.filter(id => {
        const productItem = productItems?.get(id);
        return retailer?.groupIds.includes(productItem?.productItemGroupId!);
      });

      allowedProductItemIds?.forEach(productItemId => {
        const productItem = productItems?.get(productItemId);
        const productBrand = productBrands?.get(product?.productBrandId);
        const productCategory = productCategories?.get(product?.productCategoryId);

        csvData.push({
          product: product.title,
          size: productItem?.title,
          sku: productItem?.sku,
          brand: productBrand?.title,
          category: productCategory?.title,
          price: productItem?.price
        });
      });
    });

    return {
      data: csvData,
      headers,
      filename: 'Catalog.csv'
    };
  };

  const headerActions = (
    <div>
      <Button type="primary" className="mr15">
        <CSVLink {...getCSVData()}>Export to CSV</CSVLink>
      </Button>
    </div>
  );

  return (
    <PageWrapper pageTitle="Product Catalog" actions={headerActions}>
      <div className="w100 pl10 pr10">
        <Input
          size="large"
          className="w100"
          placeholder="Search for a product..."
          allowClear
          value={fulltext}
          prefix={<Icon type={IconTypeEnum.Search} color={IconColorEnum.Black} />}
          onChange={e => setFulltext(e.currentTarget.value)}
        />
      </div>
      <div className="product-list">
        <List
          className="pt10 pl10 pr10"
          grid={{gutter: 30, column: 3}}
          loading={isLoading}
          dataSource={filteredProducts}
          renderItem={(product: IProduct) => (
            <List.Item key={product.id}>
              <ProductListItem product={product} />
            </List.Item>
          )}
        />
      </div>
    </PageWrapper>
  );
});

export default ProductList;
