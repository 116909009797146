/* eslint-disable @typescript-eslint/no-use-before-define */
import {N} from 'components/N';
import {Table} from 'components/Table';
import {IColumn} from 'components/Table/Table';
import {IQueryEnum, makeQueries} from 'core/api';
import AuthStore from 'core/auth';
import {IOrder} from 'core/models/orders/types';
import {orderStateRenderer, orderTypeRenderer} from 'core/models/orders/utils';
import {getRetailerId} from 'core/models/retailers/utils';
import {IAddress} from 'core/models/addresses/types';
import moment from 'moment';
import {useContext, useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {getFilterData} from 'utils/filterHelper';
import {DrawerFilterTypeEnum} from 'components/Table/components/TableFilter';

interface IData {
  orders: IOrder[];
  addresses: Map<string, IAddress>;
}

export interface IOrdersTableProps {
  onOrderClick: (order: IOrder) => void;
  hide?: boolean;
}

export interface IFilter {
  type?: string;
  state?: string[];
  startDate?: string;
  endDate?: string;
}

const OrdersTable: React.FC<IOrdersTableProps> = ({onOrderClick, hide}) => {
  const history = useHistory();
  const params = useMemo(() => new URLSearchParams(history.location.search), [history.location.search]);
  const retailerId = getRetailerId();
  const {isRetailer} = useContext(AuthStore);
  const [filterParams, setFilterParams] = useState<any>();

  const {
    data: {orders, addresses},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetOrders, variables: {retailerId, filters: filterParams}, isEnabled: Boolean(filterParams)},
    {type: IQueryEnum.GetAddresses, variables: {retailerId}, mapKey: 'id'}
  ]);

  useEffect(() => {
    const filterData = getFilterData(params);
    setFilterParams(filterData);
  }, [params]);

  const columns: Array<IColumn<IOrder>> = [
    {
      title: 'Type',
      width: '5%',
      dataIndex: 'type',
      render: orderTypeRenderer
    },
    {
      title: 'Date',
      width: '5%',
      dataIndex: 'createOrderDate',
      render: date => <div>{date ? moment(date).format('MM/DD/YYYY') : ''}</div>
    },
    {
      title: 'Number',
      hide: isRetailer,
      width: '5%',
      render: (_, row) => <h4>{row.number || row.id.split('-')[0]}</h4>
    },
    {
      title: 'Reference',
      dataIndex: 'reference',
      width: '10%'
    },
    {
      title: 'Status',
      dataIndex: 'state',
      width: '5%',
      render: orderStateRenderer
    },
    {
      title: 'Address',
      dataIndex: 'shippingAddress',
      width: '45%',
      render: (_, row) => <div>{addresses?.get(row.shippingAddressId)?.address}</div>
    },
    {
      title: 'Items count',
      dataIndex: 'totalQuantity',
      sorter: {
        compare: (a, b) => b.totalQuantity - a.totalQuantity
      },
      width: '5%'
    },
    {
      title: 'Price',
      dataIndex: 'totalAmount',
      sorter: {
        compare: (a, b) => b.totalAmount - a.totalAmount
      },
      render: (totalSalesAmount: number) => (
        <p className="m0">
          <N value={totalSalesAmount} type="price" />
        </p>
      ),
      width: '10%'
    }
  ];

  return (
    <Table
      data={orders}
      columns={hide ? columns.filter(column => column.hide !== hide) : columns}
      loading={isLoading}
      onRowClick={onOrderClick}
      filterConfig={{
        fulltextPlaceholder: 'Search for an order by "Number" or "Reference"',
        fulltextParams: ['reference', 'number'],
        drawerFilterType: DrawerFilterTypeEnum.OrderFilter,
        dateFilter: true
      }}
    />
  );
};

export default OrdersTable;
