import {Card} from 'antd';
import {OrderStore} from 'core/stores/OrderStore';
import React from 'react';
import OrderSummaryEmpty from './OrderSummaryEmpty';
import OrderSummaryFooter from './OrderSummaryFooter';
import OrderSummaryItemList from './OrderSummaryList';
import './style.less';

export interface IOrderSummary {
  /** Action - button on the footer of order summary */
  actionTitle: string;
  onAction: () => any;
  isActionDisabled?: boolean;
}

const OrderSummary: React.FC<IOrderSummary> = React.memo(({actionTitle, onAction, isActionDisabled}) => {
  const [order, orderItems] = OrderStore(({order, orderItems}) => [order, orderItems]);

  const orderItemsArray = Array.from(orderItems.values());

  if (orderItemsArray.length < 1) return <OrderSummaryEmpty />;
  let limitMessage: any = null;
  let productList: any = {};
  orderItemsArray.forEach(item => {
    if (item.productItemlimit && item.productItemlimit < item.quantity) {
      isActionDisabled = true;
      limitMessage = `Only ${item.productlimit} items left for that price. If you want to order more, please create a new order`;
    }
    if (item.productlimit) {
      if (productList[item.productId!]) {
        productList[item.productId!] += item.quantity;
      } else {
        productList[item.productId!] = item.quantity;
      }
      if (productList[item.productId!] > item.productlimit) {
        isActionDisabled = true;
        limitMessage = `Only ${item.productlimit} items left for that price. If you want to order more, please create a new order`;
      }
    }
  });

  return (
    <Card className="order-summary ml15" title="Order Summary" style={{height: 'fit-content'}}>
      <OrderSummaryItemList typeOrder={Boolean(order.type !== 'Floor Models')} />
      <OrderSummaryFooter
        actionTitle={actionTitle}
        onAction={onAction}
        shippingPrice={order?.shippingPrice}
        amount={order?.totalAmount}
        discount={order?.discount}
        subtotal={order?.subtotalAmount}
        isActionDisabled={isActionDisabled}
        limitMessage={limitMessage}
        recommendationThreshold={order.recommendationThreshold}
        priceRange={order.priceRange}
      />
    </Card>
  );
});

export default OrderSummary;
