import {ArchiveBoxArrowDownIcon, ArchiveBoxIcon, BookmarkIcon, PencilSquareIcon, PlusIcon} from '@heroicons/react/24/solid';
import {Form, InputNumber, Popconfirm, Input, Switch} from 'antd';
import {IMutationEnum, IQueryEnum, makeMutation, makeQueries} from 'core/api';
import {IProductItemGroups} from 'core/models/productItemGroups/types';
import {IProductItem} from 'core/models/productItems/types';
import {IProduct} from 'core/models/products/types';
import {Stock} from 'core/models/stocks/types';
import {ISupplier} from 'core/models/suppliers/types';
import {memo, useState} from 'react';
import {useParams} from 'react-router-dom';
import ProductItemForm from './ProductItemForm';

interface IData {
  productItems: IProductItem[];
  suppliers: Map<string, ISupplier>;
  products: Map<string, IProduct>;
  stocks: Stock[];
}

interface IProductItemGroupCard {
  productItemGroup: IProductItemGroups;
  showArchive: boolean;
}

const ProductItemGroupCard = memo(({productItemGroup, showArchive}: IProductItemGroupCard) => {
  const [editMode, setEditMode] = useState(false);
  const initData = {title: '', sku: '', error: ''};
  const [newProductItems, setNewProductItems] = useState<any>([]);

  const [form] = Form.useForm();
  const {productId} = useParams<{productId: string}>();

  const {
    data: {productItems = [], suppliers, products = new Map<string, IProduct>(), stocks = []}
  } = makeQueries<IData>([
    {type: IQueryEnum.GetSuppliers, mapKey: 'id'},
    {type: IQueryEnum.GetProductItems},
    {type: IQueryEnum.GetStocks},
    {type: IQueryEnum.GetProducts, mapKey: 'id'}
  ]);

  const product = products.get(productId);
  const filteredProductItems = productItems?.filter(productItem => productItem.productItemGroupId === productItemGroup.id);

  const mutationUpdate = makeMutation({
    type: IMutationEnum.UpdateProductItems,
    loadingMsg: 'Updating product item...',
    successMsg: 'Product item updated.'
  });

  const mutationArchive = makeMutation({
    type: IMutationEnum.ArchiveProductItemGroup,
    loadingMsg: 'Product item group archiving / unarchiving...',
    successMsg: 'Product item group archived / unarchived.'
  });

  function onSave() {
    const values = form.getFieldsValue();
    const transformedValues = filteredProductItems.map(productItem => ({
      id: productItem.id,
      title: values[`title_${productItem.id}`],
      outOfStock: values[`outOfStock_${productItem.id}`] || false,
      PHXAlert: values[`PHXAlert_${productItem.id}`] || 0,
      RXHAlert: values[`RXHAlert_${productItem.id}`] || 0
    }));
    mutationUpdate.mutate({productItems: transformedValues});
    setEditMode(false);
  }

  function onArchive() {
    setEditMode(false);
    mutationArchive.mutate({id: productItemGroup?.id, archived: true});
  }

  function onUnarchive() {
    mutationArchive.mutate({id: productItemGroup?.id, archived: false});
  }

  function onAdd() {
    setNewProductItems([...newProductItems, initData]);
  }

  function onEdit() {
    setEditMode(true);
  }

  return (
    <div key={productItemGroup.id} className="bg-gray-50 rounded-lg border">
      <div className="flex justify-between items-center pt-4 pb-4 bg-gray-100 p-2 rounded-t-lg">
        <h2 className="text-md font-bold">{suppliers.get(productItemGroup.supplierId)?.title}</h2>
        {!product?.archived &&
          (productItemGroup.archived ? (
            <div className="flex flex-col">
              <button
                onClick={onUnarchive}
                className="cursor-pointer inline-flex justify-center items-center px-2 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-gray-800 hover:bg-black focus:outline-none"
              >
                <ArchiveBoxArrowDownIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                Unarchive
              </button>
              <h3 className="text-md c-red pr15" style={{display: 'inline'}}>
                This supplier is archived.
              </h3>
            </div>
          ) : (
            <div>
              {showArchive && editMode && (
                <Popconfirm
                  title={
                    <div>
                      <h4 className="m0">Are you sure you want to archive this product item?</h4>
                      <h5 className="f-light c-red m0">Archiving will dissociate this product item from all retailers.</h5>
                      <h5 className="f-light c-red m0">This action is irreversible!</h5>
                    </div>
                  }
                  placement="left"
                  okType="danger"
                  onConfirm={onArchive}
                  okText="Yes"
                  cancelText="No"
                >
                  <button className="mr-1 cursor-pointer inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none">
                    <ArchiveBoxIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                    Archive
                  </button>
                </Popconfirm>
              )}
              {editMode ? (
                <button
                  onClick={onSave}
                  className="cursor-pointer inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none"
                >
                  <BookmarkIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                  Save
                </button>
              ) : (
                <button
                  onClick={onEdit}
                  className="cursor-pointer inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none"
                >
                  <PencilSquareIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                  Edit
                </button>
              )}
            </div>
          ))}
      </div>
      <div className="px-2 pb-2">
        {editMode ? (
          <div>
            <Form layout="vertical" form={form} key={productItemGroup.id} name={`${productItemGroup.id}-productItem-edit-form`}>
              {filteredProductItems.map(productItem => (
                <div key={productItem.id}>
                  <div className="flex flex-row justify-between">
                    <div className="w-2/3">
                      <Form.Item
                        className="m-0 mb-2 w-1/2"
                        name={`title_${productItem.id}`}
                        initialValue={productItem.title}
                        rules={[{required: true}]}
                      >
                        <Input placeholder="Enter product item title here" />
                      </Form.Item>
                      <h5 className="mb0 ml5 f-light c-darkblue">{productItem.sku}</h5>
                    </div>
                    <Form.Item
                      className="m-0 text-right"
                      valuePropName="checked"
                      label="Out of stock:"
                      name={`outOfStock_${productItem.id}`}
                      initialValue={productItem.outOfStock}
                    >
                      <Switch size="small" />
                    </Form.Item>
                  </div>
                  <h4 className="font-semibold text-sm ml-1 mt-1">Shipping alerts</h4>
                  <div className="flex justify-between ml-1">
                    <Form.Item
                      className="w-1/3 m-0"
                      label={`PHX(${stocks.find(s => s.productItemId === productItem.id && s.warehouse === 'PHX')?.count || 'No Data'}):`}
                      name={`PHXAlert_${productItem.id}`}
                      initialValue={productItem.PHXAlert}
                    >
                      <InputNumber />
                    </Form.Item>
                    <Form.Item
                      className="w-1/3 m-0"
                      label={`RKH(${stocks.find(s => s.productItemId === productItem.id && s.warehouse === 'RKH')?.count || 'No Data'}):`}
                      name={`RXHAlert_${productItem.id}`}
                      initialValue={productItem.RXHAlert}
                    >
                      <InputNumber />
                    </Form.Item>
                  </div>
                  <div className="my-4 border-b" />
                </div>
              ))}
            </Form>
            {newProductItems.map((productItem: any, i: number) => (
              <ProductItemForm
                index={i}
                key={i}
                productId={productId}
                productItemGroupId={productItemGroup.id}
                supplierId={productItemGroup.supplierId}
              />
            ))}
            {!productItemGroup.archived && (
              <div className="text-center mt-2">
                <button
                  onClick={onAdd}
                  className="cursor-pointer inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-gray-800 hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
                >
                  <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                  Add Item
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="space-y-4">
            {filteredProductItems.map(productItem => (
              <div key={productItem.id} className="border-t pt-4 flex justify-between items-center">
                <div>
                  <p className="font-semibold">{productItem.title}</p>
                  <p className="text-sm text-cyan-700">{productItem.sku}</p>
                </div>
                <div>
                  {productItem.outOfStock ? (
                    <span className="px-3 py-1 bg-red-200 text-red-600 rounded-md">OOS</span>
                  ) : (
                    <div className="flex flex-col gap-y-1">
                      <span className="px-2 py-1 bg-green-200 text-green-600 rounded-md">
                        PHX: {stocks.find(s => s.productItemId === productItem.id && s.warehouse === 'PHX')?.count || 'No Data'}
                      </span>
                      <span className="px-2 py-1 bg-yellow-200 text-yellow-600 rounded-md">
                        RKH: {stocks.find(s => s.productItemId === productItem.id && s.warehouse === 'RKH')?.count || 'No Data'}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
});

export default ProductItemGroupCard;
