import EnvironmentOutlined from '@ant-design/icons/lib/icons/EnvironmentOutlined';
import {Button, Card, Col, Form, Input, Row, Select} from 'antd';
import {PageWrapper} from 'components/PageWrapper';
import {IMutationEnum, IQueryEnum, makeMutation, makeQueries} from 'core/api';
import {IAddressCreateData} from 'core/models/addresses/types';
import {getRetailerId} from 'core/models/retailers/utils';
import {IState} from 'core/models/states/types';
import {useEffect, useState} from 'react';
import GoogleMap from '../RetailerStoreList/components/GoogleMap';
const {Option} = Select;

interface IData {
  states: IState[];
}
interface IAddress {
  street: string;
  state: string;
  city: string;
}

const addressStructure: IAddress = {street: '', state: '', city: ''};

const RetailerStoreCreate: React.FC = () => {
  const [form] = Form.useForm();
  const [address, setAddress] = useState<IAddress>(addressStructure);
  const retailerId = getRetailerId();
  const [successMessage, setSuccessMessage] = useState(false);

  const {
    data: {states}
  } = makeQueries<IData>([{type: IQueryEnum.GetStates}]);

  const mutation = makeMutation({
    type: IMutationEnum.CreateTask,
    keyVars: {retailerId},
    loadingMsg: 'Sending data...',
    successMsg: 'The data were sent.'
  });

  useEffect(() => {
    if (mutation.isSuccess) {
      setSuccessMessage(true);
    }
  }, [mutation]);

  const onSaveClick = (data: IAddressCreateData) => {
    const description = `\
    <body>\
      <ul>\
        <li><b>Name:</b> ${data.name}</li>\
        <li><b>Email:</b> ${data.email}</li>\
        <li><b>Address 1:</b> ${data.addressLine1}</li>\
        <li><b>Address 2:</b> ${data.addressLine2}</li>\
        <li><b>State:</b> ${data.state}</li>\
        <li><b>City:</b> ${data.city}</li>\
        <li><b>Zip:</b> ${data.zip}</li>\
        <li><b>Phone:</b> ${data.phone}</li>\
      </ul>\
    </body>\
    `;

    mutation.mutate({
      taskInput: {
        title: 'Request Store Creation',
        description,
        retailerId,
        ...data
      }
    });
  };

  const handleAddressChange = (key: string) => (e: any) => {
    setAddress(obj => ({...obj, [key]: e.target.value}));
  };
  const handleStateChange = (value: string, {name}: any) => {
    setAddress(obj => ({...obj, state: name}));
  };

  return (
    <PageWrapper pageTitle="Request To Create A Store">
      {!successMessage ? (
        <div className={'d-flex'}>
          <Card className={'w55'}>
            <Form layout="vertical" form={form} name="create-user" onFinish={onSaveClick}>
              <Form.Item name="name" label="Name" rules={[{required: true}]}>
                <Input />
              </Form.Item>

              <Form.Item name="email" label="Email" rules={[{required: true}]}>
                <Input />
              </Form.Item>
              <Form.Item name="addressLine1" label="Address 1" rules={[{required: true}]}>
                <Input onChange={handleAddressChange('street')} />
              </Form.Item>
              <Form.Item name="addressLine2" label="Address 2">
                <Input />
              </Form.Item>
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item name={'state'} className="mb0" label="State" rules={[{required: true}]}>
                    <Select onChange={handleStateChange}>
                      {states.map(state => (
                        <Option key={state.id} name={state.name} value={state.name}>
                          {state.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="city" label="City" rules={[{required: true}]}>
                    <Input onChange={handleAddressChange('city')} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item name="zip" label="Zip" rules={[{required: true}]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="phone" label="Phone" rules={[{required: true}]}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item className="tar pt15">
                <Button type="primary" htmlType="submit">
                  Request Store Creation
                </Button>
              </Form.Item>
            </Form>
          </Card>
          <div className="map-container">
            <GoogleMap
              address={`${address.state} ${address.city} ${address.street}`}
              icon={EnvironmentOutlined}
              styles={{fontSize: '26px'}}
            />
          </div>
        </div>
      ) : (
        <div>
          <p className="tac">
            Thank you for submitting a new address, Your request is currently being handled by our product team. If urgent, please contact
            your Account Manager.
          </p>
        </div>
      )}
    </PageWrapper>
  );
};

export default RetailerStoreCreate;
