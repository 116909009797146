import {PageWrapper} from 'components/PageWrapper';
import {Steps} from 'components/Steps';
import {useEffect, useState} from 'react';
import {IQueryEnum, makeQueries} from 'core/api';
import UserCreateStep1 from './components/UserCreateStep1';
import UserCreateStep2 from './components/UserCreateStep2';
import {IRetailer} from 'core/models/retailers/types';
import {IUser, UserRolesEnum} from 'core/models/users/types';
import {getRetailer} from 'core/models/retailers/utils';
import {dataLayerEvents} from 'utils/dataLayer';

interface IData {
  users: Map<string, IUser>;
  retailers: IRetailer[];
}

function SettingsUserCreate() {
  const [step, setStep] = useState(0);
  const [showSecondStep, setShowSecondStep] = useState(true);
  const [maxAllowedStep, setMaxAllowedStep] = useState(0);
  const [userId, setUserId] = useState('');
  const {retailer, isLoading: isRetailerLoading} = getRetailer();

  const {
    data: {users, retailers},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetUsers, variables: {roles: [UserRolesEnum.Sales, UserRolesEnum.Admin]}, mapKey: 'id'},
    {type: IQueryEnum.GetRetailers}
  ]);

  useEffect(() => {
    if (!isRetailerLoading && retailer) {
      dataLayerEvents('view_settings_users_create', retailer);
    }
  }, [retailer, isRetailerLoading]);

  let user = userId.length ? users?.get(userId) : null;

  const nextStep = () => {
    setStep(step + 1);
    setMaxAllowedStep(step + 1);
  };

  const onStepChange = (stepIndex: number) => {
    setStep(stepIndex);
  };

  const showAddRetailers = (value: boolean) => {
    setShowSecondStep(value);
  };

  const passUserId = (value: any) => {
    setUserId(value);
  };

  const stepsContent = [
    {
      title: 'Create user',
      content: (
        <UserCreateStep1
          passUserId={passUserId}
          showAddRetailers={showSecondStep}
          toggleAddRetailersStep={showAddRetailers}
          nextStep={nextStep}
        />
      )
    },
    {
      title: 'Add retailers',
      content: <UserCreateStep2 userOktaId={user?.oktaId!} retailerIds={user ? user.retailerIds : []} retailers={retailers} isLoading={isLoading} />
    }
  ];

  return (
    <PageWrapper pageTitle='Create User'>
      <Steps
        maxAllowedStep={maxAllowedStep}
        currentStep={step}
        onChange={onStepChange}
        steps={showSecondStep ? stepsContent : [stepsContent[0]]}
      />
    </PageWrapper>
  );
}

export default SettingsUserCreate;
