import {ColumnWidthOutlined, DatabaseOutlined, DeleteOutlined, PictureOutlined} from '@ant-design/icons';
import {ArchiveBoxIcon, BookmarkIcon} from '@heroicons/react/24/solid';
import {Button, Card, Carousel, Col, Form, Image, Input, message, Popconfirm, Row, Select, Upload} from 'antd';
import {CarouselRef} from 'antd/lib/carousel/index';
import {Buffer} from 'buffer';
import {IMutationEnum, IQueryEnum, makeMutation, makeQueries} from 'core/api';
import {IModelEnum} from 'core/api/graphql/types';
import AuthStore from 'core/auth';
import {IProductBrand} from 'core/models/productBrands/types';
import {IProductCategory} from 'core/models/productCategories/types';
import {IProduct} from 'core/models/products/types';
import {useContext, useEffect, useRef, useState} from 'react';
import {useQueryClient} from 'react-query';
import {useParams} from 'react-router-dom';
import {normalizeFile} from 'utils/normalizeFile';
import constants from '../../constants';

const {Option} = Select;
const {TextArea} = Input;

interface IData {
  products: Map<string, IProduct>;
  productBrands: IProductBrand[];
  productCategories: IProductCategory[];
}
const ProductEdit = () => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const [picUrl, setPicUrl] = useState<string>();
  const [activeSlider, setActiveSlider] = useState<number>(0);
  const [currentPic, setCurrentPic] = useState<string>('');
  const [carouselImg, setCarouselImg] = useState<Array<string>>([]);
  const {token} = useContext(AuthStore);
  const slider = useRef<CarouselRef>(null);
  const MAX_IMG_LENGTH: number = 6;
  const IMAGE_TYPES: Array<string> = ['image/jpeg', 'image/png', 'image/svg', 'image/webp'];
  const {productId} = useParams<{productId: string}>();

  const {
    data: {products = new Map<string, IProduct>(), productBrands = [], productCategories = []},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetProducts, mapKey: 'id'},
    {type: IQueryEnum.GetProductCategories},
    {type: IQueryEnum.GetProductBrands}
  ]);

  const product = products.get(productId);

  useEffect(() => {
    if (product?.images?.length) {
      setCarouselImg(product.images);
    }
  }, [product]);

  const mutationUpdate = makeMutation({
    type: IMutationEnum.UpdateProduct,
    loadingMsg: 'Updating product...',
    successMsg: 'Product updated.'
  });

  const mutationArchive = makeMutation({
    type: IMutationEnum.ArchiveProduct,
    loadingMsg: 'Product archiving / unarchiving...',
    successMsg: 'Product archived / unarchived.'
  });

  useEffect(() => {
    if (mutationArchive.isSuccess) {
      /** Item may be removed from Buying Profile, therefore reset needed  */
      queryClient.invalidateQueries([IModelEnum.Retailers]);
      queryClient.invalidateQueries([IModelEnum.ProductItemGroups]);
    }
  }, [mutationArchive.isSuccess, queryClient]);

  const onArchive = () => {
    mutationArchive.mutate({id: product?.id, archived: true});
  };

  const onUnarchive = () => {
    mutationArchive.mutate({id: product?.id, archived: false});
  };

  const onSave = (values: any) => {
    mutationUpdate.mutate({
      productUpdate: {
        id: product?.id,
        ...values,
        images: carouselImg
      }
    });
  };

  useEffect(() => {
    if (product) {
      form.setFields([
        {
          name: 'productBrandId',
          value: product.productBrandId
        },
        {
          name: 'productCategoryId',
          value: product.productCategoryId
        },
        {
          name: 'title',
          value: product.title
        },
        {
          name: 'description',
          value: product.description
        }
      ]);
    }
  }, [product, form]);

  /// carousel
  const onSlideChange = (currentSlide: number) => {
    setActiveSlider(currentSlide);
  };

  const goTo = (slideNumber: number) => {
    slider?.current?.goTo(slideNumber);
  };
  const handleDelete = () => {
    const filteredArray = carouselImg.filter((_: string, i: number) => i !== activeSlider);
    setCarouselImg(filteredArray);
  };

  //// uploader params
  const uploaderProps = {
    name: 'image',
    action: `${process.env.REACT_APP_API_URL}/files`,
    headers: {
      authorization: `Bearer ${token}`
    },
    onChange({file}: any) {
      if (file.status === 'error') {
        message.error(`${file.name} file upload failed.`);
      }
      if (file?.response?.fileUrl) {
        setPicUrl(file.response.fileUrl);
        const newArray = [...carouselImg, file.response.fileUrl];
        setCarouselImg(newArray);
      }
      if (file.status === 'removed') {
        const deleteImage = carouselImg.filter((img, index, arr) => index !== arr.indexOf(file?.response?.fileUrl));
        setPicUrl('');
        setCarouselImg(deleteImage);
      }
    },
    beforeUpload: (file: any) => {
      if (!IMAGE_TYPES.includes(file.type)) {
        message.error(`${file.name} is not a supported file`);
      }
      if (MAX_IMG_LENGTH - carouselImg?.length === 0) {
        message.error('Max number of the pictures: 6');
      }
      return IMAGE_TYPES.includes(file.type) && MAX_IMG_LENGTH - carouselImg?.length !== 0 ? true : Upload.LIST_IGNORE;
    }
  };

  //// drag n drop functions
  const dragStartHandler = (e: any, image: string) => {
    setCurrentPic(image);
  };
  const dragLeaveHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dragOverHandler = (e: any, image: any) => {
    e.preventDefault();
    e.stopPropagation();
    const curr = carouselImg.indexOf(image);
    const need = carouselImg.indexOf(currentPic);
    [carouselImg[need], carouselImg[curr]] = [carouselImg[curr], carouselImg[need]];
    setCarouselImg([...carouselImg]);
  };
  const dragEndHandler = (e: any) => {
    e.preventDefault();
  };
  const dragDropHandler = (e: any, image: string) => {
    e.preventDefault();
  };

  if (!product) {
    return (
      <div>
        <h2 className={'tac text-lg font-semibold'}>Product not found</h2>
      </div>
    );
  }

  return (
    <Form form={form} name="product-edit" onFinish={onSave} layout="vertical">
      <Card
        title="Product Management"
        extra={
          product.archived ? (
            <div>
              <h3 className="c-red text-md pr15" style={{display: 'inline'}}>
                This product is archived.
              </h3>
              <Button type="primary" size="small" onClick={onUnarchive}>
                <DatabaseOutlined />
                Unarchive
              </Button>
            </div>
          ) : (
            <>
              <Popconfirm
                title={
                  <div>
                    <h4 className="m0">Are you sure you want to archive this product?</h4>
                    <h5 className="f-light c-red m0">Archiving will dissociate this product from all retailers.</h5>
                    <h5 className="f-light c-red m0">This action is irreversible!</h5>
                  </div>
                }
                placement="left"
                okType="danger"
                onConfirm={onArchive}
                okText="Yes"
                cancelText="No"
              >
                <button className="cursor-pointer inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                  <ArchiveBoxIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                  Archive
                </button>
              </Popconfirm>
              <button
                type="submit"
                className="ml-2 cursor-pointer inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-gray-800 hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              >
                <BookmarkIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                Save
              </button>
            </>
          )
        }
      >
        <div className="grid grid-cols-1 2xl:grid-cols-2 gap-4">
          <div>
            <Form.Item name="title" label="Title">
              <Input disabled={product.archived} />
            </Form.Item>
            <Form.Item name="description" label="Description">
              <TextArea disabled={product.archived} rows={5} />
            </Form.Item>
            <Row>
              <Col xs={24} sm={12} className="pr5">
                <Form.Item name="productCategoryId" label="Select Category">
                  <Select disabled={product.archived} className="w100" loading={isLoading} allowClear>
                    {productCategories?.map(cat => (
                      <Option key={cat.id} value={cat.id}>
                        {cat.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12} className="pl5">
                <Form.Item name="productBrandId" label="Select Brand">
                  <Select disabled={product.archived} className="w100" loading={isLoading} allowClear>
                    {productBrands?.map(brand => (
                      <Option key={brand.id} value={brand.id}>
                        {brand.title}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col span={14}>
                <Carousel dots={false} ref={slider} afterChange={onSlideChange}>
                  {carouselImg.map((image: string) => (
                    <Image key={image} src={image} alt="product slider" className={'w100'} style={{objectFit: 'cover'}} />
                  ))}
                </Carousel>
              </Col>
              <Col span={10} className="pl10 tac">
                <Form.Item className="mb10" name="images" valuePropName="fileList" getValueFromEvent={normalizeFile}>
                  <Upload.Dragger
                    multiple
                    {...uploaderProps}
                    maxCount={MAX_IMG_LENGTH - carouselImg?.length}
                    name="files"
                    disabled={product.archived}
                  >
                    {picUrl ? (
                      <img src={picUrl} alt="product item" style={{maxWidth: '100%'}} />
                    ) : (
                      <>
                        <PictureOutlined style={{fontSize: '50px'}} />
                        <h3 className="text-md font-semibold ant-upload-text">"Drag & Drop"</h3>
                        <h5 className="ant-upload-text">or click here to upload</h5>
                        <h5 className="m0 c-darkblue">Upload up to 6 pictures</h5>
                      </>
                    )}
                  </Upload.Dragger>
                </Form.Item>
                {!product.archived && (
                  <Button size="small" onClick={handleDelete} danger icon={<DeleteOutlined />}>
                    Remove Image
                  </Button>
                )}
              </Col>
            </Row>
            <Row align="middle" className="mt10">
              <ColumnWidthOutlined className="mr10" style={{fontSize: 20}} />
              <h5 className="m0 f-light c-darkblue">Drag images to change their order</h5>
            </Row>
            <div className="mt15 d-flex w100">
              {carouselImg.map((image: string, index: number) => (
                <Image
                  key={image}
                  onDragStart={e => dragStartHandler(e, image)}
                  onDragLeave={e => dragLeaveHandler(e)}
                  onDragOver={e => dragOverHandler(e, image)}
                  onDragEnd={e => dragEndHandler(e)}
                  onDrop={e => dragDropHandler(e, image)}
                  draggable
                  onClick={() => goTo(index)}
                  alt="product images list"
                  className={`${activeSlider === index && carouselImg?.length > 1 ? '' : 'p10'}`}
                  preview={false}
                  height={activeSlider === index && carouselImg?.length > 1 ? 70 : 60}
                  style={{objectFit: 'cover'}}
                  src={`${constants.image.resizer}?width=100&height=60&url=${Buffer.from(image).toString('base64')}`}
                  fallback={constants.image.fallback}
                />
              ))}
            </div>
          </div>
        </div>
      </Card>
    </Form>
  );
};
export default ProductEdit;
