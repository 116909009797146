import {DownOutlined, MinusCircleOutlined, PlusOutlined, UpOutlined} from '@ant-design/icons';
import {Button, Form, Input, Popconfirm} from 'antd';
import {IMutationEnum, makeMutation} from 'core/api';

interface IProductsItemSettings {
  products: Array<{id: string; title: string}>;
  type: 'ProductBrand' | 'ProductCategory' | 'PriceList' | 'Supplier';
}
const ProductTab = ({products, type}: IProductsItemSettings) => {
  const [form] = Form.useForm();

  const mutation = makeMutation({
    type: IMutationEnum[`createOrUpdate${type}`],
    loadingMsg: 'Creating...',
    successMsg: 'Created!'
  });

  const mutationDelete = makeMutation({
    type: IMutationEnum[`delete${type}`],
    loadingMsg: 'Deleting...',
    successMsg: 'Deleted!'
  });

  const onFinish = (values: any) => {
    const userInput = values.title.map((v: any, i: number) => {
      return {...v, order: i};
    });

    mutation.mutate({
      userInput
    });
  };

  const onTitleDelete = (remove: (index: number | number[]) => void, index: number) => () => {
    const id = products[index]?.id;
    if (id) {
      mutationDelete.mutate({
        id
      });
    }
    remove(index);
  };

  return (
    <Form form={form} name="dynamic_form_item" onFinish={onFinish}>
      <Form.List initialValue={products} name="title">
        {(fields, {add, remove, move}, {errors}) => (
          <>
            {fields.map((field: any, i: any) => (
              <Form.Item key={`${field.name}-${i}`} required={false}>
                {i !== 0 && <UpOutlined className={`mr10 drag ${i === fields.length - 1 && 'ml15 pl10'}`} onClick={() => move(i, i - 1)} />}
                {i !== fields.length - 1 && (
                  <DownOutlined className={`mr10 drag ${i === 0 && 'ml15 pl10'}`} onClick={() => move(i, i + 1)} />
                )}
                <Form.Item
                  {...field}
                  name={[field.name, 'title']}
                  fieldKey={[field.fieldKey, 'id']}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Please input some text or delete this field.'
                    }
                  ]}
                  noStyle
                >
                  <Input placeholder="price list title" style={{width: '60%'}} />
                </Form.Item>
                <Popconfirm
                  title="Are you sure to delete this title?"
                  onConfirm={onTitleDelete(remove, field.name)}
                  okText="Yes"
                  cancelText="No"
                >
                  <MinusCircleOutlined className="ml10 dynamic-delete-button" />
                </Popconfirm>
              </Form.Item>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} style={{width: '60%', marginLeft: '50px'}} icon={<PlusOutlined />}>
                Add
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
      <Form.Item style={{width: '66%'}} className="tar">
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};
export default ProductTab;
