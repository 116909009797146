import {gql} from 'graphql-tag';

export const CREATE_OR_UPDATE_PROMO = gql`
  mutation createOrUpdatePromo($promoBuilderInput: promoBuilderInput!) {
    createOrUpdatePromo(promoBuilderInput: $promoBuilderInput) {
      id
      groupId
      offerTitle
      offerDescription
      discount
      unit
      expDate
      defaultItemsAvailable
      currentItemsAvailable
      promoBanner
      createdBy
    }
  }
`;
