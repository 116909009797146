import {gql} from 'graphql-tag';

export const GET_SHIPMENT = gql`
  query getShipmentByOrderId($orderId: ID!) {
    getShipmentByOrderId(orderId: $orderId) {
      id
      orderId
      trackingNumber
      carrierName
      estimatedDeliveryDate
      deliveredAt
      events {
        status
        date
        location
      }
    }
  }
`;
