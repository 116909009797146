import { BookmarkIcon } from '@heroicons/react/24/solid';
import { Form, Select, Tabs } from 'antd';
import { IMutationEnum, IQueryEnum, makeMutation, makeQueries } from 'core/api';
import { IPriceListItem } from 'core/models/priceListItems/types';
import { IPriceList } from 'core/models/priceLists/types';
import { IProductItemGroups } from 'core/models/productItemGroups/types';
import { IProductItem } from 'core/models/productItems/types';
import { IProduct } from 'core/models/products/types';
import { getRetailer } from 'core/models/retailers/utils';
import { ISupplier } from 'core/models/suppliers/types';
import { memo, useEffect, useState } from 'react';
import PriceSettingsItem from './PriceSettingsItem';

const {TabPane} = Tabs;

interface IData {
  priceLists?: IPriceList[];
  suppliers?: Map<string, ISupplier>;
  priceListItems?: Map<string, IPriceListItem>;
  productItems?: IProductItem[];
  productItemGroups: IProductItemGroups[];
}

export interface ProductSettingsProps {
  product?: IProduct;
}

const ProductSettings: React.FC<ProductSettingsProps> = memo(({product}) => {
  const [form] = Form.useForm();
  const {retailer} = getRetailer();
  const [priceListId, setPriceListId] = useState<string | undefined>();
  const [supplierId, setSupplierId] = useState<string | undefined>();
  const [selectedProductItemGroup, setSelectedProductItemGroup] = useState<IProductItemGroups | undefined>();

  const {
    data: {priceLists, priceListItems, productItems, suppliers, productItemGroups = []},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetPriceLists},
    {type: IQueryEnum.GetSuppliers, mapKey: 'id'},
    {type: IQueryEnum.GetProductItems},
    {
      type: IQueryEnum.GetPriceListItems,
      variables: {priceListId: priceListId},
      isEnabled: Boolean(priceListId),
      mapKey: 'productItemId'
    },
    {type: IQueryEnum.GetProductItemGroups, variables: {retailerId: retailer?.id}}
  ]);

  const mutation = makeMutation({
    type: IMutationEnum.CreateOrUpdatePriceListItems,
    keyVars: {priceListId: priceListId},
    loadingMsg: 'Updating prices...',
    successMsg: 'Prices updated.'
  });

  const filteredItemGroups = productItemGroups?.filter(ig => ig.productId === product?.id);
  /** Setting a first price list item in an array as a default */
  useEffect(() => {
    if (priceLists && !priceListId) {
      setPriceListId(priceLists?.[0].id);
    }
    if (suppliers && !supplierId) {
      setSupplierId(suppliers?.get(filteredItemGroups[0]?.supplierId)?.id);
    }
  }, [priceLists, priceListId, suppliers, supplierId, product, filteredItemGroups]);

  const onTabChange = (key: string) => {
    setPriceListId(key);
    form.resetFields();
  };

  const savePriceSettings = (values: any) => {
    const priceListItems = Object.entries<number>(values)
      .map(([key, value]) => {
        if (value) {
          const [productItemId, priceListItemId] = key.split('_');
          let priceListItem: any = {priceListId, productItemId, price: value};
          if (priceListItemId) priceListItem = {...priceListItem, id: priceListItemId};
          return priceListItem;
        }
        return null;
      })
      .filter(Boolean);
    mutation.mutate({priceListItems});
  };

  useEffect(() => {
    const selectedProductItemGroup = filteredItemGroups.find(ig => ig.supplierId === supplierId);

    setSelectedProductItemGroup(selectedProductItemGroup);
  }, [supplierId, filteredItemGroups]);

  const filteredProductItems = productItems?.filter(
    item => selectedProductItemGroup?.id === item?.productItemGroupId && item?.productId === product?.id
  );

  if (!priceLists || !productItems || !product || !suppliers) return null;

  return (
    <div className="bg-slate-100 p-2 rounded-md">
      <h2 className="mb-4 font-semibold text-xl">Price settings</h2>
      <h4 className="font-semibold">Supplier:</h4>
      <Select value={supplierId} onChange={setSupplierId} className="w-full mb-4">
        {filteredItemGroups?.map(({supplierId}) => (
          <Select.Option key={supplierId} value={supplierId}>
            {suppliers?.get(supplierId)?.title}
          </Select.Option>
        ))}
      </Select>
      <Tabs activeKey={priceListId} onChange={onTabChange}>
        {priceLists?.map((priceList: any) => (
          <TabPane tab={priceList.title} key={`${priceList.id}`}>
            {/* Condition is for optimization purposes */}
            {priceListId === priceList.id && (
              <Form form={form} name={`pricing-form-${priceList.id}-${supplierId}`} layout="vertical" onFinish={savePriceSettings}>
                {filteredProductItems?.map((productItem, i) => (
                  <PriceSettingsItem
                    key={productItem.id}
                    productItem={productItem}
                    priceListItem={priceListItems?.get(productItem.id)}
                    isLoading={isLoading}
                  />
                ))}
                <div className='w-full text-right'>
                <button
                  type="submit"
                  className=" mt-3 mr-2 inline-flex items-center px-2 py-1 border border-transparent shadow-xs text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                >
                  <BookmarkIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                  Save
                </button>
                </div>
              </Form>
            )}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
});

export default ProductSettings;
