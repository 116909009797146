import LeftOutlined from '@ant-design/icons/lib/icons/LeftOutlined';
import RightOutlined from '@ant-design/icons/lib/icons/RightOutlined';
import {Button, Col, Modal, Radio, Row, Carousel, Image} from 'antd';
import {CarouselRef} from 'antd/lib/carousel';
import {N} from 'components/N';
import {IProductItem} from 'core/models/productItems/types';
import {getSupplyText} from 'core/models/productItems/utils';
import {IProduct} from 'core/models/products/types';
import {getRetailer} from 'core/models/retailers/utils';
import {memo, useEffect, useRef, useState} from 'react';
import {actionEnum, dataLayerEcommerceActions} from 'utils/dataLayer';
import './style.less';
import constants from '../../../../../constants';
import {Buffer} from 'buffer';

export interface IProductCardDetailProps {
  product?: IProduct;
  defaultProductItem?: IProductItem;
  productItems?: Map<string, IProductItem>;
  onAdd: (product: IProduct, productItem: IProductItem) => void;
  resetProduct: () => void;
  productBrand: string;
  productCategory: string;
}

const ProductModal: React.FC<IProductCardDetailProps> = memo(
  ({product, defaultProductItem, onAdd, resetProduct, productItems, productBrand = '', productCategory = ''}) => {
    const {retailer} = getRetailer();
    const [isOpen, setIsOpen] = useState(false);
    const [productItem, setSelectedVariant] = useState<IProductItem | undefined>(defaultProductItem);
    const slider = useRef<CarouselRef>(null);

    const allowedProductItemIds = product?.productItemIds?.filter(id => {
      const productItem = productItems?.get(id);
      return retailer?.groupIds.includes(productItem?.productItemGroupId!);
    });

    useEffect(() => {
      if (product && defaultProductItem && !isOpen) {
        setSelectedVariant(defaultProductItem);
        setIsOpen(true);
      }
    }, [product, defaultProductItem, isOpen]);

    function onClose() {
      resetProduct();
      setIsOpen(false);
    }

    function onVariantChange({target: {value}}: any) {
      dataLayerEcommerceActions(actionEnum.viewItem, product, value, productBrand, productCategory);
      setSelectedVariant(value);
    }

    const onAddClick = () => {
      dataLayerEcommerceActions(actionEnum.addToCard, product, productItem, productBrand, productCategory);

      onAdd(product!, productItem!);
      onClose();
    };

    const SampleNextArrow = (props: any) => {
      const {className, style, onClick} = props;
      return (
        <div className={className} style={{...style, color: 'black', fontSize: '22px'}} onClick={onClick}>
          <RightOutlined />
        </div>
      );
    };

    const SamplePrevArrow = (props: any) => {
      const {className, style, onClick} = props;
      return (
        <div className={className} style={{...style, color: 'black', fontSize: '22px'}} onClick={onClick}>
          <LeftOutlined />
        </div>
      );
    };

    const settings = {
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      slidesToShow: 1,
      swipeToSlide: true
    };

    return (
      <Modal className={'product-settings-modal'} style={{left: '-10%'}} visible={isOpen} onCancel={onClose} footer={<div />} centered>
        {productItem && product && (
          <Row gutter={32}>
            <Col xl={11}>
              <Carousel arrows dots={{className: 'dotsClass'}} {...settings} ref={slider}>
                {product?.images?.map((img: string) => (
                  <div key={img}>
                    <Image
                      src={
                        product?.images?.[0]
                          ? `${constants.image.resizer}?width=250&height=250&url=${Buffer.from(product.images[0]).toString('base64')}`
                          : constants.image.fallback
                      }
                      preview={false}
                      fallback={constants.image.fallback}
                      className="w100 p5"
                      alt={product.title}
                    />
                  </div>
                ))}
              </Carousel>
            </Col>
            <Col xl={11}>
              <h2 className="f-light text-lg font-semibold mb-1">{product.title}</h2>
              <p>{product.description}</p>
              <h4 className="c-darkblue pb10">
                <span className="mr5">{productItem.title}:</span>
                {getSupplyText(productItem.outOfStock)}
              </h4>
              <Radio.Group className="mb10" value={productItem} buttonStyle="solid" onChange={onVariantChange}>
                {allowedProductItemIds?.map(productItemId => {
                  const productItem = productItems?.get(productItemId);
                  return (
                    <Radio.Button key={productItem?.sku} value={productItem}>
                      {productItem?.title}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
              <Row justify="end" align="bottom" className="mt15">
                <Button type="primary" onClick={onAddClick} disabled={productItem.outOfStock}>
                  Add&nbsp;•&nbsp;
                  <N value={productItem.price} type="price" />
                </Button>
              </Row>
            </Col>
          </Row>
        )}
      </Modal>
    );
  }
);

export default ProductModal;
