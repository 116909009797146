import {UserOutlined} from '@ant-design/icons';
import {Avatar, Divider, Progress, Row, Skeleton, Tooltip} from 'antd';
import {getAvailablePercent, getAvailablePercentColor, getRetailer} from 'core/models/retailers/utils';
import AuthStore from 'core/auth';
import {memo, useContext} from 'react';

const SidebarHeader = memo(() => {
  const {userProfile, isAdmin, isSales, isOwner, isRetailer} = useContext(AuthStore);
  const {retailer} = getRetailer();

  const getCompanyName = () => {
    if (isAdmin || isSales) return 'Resident Home';
    return retailer?.name;
  };

  const getRole = () => {
    if (isAdmin) return '(Admin)';
    if (isSales) return '(Sales)';
    /** Retailer does not have to see his role */
    return '';
  };

  const isAppLoaded = userProfile && (!isRetailer || retailer);

  const getColorFromClassName = (className: string) => {
    if (className === 'c-red') return '#8A0B29';
    if (className === 'c-green') return '#89B16A';
    if (className === 'c-yellow') return '#E0BB5B';
    return '';
  };

  return (
    <div className='sidebar-header-wrapper'>
      {isAppLoaded ? (
        <>
          <div className="sidebar-header-content">
            <Avatar className="sidebar-header-avatar" size={52} icon={<UserOutlined />} alt="User's avatar" src={userProfile?.photoUrl} />
            <div className="sidebar-header-text-container">
              <p>{`${userProfile?.firstName} ${userProfile?.lastName}`}</p>
              <Tooltip placement="right" title={getCompanyName()}>
                <p className="c-darkblue sidebar-header-text">{getCompanyName()}</p>
              </Tooltip>
              <p className="c-darkblue">{getRole()}</p>
            </div>
          </div>
          {isOwner && retailer && retailer.withCreditLimit && (
            <>
              <Divider />
              <div className="sidebar-header-text-container pl10 pr10 pt10">
                <Row justify="space-between">
                  <p className="mb0">Available:</p>
                  <p className={`mb0 ${getAvailablePercentColor(retailer)}`}>${retailer.creditAvailable}</p>
                </Row>
                <Row justify="space-between" className="c-darkblue">
                  <p className="mb0">Total:</p> <p className="mb0">${retailer.creditLimit}</p>
                </Row>
              </div>
              <Progress
                className="pl10 pr10 pb15"
                type="line"
                width={60}
                status="normal"
                size="small"
                strokeColor={getColorFromClassName(getAvailablePercentColor(retailer))}
                percent={getAvailablePercent(retailer)}
              />
            </>
          )}
        </>
      ) : (
        <Skeleton avatar paragraph={{rows: 1}} />
      )}
      <Divider />
    </div>
  );
});

export default SidebarHeader;
