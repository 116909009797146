import {FC, memo} from 'react';

interface IN {
  value?: number;
  type?: 'price' | 'percent';
  className?: string;
  toFixed?: number;
}

/** Numbers rendering component */
const N: FC<IN> = memo(({value, type, toFixed, className}) => {
  if (value === undefined || value === null) return null;
  let formattedValue = value.toLocaleString('en-US', {minimumFractionDigits: toFixed || type === 'price' ? 2 : 0})

  if (type === 'price') {
    if (value < 0) {
      formattedValue = `-$${formattedValue.substring(1)}`;
    } else {
      formattedValue = `$${formattedValue}`;
    }
  }

  if (type === 'percent') {
    formattedValue = `${formattedValue}%`;
  }

  return <span className={className || ''}>{formattedValue}</span>;
});

export default N;
