import {gql} from 'graphql-tag';

export const GET_USERS = gql`
  query getUsers($roles: [String], $retailerId: String) {
    getUsers(roles: $roles, retailerId: $retailerId) {
      id
      oktaId
      firstName
      lastName
      email
      retailerIds
      storeIds
      role
      fulfilId
      acceptedTermsAt
      lastSeen
    }
  }
`;
