import {PageWrapper} from 'components/PageWrapper';
import {pageUrls} from 'core/router/pages';
import ProductEdit from 'partials/product-view/ProductEdit';
import ProductItemsSidebar from 'partials/product-view/ProductItemsSidebar';
import './style.less';

function ProductView() {
  return (
    <PageWrapper pageTitle="Edit Product" topMenuType={'catalog'} breadcrumbs={[{url: pageUrls.productListAll, title: `Product List`}]}>
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="flex-1 flex items-stretch overflow-hidden">
          <div className="flex-1 overflow-y-auto">
            <div className="max-w-7xl mx-auto pr-4 sm:pr-6 pl-1">
              <ProductEdit />
            </div>
          </div>
          <ProductItemsSidebar />
        </div>
      </div>
    </PageWrapper>
  );
}
export default ProductView;
