import {gql} from 'graphql-tag';

export const GET_RETURNS = gql`
  query getReturns($retailerId: String) {
    getReturns(retailerId: $retailerId) {
      id
      retailerId
      productItemId
      issueType
      orderSO
      customer {
        name
        receiptNumber
        purchaseDate
      }
      quantity
      description
      isPickedByRetailer
      comments
      attachments {
        receipt
        damagedProduct
        lawTag
        bol
      }
      orderReference
      processedReason
      reference
      createdBy
      createdById
      state
      createdAt
    }
  }
`;
