import {Card, Tabs} from 'antd';
import {PageWrapper} from 'components/PageWrapper';
import {IQueryEnum, makeQueries} from 'core/api';
import {IGroup} from 'core/models/groups/types';
import {IShippingRule} from 'core/models/shippingRules/types';
import ShippingTypeRules from './components/ShippingTypeRules';
import ShippingPlansRules from './components/ShippingPlansRules';

const {TabPane} = Tabs;
interface IData {
  shippingRules: IShippingRule[];
  groups: IGroup[];
}

export enum IMethodEnum {
  ProductBrand = 'ProductBrand',
  ProductCategory = 'ProductCategory',
  PriceList = 'PriceList',
  Supplier = 'Supplier'
}

function SettingsShipping() {
  const {
    data: {shippingRules = [], groups = []},
    isLoading
  } = makeQueries<IData>([{type: IQueryEnum.GetShippingRules}, {type: IQueryEnum.GetGroups}]);

  const variants = [
    {tabName: 'Floor Models', orderType: 'Floor Models'},
    {tabName: 'Order', orderType: 'Order'},
    {tabName: 'Direct Ship Orders', orderType: 'Dropshipment'}
  ];

  return (
    <PageWrapper pageTitle='Shipping Rules' topMenuType='settings'>
      <ShippingPlansRules />
      <Card loading={isLoading}>
        <Tabs tabPosition={'left'}>
          {variants.map(({tabName, orderType}, index) => (
            <TabPane tab={tabName} key={index + 1}>
              <ShippingTypeRules name={orderType} groups={groups} shippingRules={shippingRules} />
            </TabPane>
          ))}
        </Tabs>
      </Card>
    </PageWrapper>
  );
}

export default SettingsShipping;
