import {memo, useContext, useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Card, Form, Input, Button, Radio, Upload, message, InputNumber} from 'antd';
import {UserAddOutlined} from '@ant-design/icons';
import {IMutationEnum, makeMutation} from 'core/api';
import {pageUrls} from 'core/router/pages';
import AuthStore from 'core/auth';
import {normalizeFile} from 'utils/normalizeFile';

import './style.less';
import { getUrl } from 'utils/getUrl';

const UserCreateStep1: React.FC<{
  nextStep: () => void;
  toggleAddRetailersStep: (value: boolean) => void;
  showAddRetailers: boolean;
  passUserId: (value: string) => void;
}> = memo(({nextStep, toggleAddRetailersStep, showAddRetailers, passUserId}) => {
  const history = useHistory();
  const [photoUrl, setPhotoUrl] = useState();
  const {token} = useContext(AuthStore);
  const [form] = Form.useForm();
  const mutation = makeMutation({
    type: IMutationEnum.CreateUser,
    loadingMsg: 'Creating user...',
    successMsg: 'User created.'
  });

  useEffect(() => {
    if (mutation.isSuccess) {
      if (showAddRetailers) {
        passUserId(mutation.data.id);
        nextStep();
      } else {
        history.push(getUrl(pageUrls.settingsUserView, {userId: mutation.data.id}));
      }
    }
  }, [mutation, history, nextStep, passUserId, showAddRetailers]);

  const onSaveClick = (values: any) => {
    const {firstName, lastName, role, email, fulfilId} = values;

    mutation.mutate({
      userInput: {
        firstName,
        lastName,
        role,
        email,
        photoUrl,
        fulfilId
      }
    });
  };

  const handleRoleChange = ({target}: any) => {
    toggleAddRetailersStep(target.value === 'sales');
  };

  const formLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 10}
  };

  const uploaderProps = {
    name: 'file',
    action: `${process.env.REACT_APP_API_URL}/files`,
    headers: {
      authorization: `Bearer ${token}`
    },
    onChange({file}: any) {
      if (file.status === 'error') {
        message.error(`${file.name} file upload failed.`);
      }
      if (file?.response?.fileUrl) {
        setPhotoUrl(file?.response?.fileUrl);
      }
    }
  };

  return (
    <Card>
      <Form {...formLayout} layout="vertical" form={form} name="create-user" onFinish={onSaveClick}>
        <Form.Item
          label="Avatar"
          name="avatar"
          className="w60 avatar-uploader"
          valuePropName="fileList"
          getValueFromEvent={normalizeFile}
          rules={[{required: false}]}
        >
          <Upload.Dragger {...uploaderProps} maxCount={1} name="files">
            {photoUrl ? (
              <img src={photoUrl} alt="avatar" className="w100" />
            ) : (
              <>
                <UserAddOutlined style={{fontSize: '100px'}} />
                <p className="ant-upload-text">Drag & Drop your photo</p>
              </>
            )}
          </Upload.Dragger>
        </Form.Item>
        <Form.Item name="firstName" label="First Name" rules={[{required: true}]}>
          <Input />
        </Form.Item>
        <Form.Item name="lastName" label="Last Name" rules={[{required: true}]}>
          <Input />
        </Form.Item>
        <Form.Item name="email" label="Email" rules={[{required: true}]}>
          <Input />
        </Form.Item>
        <Form.Item name="fulfilId" label="Fulfil ID" tooltip="ID from fulfil is required in order to display the correct 'Sales Name' for created orders within Fulfil.">
          <InputNumber />
        </Form.Item>
        <Form.Item label="User role" name="role" rules={[{required: true}]}>
          <Radio.Group buttonStyle="solid" onChange={handleRoleChange} value={'sales'}>
            <Radio value="Sales">Sales</Radio>
            <Radio value="Admin">Admin</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item className="tar pt15">
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
});

export default UserCreateStep1;
