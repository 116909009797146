import {gql} from 'graphql-tag';

export const CREATE_OR_UPDATE_PRICE_LIST_ITEMS = gql`
  mutation createOrUpdatePriceListItems($priceListItems: [PriceListItemInput]!) {
    createOrUpdatePriceListItems(priceListItems: $priceListItems) {
      id
      priceListId
      productItemId
      price
    }
  }
`;
