import {PlusOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import {PageWrapper} from 'components/PageWrapper';
import {IQueryEnum, makeQueries} from 'core/api';
import {IProduct} from 'core/models/products/types';
import {getRetailer} from 'core/models/retailers/utils';
import {pageUrls} from 'core/router/pages';
import ProductFilter from 'partials/product-list-all/ProductFilter';
import ProductListItem from 'partials/product-list-all/ProductListItem';
import ProductSidebar from 'partials/product-list-all/ProductSidebar';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ArrayParam, StringParam, useQueryParam} from 'use-query-params';
import {dataLayerEvents} from 'utils/dataLayer';

interface IData {
  products?: IProduct[];
}

function ProductListAll() {
  const history = useHistory();
  const {retailer, isLoading: isRetailerLoading} = getRetailer();
  const [filteredProducts, setFilteredProducts] = useState<IProduct[] | undefined>();
  const [activeProduct, setActiveProduct] = useState<IProduct | undefined>();
  const [nameFilter, setNameFilter] = useQueryParam<string>('name', StringParam as any);
  const [brandsFilter, setBrandsFilter] = useQueryParam<string[]>('brands', ArrayParam as any);
  const [categoriesFilter, setCategoriesFilter] = useQueryParam<string[]>('categories', ArrayParam as any);

  useEffect(() => {
    if (!isRetailerLoading && retailer) {
      dataLayerEvents('view_catalog', retailer);
    }
  }, [isRetailerLoading, retailer]);

  const {
    data: {products = []},
    isLoading
  } = makeQueries<IData>([{type: IQueryEnum.GetProducts}]);

  useEffect(() => {
    if(!products) return;
    let filteredProducts = products;
    if (nameFilter) {
      filteredProducts = filteredProducts.filter(product => product.title?.toLowerCase().includes(nameFilter.toLowerCase()));
    }
    if (brandsFilter) {
      filteredProducts = filteredProducts.filter(product => brandsFilter.includes(product.productBrandId));
    }
    if (categoriesFilter) {
      filteredProducts = filteredProducts.filter(product => categoriesFilter.includes(product.productCategoryId));
    }
    setFilteredProducts(filteredProducts);
  }, [products, nameFilter, brandsFilter, categoriesFilter]);

  function onCreateClick() {
    history.push(pageUrls.productCreate);
  }

  const handleProductClick = (product: IProduct) => () => {
    setActiveProduct(product);
  };

  const headerActions = (
    <div>
      <Button type="primary" icon={<PlusOutlined />} onClick={onCreateClick}>
        Create
      </Button>
    </div>
  );

  return (
    <PageWrapper pageTitle="Product Catalog" topMenuType={'catalog'} actions={headerActions} breadcrumbs={[]}>
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="flex-1 flex items-stretch overflow-hidden">
          <div className="flex-1 overflow-y-auto">
            <div className="max-w-7xl mx-auto pr-4 sm:pr-6 pl-1">
              <ProductFilter
                nameFilter={nameFilter}
                setNameFilter={setNameFilter}
                brandsFilter={brandsFilter}
                setBrandsFilter={setBrandsFilter}
                categoriesFilter={categoriesFilter}
                setCategoriesFilter={setCategoriesFilter}
              />
              <section className="mt-8 pb-16" aria-labelledby="gallery-heading">
                {filteredProducts?.length! > 0  || products?.length! > 0 || isLoading ? (
                  <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 2xl:grid-cols-4">
                    {(filteredProducts || products)?.map(product => (
                      <ProductListItem
                        key={product.id}
                        product={product}
                        activeProductId={activeProduct?.id}
                        handleProductClick={handleProductClick}
                      />
                    ))}
                  </ul>
                ) : (
                  <div className="text-center">
                    <h3 className="mt-2 text-sm font-medium text-gray-900">No products found</h3>
                    <p className="mt-1 text-sm text-gray-500">Try to change your search parameters.</p>
                  </div>
                )}
              </section>
            </div>
          </div>
          <ProductSidebar product={activeProduct} />
        </div>
      </div>
    </PageWrapper>
  );
}

export default ProductListAll;
