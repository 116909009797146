import {CalendarOutlined, CodeSandboxOutlined, EnvironmentOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import {Card, Col, Divider, Image, List, Row, Steps} from 'antd';
import {N} from 'components/N';
import {PageWrapper} from 'components/PageWrapper';
import {IMutationEnum, IQueryEnum, makeMutation, makeQueries} from 'core/api';
import {IAddress} from 'core/models/addresses/types';
import {IOrderItem} from 'core/models/orderItems/types';
import {IOrder, OrderStateEnum} from 'core/models/orders/types';
import {IProductItem} from 'core/models/productItems/types';
import {IProduct} from 'core/models/products/types';
import {getRetailer} from 'core/models/retailers/utils';
import {IShipment} from 'core/models/shipments/types';
import {IShippingPlans} from 'core/models/shippingPlans/types';
import {IState} from 'core/models/states/types';
import {pageUrls} from 'core/router/pages';
import moment from 'moment';
import {useContext, useEffect} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
import {dataLayerEvents} from 'utils/dataLayer';
import {getUrl} from 'utils/getUrl';
import imgPlaceHolder from '../../../assets/placeholder-image.png';
import constants from '../../../constants';
import {getOrderStatus} from './getOrderStatus';
import {Buffer} from 'buffer';

import './style.less';
import AuthStore from 'core/auth';

const {Step} = Steps;
interface IData {
  orders: Map<string, IOrder>;
  shipments: IShipment;
  orderItems: IOrderItem[];
  products: Map<string, IProduct>;
  addresses: Map<string, IAddress>;
  productItems: Map<string, IProductItem>;
  states: Map<string, IState>;
  shippingPlans: IShippingPlans[];
}

function OrderView() {
  const {retailer, isLoading} = getRetailer();
  const {isRetailer} = useContext(AuthStore);

  const history = useHistory();
  const {orderId} = useParams<{retailerId: string; orderId: string}>();
  const MIN_MATTRESSES = 5;
  const MATTRESSES_PRODUCT_CATEGORY_ID = 'ba78c27a-9a70-4753-8fd4-21fbc24e1914';

  useEffect(() => {
    if (!isLoading && retailer) {
      dataLayerEvents('view_orders_detail', retailer);
    }
  }, [retailer, isLoading]);

  const {
    data: {orders, orderItems, productItems, products, addresses, states, shippingPlans}
  } = makeQueries<IData>([
    {type: IQueryEnum.GetOrders, variables: {retailerId: retailer?.id}, mapKey: 'id', isEnabled: Boolean(retailer?.id)},
    {type: IQueryEnum.GetOrderItems, variables: {orderId, retailerId: retailer?.id}, isEnabled: Boolean(orderId)},
    {type: IQueryEnum.GetProducts, mapKey: 'id'},
    {type: IQueryEnum.GetAddresses, variables: {retailerId: retailer?.id}, mapKey: 'id', isEnabled: Boolean(retailer?.id)},
    {type: IQueryEnum.GetProductItems, mapKey: 'id'},
    {type: IQueryEnum.GetStates, mapKey: 'id'},
    {type: IQueryEnum.GetShippingPlans}
  ]);

  const order = orders?.get(orderId);

  const {
    data: {shipments: shipment}
  } = makeQueries<IData>([
    {
      type: IQueryEnum.GetShipment,
      variables: {orderId},
      isEnabled: Boolean(order?.state === OrderStateEnum.Delivering || order?.state === OrderStateEnum.Done)
    }
  ]);

  function getCurrentStep() {
    switch (order?.state) {
      case OrderStateEnum.Processing:
      case OrderStateEnum.Canceled:
        return 1;
      case OrderStateEnum.Fulfilling:
        return 2;
      case OrderStateEnum.Delivering:
        return 3;
      case OrderStateEnum.Done:
        return 4;
      default:
        return 0;
    }
  }
  const stateId = addresses?.get(order?.shippingAddressId!)?.stateId;
  const region = states?.get(stateId!)?.region;
  const filteredShippingPlans: any = shippingPlans?.filter(sp => sp.region === region);

  const mattressesQuantity = orderItems?.reduce((acc, item) => {
    const productCategory = products.get(item.productId || '')?.productCategoryId === MATTRESSES_PRODUCT_CATEGORY_ID;
    if (productCategory) {
      acc += item.quantity;
    }
    return acc;
  }, 0);

  function getEstimatedDate() {
    if (filteredShippingPlans?.length && mattressesQuantity >= MIN_MATTRESSES) {
      const sortedDates = filteredShippingPlans
        .map((sp: any) => moment().day(sp.shipDay).add(7, 'days').format('MM/DD/YYYY'))
        .map((date: string) => {
          return {date, days: moment(date).diff(moment(), 'days')};
        })
        .sort((a: any, b: any) => (a.days < b.days ? -1 : a.days > b.days ? 1 : 0));

      return `Your order is estimated to ship on ${sortedDates[0].date}`;
    }

    return 'Your order will be shipped soon';
  }

  const estimatedDate = getEstimatedDate();

  const cancelMutation = makeMutation({
    type: IMutationEnum.CancelOrder,
    loadingMsg: 'Canceling order...',
    successMsg: 'Order canceled'
  });

  function cancelOrder() {
    cancelMutation.mutate({id: order!.id});
  }

  return (
    <PageWrapper pageTitle={retailer?.name}>
      <Row>
        <Col span={14} className="pr5">
          <Card
            title={
              <Row justify="space-between">
                <h4>
                  <CodeSandboxOutlined className="mr5" />
                  <span>
                    №:&nbsp;<strong>{order?.reference}</strong>
                  </span>
                </h4>
                <h4>
                  <CalendarOutlined className="mr5" />
                  <span>{moment(order?.createOrderDate as string).format('MM/DD/YYYY')}</span>
                </h4>
              </Row>
            }
          >
            <div style={{display: 'flex'}}>
              <Steps direction="vertical" size="small" current={getCurrentStep()}>
                {order?.scheduledAt ?  <Step title={OrderStateEnum.Scheduled} /> :  <Step title={OrderStateEnum.Created} />}
                {order?.state === OrderStateEnum.Canceled ? (
                  <Step title={OrderStateEnum.Canceled} />
                ) : (
                  <>
                    <Step title={OrderStateEnum.Processing} />
                    <Step title={OrderStateEnum.Fulfilling} />
                    <Step title={OrderStateEnum.Delivering} />
                    <Step title={OrderStateEnum.Done} />
                  </>
                )}
              </Steps>
              <div>
                <div>
                  <EnvironmentOutlined className="mr5" />
                  <span>{addresses?.get(order?.shippingAddressId!)?.address}</span>
                </div>
                <Divider />
                <Row justify="space-between">
                  <span>Subtotal:</span>
                  <span>
                    <N value={order?.subtotalAmount} type="price" />
                  </span>
                </Row>
                <Row justify="space-between" className="mt10">
                  <span>Shipping:</span>
                  <N value={order?.shippingPrice || 0} type="price" />
                </Row>
                {order?.discount! > 0 && (
                  <Row justify="space-between" className="mt10">
                    <span>Discount:</span>
                    <span>
                      <N value={order?.discount! * -1} type="price" className="c-red" />
                    </span>
                  </Row>
                )}
                <Divider />
                <Row justify="space-between" className="mt10">
                  <strong>Order total</strong>
                  <strong>
                    <N value={order?.totalAmount!} type="price" />
                  </strong>
                </Row>
                {order?.relatedOrderId && (
                  <Row className="mt10">
                    <strong>This order has been split</strong>

                    <Link to={getUrl(pageUrls.orderView, {retailerId: retailer?.id, orderId: order?.relatedOrderId})}>
                      <span style={{color: 'blue'}}>
                        click here to review order{' '}
                        {order?.reference?.includes('2 of 2') || order?.reference?.includes('2-of-2') ? '1 of 2' : '2 of 2'}
                      </span>
                    </Link>
                  </Row>
                )}
              </div>
            </div>
          </Card>
        </Col>
        <Col span={10} className="pl5">
          <Card
            title="Order Status"
            style={{height: '100%'}}
            extra={
              <Row align="middle">
                <QuestionCircleOutlined className="mr5" />
                <h5
                  className="c-darkblue m0 cursor-pointer"
                  style={{textDecoration: 'underline'}}
                  onClick={() => history.push(pageUrls.support)}
                >
                  Get Help
                </h5>
              </Row>
            }
          >
            {getOrderStatus(order, shipment, estimatedDate, !isRetailer ? cancelOrder : undefined)}
          </Card>
        </Col>
      </Row>
      <Card className="mt10 order-detail-items">
        <List
          dataSource={orderItems?.filter(item => Boolean(products?.get(item.productId!)))}
          loading={!orderItems}
          grid={{xl: 4, gutter: 15}}
          renderItem={item => {
            const product = products?.get(item.productId!)!;
            const productItem = productItems?.get(item.productItemId)!;

            return (
              <List.Item>
                <Card
                  hoverable
                  title={product.title}
                  cover={
                    <Image
                      alt="example"
                      preview={false}
                      height={150}
                      style={{objectFit: 'cover', padding: 1}}
                      src={
                        product.images?.[0]
                          ? `${constants.image.resizer}?width=250&height=150&url=${Buffer.from(product.images?.[0]).toString('base64')}`
                          : imgPlaceHolder
                      }
                    />
                  }
                >
                  <div>
                    <Row>
                      <h4 className="m0">Size:</h4>
                      <h4 className="f-light c-darkblue m0 ml5">{productItem?.title || 'xxxx'}</h4>
                    </Row>
                    <Row>
                      <h4 className="m0">SKU:</h4>
                      <h4 className="f-light c-darkblue m0 ml5">{productItem?.sku || 'xxxx'}</h4>
                    </Row>
                    <Row>
                      <h4 className="m0">Price:</h4>
                      <h4 className="f-light c-darkblue m0 ml5">
                        <N value={item?.price} type="price" />
                      </h4>
                    </Row>
                    <Row>
                      <h4 className="m0">Quantity:</h4>
                      <h4 className="f-light c-darkblue m0 ml5">{item?.quantity}</h4>
                    </Row>
                    <Row>
                      <h4 className="m0">Amount:</h4>
                      <h4 className="f-light">
                        <N value={item?.amount} type="price" className="f-light c-darkblue m0 ml5" />
                      </h4>
                    </Row>
                  </div>
                </Card>
              </List.Item>
            );
          }}
        />
      </Card>
    </PageWrapper>
  );
}

export default OrderView;
