import {PlusOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import {PageWrapper} from 'components/PageWrapper';
import {Table} from 'components/Table';
import {IColumn} from 'components/Table/Table';
import {IQueryEnum, makeQueries} from 'core/api';
import {getRetailer} from 'core/models/retailers/utils';
import {IUser, UserRolesEnum} from 'core/models/users/types';
import {userRoleRenderer} from 'core/models/users/utils';
import {pageUrls} from 'core/router/pages';
import {memo, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {dataLayerEvents} from 'utils/dataLayer';
import {getUrl} from 'utils/getUrl';

interface IData {
  users: IUser[];
}

const RetailerUserList = memo(() => {
  const {retailer, isLoading: isRetailerLoading} = getRetailer();
  const history = useHistory();

  useEffect(() => {
    if (!isRetailerLoading && retailer) {
      dataLayerEvents('view_retailers_users', retailer);
    }
  }, [retailer, isRetailerLoading]);

  const {
    data: {users},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetUsers, variables: {roles: [UserRolesEnum.Owner, UserRolesEnum.Manager, UserRolesEnum.Returns], retailerId: retailer?.id}}
  ]);

  const onUserClick = (user: IUser) => {
    history.push(getUrl(pageUrls.retailerUserView, {userId: user.id, retailerId: retailer?.id}));
  };

  const columns: IColumn<IUser>[] = [
    {
      title: 'Name',
      render: (_, row) => (
        <>
          <span className="mr10">{row.firstName}</span>
          <span>{row.lastName}</span>
        </>
      ),
      width: '30%'
    },
    {
      title: 'Email',
      dataIndex: 'email',

      width: '30%'
    },
    {
      title: 'User Role',
      dataIndex: 'role',
      width: '15%',
      render: userRoleRenderer
    },
    {
      title: 'Stores count',
      dataIndex: 'storeIds',
      render: (ids: string[], user) => user.role === UserRolesEnum.Manager && ids?.length,
      width: '10%'
    }
  ];
  const onCreateClick = () => {
    history.push(getUrl(pageUrls.retailerUserCreate, {retailerId: retailer?.id}));
  };

  const headerActions = (
    <Button type="primary" icon={<PlusOutlined />} onClick={onCreateClick}>
      Create
    </Button>
  );

  return (
    <PageWrapper withRetailerSelector pageTitle="User Management" topMenuType='retailers' actions={headerActions}>
      <Table
        data={users}
        filterConfig={{fulltextParams: ['firstName', 'lastName', 'email'], fulltextPlaceholder: 'Search for an address'}}
        columns={columns}
        onRowClick={onUserClick}
        loading={isLoading}
      />
    </PageWrapper>
  );
});

export default RetailerUserList;
