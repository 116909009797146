import {Card, Form, Input} from 'antd';
import {IUser} from 'core/models/users/types';
import {memo} from 'react';

export interface IUserSettingProps {
  user?: IUser;
}

const UserCard: React.FC<IUserSettingProps> = memo(({user}) => {
  const [form] = Form.useForm();

  const formLayout = {
    labelCol: {span: 12},
    wrapperCol: {span: 23}
  };

  return (
    <Card>
      <Form
        {...formLayout}
        initialValues={{firstName: user?.firstName, role: user?.role, email: user?.email, lastName: user?.lastName}}
        layout="vertical"
        form={form}
        name="create-user"
      >
        <Form.Item name="firstName" label="First Name">
          <Input disabled />
        </Form.Item>
        <Form.Item name="lastName" label="Last Name">
          <Input disabled />
        </Form.Item>
        <Form.Item name="email" label="Email">
          <Input disabled />
        </Form.Item>
        <Form.Item name="role" label="User Role">
          <Input disabled />
        </Form.Item>
      </Form>
    </Card>
  );
});

export default UserCard;
