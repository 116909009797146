import {PageWrapper} from 'components/PageWrapper';
import {InboxOutlined} from '@ant-design/icons';
import {Button, Card, Form, Upload} from 'antd';
import {useState} from 'react';
import {normalizeFile} from 'utils/normalizeFile';
import {IMutationEnum, makeMutation} from 'core/api';

function SettingsUserCreateBulk() {
  const [form] = Form.useForm();
  const [file, setFile] = useState(null);

  const mutation = makeMutation({
    type: IMutationEnum.CreateUsers,
    loadingMsg: 'Creating users...',
    successMsg: 'Users are created.'
  });

  const handleUploaderChange = ({file}: any) => {
    setFile(file);
  };

  function onSubmit() {
    mutation.mutate({ file });
  }

  return (
    <PageWrapper pageTitle='Bulk User Create'>
      <Card>
        <Form
          layout="vertical"
          form={form}
          name="create-return"
          onFinish={onSubmit}
          className="w50"
        >
          <Form.Item label="Upload files" name="attachments" valuePropName="fileList" getValueFromEvent={normalizeFile}>
            <Upload.Dragger beforeUpload={()=> false} accept=".csv" maxCount={1} name="files" onChange={handleUploaderChange}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Upload the CSV file</p>
            </Upload.Dragger>
          </Form.Item>
          <Form.Item className="tar">
            <Button htmlType="submit" type="primary">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </PageWrapper>
  );
}

export default SettingsUserCreateBulk;
