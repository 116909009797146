import {List} from 'antd';
import {makeQueries, IQueryEnum} from 'core/api';
import {OrderStore} from 'core/stores/OrderStore';
import {IProductItem} from 'core/models/productItems/types';
import {IProduct} from 'core/models/products/types';
import {getRetailer} from 'core/models/retailers/utils';
import {FC} from 'react';
import OrderSummaryItem from './OrderSummaryItem';
import {IProductBrand} from 'core/models/productBrands/types';
import {IProductCategory} from 'core/models/productCategories/types';

interface IData {
  products: Map<string, IProduct>;
  productItems: Map<string, IProductItem>;
  productBrands: Map<string, IProductBrand>;
  productCategories: Map<string, IProductCategory>;
}
export interface IOrderSummary {
  typeOrder?: boolean;
}

const OrderSummaryList: FC<IOrderSummary> = ({typeOrder}) => {
  const {retailer} = getRetailer();
  const orderItems = OrderStore(({orderItems}) => orderItems);
  const SERENITY_PRODUCT = '7662e072-1de7-4709-aea1-f5690491c378';
  const {
    data: {products, productItems, productBrands, productCategories}
  } = makeQueries<IData>([
    {type: IQueryEnum.GetProducts, mapKey: 'id'},
    {type: IQueryEnum.GetProductItems, variables: {retailerId: retailer?.id}, mapKey: 'id', isEnabled: Boolean(retailer)},
    {type: IQueryEnum.GetProductBrands, mapKey: 'id'},
    {type: IQueryEnum.GetProductCategories, mapKey: 'id'}
  ]);
  const serenity = products.get(SERENITY_PRODUCT);
  const serenityProductItems = serenity?.productItemIds?.map(id => productItems?.get(id)!);

  return (
    <List
      dataSource={Array.from(orderItems.values())}
      renderItem={item => {
        const product = products.get(item.productId!);
        const productItem = productItems.get(item.productItemId);
        const productBrandName = productBrands.get(product?.productBrandId!)?.title;
        const productCategoryName = productCategories.get(product?.productCategoryId!)?.title;

        return (
            <OrderSummaryItem
              key={item.id}
              product={product}
              serenity={serenity}
              serenityProductItems={serenityProductItems}
              productItem={productItem}
              orderItem={item}
              productBrand={productBrandName}
              productCategory={productCategoryName}
              typeOrder={typeOrder}
            />
        );
      }}
    />
  );
};

export default OrderSummaryList;
