import {Checkbox, Form, Radio} from 'antd';
import {OrderStateEnum, OrderTypeEnum} from 'core/models/orders/types';
import {useHistory} from 'react-router-dom';

const OrderFilter = () => {
  const history = useHistory();
  const params: any = new URLSearchParams(history.location.search);
  const getState = params.get('state');
  const state: any = getState ? (getState?.includes(',') ? getState?.split(',') : [getState]) : getState;

  return (
    <>
      <Form.Item label="Type" name="type" initialValue={params.get('type')}>
        <Radio.Group buttonStyle="solid">
          {Array.from(Object.values(OrderTypeEnum)).map(orderType => (
            <Radio.Button key={orderType} value={orderType}>
              {orderType}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item name="state" label="State" initialValue={state}>
        <Checkbox.Group style={{display: 'grid', textTransform: 'capitalize'}}>
          {Array.from(Object.values(OrderStateEnum)).map(orderState => (
            <Checkbox key={orderState} value={orderState} style={{marginLeft: 0}}>
              {orderState}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </Form.Item>
    </>
  );
};

export default OrderFilter;
