import {gql} from 'graphql-tag';

export const GET_RETAILERS = gql`
  query getRetailers {
    getRetailers {
      id
      name
      ordersCount
      storesCount
      priceListId
      totalSalesAmount
      productCategoryIds
      productBrandIds
      creditAvailable
      creditLimit
      withCreditLimit
      website
      notes
      facebook
      instagram
      twitter
      youtube
      logoUrl
      billingAddressId
      salesManagerId
      productIds
      groupIds
    }
  }
`;
