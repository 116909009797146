import {pageUrls} from 'core/router/pages';
import {CalendarOutlined, HomeOutlined, QuestionCircleOutlined, PercentageOutlined, RetweetOutlined, SnippetsOutlined, AreaChartOutlined} from '@ant-design/icons';
import {Icon, IconTypeEnum, IconColorEnum} from 'components/Icon';
import { IAuthStore } from 'core/auth';

export interface ISidebarItem {
  title: string;
  url: string;
  retailerUrl?: string;
  icon?: JSX.Element;
}

function getAdminItems(): ISidebarItem[] {
  return [
    {
      title: 'Dashboard',
      icon: <HomeOutlined style={{fontSize: 20}} />,
      url: pageUrls.dashboard
    },
    {
      title: 'Clients',
      icon: <Icon type={IconTypeEnum.Clients} color={IconColorEnum.Blue} />,
      url: pageUrls.retailerList,
      retailerUrl: pageUrls.retailerGeneral
    },
    {
      title: 'Orders',
      icon: <Icon type={IconTypeEnum.Orders} color={IconColorEnum.Blue} />,
      url: pageUrls.orderRetailerList,
      retailerUrl: pageUrls.orderList
    },
    {
      title: 'Invoices',
      icon: <SnippetsOutlined style={{fontSize: 20}} />,
      url: pageUrls.invoiceRetailerList,
      retailerUrl: pageUrls.invoiceList
    },
    {
      title: 'Returns',
      icon: <RetweetOutlined style={{fontSize: 20}} />,
      url: pageUrls.returnListAll,
    },
    {
      title: 'Product Catalog',
      icon: <Icon type={IconTypeEnum.Catalog} color={IconColorEnum.Blue} />,
      url: pageUrls.productListAll
    },
    {
      title: 'Promo Builder',
      icon: <PercentageOutlined style={{fontSize: 20}} />,
      url: pageUrls.promoBuilderList
    },
    {
      title: 'Marketing Assets',
      icon: <Icon type={IconTypeEnum.Assets} color={IconColorEnum.Blue} />,
      url: pageUrls.assetList
    },
    {
      title: 'Reports',
      icon: <AreaChartOutlined color={IconColorEnum.Blue} style={{fontSize: 20}} />,
      url: pageUrls.reports
    },
    {
      title: 'Settings',
      icon: <Icon type={IconTypeEnum.Settings} color={IconColorEnum.Blue} />,
      url: pageUrls.settingsUserList
    },
    {
      title: 'Support',
      icon: <QuestionCircleOutlined style={{fontSize: 20}} />,
      url: pageUrls.support
    },
    {
      title: 'POP / Colonial',
      url: 'https://portal.colonialllc.com/portal/',
    }
  ];
}

function getSalesItems(): ISidebarItem[] {
  return [
    {
      title: 'Dashboard',
      icon: <HomeOutlined style={{fontSize: 20}} />,
      url: pageUrls.dashboard
    },
    {
      title: 'Clients',
      icon: <Icon type={IconTypeEnum.Clients} color={IconColorEnum.Blue} />,
      url: pageUrls.retailerList,
      retailerUrl: pageUrls.retailerGeneral,
    },
    {
      title: 'Orders',
      icon: <Icon type={IconTypeEnum.Orders} color={IconColorEnum.Blue} />,
      url: pageUrls.orderRetailerList,
      retailerUrl: pageUrls.orderList,
    },
    {
      title: 'Invoices',
      icon: <SnippetsOutlined style={{fontSize: 20}} />,
      url: pageUrls.invoiceRetailerList,
      retailerUrl: pageUrls.invoiceList
    },
    {
      title: 'Returns',
      icon: <RetweetOutlined style={{fontSize: 20}} />,
      url: pageUrls.returnRetailerList,
      retailerUrl: pageUrls.returnList,
    },
    {
      title: 'Product Catalog',
      icon: <Icon type={IconTypeEnum.Catalog} color={IconColorEnum.Blue} />,
      url: pageUrls.productListAll
    },
    {
      title: 'Marketing Assets',
      icon: <Icon type={IconTypeEnum.Assets} color={IconColorEnum.Blue} />,
      url: pageUrls.assetList
    },
    {
      title: 'Support',
      icon: <QuestionCircleOutlined style={{fontSize: 20}} />,
      url: pageUrls.support
    },
    {
      title: 'POP / Colonial',
      url: 'https://portal.colonialllc.com/portal/',
    }
  ];
}

function getOwnerItems(): ISidebarItem[] {
  return [
    {
      title: 'Dashboard',
      icon: <HomeOutlined style={{fontSize: 20}} />,
      url: pageUrls.dashboard
    },
    {
      title: 'Store Management',
      icon: <Icon type={IconTypeEnum.Clients} color={IconColorEnum.Blue} />,
      url: pageUrls.retailerGeneral
    },
    {
      title: 'Orders',
      icon: <Icon type={IconTypeEnum.Orders} color={IconColorEnum.Blue} />,
      url: pageUrls.orderList
    },
    {
      title: 'Invoices',
      icon: <SnippetsOutlined style={{fontSize: 20}} />,
      url: pageUrls.invoiceList
    },
    {
      title: 'Request A Credit',
      icon: <RetweetOutlined style={{fontSize: 20}} />,
      url: pageUrls.returnList
    },
    {
      title: 'Product Catalog',
      icon: <Icon type={IconTypeEnum.Catalog} color={IconColorEnum.Blue} />,
      url: pageUrls.productList
    },
    {
      title: 'Marketing Assets',
      icon: <Icon type={IconTypeEnum.Assets} color={IconColorEnum.Blue} />,
      url: pageUrls.assetList
    },
    {
      title: 'Schedule Training',
      icon: <CalendarOutlined style={{fontSize: 20}} />,
      url: 'https://calendly.com/TrainingTeam',
    },
    {
      title: 'Support',
      icon: <QuestionCircleOutlined style={{fontSize: 20}} />,
      url: pageUrls.support
    }
  ];
}

function getManagerItems(): ISidebarItem[] {
  return [
    {
      title: 'Marketing Assets',
      icon: <Icon type={IconTypeEnum.Assets} color={IconColorEnum.Blue} />,
      url: pageUrls.assetList
    },
    {
      title: 'Support',
      icon: <QuestionCircleOutlined style={{fontSize: 20}} />,
      url: pageUrls.support
    }
  ];
}

function getReturnsItems(): ISidebarItem[] {
  return [
    {
      title: 'Returns',
      icon: <RetweetOutlined style={{fontSize: 20}} />,
      url: pageUrls.returnRetailerList,
      retailerUrl: pageUrls.returnList,
    },
    {
      title: 'Support',
      icon: <QuestionCircleOutlined style={{fontSize: 20}} />,
      url: pageUrls.support
    }
  ];
}

export function getSidebarItems(auth: IAuthStore) {
  const {isAdmin, isSales, isOwner, isReturns} = auth;
  if (isAdmin) return getAdminItems();
  if (isSales) return getSalesItems();
  if (isOwner) return getOwnerItems();
  if (isReturns) return getReturnsItems();
  return getManagerItems();
}
