import {PageWrapper} from 'components/PageWrapper';
import {Table} from 'components/Table';
import {IQueryEnum, makeQueries} from 'core/api';
import {PlusOutlined} from '@ant-design/icons';
import {IUser, UserRolesEnum} from 'core/models/users/types';
import {useHistory} from 'react-router-dom';
import {Button} from 'antd';
import {pageUrls} from 'core/router/pages';
import {getUrl} from 'utils/getUrl';
import {IColumn} from 'components/Table/Table';
import {useEffect} from 'react';
import {userRoleRenderer} from 'core/models/users/utils';
import {getRetailer} from 'core/models/retailers/utils';
import {dataLayerEvents} from 'utils/dataLayer';

interface IData {
  users: IUser[];
}

function SettingsUserList() {
  const history = useHistory();
  const {retailer, isLoading: isRetailerLoading} = getRetailer();

  useEffect(() => {
    if (!isRetailerLoading && retailer) {
      dataLayerEvents('view_settings_users', retailer);
    }
  }, [retailer, isRetailerLoading]);

  const onItemClick = (userId: string) => {
    history.push(getUrl(pageUrls.settingsUserView, {userId}));
  };

  const onCreateClick = () => {
    history.push(pageUrls.settingsUserCreate);
  };

  const onBulkCreateClick = () => {
    history.push(pageUrls.settingsUserCreateBulk);
  };

  const {
    data: {users},
    isLoading
  } = makeQueries<IData>([{type: IQueryEnum.GetUsers, variables: {roles: [UserRolesEnum.Sales, UserRolesEnum.Admin]}}]);

  const columns: IColumn<IUser>[] = [
    {
      title: 'Name',
      render: (_, row) => (
        <>
          {row.firstName}&nbsp;{row.lastName}
        </>
      ),
      width: '30%'
    },
    {
      title: 'User Role',
      dataIndex: 'role',
      width: '15%',
      render: userRoleRenderer
    },
    {
      title: 'Email',
      dataIndex: 'email',

      width: '25%'
    },
    {
      title: 'Retailers count',
      render: (_, row) => (row.role === UserRolesEnum.Sales ? row.retailerIds?.length || 0 : ''),
      width: '15%'
    }
  ];

  const headerActions = (
    <div>
      <Button type="primary" icon={<PlusOutlined />} onClick={onCreateClick}>
        Create
      </Button>
      <Button type="primary" className={'ml10'} icon={<PlusOutlined />} onClick={onBulkCreateClick}>
        Bulk Create
      </Button>
    </div>
  );

  return (
    <>
      <PageWrapper pageTitle="User List" topMenuType='settings' actions={headerActions}>
        <Table
          data={users}
          filterConfig={{fulltextParams: ['firstName', 'lastName', 'email'], fulltextPlaceholder: 'Search for a user'}}
          columns={columns}
          loading={isLoading}
          onRowClick={record => {
            onItemClick(record.id);
          }}
        />
      </PageWrapper>
    </>
  );
}

export default SettingsUserList;
