import {gql} from 'graphql-tag';

export const GET_PRODUCT_ITEMS = gql`
  query getProductItems($retailerId: ID) {
    getProductItems(retailerId: $retailerId) {
      id
      productId
      sku
      title
      price
      unit
      outOfStock
      discount
      finalDiscountUSD
      productItemGroupId
      discountStartDate
      discountEndDate
      fMdiscount
      fMfinalDiscountUSD
      fMprice
      fMunit
      RXHAlert
      PHXAlert
    }
  }
`;

export const GET_ALL_PRODUCT_ITEMS = gql`
  query getAllProductItems($retailerId: ID!) {
    getAllProductItems(retailerId: $retailerId) {
      id
      title
      productId
    }
  }
`;
