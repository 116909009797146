import {Button, Card, Form, Input, InputNumber, Select, Tag} from 'antd';
import {IQueryEnum, makeQueries} from 'core/api';
import {IProductItem} from 'core/models/productItems/types';
import {IProduct} from 'core/models/products/types';
import {IReturn} from 'core/models/returns/types';
import {ReturnStore} from 'core/stores/ReturnStore';
import {memo} from 'react';
import {IReturnsCreateStep} from '../ReturnCreate';

const {Option} = Select;

interface IData {
  products: Map<string, IProduct>;
  productItems: Map<string, IProductItem>;
}

const ReturnsStep2: React.FC<IReturnsCreateStep> = memo(({prevStep, nextStep, formLayout}) => {
  const [form] = Form.useForm();
  const {updateReturn, returnItem} = ReturnStore.getState();

  const {
    data: {productItems = new Map(), products = new Map()},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetAllProductItems, variables: {retailerId: returnItem?.retailerId, specialVar: 'return'}, mapKey: 'id'},
    {type: IQueryEnum.GetProducts, mapKey: 'id'}
  ]);
  function onSubmit(values: Partial<IReturn>) {
    updateReturn(values);
    nextStep();
  }

  return (
    <Card>
      <Form
        layout="vertical"
        form={form}
        name="create-return"
        onFinish={onSubmit}
        initialValues={{
          productItemId: returnItem.productItemId,
          quantity: returnItem?.quantity,
          description: returnItem?.description
        }}
      >
        <Form.Item name="productItemId" label="Pick a product" rules={[{required: true}]}>
          <Select
            showSearch
            loading={isLoading}
            placeholder="Select a product"
            filterOption={(input, option: any) => {
              const label = option?.children?.props?.children[0] as string;
              return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            notFoundContent={null}
            allowClear
          >
            {Array.from(productItems.values()).map(productItem => {
              const product = products?.get(productItem.productId!);
              return (
                <Option key={productItem.id} value={productItem.id}>
                  {product ? (
                    <span>
                      {product.title}
                      <Tag className="ml10" color="green">
                        {productItem?.title}
                      </Tag>
                    </span>
                  ) : (
                    'Product is not available anymore'
                  )}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="quantity" label="Product quantity" rules={[{required: true, min: 1, type: 'number'}]}>
          <InputNumber />
        </Form.Item>
        <Form.Item name="description" label="Describe your issue" className='w60' rules={[{required: true}]} labelCol={{span: 6}}>
          <Input.TextArea />
        </Form.Item>
        <Form.Item wrapperCol={{span: 14}} className="tar">
          <Button htmlType="button" onClick={prevStep} className="mr10">
            Back
          </Button>
          <Button htmlType="submit" type="primary">
            Next
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
});

export default ReturnsStep2;
