import {gql} from 'graphql-tag';

export const CREATE_RETURN = gql`
  mutation createReturn($returnInput: ReturnCreateInput!) {
    createReturn(returnInput: $returnInput) {
      id
      retailerId
      productItemId
      issueType
      orderSO
      customer {
        name
        receiptNumber
        purchaseDate
      }
      quantity
      description
      isPickedByRetailer
      comments
      attachments {
        receipt
        damagedProduct
        lawTag
        bol
      }
      orderReference
      reference
      createdBy
      createdById
      state
      createdAt
    }
  }
`;

export const UPDATE_RETURN = gql`
  mutation updateReturn($returnUpdate: ReturnUpdateInput!) {
    updateReturn(returnUpdate: $returnUpdate) {
      id
      retailerId
      productItemId
      issueType
      orderSO
      customer {
        name
        receiptNumber
        purchaseDate
      }
      quantity
      description
      isPickedByRetailer
      comments
      attachments {
        receipt
        damagedProduct
        lawTag
        bol
      }
      orderReference
      reference
      processedReason
      createdBy
      state
      createdById
    }
  }
`;
