import {gql} from 'graphql-tag';

export const GET_LOGS = gql`
  query getLogs($filter: LogFilter) {
    getLogs(filter: $filter) {
      id
      titleHTML
      type
      userId
      retailerId
      descriptionHTML
      urlSlug
      createdAt
    }
  }
`;
