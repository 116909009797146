import {PageWrapper} from 'components/PageWrapper';
import {getAsset} from 'core/brandfolder/getAssets';
import {getRetailer} from 'core/models/retailers/utils';
import {pageUrls} from 'core/router/pages';
import AssetCover from 'partials/asset-view/AssetCover';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {dataLayerEvents} from 'utils/dataLayer';

import AssetSidebar from '../../partials/asset-view/AssetSidebar';
import {makeMutation, IMutationEnum} from 'core/api';

export default function AssetList() {
  const [analyticsSent, setAnalyticsSent] = useState(false);
  const {retailer, isLoading: isRetailerLoading} = getRetailer();
  const {assetId} = useParams<{assetId: string}>();

  const analyticsMutation = makeMutation({
    type: IMutationEnum.CreateAnalyticsRecord
  });

  useEffect(() => {
    if (!isRetailerLoading && retailer) {
      dataLayerEvents('view_asset_detail', retailer);
    }
  }, [retailer, isRetailerLoading]);

  const {data: asset} = getAsset(assetId);

  useEffect(() => {
    if (asset && !analyticsSent) {
      setAnalyticsSent(true);
      const analyticsCreateInput = {
        retailerId: retailer?.id,
        event: 'asset_view',
        data: {
          id: asset?.id,
          name: asset?.name,
          thumbnailUrl: asset?.thumbnailUrl
        }
      };

      analyticsMutation.mutate({analyticsCreateInput});
    }
  }, [asset, analyticsSent]);

  return (
    <PageWrapper pageTitle={`Asset "${asset?.name}"`} breadcrumbs={[{title: 'Marketing Assets', url: pageUrls.assetList}]}>
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="flex-1 flex items-stretch overflow-hidden">
          <div className="flex-1 overflow-y-auto">
            <div className="max-w-7xl mx-auto pr-4 sm:pr-6 pl-1">
              <section aria-labelledby="gallery-heading">
                <div className="block w-full aspect-w-10 aspect-h-7 overflow-hidden">
                  <AssetCover asset={asset} />
                </div>
              </section>
            </div>
          </div>
          <AssetSidebar asset={asset} />
        </div>
      </div>
    </PageWrapper>
  );
}
