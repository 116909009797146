import {PageWrapper} from 'components/PageWrapper';
import {IQueryEnum, makeQueries} from 'core/api';
import AuthStore from 'core/auth';
import {getAssets} from 'core/brandfolder';
import {IAsset} from 'core/brandfolder/types';
import {IProductBrand} from 'core/models/productBrands/types';
import {getRetailer} from 'core/models/retailers/utils';
import {useContext, useEffect} from 'react';
import {ArrayParam, NumberParam, StringParam, useQueryParam} from 'use-query-params';
import {dataLayerEvents} from 'utils/dataLayer';

import AssetsFilter from '../../partials/asset-list/AssetsFilter';
import AssetSidebar from '../../partials/asset-list/AssetSidebar';
import AssetsListItem from '../../partials/asset-list/AssetsListItem';

interface IData {
  productBrands?: IProductBrand[];
}

export default function AssetList() {
  const {isRetailer} = useContext(AuthStore);
  const {retailer, isLoading: isRetailerLoading} = getRetailer();
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [activeAssetId, setActiveAssetId] = useQueryParam<string>('activeAssetId', StringParam as any);
  const [nameFilter, setNameFilter] = useQueryParam<string>('name', StringParam as any);
  const [sectionFilter, setSectionFilter] = useQueryParam<string>('section', StringParam as any);
  const [brandsFilter, setBrandsFilter] = useQueryParam<string[]>('brands', ArrayParam as any);
  const [categoriesFilter, setCategoriesFilter] = useQueryParam<string[]>('categories', ArrayParam as any);
  const [typesFilter, setTypesFilter] = useQueryParam<string[]>('types', ArrayParam as any);
  const [extensionsFilter, setExtensionsFilter] = useQueryParam<string[]>('extensions', ArrayParam as any);

  useEffect(() => {
    if (!isRetailerLoading && retailer) {
      dataLayerEvents('view_assets', retailer);
    }
  }, [retailer, isRetailerLoading]);

  useEffect(() => {
    if (!page) {
      setPage(1);
    }
  }, [page, setPage]);

  const handleAssetClick = (asset: IAsset) => () => {
    setActiveAssetId(asset?.id);
  };

  const {
    data: {productBrands}
  } = makeQueries<IData>([{type: IQueryEnum.GetProductBrands, isEnabled: Boolean(retailer)}]);

  const allowedBrandIds =
    (isRetailer ? productBrands?.filter(brand => retailer?.productBrandIds && retailer.productBrandIds.includes(brand.id)) : [])
      ?.filter(brand => Boolean(brand.brandfolderId))
      .map(b => b.brandfolderId) || [];

  const {data, isLoading} = getAssets(
    {
      name: nameFilter,
      brands: brandsFilter || [],
      types: typesFilter,
      extensions: extensionsFilter,
      categories: categoriesFilter,
      section: sectionFilter
    },
    page,
    allowedBrandIds
  );
  const {assets, totalItems} = data || {};
  const activeAsset = assets?.find(asset => asset.id === activeAssetId);

  return (
    <PageWrapper pageTitle="Marketing Assets">
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="flex-1 flex items-stretch overflow-hidden">
          <div className="flex-1 overflow-y-auto">
            <div className="max-w-7xl mx-auto pr-4 sm:pr-6 pl-1">
              <AssetsFilter
                page={page || 1}
                setPage={setPage}
                nameFilter={nameFilter}
                setNameFilter={setNameFilter}
                sectionFilter={sectionFilter}
                setSectionFilter={setSectionFilter}
                brandsFilter={brandsFilter}
                setBrandsFilter={setBrandsFilter}
                categoriesFilter={categoriesFilter}
                setCategoriesFilter={setCategoriesFilter}
                totalItems={totalItems}
                typesFilter={typesFilter}
                setTypesFilter={setTypesFilter}
                extensionsFilter={extensionsFilter}
                setExtensionsFilter={setExtensionsFilter}
              />
              <section className="mt-8 pb-16" aria-labelledby="gallery-heading">
                {assets?.length! > 0 || isLoading ? (
                  <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 2xl:grid-cols-4">
                    {assets?.map(asset => (
                      <AssetsListItem key={asset.id} asset={asset} activeAssetId={activeAssetId} handleAssetClick={handleAssetClick} />
                    ))}
                  </ul>
                ) : (
                  <div className="text-center">
                    <h3 className="mt-2 text-sm font-medium text-gray-900">No assets found</h3>
                    <p className="mt-1 text-sm text-gray-500">Try to change your search parameters.</p>
                  </div>
                )}
              </section>
            </div>
          </div>
          <AssetSidebar asset={activeAsset} />
        </div>
      </div>
    </PageWrapper>
  );
}
