import {Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select, Switch} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {IMutationEnum, IQueryEnum, makeMutation, makeQueries} from 'core/api';
import {IGroup} from 'core/models/groups/types';
import {IProductItem} from 'core/models/productItems/types';
import {IProduct} from 'core/models/products/types';
import {BannerVariants, IPromoBuilder, UnitType} from 'core/models/promoBuilder/types';
import {pageUrls} from 'core/router/pages';
import moment from 'moment-timezone';
import {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {getUrl} from 'utils/getUrl';
import {PageWrapper} from '../../../components/PageWrapper';
// import {normalizeFile} from 'utils/normalizeFile';

interface IData {
  groups: Map<string, IGroup>;
  products: Map<string, IProduct>;
  productItems: Map<string, IProductItem>;
  promoBuilderList: Map<string, IPromoBuilder>;
}

function PromoEdit() {
  const [form] = Form.useForm();
  const [dateTime, setDateTime] = useState<any>();
  const [bannerImage, setBannerImage] = useState('');
  const [isUploaderVisible, setIsUploaderVisible] = useState<any>();
  const {promoId} = useParams<{promoId: string}>();
  const history = useHistory();
  // const {token} = useContext(AuthStore);
  const UPLOADER_TYPES = ['ORDER_TYPE', 'ORDER_REVIEW'];

  const {
    data: {groups, products, productItems, promoBuilderList},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetGroups, mapKey: 'id'},
    {type: IQueryEnum.GetProducts, mapKey: 'id'},
    {type: IQueryEnum.GetProductItems, mapKey: 'id'},
    {type: IQueryEnum.GetPromoBuilderList, mapKey: 'id'}
  ]);

  const promo: IPromoBuilder = promoBuilderList?.get(promoId)!;
  const group = groups?.get(promo?.groupId);

  const items = promo?.defaultItemsAvailable
    ? Array.from(Object.keys(promo.defaultItemsAvailable)).map((id: any) => {
        const currentItem = group?.type === 'Products' ? products?.get(id) : productItems?.get(id);

        return {
          title: currentItem?.title,
          defaultQuantity: promo?.defaultItemsAvailable[id],
          currentQuantity: promo?.currentItemsAvailable[id]
        };
      })
    : null;

  const mutation = makeMutation({
    type: IMutationEnum.CreateOrUpdatePromo,
    loadingMsg: 'Updating promo...',
    successMsg: 'Promo successfully Updated!'
  });

  useEffect(() => {
    if (mutation.isSuccess) {
      history.push(getUrl(pageUrls.promoBuilderList, {}));
    }
  }, [history, mutation]);

  useEffect(() => {
    if (promo?.promoBanner?.image) {
      setBannerImage(promo.promoBanner.image);
    }
    if (promo?.promoBanner?.types) {
      setIsUploaderVisible(promo.promoBanner.types);
    }
  }, [promo]);

  const handleSubmit = (values: any) => {
    let promoBuilderInput: any = {id: promo.id};
    for (let key in values) {
      if (key === 'expDate') {
        promoBuilderInput[key] = dateTime;
      } else if (!key.startsWith('quantity_')) {
        promoBuilderInput[key] = values[key];
      }
    }
    promoBuilderInput.promoBanner = {...values.promoBanner, image: bannerImage};

    mutation.mutate({promoBuilderInput});
  };

  // const uploaderProps = {
  //   name: 'file',
  //   accept: '.png, .svg, .webp',
  //   action: `${process.env.REACT_APP_API_URL}/files`,
  //   headers: {
  //     authorization: `Bearer ${token}`
  //   },
  //   onChange({file}: any) {
  //     if (file.status === 'error') {
  //       message.error(`${file.name} file upload failed.`);
  //     }
  //     if (file?.response?.fileUrl) {
  //       setBannerImage(file?.response?.fileUrl);
  //     }
  //     if (file.status === 'removed') {
  //       setBannerImage('');
  //     }
  //   }
  // };

  const onDateTimeChange = (value: any) => {
    setDateTime(moment.tz(value.format('YYYY-MM-DD HH:mm'), 'America/New_York'));
  };

  const setValue = (e: any) => {
    setIsUploaderVisible(e);
    if (!isShowUploader(isUploaderVisible)) {
      setBannerImage('');
    }
  };
  const isShowUploader = (values: BannerVariants[] | undefined) => values?.some(value => UPLOADER_TYPES.includes(value));

  return (
    <PageWrapper pageTitle={'Promo Builder Edit'}>
      <Card loading={isLoading}>
        <Form layout="vertical" form={form} name="filter" onFinish={handleSubmit}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name={'isActive'} label={'Active'} initialValue={promo?.isActive} valuePropName="checked">
                <Switch />
              </Form.Item>
              <Form.Item name={'offerTitle'} label={'Title'} initialValue={promo?.offerTitle} rules={[{required: true}]}>
                <Input placeholder="Promo title" />
              </Form.Item>
              <Form.Item name={'offerDescription'} label={'Description'} initialValue={promo?.offerDescription}>
                <TextArea rows={4} placeholder="Promo description" />
              </Form.Item>
              <p>Group: {group?.title}</p>
              <Row>
                <Form.Item name={'discount'} label={'Discount'} initialValue={promo?.discount} className="mr15" rules={[{required: true}]}>
                  <InputNumber placeholder="0" min={0} />
                </Form.Item>
                <Form.Item name={'unit'} label={'Unit'} initialValue={promo?.unit} rules={[{required: true}]}>
                  <Select>
                    <Select.Option value={UnitType.PERCENT}>%</Select.Option>
                    <Select.Option value={UnitType.USD}>$</Select.Option>
                  </Select>
                </Form.Item>
              </Row>
              <Form.Item
                initialValue={moment.tz(moment(promo?.expDate).format(), 'America/New_York')}
                name={'expDate'}
                label="Eastern Time (ET)"
                rules={[{required: true}]}
              >
                <DatePicker onChange={onDateTimeChange} showTime format="YYYY-MM-DD HH:mm" className="mr15" />
              </Form.Item>
              {items &&
                items.length > 0 &&
                items.map((item, i: number) => (
                  <p key={`item.title_${i}`}>
                    {item.title}: Available {item.currentQuantity} from {item.defaultQuantity}
                  </p>
                ))}
            </Col>
            <Col span={12}>
              <Form.Item name={['promoBanner', 'types']} initialValue={promo?.promoBanner?.types} label="Promo Placement">
                <Checkbox.Group onChange={setValue} className="mb10">
                  <Row>
                    <Col span={24}>
                      <Checkbox value={BannerVariants.TOP_BAR}>Nano bar</Checkbox>
                    </Col>
                    {/* <Col span={24}>
                      <Checkbox value={BannerVariants.ORDER_TYPE}>Show order type banner?</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value={BannerVariants.ORDER_REVIEW}>Show order review banner?</Checkbox>
                    </Col> */}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
              {/* {isShowUploader(isUploaderVisible) && (
                <Form.Item label="Banner" valuePropName="fileList" getValueFromEvent={normalizeFile}>
                  <Upload.Dragger {...uploaderProps} maxCount={1} name="files">
                    {bannerImage ? (
                      <img src={bannerImage} alt="logo" height="100" className="w100" />
                    ) : (
                      <>
                        <UserAddOutlined style={{fontSize: '50px'}} />
                        <p className="ant-upload-text">Drag & Drop your logo</p>
                      </>
                    )}
                  </Upload.Dragger>
                  <h5>*Accepted formats: .png .svg .webp</h5>
                </Form.Item>
              )} */}
            </Col>
          </Row>
          <Row justify="end" className="mt15">
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Row>
        </Form>
      </Card>
    </PageWrapper>
  );
}

export default PromoEdit;
