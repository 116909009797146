import {IdentificationIcon, ShoppingBagIcon, ArrowPathIcon, ChatBubbleBottomCenterIcon, QuestionMarkCircleIcon, AdjustmentsHorizontalIcon, CommandLineIcon, UserGroupIcon, ShoppingCartIcon} from '@heroicons/react/24/solid';
import { getClassName } from 'utils/getClassName';

export const logTypeRendered = (
  type: 'Account' | 'Order' | 'Return' | 'Support' | 'Retailer' | 'System' | 'Product' | 'Settings' | 'Other'
) => {
  const className = 'h-10 w-10 rounded-full items-center justify-center ring-8 ring-white p-2 text-white';

  switch (type) {
    case 'Account':
      return <IdentificationIcon className={getClassName(className, 'bg-indigo-600')}  />;
    case 'Order':
      return <ShoppingCartIcon className={getClassName(className, 'bg-green-600')} />;
    case 'Return':
      return <ArrowPathIcon className={getClassName(className, 'bg-red-600')} />;
    case 'Support':
      return <ChatBubbleBottomCenterIcon className={className} />;
    case 'Retailer':
      return <UserGroupIcon className={className} />;
    case 'System':
      return <CommandLineIcon className={className} />;
    case 'Product':
      return <ShoppingBagIcon className={className} />;
    case 'Settings':
      return <AdjustmentsHorizontalIcon className={className} />;
    default:
      return  <QuestionMarkCircleIcon className={className} />;
  }
};
