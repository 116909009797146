import {Steps as AntdSteps} from 'antd';
import {UserRolesEnum} from 'core/models/users/types';
import {memo, FC} from 'react';

const {Step} = AntdSteps;

export interface ISteps {
  currentStep: number;
  steps: IStep[];
  maxAllowedStep: number;
  onChange?: (step: number) => void;
  className?: string;
}

export interface IStep {
  title: string;
  content: JSX.Element;
  footer?: JSX.Element;
  allowedRoles?: UserRolesEnum[];
}

const Steps: FC<ISteps> = memo(({steps, currentStep, onChange, maxAllowedStep}) => {
  function getStepStatus(stepIndex: number) {
    if (stepIndex < currentStep) return 'finish';
    if (stepIndex === currentStep) return 'process';
    return 'wait';
  }

  return (
    <div>
      <AntdSteps type="navigation" size="small" current={currentStep} onChange={onChange} className="mb15">
        {steps.map(({title}, i) => (
          <Step key={title} title={title} disabled={i > maxAllowedStep} status={getStepStatus(Number(i))} />
        ))}
      </AntdSteps>
      {steps[currentStep].content}
      <div className="mt15">{steps[currentStep].footer}</div>
    </div>
  );
});

export default Steps;
