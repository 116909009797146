import Select from 'components/Select';
import {IQueryEnum, makeQueries} from 'core/api';
import {ILog} from 'core/models/logs/types';
import {logTypeRendered} from 'core/models/logs/utils';
import {IUser} from 'core/models/users/types';
import moment from 'moment-timezone';
import {useMemo, useState} from 'react';
import {Link} from 'react-router-dom';
import {unixToFromNow} from 'utils/unixToFromNow';

const timeOptions = [
  {value: 7, label: 'Last 7 days'},
  {value: 30, label: 'Last month'},
  {value: 0, label: 'All time'}
];

const logTypeOptions = [
  {value: '', label: 'All'},
  {value: 'Account', label: 'Account'},
  {value: 'Return', label: 'Return'},
  {value: 'Support', label: 'Support'},
  {value: 'Order', label: 'Order'}
];

interface IData {
  logs: ILog[];
}

export default function UserActivity({user}: {user?: IUser}) {
  const [dateFrom, setDteFrom] = useState(7);
  const [logType, setLogType] = useState<string>('');

  const memoizedDateFrom = useMemo(() => {
    return dateFrom ? moment().subtract(dateFrom, 'days').toISOString() : null;
  }, [dateFrom]);

  const {
    data: {logs},
    isLoading
  } = makeQueries<IData>([
    {
      type: IQueryEnum.GetLogs,
      variables: {
        filter: {userId: user?.id, types: logType ? [logType] : [], dateFrom: memoizedDateFrom}
      },
      isEnabled: Boolean(user)
    }
  ]);

  return (
    <aside className="hidden xl:order-first xl:flex xl:flex-col flex-shrink-0 w-96">
      <div className="flow-root px-6">
        <h2 className="text-lg font-semibold">User Activity</h2>
        <div className="flex flex-row">
          <Select options={timeOptions} label="Time period" value={dateFrom} onChange={setDteFrom} className="w-1/2 pr-1" />
          <Select options={logTypeOptions} label="Activity type" value={logType} onChange={setLogType} className="w-1/2 pl-1" />
        </div>
        {logs?.length > 0 ? (
          <ul className="mt-6">
            {logs.map((log, index) => (
              <li key={log?.id}>
                <div className="relative pb-8">
                  {index !== logs.length - 1 ? (
                    <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                  ) : null}
                  <div className="relative flex items-start space-x-3">
                    <div className="relative">{logTypeRendered(log.type)}</div>
                    <div className="min-w-0 flex-1">
                      <div>
                        <div className="text-sm">
                          <p className="font-medium text-gray-900" dangerouslySetInnerHTML={{__html: log.titleHTML}} />
                        </div>
                        {log.urlSlug && (
                          <Link to={log.urlSlug} className="font-medium text-cyan-600 mt-0.5 text-sm hover:text-cyan-900 underline">
                            View here
                          </Link>
                        )}
                        <p className="mt-0.5 text-sm text-gray-500">{unixToFromNow(log.createdAt)}</p>
                      </div>
                      <div className="mt-2 text-sm text-gray-700">
                        {log.descriptionHTML && <p dangerouslySetInnerHTML={{__html: log.descriptionHTML}} />}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : isLoading ? (
          <div className="shadow rounded-md p-4 max-w-sm w-full mx-auto mt-6">
            <div className="animate-pulse flex space-x-4">
              <div className="rounded-full bg-slate-200 h-10 w-10"></div>
              <div className="flex-1 space-y-6 py-1">
                <div className="h-2 bg-slate-200 rounded"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-200 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-200 rounded col-span-1"></div>
                  </div>
                  <div className="h-2 bg-slate-200 rounded"></div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center mt-6">
            <h3 className="mt-2 text-sm font-medium text-gray-900">No activity detected for current parameters</h3>
            <p className="mt-1 text-sm text-gray-500">Try to update filter.</p>
          </div>
        )}
      </div>
    </aside>
  );
}
