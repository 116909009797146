import {gql} from 'graphql-tag';

export const GET_STOCKS = gql`
  query getStocks {
    getStocks {
      id
      productItemId
      warehouse
      count
    }
  }
`;
