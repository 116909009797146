import {gql} from 'graphql-tag';

export const GET_INVOICES = gql`
  query getInvoices($retailerId: ID!) {
    getInvoices(retailerId: $retailerId) {
      id
      addressId
      orderId
      createDate
      number
      totalAmount
      state
    }
  }
`;
