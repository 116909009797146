/* eslint-disable @typescript-eslint/no-use-before-define */
import {N} from 'components/N';
import {Table} from 'components/Table';
import {IColumn} from 'components/Table/Table';
import {IQueryEnum, makeQueries} from 'core/api';
import {IInvoice} from 'core/models/invoices/types';
import {IOrder} from 'core/models/orders/types';
import {invoiceStateRenderer} from 'core/models/invoices/utils';
import {getRetailerId} from 'core/models/retailers/utils';
import moment from 'moment';

interface IData {
  invoices: IInvoice[];
  orders: Map<string, IOrder>;
}

export interface IInvoicesTableProps {
  onClick?: (invoice: IInvoice) => void;
  hide?: boolean;
}

export interface IFilter {
  type: string;
  state: string[];
  isFilter: boolean;
}

const InvoicesTable: React.FC<IInvoicesTableProps> = ({onClick, hide}) => {
  const retailerId = getRetailerId();

  const {
    data: {invoices, orders},
    isLoading
  } = makeQueries<IData>([
    {type: IQueryEnum.GetInvoices, variables: {retailerId}},
    {type: IQueryEnum.GetOrders, variables: {retailerId}, mapKey: 'id'}
  ]);

  const columns: Array<IColumn<IInvoice>> = [
    {
      title: 'Date',
      width: '10%',
      dataIndex: 'createDate',
      render: date => <div>{date ? moment(date).format('MM/DD/YYYY') : ''}</div>
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      sorter: {
        compare: (a, b) => b.totalAmount - a.totalAmount
      },
      render: (totalAmount: number) => (
        <p className="m0">
          <N value={totalAmount} type="price" />
        </p>
      ),
      width: '10%'
    },

    {
      title: 'State',
      dataIndex: 'state',
      render: invoiceStateRenderer,
      width: '10%'
    },
    {
      title: 'Number',
      dataIndex: 'number',
      width: '10%'
    },
    {
      title: 'Reference',
      dataIndex: 'orderId',

      render: (orderId: string) => <p className="m0">{orders?.get(orderId)?.reference || '----'}</p>,
      width: '10%'
    }
  ];

  return (
    <Table
      data={invoices}
      filterConfig={{fulltextParams: ['reference', 'number'], fulltextPlaceholder: 'Search for an invoice'}}
      columns={hide ? columns.filter(column => column.hide !== hide) : columns}
      loading={isLoading}
      onRowClick={onClick}
    />
  );
};

export default InvoicesTable;
